import { useEffect, useRef, useState } from 'react';
import './select_box.css';
import styled from '@emotion/styled';

type SelectBoxProps = {
    className?: string;
    options: SelectOptionsProps[];
    width?: number;
    height?: number;
    gap?: number;
    fontClass?: string;
    defaultValue?: number;
    isControllFromOuterComponent?: boolean;
    onSelect?: (event:React.MouseEvent<HTMLButtonElement, MouseEvent>, index?:number) => void;
}

type SelectOptionsProps = {
    label: string;
    value: string;
}

const space_text = " ";

const JeomsinSelectBox:React.FC<SelectBoxProps> = (
    props
) => {
    const [selectIndex, setSelectIndex] = useState<number | undefined>(props.defaultValue);

    useEffect(()=>{
        //외부 컴포넌트에서 컨트롤이 필요한 경우 DefaultValue로 컨트롤
        if(props.isControllFromOuterComponent) {
            setSelectIndex(props.defaultValue);
        }
    },[props.defaultValue]);

    let classNm = props.className ? space_text+props.className : "";
    let fontClsNm = props.fontClass ? props.fontClass : "ts16 bold6";

    return (
        <section className={"select-box-wrap" + classNm}>
            <div 
                className="select-content"
                style={{gap:props.gap}}
            >
            { 
                props.options.map((option, index) => (
                    <SelectButton
                    key={index}
                    className={fontClsNm + ((selectIndex == index) ? space_text + "selected" : "")}
                    style={{width:props.width, height:props.height}}
                    value={option.value}
                    onClick={(event) => {
                        //외부 컴포넌트에서 컨트롤이 필요한 경우 DefaultValue로 컨트롤
                        if(!props.isControllFromOuterComponent) {
                            setSelectIndex(index);
                        }

                        if(props.onSelect){
                            props.onSelect(event, index);
                        }
                    }}>{option.label}</SelectButton>
                ))
            }
            </div>
        </section>
    );
};

const SelectButton = styled("button")`
    cursor: pointer;
`

export default JeomsinSelectBox;