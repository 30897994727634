import FlexBox, { FlexAlignItemsType, FlexDirectionType } from "@Views/layer/flex_box/flex_box";
import css from "./guide_box.module.css";
import { Spacing, SpacingType } from "@Views/common_component/spacer/spacing";

type GuideBoxProps = {
  onClick?: () => void;
};

const GuideBox: React.FC<GuideBoxProps> = (props) => {
  return (
      <div className={css.modal_content}>
        <Spacing direction={SpacingType.vertical} gap={52}/>
        <FlexBox
          class={css.title_wrap}
          direction={FlexDirectionType.row}
          alignItems={FlexAlignItemsType.start}
          gap={6}
        >
          <img className={css.title_icon} src="images/mypage/notice.png" alt="icon"/>
          <div className={css.title}>간편결제등록 유의사항</div>
        </FlexBox>
        <div className={`${css.contents} mb40 pl24 pr24`}>
            <FlexBox 
              class={css.guide_item}
              direction={FlexDirectionType.row}
              alignItems={FlexAlignItemsType.start}
              gap={6}
            >
              <span>•</span>
              <FlexBox 
                class={css.guide_text_wrap}
                direction={FlexDirectionType.column}
                alignItems={FlexAlignItemsType.start}
              >
                <span className={css.main_text}>간편결제 카드 등록 개수 (1장)</span>
              </FlexBox>
            </FlexBox>
            <Spacing direction={SpacingType.vertical} gap={6}/>
            <FlexBox 
              class={css.guide_item}
              direction={FlexDirectionType.row}
              alignItems={FlexAlignItemsType.start}
              gap={6}
            >
              <span>•</span>
              <FlexBox 
                class={css.guide_text_wrap}
                direction={FlexDirectionType.column}
                alignItems={FlexAlignItemsType.start}
              >
                  <span className={css.main_text}>등록 가능 카드</span>
                  <span className={css.sub_text}>비씨, 국민, 외환, 삼성, 신한, 현대, 롯데, 한미, 신세계한미, 시티, 농협, 수협, 평화, 우리, 하나, 동남(주택), 주택, 조흥(강원), 축협(농협), 광주, 전북, 제주, 산업은행</span>
              </FlexBox>
            </FlexBox>
            <Spacing direction={SpacingType.vertical} gap={6}/>
            <FlexBox 
              class={css.guide_item}
              direction={FlexDirectionType.row}
              alignItems={FlexAlignItemsType.start}
              gap={6}
            >
              <span>•</span>
              <FlexBox 
                class={css.guide_text_wrap}
                direction={FlexDirectionType.column}
                alignItems={FlexAlignItemsType.start}
              >
                <span className={css.main_text}>카카오 카드의 경우 BC, 기업 등 제휴된 카드사로 표시됩니다.</span>
              </FlexBox>
            </FlexBox>
        </div>
        <div className={css.close_button} onClick={props.onClick}>
          <span>닫기</span>
        </div>
      </div>
    );
};

export { GuideBox };
