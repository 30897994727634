import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface Response {
  cnt_list: CountEntry[];
  result: boolean;
  TTL: string;
}

interface CountEntry {
  cnt_code: string;
  cnt: string;
}

const apiGetSNSContentCount = async (): Promise<Response | undefined> => {
  let request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetSNSContentCount,
    params: {},
  };

  let result: Response | undefined;
  try {
    result = (await JeomsinRequest(request)) as Response;
  } catch (_) {
    return undefined;
  }

  return result;
};

interface ZSnsCount {
  snsCount: Response | undefined;
  setSnsCount: (value: Response | undefined) => void;
}

const useSNSCountStore = create(
  persist<ZSnsCount>(
    (set) => ({
      snsCount: undefined,
      setSnsCount: (value: Response | undefined) => set({ snsCount: value }),
    }),
    {
      name: "sns-count-store",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export { apiGetSNSContentCount, useSNSCountStore };
export type { Response as SNSContentCountResponse };
