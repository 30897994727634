export const goToAppStore = (): { didFail: boolean } => {
  if (isAndroid()) {
    window.location.href = "intent://jeomsin#Intent;scheme=divination;package=handasoft.mobile.divination;end;";
    return { didFail: false };
  } else if (isIOS()) {
    //사파리에서는 앱 설치여부 판단 결과 앱이 없다면 "주소가 유효하지 않습니다"라는 alert창이 뜨고, 그에 대한 결과를 리턴 받을 수 없어 앱스토어와 앱이동이 명확하게 분리되지 않는 이슈가 있음.
    //alert 발생 시 blur로 판단되며, document hidden은 판단까지 약 2초가 소요됨
    //따라서, iOS에서는 단순 앱스토어 이동만 지원
    window.location.href = "https://itunes.apple.com/kr/app/jeomsin-2016/id960571015?mt=8";
    return { didFail: false };
  }

  return { didFail: true };
};

export const isAndroid = () => {
  return /android/i.test(navigator.userAgent);
};

export const isIOS = () => {
  return /ipad|iphone|ipod/i.test(navigator.userAgent);
};
