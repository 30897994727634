import { JeomsinPagePath } from "@Config/jeomsin_page_path";
import { Box } from '@mui/material';
import { Appbar, AppbarRightType } from "@Views/layer/appbar/appbar";
import { TitleBar, TitlebarType } from "@Views/layer/titlebar/titlebar";

export type HeaderProps = {
    type: JeomsinPageType;
    title?: string;
    rightIcons?: React.ReactNode;
    pageGuide?: JeomsinPagePath;
    tabIndex?: number;
    pageBackEvent?: () => void;
    fontSizeButtonPressed?: () => void;
    shareButtonPressed?: () => void;
}

const JeomsinHeader:React.FC<HeaderProps>  = ( props ) => {
    let headerInfo = getPageHeaderInfo(props.type, props.title);
    return (
        <Box>
            <Appbar showAppbar={headerInfo.showAppbar} appbarRightType={headerInfo.appbarRightType} tabIndex={props.tabIndex ?? 0}></Appbar>
            <TitleBar 
                type={headerInfo.titlebarType} 
                title={headerInfo.title}
                pageGuide={props.pageGuide}
                pageBackEvent={props.pageBackEvent} 
                fontSizeButtonPressed={props.fontSizeButtonPressed} 
                shareButtonPressed={props.shareButtonPressed}
                children={props.rightIcons}
            />
        </Box>
    );
}

interface IAppbarBarInfoProp {
    showAppbar : Boolean;
    appbarRightType : AppbarRightType;
    titlebarType : TitlebarType;
    title : string;
}

// Page 추가 시 작업
enum JeomsinPageType {
    MainTab,
    SajuInput,
    Sub,
    UnseResult,
    UnseResultNoShare,
    SubRightCustom,
    FullPopup,
    NoneButton,
    SajuMyeongsik,
    SubOnlyRightButton,
    None
}

// Page 추가 시 PageInfo 추가 추가
const getPageHeaderInfo = (type:JeomsinPageType, titleText?:string) => {
    let result: IAppbarBarInfoProp | null;

    switch(type){
        case JeomsinPageType.MainTab:
            result = { showAppbar:true, appbarRightType:AppbarRightType.MainTab,  titlebarType:TitlebarType.None, title: "" };
            break;
        case JeomsinPageType.SajuInput:
            result = { showAppbar:false, appbarRightType:AppbarRightType.None,  titlebarType:TitlebarType.SubPageNoButton, title: "사주정보 입력" };
            break;
        case JeomsinPageType.Sub:
            result = { showAppbar:false, appbarRightType:AppbarRightType.None,  titlebarType:TitlebarType.SubPageNoButton, title: titleText ?? "" };
            break;
        case JeomsinPageType.UnseResult:
            result = { showAppbar:false, appbarRightType:AppbarRightType.None,  titlebarType:TitlebarType.SubPageTwoButton, title: titleText ?? "" };
            break;
        case JeomsinPageType.UnseResultNoShare:
            result = { showAppbar:false, appbarRightType:AppbarRightType.None,  titlebarType:TitlebarType.SubPageOneButton, title: titleText ?? "" };
            break;
        case JeomsinPageType.SubRightCustom:
            result = { showAppbar:false, appbarRightType:AppbarRightType.None,  titlebarType:TitlebarType.SubPageRightCustom, title: titleText ?? "" };
            break;
        case JeomsinPageType.FullPopup:
            result = { showAppbar:false, appbarRightType:AppbarRightType.None,  titlebarType:TitlebarType.FullPopup, title: titleText ?? "" };
            break;
        case JeomsinPageType.NoneButton:
            result = { showAppbar:false, appbarRightType:AppbarRightType.None,  titlebarType:TitlebarType.NoneButton, title: titleText ?? "" };
            break;
        case JeomsinPageType.SajuMyeongsik:
            result = { showAppbar:true, appbarRightType:AppbarRightType.None,  titlebarType:TitlebarType.SajuMyeongsik, title: titleText ?? "" };
            break;
        case JeomsinPageType.SubOnlyRightButton:
            result = { showAppbar:true, appbarRightType:AppbarRightType.None,  titlebarType:TitlebarType.SubOnlyRightButton, title: titleText ?? "" };
            break;
        case JeomsinPageType.None:
        default:
            result = { showAppbar:false, appbarRightType:AppbarRightType.None,  titlebarType:TitlebarType.None, title: "" };
            break;
    }

    return result;
}

export {
    JeomsinHeader,
    JeomsinPageType
};