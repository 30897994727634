/** @jsxImportSource @emotion/react */

import {
  ModalPopupUIProps,
  useModalPopup,
} from "@Views/common_component/async_modal_popup/async_modal_popup";
import apiCheckCombineSnsSign from "@Network/request/api_check_combine_sns_sign";
import {
  apiCombineMemberLogin,
  IdType,
  IdTypes,
} from "@Network/request/api_combine_member_login";
import apiGetSajuList, {
  MemberManseInfo,
} from "@Network/request/api_get_saju_list";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useUserInfoStore } from "@State/user_info_store";
import CookieKeys from "@Storage/cookie_keys";
import CookieManager from "@Storage/cookie_manager";
import {
  createContext,
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  CombineMemberModel,
  JeomsinLoginMemberInfo,
  JeomsinUserInfo,
} from "@DataDefine/jeomsin_user_info";
import { JeomsinPagePath } from "@Config/jeomsin_page_path";
import { useLocation, useNavigate } from "react-router";
import apiMemberSNSTokenUpdate, {
  MemberSNSTokenUpdateResponse,
} from "@Network/request/api_member_sns_token_update";
import apiCombineMemberSign from "@Network/request/api_combine_member_sign";
import { create } from "zustand";
import { v4 } from "uuid";
import { LoginHandlerResponse } from "@Views/member/login_handler/login_handler";
import { useToastPopupStore } from "@Views/common_component/toast_popup/toast_popup_manager";
import { persist } from "zustand/middleware";
import useHandleUserAutoLogin from "./use_handle_auto_login";
import { get_naver_userprofile } from "@Network/naver_user_profile";
import { css } from "@emotion/react";
import RequirePhoneNumber from "@Views/member/require_phone_number/require_phone_number";
import useAsyncPage from "@Views/common_component/async_page/async_page_setter";

const buttons = ["점신 시작하기", "기존 회원 로그인하기"] as const;
const snsButtons = ["naver", "kakao", "google", "apple"] as const;
const kakaoClientId = process.env.REACT_APP_KAKAO_CLIENT_ID;

export const callbackURLDomain = window.location.protocol + "//" + window.location.host;

interface LoginState {
  token: undefined | string;
  setToken: (token?: string) => void;
}

const useLoginStateStore = create(
  persist<LoginState>(
    (set, get) => ({
      token: undefined,
      setToken: (token) => set({ token }),
    }),
    {
      name: "login-state-token",
      // TODO: Change to session storage
    }
  )
);

const useShowMemberIntegratedWelcomeDialog = () =>
  useContext(ShowMemberIntegratedWelcomeDialogContext);

export const ShowMemberIntegratedWelcomeDialogContext = createContext({
  showMemberIntegratedWelcomeDialog: async (
    showOnboading?: boolean,
    snsConnectionTitle?: string
  ) => {},
  showSNSConnectionDialog: async (
    referrer?: string,
    customDescription?: string
  ) => {},
  triggerSecondStep: async (
    userInfo: JeomsinUserInfo | undefined,
    phoneNumber: string | undefined,
    isYnIdType: boolean
  ) => {},
  logout: async () => {},
});

export const useMemberIntegratedState = create<{
  isSign: boolean | undefined;
  setSign: (value: boolean | undefined) => void;
  isRegistered: boolean | undefined;
  setRegistered: (value: boolean | undefined) => void;
  idType: IdType | undefined;
  setIdType: (value: IdType | undefined) => void;
  idCode: string | undefined;
  setIdCode: (value: string | undefined) => void;
  memMobile: string | undefined;
  setMemMobile: (value: string | undefined) => void;
  didUpdate: boolean | undefined;
  setDidUpdate: (value: boolean | undefined) => void;
  referrer: string | undefined;
  setReferrer: (value: string | undefined) => void;
  destination: string | undefined;
  setDestination: (value: string | undefined) => void;
}>((set) => ({
  isSign: undefined,
  setSign: (isSign) =>
    set((state) => ({
      ...state,
      isSign,
    })),
  isRegistered: undefined,
  setRegistered: (isRegistered) =>
    set((state) => ({
      ...state,
      isRegistered,
    })),
  idType: undefined,
  setIdType: (idType) =>
    set((state) => ({
      ...state,
      idType,
    })),
  idCode: undefined,
  setIdCode: (idCode) =>
    set((state) => ({
      ...state,
      idCode,
    })),
  memMobile: undefined,
  setMemMobile: (memMobile) =>
    set((state) => ({
      ...state,
      memMobile,
    })),
  didUpdate: undefined,
  setDidUpdate: (didUpdate) =>
    set((state) => ({
      ...state,
      didUpdate,
    })),
  referrer: undefined,
  setReferrer: (referrer) =>
    set((state) => ({
      ...state,
      referrer,
    })),
  destination: undefined,
  setDestination: (destination) =>
    set((state) => ({
      ...state,
      destination,
    })),
}));

export function useShowMemberIntegratedWelcomeDialogInternal() {
  const { setModalPopupContent } = useModalPopup();
  const { setToastPopupContent } = useToastPopupStore();
  const {
    setRepresentativeUserInfo,
    setUserInfoList,
    representativeUserInfo,
    userInfoList,
    setLoginMemberInfo,
    setFullySignedIn,
    loginMemberInfo,
  } = useUserInfoStore();

  const navigate = useNavigate();
  const location = useLocation();
  const path = useRef(location.pathname);

  const {
    isSign,
    setSign,
    isRegistered,
    setRegistered,
    idType,
    setIdType,
    idCode,
    setIdCode,
    memMobile,
    setMemMobile,
    didUpdate,
    setDidUpdate,
    setReferrer,
    destination,
    setDestination,
  } = useMemberIntegratedState();

  const isSignWrapper = useRef(isSign);
  const setSignWrapper = useRef(setSign);
  const isRegisteredWrapper = useRef(isRegistered);
  const setRegisteredWrapper = useRef(setRegistered);
  const idTypeWrapper = useRef(idType);
  const setIdTypeWrapper = useRef(setIdType);
  const idCodeWrapper = useRef(idCode);
  const setIdCodeWrapper = useRef(setIdCode);
  const memMobileWrapper = useRef(memMobile);
  const setMemMobileWrapper = useRef(setMemMobile);
  const didUpdateWrapper = useRef(didUpdate);
  const setDidUpdateWrapper = useRef(setDidUpdate);
  const setReferrerWrapper = useRef(setReferrer);
  const destinationWrapper = useRef(destination);
  const setDestinationWrapper = useRef(setDestination);

  const modalPopupSetter = useRef(setModalPopupContent);
  const toastPopupContentSetter = useRef(setToastPopupContent);
  const representativeUserInfoSetter = useRef(setRepresentativeUserInfo);
  const representativeUserInfoGetter = useRef(representativeUserInfo);
  const userInfoListGetter = useRef(userInfoList);
  const userInfoListSetter = useRef(setUserInfoList);
  const navigateWrapper = useRef(navigate);
  const setLoginMemberInfoWrapper = useRef(setLoginMemberInfo);
  const setFullySignedInWrapper = useRef(setFullySignedIn);

  const { token, setToken } = useLoginStateStore();
  const { wipeout } = useHandleUserAutoLogin();

  useEffect(() => {
    // 아래 항목들은 useEffect 혹은 useCallback 훅 내에서 사용하는 프로퍼티들이다.
    // 하기 프로퍼티는 값이 수정되었을 경우, 변경된 값에 대한 반영이 필요하나,
    // 변경되었다고 해서 로직 재실행을 할 필요는 없다. 그런 이유로 useRef로 묶어 사용하고 있다.
    // 하기 코드는 값이 변경되었을 때, reference 내 묶인 값을 업데이트하는 로직.

    path.current = location.pathname;
    isSignWrapper.current = isSign;
    setSignWrapper.current = setSign;
    isRegisteredWrapper.current = isRegistered;
    setRegisteredWrapper.current = setRegistered;
    idTypeWrapper.current = idType;
    setIdTypeWrapper.current = setIdType;
    idCodeWrapper.current = idCode;
    setIdCodeWrapper.current = setIdCode;
    memMobileWrapper.current = memMobile;
    setMemMobileWrapper.current = setMemMobile;
    didUpdateWrapper.current = didUpdate;
    setDidUpdateWrapper.current = setDidUpdate;
    setReferrerWrapper.current = setReferrer;
    destinationWrapper.current = destination;
    setDestinationWrapper.current = setDestination;
    modalPopupSetter.current = setModalPopupContent;
    toastPopupContentSetter.current = setToastPopupContent;
    representativeUserInfoSetter.current = setRepresentativeUserInfo;
    representativeUserInfoGetter.current = representativeUserInfo;
    userInfoListGetter.current = userInfoList;
    userInfoListSetter.current = setUserInfoList;
    navigateWrapper.current = navigate;
    setLoginMemberInfoWrapper.current = setLoginMemberInfo;
    setFullySignedInWrapper.current = setFullySignedIn;
  }, [
    location.pathname,
    isSign,
    setSign,
    isRegistered,
    setRegistered,
    idType,
    setIdType,
    idCode,
    setIdCode,
    memMobile,
    setMemMobile,
    didUpdate,
    setDidUpdate,
    setReferrer,
    destination,
    setDestination,
    navigate,
    setLoginMemberInfo,
    setFullySignedIn,
    setModalPopupContent,
    setToastPopupContent,
    setRepresentativeUserInfo,
    representativeUserInfo,
    userInfoList,
    setUserInfoList,
  ]);

  const resetState = useRef(() => {
    setSignWrapper.current(undefined);
    setRegisteredWrapper.current(undefined);
    setIdTypeWrapper.current(undefined);
    setIdCodeWrapper.current(undefined);
    setMemMobileWrapper.current(undefined);
    setDidUpdateWrapper.current(undefined);
    setReferrerWrapper.current(undefined);
    setDestinationWrapper.current(undefined);
  });

  const loginSuccessAction = useCallback(
    (combineData: JeomsinLoginMemberInfo, userName: string) => {
      CookieManager.SetBoolean(CookieKeys.statusCombineMember, true);

      let memInfo = combineData.mem_info!;
      memInfo.mem_myname = userName;

      const nameInjectedInfo: JeomsinLoginMemberInfo = {
        mem_info: memInfo,
        mem_item: combineData.mem_item,
        ...combineData,
      };

      CookieManager.SetJSON(CookieKeys.combineMemData, { ...nameInjectedInfo });
      setLoginMemberInfoWrapper.current({ ...nameInjectedInfo });
      setFullySignedInWrapper.current(true);
    },
    []
  );

  const { setPageContent } = useAsyncPage();

  const triggerSecondStep = useCallback(
    async (
      userInfo: JeomsinUserInfo | undefined,
      phoneNumber: string | undefined,
      isYnIdType: boolean
    ) => {
      const idType = idTypeWrapper.current;
      const idCode = idCodeWrapper.current;
      const memMobile = phoneNumber ?? memMobileWrapper.current;
      const isRegistered = isRegisteredWrapper.current;
      const isSign = isSignWrapper.current;
      const destination = destinationWrapper.current;

      if (
        idType !== undefined &&
        idCode !== undefined &&
        memMobile !== undefined
      ) {
        // 로그인 로직을 실행하기 이전, 화면이 재렌더링이 되더라도 코드가 재실행되지 않게 리셋을 먼저 세팅.
        resetState.current();

        if (isYnIdType) {
          // 이미 SNS 계정이 가입된 이력이 있는 경우이다. 현재 가입하고자 하는 SNS로 이관을 진행한다.
          let response: MemberSNSTokenUpdateResponse;

          try {
            response = await apiMemberSNSTokenUpdate(idCode, idType, memMobile);
          } catch {
            toastPopupContentSetter.current(
              "정회원 로그인이 실패했어요.\n다시 시도해주세요."
            );
            return;
          }

          const memNo = response.mem_info.mem_no;
          const sajuList = await apiGetSajuList(memNo);

          if (sajuList.length > 0) {
            const userDecisionButtons = ["취소하기", "로그인하기"] as const;

            // 현재 로컬에 남아있는 정보가 있을 경우, 먼저 유저에게 물어본다.
            if ((userInfoListGetter.current?.length ?? 0) > 0) {
              const decision =
                userDecisionButtons[
                  await modalPopupSetter.current({
                    title: "이미 가입된 계정이에요.",
                    description:
                      "선택 계정으로 로그인하실 경우,\n지금까지 이용한 정보는 모두 지워져요.\n선택 계정으로 로그인하시겠어요?",
                    buttonTitles: [...userDecisionButtons],
                    buttonStackDirection: "row",
                    isSecondButtonColored: true,
                  })
                ];

              if (decision === "취소하기") {
                return;
              }
            }

            const userInfos = sajuList
              .map((saju) => memberManseInfoToJeomsinUserInfo(saju))
              .sort((a, b) => a.localId - b.localId);

            loginSuccessAction(response, userInfos.at(0)?.name ?? "");

            userInfoListSetter.current(userInfos);
            representativeUserInfoSetter.current(userInfos.at(0));

            if (destination) {
              navigateWrapper.current(-1); //SNS 로그인이 이뤄졌고, 히스토리 백이 필요한 경우
            }
            // Login Success
            return;
          } else {
            //일반페이지 -> 점신 시작하기 -> SNS 로그인 -> 정회원인데 사주정보가 없는 경우
            //일반페이지 -> SNS 로그인 -> 정회원인데 사주정보가 없는 경우
            //일반 페이지로 복귀를 위해 replace 적용
            setReferrerWrapper.current(path.current);
            setSignWrapper.current(isSign);
            setRegisteredWrapper.current(isRegistered);
            setIdTypeWrapper.current(idType);
            setIdCodeWrapper.current(idCode);
            navigateWrapper.current(JeomsinPagePath.SajuModify, {
              state: {
                menuInfo: "대표 사주정보 입력",
              },
              replace: path.current === JeomsinPagePath.SajuInput ? true : false
            });
            return;
          }
        } else {
          const user = userInfo ?? representativeUserInfoGetter.current;

          // 비회원의 경우, 회원가입을 진행하고 로그인 시켜준다.
          // 회원가입의 경우, 사주명식을 반드시 기입하고 아래 로직을 진행한다.
          const response = await apiCombineMemberSign(
            idCode,
            idType,
            memMobile,
            user!
          );

          if (response.mem_info === undefined) {
            setToastPopupContent(`기존에 저장된 정보와 일치하지 않습니다.\n다시 로그인 해주세요.`);
            return;
          }

          loginSuccessAction(
            response as { mem_info: CombineMemberModel; mem_item: any },
            response.mem_saju.mem_myname
          );

          const memberInfoFromServer = memberManseInfoToJeomsinUserInfo(
            response.mem_saju
          );
          representativeUserInfoSetter.current(memberInfoFromServer);

          const popFree3Min = response.mem_item.pop_free_3min;
          if (popFree3Min !== undefined) {
            if (popFree3Min === "Y") {
              setToastPopupContent(`SNS 연동 완료 / 정회원 가입이 완료 되었으며\n3분 무료 상담 쿠폰이 발급 되었어요.`);
            }
          }

          if (destination) {
            navigateWrapper.current(-1); //히스토리 백이 필요한 경우
          }
        }
      }
    },
    [loginSuccessAction]
  );

  const snsLogin = useCallback(
    async (idCode: string, idType: IdType, explicitPath?: string) => {
      let response = await apiCheckCombineSnsSign(idCode, idType);

      if (response === undefined) {
        toastPopupContentSetter.current("로그인 실패");
        return;
      }

      const userName = response.mem_myname;
      const isNameNotEmpty = userName.length > 0;
      // If the member name is not set to true or false and the member name is not empty, we consider the account to be registered.
      const isRegistered = isNameNotEmpty
        ? userName === "true" || userName === "false"
          ? false
          : true
        : false;
      const isSign = response.sign === "Y";

      if (isSign && isRegistered) {
        // if the user is signed in and the name is set
        const combineLogin = await apiCombineMemberLogin(idCode, idType);

        if (combineLogin === undefined) {
          toastPopupContentSetter.current(
            "정회원 로그인이 실패했어요.\n다시 시도해주세요."
          );

          resetState.current();
          return;
        }

        const memNo = combineLogin.mem_info.mem_no;
        const sajuList = await apiGetSajuList(memNo);

        if (sajuList.length > 0) {
          const userDecisionButtons = ["취소하기", "로그인하기"] as const;

          // 현재 로컬에 남아있는 정보가 있을 경우, 먼저 유저에게 물어본다.
          if ((userInfoListGetter.current?.length ?? 0) > 0) {
            const decision =
              userDecisionButtons[
                await modalPopupSetter.current({
                  title: "이미 가입된 계정이에요.",
                  description:
                    "선택 계정으로 로그인하실 경우,\n지금까지 이용한 정보는 모두 지워져요.\n선택 계정으로 로그인하시겠어요?",
                  buttonTitles: [...userDecisionButtons],
                  buttonStackDirection: "row",
                  isSecondButtonColored: true,
                })
              ];

            if (decision === "취소하기") {
              resetState.current();
              return;
            }
          }

          loginSuccessAction(combineLogin, userName);

          const newSajuMyeonsikList = sajuList
            .map((info) => {
              return memberManseInfoToJeomsinUserInfo(info);
            })
            .sort((a, b) => a.localId - b.localId);

          userInfoListSetter.current(newSajuMyeonsikList);
          representativeUserInfoSetter.current(newSajuMyeonsikList.at(0));

          resetState.current();

          //popup 점신시작하기 버튼 누르기 or 점신시작하기에서 explicitPath를 세팅한경우
          if (explicitPath) {
            setReferrerWrapper.current(explicitPath);
            setIdTypeWrapper.current(idType);
            setIdCodeWrapper.current(idCode);
            navigateWrapper.current(-1); 
            return;
          }

          const destination = destinationWrapper.current;

          if (destination) {
            navigateWrapper.current(-1);
            setDestinationWrapper.current(undefined);
            return;
          }

          return;
          // Login Success
        }
      } else if (isSign && isRegistered === false) {
        setReferrerWrapper.current(explicitPath ?? path.current);
        setSignWrapper.current(isSign);
        setRegisteredWrapper.current(isRegistered);
        setIdTypeWrapper.current(idType);
        setIdCodeWrapper.current(idCode);

        navigateWrapper.current(JeomsinPagePath.SajuModify, {
          state: {
            menuInfo: "대표 사주정보 입력",
          },
          replace: path.current === JeomsinPagePath.SajuInput ? true : false
        });
        return;
      } else {
        // 아예 회원가입이 되지 않은 유저, 이런 유저는 먼저 휴대전화 번호를 요구한다.
        setReferrerWrapper.current(explicitPath ?? path.current);
        setIdTypeWrapper.current(idType);
        setIdCodeWrapper.current(idCode);

        // 휴대전화 인증 마무리 이후 홈으로 갈 수 있도록 설정해준다.
        if (explicitPath !== undefined) {
          setDestinationWrapper.current(explicitPath);
        }

        const removalId = v4();

        const requirePhoneNumberResponse:
          | {
              representativeUserInfo: JeomsinUserInfo;
              phoneNumber: string | undefined;
              isYnIdType: boolean;
            }
          | undefined = await setPageContent(
          removalId,
          <RequirePhoneNumber removalId={removalId} />
        );

        if (requirePhoneNumberResponse) {
          const { representativeUserInfo, phoneNumber, isYnIdType } =
            requirePhoneNumberResponse;
          await triggerSecondStep(
            representativeUserInfo,
            phoneNumber,
            isYnIdType
          );
        }

        return;
      }
    },
    [loginSuccessAction, setPageContent, triggerSecondStep]
  );

  const idResolver = useRef<
    | { resolve: (uniqueId: string) => void; reject: (reason: string) => void }
    | undefined
  >();
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse: any) => {
      try {
        const response = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );

        const raw = (await response.json()) as { sub: string };
        const googleUniqueId = raw.sub;
        idResolver.current?.resolve(googleUniqueId);
        idResolver.current = undefined;
      } catch (error) {
        idResolver.current?.reject((error as Error).message);
      }
    },
    onError: async (error) => {
      idResolver.current?.reject(error.error_description ?? "");
    },
  });

  const { requestUniqueIdFromNaver, requestUniqueIdFromKakao } = useSNSLogin();

  return {
    logout: async () => {
      if (
        loginMemberInfo === undefined ||
        loginMemberInfo.mem_info === undefined
      ) {
        return;
      }

      const idTypeRaw = loginMemberInfo.mem_info.id_type;
      const keys = Object.keys(IdTypes) as Array<keyof typeof IdTypes>;
      const sns = keys
        .filter((eachKey) => String(idTypeRaw) === `${IdTypes[eachKey].sendableIdType}`)
        .at(0);

      if (sns === undefined) {
        return;
      }

      switch (sns) {
        case "Apple":
          //로그아웃 API가 없으므로 별도 작업을 수행하지 않음
          break;
        case "Google":
          googleLogout();
          break;
        case "Kakao":
          if (token === undefined) {
            break;
          }

          await fetch(`https://kapi.kakao.com/v1/user/logout`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setToken(undefined);
          break;
        case "Naver":
          if (token === undefined) {
            break;
          }
          setToken(undefined);
          // Naver does not require the logout API to be explicitly called
          break;
      }

      wipeout();
    },
    showMemberIntegratedWelcomeDialog: async (
      showOnboading: boolean = false,
      snsConnectionTitle: string = "로그인 하기"
    ) => {
      if (showOnboading) {
        const selectedIndex = await setModalPopupContent({
          title: "당신의 앞날이 궁금하다면?",
          description: (
            <span
              css={css`
                font-weight: 500;
                font-size: 14px;
                line-height: 23px;
                text-align: center;
              `}
            >
              지금 사주정보를 입력하고
              <br />
              점신에서 알아봐요!
            </span>
          ),
          buttonTitles: [...buttons],
          anchor: "bottom",
          showCloseButton: "topRight",
        });

        if (selectedIndex === -1) {
          return;
        }

        const selectedButton = buttons[selectedIndex];

        if (selectedButton === "점신 시작하기") {
          setReferrerWrapper.current(path.current);
          navigate(JeomsinPagePath.SajuInput);
          return;
        }
      }

      // "기존 회원 로그인하기" 선택
      const selectedSNS =
        snsButtons[
          await setModalPopupContent(snsLoginPopupUI(snsConnectionTitle))
        ];

      if (selectedSNS === "naver") {
        const naverResponse = await requestUniqueIdFromNaver();

        if (naverResponse) {
          const [idCode, token] = naverResponse;
          setToken(token);

          await snsLogin(idCode, IdTypes.Naver);
        }
      } else if (selectedSNS === "kakao") {
        const kakaoResponse = await requestUniqueIdFromKakao();

        if (kakaoResponse) {
          const [idCode, token] = kakaoResponse;
          setToken(token);

          await snsLogin(idCode, IdTypes.Kakao);
        }
      } else if (selectedSNS === "google") {
        const googleUniqueIdGetter = new Promise(
          (
            resolve: (uniqueId: string) => void,
            reject: (reason: string) => void
          ) => {
            idResolver.current = {
              resolve,
              reject,
            };
          }
        );

        login();

        let idCode: string;
        try {
          idCode = await googleUniqueIdGetter;
        } catch (error) {
          setToastPopupContent((error as Error).message);
          return;
        }

        await snsLogin(idCode, IdTypes.Google);
      } else if (selectedSNS === "apple") {
        try {
          const appleID = (window as any).AppleID;
          const response = await appleID.auth.signIn();

          if(response){
            const idToken = response.authorization.id_token;
            const encodingData = idToken.split(".")[1];
            const decodingData = atob(encodingData);
            const idCode = JSON.parse(decodingData)["sub"];

            await snsLogin(idCode, IdTypes.Apple);
          } else {
            throw new Error("Apple 서버와 통신에 실패했습니다.");
          }
        } catch(error) {
          setToastPopupContent(`정회원 로그인이 실패했어요.\n다시 시도해주세요. [사유: userId 가져오기 실패]`);
          return;
        }
      }
    },
    showSNSConnectionDialog: async (
      referrer?: string,
      customDescription?: string
    ) => {
      const selectedIndex = await setModalPopupContent({
        title: "SNS 연동하기",
        description:
          customDescription ??
          "연동하지 않으면, 사주정보가 저장되지 않아요🥲\n단 한 번의 연동으로 더 편리하게 사용하세요!",
        buttonTitles: [
          <img
            src={`images/member_integrated/member/${snsButtons[0]}.png`}
            alt="Connect Naver"
          />,
          <img
            src={`images/member_integrated/member/${snsButtons[1]}.png`}
            alt="Connect Kakao"
          />,
          <img
            src={`images/member_integrated/member/${snsButtons[2]}.png`}
            alt="Connect Google"
          />,
          <img
            src={`images/member_integrated/member/${snsButtons[3]}.png`}
            alt="Connect Apple"
          />,
        ],
        buttonStackDirection: "row",
        anchor: "bottom",
        showCloseButton: "topRight",
        buttonWidth: "60px",
        buttonGap: 16,
      });

      if (selectedIndex === -1) {
        if (referrer !== undefined) {
          navigate(-1);
        }
        return;
      }

      const selectedSNS = snsButtons[selectedIndex];

      if (selectedSNS === "naver") {
        const naverResponse = await requestUniqueIdFromNaver();

        if (naverResponse) {
          const [idCode, token] = naverResponse;
          setToken(token);

          await snsLogin(idCode, IdTypes.Naver, referrer);
        }
      } else if (selectedSNS === "kakao") {
        const kakaoResponse = await requestUniqueIdFromKakao();

        if (kakaoResponse) {
          const [idCode, token] = kakaoResponse;
          setToken(token);

          await snsLogin(idCode, IdTypes.Kakao, referrer);
        }
      } else if (selectedSNS === "google") {
        const googleUniqueIdGetter = new Promise(
          (
            resolve: (uniqueId: string) => void,
            reject: (reason: string) => void
          ) => {
            idResolver.current = {
              resolve,
              reject,
            };
          }
        );

        login();

        let idCode: string;
        try {
          idCode = await googleUniqueIdGetter;
        } catch (error) {
          setToastPopupContent((error as Error).message);
          return;
        }

        await snsLogin(idCode, IdTypes.Google, referrer);
      } else if (selectedSNS === "apple") {
        try {
          const appleID = (window as any).AppleID;
          const response = await appleID.auth.signIn();

          if(response){
            const idToken = response.authorization.id_token;
            const encodingData = idToken.split(".")[1];
            const decodingData = atob(encodingData);
            const idCode = JSON.parse(decodingData)["sub"];

            await snsLogin(idCode, IdTypes.Apple, referrer);
          } else {
            throw new Error("Apple 서버와 통신에 실패했습니다.");
          }
        } catch(error) {
          setToastPopupContent(`정회원 로그인이 실패했어요.\n다시 시도해주세요. [사유: userId 가져오기 실패]`);
          return;
        }
      }
    },
    triggerSecondStep: triggerSecondStep,
  };
}

function snsLoginPopupUI(snsConnectionTitle: string): ModalPopupUIProps {
  const buttonStyle: CSSProperties = {
    width: "60px",
    height: "60px",
    objectFit: "contain",
  };

  return {
    title: snsConnectionTitle,
    description: (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
            alignItems: "flex-start",
            gap: "7px",
          }}
        >
          <span
            style={{
              fontSize: "19px",
              fontWeight: "700",
              color: "var(--MainText)",
            }}
          >
            SNS 계정으로 로그인
          </span>
          <span
            style={{
              fontSize: "15px",
              fontWeight: "500",
              color: "var(--SubText01)",
            }}
          >
            연동했던 SNS 계정을 선택해 주세요.
          </span>
        </div>
      </div>
    ),
    buttonTitles: [
      <img
        style={buttonStyle}
        src={`images/member_integrated/member/${snsButtons[0]}.png`}
        alt="Connect Naver"
      />,
      <img
        style={buttonStyle}
        src={`images/member_integrated/member/${snsButtons[1]}.png`}
        alt="Connect Kakao"
      />,
      <img
        style={buttonStyle}
        src={`images/member_integrated/member/${snsButtons[2]}.png`}
        alt="Connect Googe"
      />,
      <img
        style={buttonStyle}
        src={`images/member_integrated/member/${snsButtons[3]}.png`}
        alt="Connect Apple"
      />,
    ],
    buttonStackDirection: "row",
    anchor: "bottom",
    buttonWidth: "60px",
    buttonGap: 16,
    showCloseButton: "topRight",
  };
}

export function memberManseInfoToJeomsinUserInfo(
  info: MemberManseInfo
): JeomsinUserInfo {
  return {
    serverIdx: Number(info.idx.length > 0 ? info.idx : "0"),
    localId: Number(info.local_idx.length > 0 ? info.local_idx : "1"),
    name: info.mem_myname,
    gender: info.mem_mygender,
    genderText: info.mem_mygender === "0" ? "남성" : "여성",
    birthYear: info.mem_myyear,
    birthMonth: info.mem_mymonth,
    birthDay: info.mem_myday,
    birthTime: info.mem_myhour,
    birthMinute: info.mem_btime,
    isBirthTimeUnknown: info.mem_btime_code === "1",
    calendarType: Number(info.mem_mycalendar),
    calendarTypeText: info.mem_mycalendar === "0" ? "양력" : "음력",
    isLeapMonth: info.mem_myleap === "1",
    isLeapMonthText: info.mem_myleap === "0" ? "평달" : "윤달",
    group_type: info.group_type,
    relation_type: info.relation,
    saju_info: {
      shareXmlContent: "",
      myOhangNum: info.myNum,
      myOhangText: info.myText,
      chunganJijiOhangNodes: [
        {
          num: Number(info.chunNum1),
          text: info.chunText1,
          ohang: Number(info.chunOhang1),
        },
        {
          num: Number(info.chunNum2),
          text: info.chunText2,
          ohang: Number(info.chunOhang2),
        },
        {
          num: Number(info.chunNum3),
          text: info.chunText3,
          ohang: Number(info.chunOhang3),
        },
        {
          num: Number(info.chunNum4),
          text: info.chunText4,
          ohang: Number(info.chunOhang4),
        },
        {
          num: Number(info.jiNum1),
          text: info.jiText1,
          ohang: Number(info.jiOhang1),
        },
        {
          num: Number(info.jiNum2),
          text: info.jiText2,
          ohang: Number(info.jiOhang2),
        },
        {
          num: Number(info.jiNum3),
          text: info.jiText3,
          ohang: Number(info.jiOhang3),
        },
        {
          num: Number(info.jiNum4),
          text: info.jiText4,
          ohang: Number(info.jiOhang4),
        },
      ],
      chunGan10Sungs: [
        Number(info.chunGanShipSeongNum1),
        Number(info.chunGanShipSeongNum2),
        Number(info.chunGanShipSeongNum3),
        Number(info.chunGanShipSeongNum4),
      ],
      jiji10Sungs: [
        Number(info.jiJiShipSeongNum1),
        Number(info.jiJiShipSeongNum2),
        Number(info.jiJiShipSeongNum3),
        Number(info.jiJiShipSeongNum4),
      ],
      unSungs: [
        Number(info.shipYiUnSeongNum1),
        Number(info.shipYiUnSeongNum2),
        Number(info.shipYiUnSeongNum3),
        Number(info.shipYiUnSeongNum4),
      ],
    },
  } as JeomsinUserInfo;
}

function useSNSLogin() {
  const { setToastPopupContent } = useToastPopupStore();

  return {
    requestUniqueIdFromNaver: async (): Promise<
      [string, string | undefined] | undefined
    > => {
      window.open(
        `${callbackURLDomain}${JeomsinPagePath.NaverLoginSDKHandler}`,
        "Naver Login",
        "width=800,height=600"
      );

      const response = await waitForMessageFromLoginHandler();

      if (response.access_token === undefined) {
        // 네이버 로그인 실패
        setToastPopupContent(`정회원 로그인이 실패했어요.\n다시 시도해주세요.`);
        return;
      }

      const userId: string = await get_naver_userprofile(response.access_token);

      if (userId === undefined) {
        setToastPopupContent(
          `정회원 로그인이 실패했어요.\n다시 시도해주세요. [사유: userId 가져오기 실패]`
        );
        return;
      }

      return [userId, response.access_token];
    },
    requestUniqueIdFromKakao: async (): Promise<
      [string, string] | undefined
    > => {
      let callbackUrl = encodeURIComponent(`${callbackURLDomain}/oauth`);

      // Static pages cannot handle dynamic paths, the homepage will handle the login
      if (callbackURLDomain.includes("github")) {
        callbackUrl = callbackURLDomain;
      }

      window.open(
        `https://kauth.kakao.com/oauth/authorize?client_id=${kakaoClientId}&redirect_uri=${callbackUrl}&response_type=code`,
        "Kakao Login",
        "width=800,height=600"
      );

      const response = await waitForMessageFromLoginHandler();

      if (response.code === undefined) {
        // 네이버 로그인 실패
        setToastPopupContent(
          `정회원 로그인이 실패했어요.\n다시 시도해주세요. [사유 (${
            response.error ?? ""
          }): ${response.error_description ?? ""}]`
        );
        return;
      }

      const code = response.code;

      if (code === undefined) {
        setToastPopupContent(
          `정회원 로그인이 실패했어요.\n다시 시도해주세요. [사유 (${
            response.error ?? ""
          }): ${response.error_description ?? ""}]`
        );
        return;
      }

      const tokenResponse: {
        token_type: string;
        access_token: string;
      } = await (
        await fetch(
          `https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=${kakaoClientId}&redirect_uri=${callbackUrl}&code=${code}`,
          {
            method: "POST",
          }
        )
      ).json();

      const loginResponse: {
        id: number;
      } = await (
        await fetch("https://kapi.kakao.com/v2/user/me", {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        })
      ).json();

      return [String(loginResponse.id), tokenResponse.access_token];
    },
  };
}

function waitForMessageFromLoginHandler() {
  const bc = new BroadcastChannel("jeomsin-react-sns-login");

  return new Promise((resolve: (value: LoginHandlerResponse) => void) => {
    bc.onmessage = (e: MessageEvent<LoginHandlerResponse>) => {
      resolve(e.data);
      bc.close();
    };
  });
}

export const SNSLoginCustomText = {
  Type1:"해당 서비스는 SNS 계정 연동이 필요해요.\n단 한 번의 연동으로 모든 서비스를 이용해 보세요!"
}

export default useShowMemberIntegratedWelcomeDialog;
