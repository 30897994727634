import Weather from "@Models/weather";
import {
  JeomsinDomain,
  JeomsinEndPoint,
  JeomsinMethod,
  RequestProps,
} from "@Network/network_constants";
import { JeomsinRequest } from "@Network/network_manager";

export interface GetWeatherDefaultResponse {
  weather: Weather[];
}

export default async function apiGetWeatherDefault(): Promise<GetWeatherDefaultResponse> {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetWeatherDefault,
    params: {},
  };

  return (await JeomsinRequest(request)) as GetWeatherDefaultResponse;
}
