import { useUserInfoStore } from "@State/user_info_store";
import { Navigate, Outlet, useLocation } from "react-router";

interface CheckRouterProps {
  redirectPath?: string,
  checkSaju?: boolean,
  checkLogin?: boolean,
  checkPrevParam?: boolean
}
const CheckRouter:React.FC<CheckRouterProps> = ({
  redirectPath = "/",
  checkSaju = false,
  checkLogin = false,
  checkPrevParam = false,
}) => {
  let isAuthenticated = !checkLogin && !checkPrevParam;
  const { representativeUserInfo, isFullySignedIn } = useUserInfoStore();
  const prevParams = useLocation().state;

  if(checkSaju) {
    isAuthenticated = representativeUserInfo ? true : false;
  }

  if(checkLogin) {
    isAuthenticated = isFullySignedIn;
  }

  if(checkPrevParam) {
    isAuthenticated = prevParams ? true : false;

    if(window.location.hash) { //해시인 경우 경로 유지
      isAuthenticated = true;
    }
  }

  return (
    isAuthenticated ?  <Outlet/> : <Navigate to={redirectPath} replace/>
  )
};

export default CheckRouter;