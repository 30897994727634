import { SelectedScaleOption } from "@Config/jeomsin_scale";
import { CSSProperties, FC, forwardRef } from "react";

export const FlexDirectionType = {
    row:"row",
    column:"column",
} as const
export type FlexDirectionType = (typeof FlexDirectionType)[keyof typeof FlexDirectionType];

export const FlexAlignContentType = {
    normal:"normal",
    center:"center",
    start:"flex-start",
    end:"flex-end",
    spaceAround:"space-around",
    spaceBetween:"space-between",
    stretch:"stretch"
} as const
export type FlexAlignContentType = (typeof FlexAlignContentType)[keyof typeof FlexAlignContentType];

export const FlexJustifyContentType = {
    normal:"normal",
    center:"center",
    start:"flex-start",
    end:"flex-end",
    spaceAround:"space-around",
    spaceBetween:"space-between",
    spaceEvenly:"space-evenly"
} as const
export type FlexJustifyContentType = (typeof FlexJustifyContentType)[keyof typeof FlexJustifyContentType];

export const FlexAlignItemsType = {
    normal:"normal",
    center:"center",
    start:"flex-start",
    end:"flex-end",
    stretch:"stretch",
    baseline:"baseline",
} as const
export type FlexAlignItemsType = (typeof FlexAlignItemsType)[keyof typeof FlexAlignItemsType];

export type FlexBoxProps = {
    children: React.ReactNode;
    direction?:FlexDirectionType;
    alignContent?:FlexAlignContentType;
    justifyContent?:FlexJustifyContentType;
    alignItems?:FlexAlignItemsType;
    gap?:number;
    class?:string;
    onClick?:() => void;
    onAnimationStart?:() => void;
};

const FlexBox = forwardRef<HTMLDivElement, FlexBoxProps>((props, ref) => {
    const gap = props.gap ? (props.gap + SelectedScaleOption) : 0;

    return (        
        <div 
            ref={ref}
            className={props.class}
            style={{
                display:"flex",
                flexDirection:props.direction ?? FlexDirectionType.row,
                alignContent:props.alignContent ?? FlexAlignContentType.normal,
                justifyContent:props.justifyContent ?? FlexJustifyContentType.normal,
                alignItems:props.alignItems ?? FlexAlignItemsType.normal,
                gap:gap,
            }}
            onClick={props.onClick}
            onAnimationStart={props.onAnimationStart}
        >
            {props.children}
        </div>
    );
});

export default FlexBox;