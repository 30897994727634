import { ReactElement } from "react";
import { v4 } from "uuid";
import { create } from "zustand";
import styles from "./async_modal_popup.module.css";
import { EachModalPopup } from "./each_modal_popup";

export default function AsyncModalPopupManager() {
  const { modalPopupContents, removeModalPopupById } =
    useModalPopupStoreInternal();
  const shouldShow = modalPopupContents.length > 0;

  return (
    <>
      {modalPopupContents.map((eachContent, contentIndex) => {
        const anchor = eachContent.content.anchor ?? "center";

        return (
          <EachModalPopup
            key={eachContent.removalId}
            hidden={eachContent.content.hidden ?? false}
            anchor={anchor}
            eachContent={eachContent}
            contentIndex={contentIndex}
            removeModalPopupById={removeModalPopupById}
          />
        );
      })}
      <div
        onClick={() => {
          if (modalPopupContents.length === 0) {
            return;
          }

          const popup = modalPopupContents.at(modalPopupContents.length - 1);
          const removalId = popup?.removalId;
          if (removalId !== undefined && popup !== undefined) {
            const isAccept = popup.content.decisionHandler?.(-1) ?? true;

            if (isAccept === false) {
              return;
            }

            popup.resolver(-1);
            removeModalPopupById(removalId);
          }
        }}
        className={styles.dimLayer}
        style={{
          opacity: shouldShow ? "50%" : "0%",
          pointerEvents: shouldShow ? "all" : "none",
        }}
      />
    </>
  );
}

export interface ModalPopupUIProps {
  title?: string | ReactElement;
  description?: string | ReactElement;
  buttonTitles?: (string | ReactElement)[];
  buttonStackDirection?: "row" | "column";
  anchor?: "center" | "bottom";
  decisionHandler?: (clickedIndex: number) => boolean;
  hidden?: boolean;
  buttonWidth?: string;
  buttonGap?: number;
  showCloseButton?: "topRight";
  isSecondButtonColored?: boolean;
  topPadding?: number;
  customVerticalGaps?: [
    number | undefined,
    number | undefined,
    number | undefined,
    number | undefined,
  ];
  customHeight?: number;
  buttonBorderRadius?: number;
}

interface ZModalPopupInfo {
  modalPopupContents: {
    removalId: string;
    content: ModalPopupUIProps;
    resolver: (clickedButtonIndex: number) => void;
  }[];
  setModalPopupContent: (
    removalId: string,
    content: ModalPopupUIProps
  ) => Promise<number>;
  removeModalPopupById: (removalId: string) => void;
}

export function useModalPopup() {
  const { setModalPopupContent } = useModalPopupStoreInternal();

  return {
    setModalPopupContent: (content: ModalPopupUIProps) => {
      return setModalPopupContent(v4(), content);
    },
  };
}

export const useModalPopupStoreInternal = create<ZModalPopupInfo>((set) => ({
  modalPopupContents: [],
  asyncActions: [],

  setModalPopupContent: (removalId, content) => {
    const promise = new Promise(
      (
        resolve: (clickedButtonIndex: number) => void,
        reject: (reason: string) => void
      ) => {
        set((state) => ({
          ...state,
          modalPopupContents: [
            ...state.modalPopupContents,
            {
              removalId,
              content,
              resolver: resolve,
            },
          ],
        }));
      }
    );

    return promise;
  },
  removeModalPopupById: (removalId) => {
    set((state) => {
      let newState = {
        ...state,
      };

      let found = newState.modalPopupContents.find(
        (each) => each.removalId === removalId
      );
      if (found) {
        found.content.hidden = true;
      }

      return newState;
    });

    setTimeout(() => {
      set((state) => {
        const filtered = state.modalPopupContents.filter(
          (each) => each.removalId !== removalId
        );

        return {
          ...state,
          modalPopupContents: filtered,
        };
      });
    }, 300);
  },
}));
