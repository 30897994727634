export const JeomsinPagePath = {
    Home: "/",
    SajuInput: "/sajuinput",
    SajuModify: "/sajumodify",
    SajuMyeongsik: "/sajumyeongsik",
    SajuMyeongsikDetail: "/sajumyeongsikdetail",
    SajuMyeongsikLotto: "/sajumyeongsiklotto",
    SajuAnalyzing: "/sajuanalyzing",
    TodayUnse: "/todayunse",
    NextDayUnse: "/nextdayunse",
    SpecifiedDayUnse: "/specifieddayunse",
    WhatIsOhangGuide: "/whatisohangguide",
    NewYearUnse: "/newyearunse",
    NewYearUnseDetail: "/newyearunsedetail",
    TojeongBigyeol: "/tojeongbigyeol",
    JeongtongSaju: "/jeongtongsaju",
    AstroUnse: "/astrounse",
    WorkUnse: "/workunse",
    AbilityEvaluation: "/abilityevaluation",
    SajuSimli: "/sajusimli",
    GunghapChunsaeng: "/gunghapchunsaeng",
    SajuGyejeol: "/sajugyejeol",
    LottoFortuneIntro: "/lottointro",
    LottoFortune: "/lotto",
    FreeBirth: "/freeBirth",
    SajuJeonSaeng: "/sajuJeonSaeng",
    ThemeBirthstone: "/themeBirthstone",
    GonghapIneyun: "/gonghapIneyun",
    Salpuri: "/salpuri",
    SalpuriResult: "/salpuriresult",
    AstroGunghap: "/astrogunghap",
    AstroGunghapResult: "/astrogunghapresult",
    SajuMyeongsikCouple: "/sajuMyeongsikCouple",
    BloodTypeGunghap: "/bloodtypegunghap",
    BloodTypeGunghapResult: "/bloodtypegunghapresult",
    GunghapMZ: "/gunghapmz",
    GunghapJeongtong: "/gunghapjeongtong",
    HouseMove: "/housemove",
    HouseMoveDetail: "/housemovedetail",
    CelebGunghap: "/celebgunghap",
    CelebGunghapResult: "/celebgunghapresult",
    Dream:"/dreammain",
    DreamSearch:"/dreamsearch",
    DreamDetail:"/dreamdetail",
    SajuMultiInfomation:"/sajumultiinfomation",
    RequirePhoneNumber:"/requirephonenumber",
    CounselorProfile:"/counselorprofile",
    SharableCounselorProfile:"/sharable/counselorprofile",
    CounselHome:"/counselhome",
    AllBannerListView:"/allbannerlistview",
    ProfessionalCounselor:"/professionalcounselor",
    SearchView:"/searchview",
    CounselorProfileReviewReport:"/counselorprofilereviewreport",
    FieldSearchCounselor:"/fieldsearchcounselor",
    CounselWorry:"/counselworry",
    CounselMyWorry:"/counselmyworry",
    CounselMyWorryWrite:"/counselmyworryrite",
    ThreeMinuteCounselor:"/threeminutecounselor",
    ThreeMinuteCounselorGuide:"/threeminutecounselorguide",
    RealTimeCounselorGuide:"/realtimecounselorguide",
    CouponBox:"/couponbox",
    SimliCounselGuide: "/simlicounselguide",
    MypageHome: "/mypagehome",
    NaverLoginCallback: "/callbacks/naver/login",
    Settings: "/settings",
    KakaoLoginCallback: "/oauth",
    Exit: "/exit",
    MyWallet:"/mywallet",
    CoinHistory:"/coinhistory",
    BuyHistory:"/buyhistory",
    Notice:"/notice",
    MyFavoriteCounselor:"/myfavoritecounselor",
    ChangePhoneNumber: "/changephonenumber",
    AccountManagement: "/accountmanagement",
    CounselHistory: "/counselhistory",
    CustomerCenter: "/customercenter",
    Reviews: "/reviews",
    CounselHistoryDetail: "/counselhistorydetail",
    WriteReview: "/writereview",
    AppUsageGuide: "/appusageguide",
    ChargingStation: "/chargingstation",
    CardRegistration: "/cardregistration",
    PasswordRegistration: "/passwordregistration",
    GenericSettings: "/genericsettings",
    NaverLoginSDKHandler: "/naverloginsdkhandler",
    CounselConnectionPayDecision: "/counselconnectionpaydecision",
    AppleLoginCallback: "/apple/oauth",
    TarotHome: "/tarothome",
    TarotCardSettings: "/tarothome/settings",
    TarotCardMeaning: "/tarothome/settings/meaning",
    TarotSelection: "/tarothome/selection",
    TarotAnalyzing: "/tarothome/selection/tarotanalyzing",
    TarotResult: "/tarothome/selection/tarotanalyzing/tarotresult",
} as const;
export type JeomsinPagePath = (typeof JeomsinPagePath)[keyof typeof JeomsinPagePath];