import { useEffect, useRef } from "react";

export default function useChangeTheme(themeColor: string) {
  const original = useRef<string | undefined>();

  useEffect(() => {
    const theme = document
      .getElementsByTagName("meta")
      .namedItem("theme-color");

    if (original.current === undefined) {
      original.current = theme!.content;
    }

    theme!.content = themeColor;

    return () => {
      if (original.current) {
        theme!.content = original.current;
      }
    };
  }, [themeColor]);
}
