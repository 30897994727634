import { CoupangPageCode } from "@Models/coupang_config/coupang_config_model";
import { Box } from "@mui/material";
import { AdStatus, checkAdStatus, userAcceptedCoupangAd, userRejectedCoupangAd } from "@Storage/coupang_ad_time_manager";
import { FC, useEffect, useState } from "react";
import CoupangRectAdBox from "../coupang_rect_ad_box/couapng_rect_ad_box";
import { CoupangRectAdDataModel } from "@Models/coupang_config/coupang_ad_data_model";
import css from "./saju_analyzing_couapng_ad_box.module.css"
import { useCoupangAdViewStore } from "@Views/web_ad/use_coupang_ad_view_store";
import NetworkAdView from "@Views/web_ad/network_ad_view/network_ad_view";
import { JeomsinAdType, NetworkAdTags } from "@Views/web_ad/web_ad_data";
import { useNavigate } from "react-router";
import { JeomsinPagePath } from "@Config/jeomsin_page_path";
import { useFortuneStore } from "@Views/main_tab/fortune/use_fortune_store";

interface SajuAnalyzingCouapngAdBoxProps {
    adType:JeomsinAdType,
    adData:CoupangRectAdDataModel|undefined,
    isLoaded:boolean,
}

const SajuAnalyzingCouapngAdBox:FC<SajuAnalyzingCouapngAdBoxProps> = ({
    adType,
    adData,
    isLoaded,
}) => {
    const nav = useNavigate();
    const [closeButtonTimer, setCloseButtonTimer] = useState<number>(5);
    const { setDoScrollTo } = useFortuneStore();
    // const [adType, setAdType] = useState<string>(adType);
    useEffect(() => {    
        const timer = setInterval(() => {
          if (closeButtonTimer < 0) {
            return;
          }
    
          setCloseButtonTimer((leftTime) => leftTime - 1);
        }, 1000);
    
        return () => {
          clearInterval(timer);
        };
    }, [closeButtonTimer]);

    return (
      <>
        {
          adType === JeomsinAdType.None ? <></>
          : adType === JeomsinAdType.Network ? (
            <NetworkAdView tag={NetworkAdTags.SajuAnalyzingBoxBanner}/>
          ) : (
            <Box className={`${css.container} ${adType === JeomsinAdType.None ? css.none : JeomsinAdType.Network ? css.network : ""}`} 
              onClick={() => {
                  // 상단에서만 노출
                  window.open(adData?.productUrl);
                  userAcceptedCoupangAd();
              }}
            >
              {
                // adType === JeomsinAdType.None ? <></>
                // : adType === JeomsinAdType.Network ? (
                //   <NetworkAdView tag={NetworkAdTags.SajuAnalyzingBoxBanner}/>
                // ) : (
                    adData ? (
                      <>
                      <CoupangRectAdBox adData={adData}/>
                      <Box className={css.close_btn_wrap}>
                        {closeButtonTimer < 1 ? (
                          <img
                            onClick={() => {
                                userRejectedCoupangAd();
                                setDoScrollTo(true);
                                nav(JeomsinPagePath.Home);
                            }}
                            src="img/x_button.png"
                            alt="x-button"
                            width="22px"
                            height="22px"
                          />
                        ) : (
                          <div
                            className={css.close_timer_wrap}
                          >
                            <div className={css.time_text_wrap}>
                              <span className={css.time_text}>
                                {closeButtonTimer}
                              </span>
                            </div>
                          </div>
                        )}
                      </Box>
                      </>
                    ) : <></>
                // )
              }    
            </Box>
          )
        }
      </>
    )
}


export default SajuAnalyzingCouapngAdBox;