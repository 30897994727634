import { CoupangLineAdDataModel, CoupangRectAdDataModel } from "@Models/coupang_config/coupang_ad_data_model";
import { CoupangConfigModel, CoupangPageCode } from "@Models/coupang_config/coupang_config_model";
import { CoupangConfigViewModel } from "@Models/coupang_config/coupang_config_view_model";
import { apiGetCoupangConfigV2 } from "@Network/request/api_get_coupang_config_v2";
import { apiRequestCojiCoupangAd } from "@Network/request/api_request_coji_coupang_ad";
import { apiRequestCoupangAdReco } from "@Network/request/api_request_coupang_ad_reco";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { JeomsinAdType } from "./web_ad_data";
import { AdStatus, checkAdStatus } from "@Storage/coupang_ad_time_manager";

interface ZCoupangAdView {
    coupangConfig:CoupangConfigViewModel|undefined,
    
    initState:()=>void,
    loadCoupangConfig:()=>void,
    loadRectCoupangAd:(size:string, pageCode:CoupangPageCode)=>Promise<CoupangRectAdDataModel|undefined>,
    loadLineCoupangAd:(size:string)=>Promise<CoupangRectAdDataModel|undefined>,

    getPageCoupangConfig:(pageCode:CoupangPageCode)=>CoupangConfigModel|undefined,
    getCoupangWaitTime:()=>number,
    checkAdType:(serverAdType?:JeomsinAdType)=>JeomsinAdType,
    isOnCoupangAd:()=>boolean,
}

const useCoupangAdViewStore = create(
  persist<ZCoupangAdView>(
        (set, get) => ({
            coupangConfig:undefined,

            initState:() => {
                set({
                    coupangConfig:undefined,
                })
            },
            loadCoupangConfig:async () => {
                const response = await apiGetCoupangConfigV2();
                const config = CoupangConfigViewModel().getAdConfig(response);
                set({ coupangConfig: config });
            },
            getPageCoupangConfig:(pageCode:CoupangPageCode) => {
                const pageConfig = CoupangConfigViewModel().getPageAdConfig(get().coupangConfig?.coupangPageConfigList ?? [], pageCode);
                return pageConfig;
            },
            loadRectCoupangAd:(size:string, pageCode:CoupangPageCode) => {
                return new Promise(async (resolve, reject) => {
                    const pageCoupangConfig = get().getPageCoupangConfig(pageCode);
                    const coupangConfig = get().coupangConfig?.coupangRectAd;
                    const today = new Date();
                    const topIamgeUrl = today.getHours() >= 12 ? pageCoupangConfig?.img2 : pageCoupangConfig?.img1;

                    try{
                        let data:CoupangRectAdDataModel|undefined = undefined;
                        const adType = coupangConfig?.ad_type;
                        //adType 1 코지마망, 2 네트워크 광고, 3 테크랩스 쿠팡
                        const checkAdType = get().checkAdType(adType);

                        if(checkAdType === JeomsinAdType.CojiCoupang) {
                            const response = await apiRequestCojiCoupangAd(size, coupangConfig?.coupang_id, coupangConfig?.coupang_subid);
                            const selectNumber = Math.floor(Math.random() * (response?.data?.length ?? 0));

                            data = {
                                productImage: response?.data[selectNumber].productImage ?? "",
                                productUrl: response?.data[selectNumber].landingUrl ?? "",
                                topImage: topIamgeUrl ?? "",
                                btnName:coupangConfig?.btn_name
                            }
                        } else if(checkAdType === JeomsinAdType.TechlabsCoupang) {
                            const response = await apiRequestCoupangAdReco(
                                coupangConfig?.coupang_id, 
                                coupangConfig?.coupang_subid, 
                                size, 
                                pageCode
                            );

                            data = {
                                productImage: response?.data.productImage ?? "",
                                productUrl: response?.data.productUrl ?? "",
                                topImage: topIamgeUrl ?? "",
                                btnName:coupangConfig?.btn_name
                            }
                        } else {
                            reject(undefined);
                        }
                        
                        resolve(data);
                    } catch(error) {
                        reject(undefined);
                    }
                });
            },
            loadLineCoupangAd:(size:string) => {
                return new Promise(async (resolve, reject) => {
                    const config = get().coupangConfig?.coupangLineAd;
                    try {
                        let data:CoupangLineAdDataModel|undefined = undefined;
                        const adType = config?.ad_type;
                        //adType 1 코지마망, 2 네트워크 광고, 3 테크랩스 쿠팡
                        const checkAdType = get().checkAdType(adType);
    
                        if(checkAdType === JeomsinAdType.CojiCoupang) {
                            const response = await apiRequestCojiCoupangAd(size, config?.coupang_id, config?.coupang_subid);
                            const selectNumber = Math.floor(Math.random() * (response?.data?.length ?? 0));
    
                            data = {
                                productImage: response?.data[selectNumber].productImage ?? "",
                                productUrl: response?.data[selectNumber].landingUrl ?? "",
                                coupangImage: config?.coupangV4Img ?? "",
                            }
                        } else if(checkAdType === JeomsinAdType.TechlabsCoupang) {
                            const response = await apiRequestCoupangAdReco(config?.coupang_id, size, config?.coupang_subid);
                            data = {
                                productImage: response?.data.productImage ?? "",
                                productUrl: response?.data.productUrl ?? "",
                                coupangImage: config?.coupangV4Img ?? "",
                            }
                        } else {
                            reject(undefined);
                        }
                        
                        resolve(data);
                    } catch (error) {
                        reject(undefined);
                    }
                });
            },
            getCoupangWaitTime:() => {
                //V2(사각), V4(띠) 구분 필요 => 앱 소스에는 구분 없이 V2(Rect) 것을 사용하는 것을 확인
                // const waitTime:number = Number(3); //분단위로 서버에서 설정되므로 환산
                const waitTime:number = Number(get().coupangConfig?.coupangRectAd?.ad_wait_time);

                return waitTime;
            },
            checkAdType:(serverAdType?:JeomsinAdType) => { //v2, v4 개별 광고 타입 체크
                let result = JeomsinAdType.None;
                const adStatus = checkAdStatus();

                if(adStatus === AdStatus.adFree) {// 광고 프리 상태에서는 모든 광고 송출 X
                    result = JeomsinAdType.None;
                } else if(adStatus === AdStatus.showCoupangAd) { //쿠팡광고를 송출해야 하는 경우 서버의 결정에 따라 수행,(코지마망, 네트워크, 테크랩스 쿠팡)
                    result = serverAdType ?? JeomsinAdType.None;
                } else if(adStatus === AdStatus.showNetworkAd) { //네트워크 광고를 송출해야 하는 경우 서버의 결정에 상관없이 네트워크 광고 송출
                    result = JeomsinAdType.Network;
                }

                return result;
            },
            isOnCoupangAd:() => { //v2(사각), v4(라인)을 통틀어서 쿠팡광고가 하나라도 켜져있는지 체크
                let result = false;
                const v2Config = get().coupangConfig?.coupangRectAd;
                const v4Config = get().coupangConfig?.coupangLineAd;
                const adStatus = checkAdStatus();

                if(adStatus === AdStatus.showCoupangAd) { //쿠팡광고 스킵 상태에서는 false, 쿠팡광고 송출해야하는데 쿠팡 광고가 아닌 경우 false
                    if(
                        v2Config?.ad_type === JeomsinAdType.CojiCoupang
                        || v2Config?.ad_type === JeomsinAdType.TechlabsCoupang
                        || v4Config?.ad_type === JeomsinAdType.CojiCoupang
                        || v4Config?.ad_type === JeomsinAdType.TechlabsCoupang
                    ){
                        result = true;
                    }
                }

                return result;
            }
        }),
        { 
          name: 'counpang-ad-view-storage',
          storage: createJSONStorage(() => sessionStorage) //탭 종료 시 스토리지에 저장된 데이터 Clear
        }
    )
);

export { useCoupangAdViewStore };
