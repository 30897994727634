import { ColoredRectangleButton } from "@Views/common_component/colored_rectangle_button/colored_rectangle_button";
import css from "./wheel_picker_box.module.css";
import IosStylePicker, { IosStylePickerItem } from "@Views/common_component/ios_style_picker/ios_style_picker";
import { useState } from "react";


type WheelPickerProps = {
  data:IosStylePickerItem[];
  onPicked:(item:IosStylePickerItem | undefined) => void;
}

const WheelPicker: React.FC<WheelPickerProps> = (props) => {
  const [selectedItem, setSeletectedItem] = useState<IosStylePickerItem>();
  return (
    <>
      <div className={`${css.wheel_picker_box}`}>
        <div className={`${css.spacer}`} />
        <IosStylePicker 
          data={props.data} 
          selectionHandler={(selection) => {
            setSeletectedItem(selection)
          }}
          prepareUI={true}
        />
        <div className={`${css.spacer}`} />
        <ColoredRectangleButton title="확인" cornerRadius={true} height="50px" width="100%" clickHandler={() => {
            props.onPicked(selectedItem);
        }} />
      </div>
    </>
  );
};

export { WheelPicker };
export type {
  WheelPickerProps
};

