import { CounselorField } from "@DataDefine/counselor_data";
import { JeomsinPageInfo } from "@DataDefine/page_code";

export const SearchType = {
    Menu:0,
    Counselor:1,
}

export const CounselorSearchRecommendFieldList = [
    CounselorField.Unse,
    CounselorField.Gunghap,
    CounselorField.Romance
]

export const MenuSearchRecommendList = [
    "운세",
    "타로",
    "상담",
    "부적"
]

export const JeomsinSummarizeCatagory = {
    All:{title:"전체", idx:0, img:"/images/fortune_main/summarized_menu_etc.png"},
    Heart:{title:"인연/애정", idx:1, img:"/images/fortune_main/summarized_menu_heart.png"},
    FacialAndAbility:{title:"얼굴/능력", idx:2, img:"images/fortune_main/summarized_menu_facial_and_ability.png"},
    Day:{title:"하루운명", idx:3, img:"images/fortune_main/summarized_menu_day.png"},
    Tradition:{title:"정통운세", idx:4, img:"images/fortune_main/summarized_menu_tradition.png"},
    Lucky:{title:"행운/액운", idx:5, img:"images/fortune_main/summarized_menu_lucky.png"},
    Birth:{title:"생일운명", idx:6, img:"images/fortune_main/summarized_menu_birth.png"},
    Counsel:{title:"고민/조언", idx:7, img:"images/fortune_main/summarized_menu_counsel.png"},
};
export type JeomsinSummarizeCatagory = (typeof JeomsinSummarizeCatagory)[keyof typeof JeomsinSummarizeCatagory]

export type JeomsinSummarizeMenu = {
    title:string,
    isFavorite:boolean,
    category:JeomsinSummarizeCatagory,
    order:number,
    pageInfo:JeomsinPageInfo,
}

export const JeomsinSummarizeMenuList:JeomsinSummarizeMenu[] = [
    { title: '전체', isFavorite: false, category: JeomsinSummarizeCatagory.All, order:0, pageInfo:JeomsinPageInfo.NONE},

    { title: '나의 인연 운세', isFavorite: false, category: JeomsinSummarizeCatagory.Heart, order:1 , pageInfo:JeomsinPageInfo.D10095},
    { title: '별자리 궁합', isFavorite: false, category: JeomsinSummarizeCatagory.Heart, order:2 , pageInfo:JeomsinPageInfo.D10076},
    { title: '연예인 궁합', isFavorite: false, category: JeomsinSummarizeCatagory.Heart, order:3 , pageInfo:JeomsinPageInfo.D10059},
    { title: '정통궁합', isFavorite: false, category: JeomsinSummarizeCatagory.Heart, order:4 , pageInfo:JeomsinPageInfo.D10031},
    { title: '짝궁합', isFavorite: false, category: JeomsinSummarizeCatagory.Heart, order:5 , pageInfo:JeomsinPageInfo.D10137},
    { title: '혈액형 궁합', isFavorite: false, category: JeomsinSummarizeCatagory.Heart, order:6 , pageInfo:JeomsinPageInfo.D10073},

    { title: '관상', isFavorite: false, category: JeomsinSummarizeCatagory.FacialAndAbility, order:7 , pageInfo:JeomsinPageInfo.NONE},
    { title: '취업 운세', isFavorite: false, category: JeomsinSummarizeCatagory.FacialAndAbility, order:8 , pageInfo:JeomsinPageInfo.D10039},
    { title: '능력 평가', isFavorite: false, category: JeomsinSummarizeCatagory.FacialAndAbility, order:9 , pageInfo:JeomsinPageInfo.D10043},
    { title: '사주 심리분석', isFavorite: false, category: JeomsinSummarizeCatagory.FacialAndAbility, order:10 , pageInfo:JeomsinPageInfo.D10091},

    { title: '오늘의 운세', isFavorite: false, category: JeomsinSummarizeCatagory.Day, order:11 , pageInfo:JeomsinPageInfo.D10003},
    { title: '내일의 운세', isFavorite: false, category: JeomsinSummarizeCatagory.Day, order:12 , pageInfo:JeomsinPageInfo.D10007},
    { title: '지정일 운세', isFavorite: false, category: JeomsinSummarizeCatagory.Day, order:13 , pageInfo:JeomsinPageInfo.D10011},
    { title: '인맥보고서', isFavorite: false, category: JeomsinSummarizeCatagory.Day, order:14 , pageInfo:JeomsinPageInfo.NONE},
    { title: '시간대별 운세', isFavorite: false, category: JeomsinSummarizeCatagory.Day, order:15 , pageInfo:JeomsinPageInfo.D10015},
    { title: '행운의 골프', isFavorite: false, category: JeomsinSummarizeCatagory.Day, order:16 , pageInfo:JeomsinPageInfo.NONE},

    { title: '신년운세', isFavorite: false, category: JeomsinSummarizeCatagory.Tradition, order:17 , pageInfo:JeomsinPageInfo.D10019},
    { title: '정통사주', isFavorite: false, category: JeomsinSummarizeCatagory.Tradition, order:18 , pageInfo:JeomsinPageInfo.D10027},
    { title: '토정비결', isFavorite: false, category: JeomsinSummarizeCatagory.Tradition, order:19 , pageInfo:JeomsinPageInfo.D10023},

    { title: '행운의 부적', isFavorite: false, category: JeomsinSummarizeCatagory.Lucky, order:20 , pageInfo:JeomsinPageInfo.D10082},
    { title: '살풀이', isFavorite: false, category: JeomsinSummarizeCatagory.Lucky, order:21 , pageInfo:JeomsinPageInfo.D10035},
    { title: '이사택일', isFavorite: false, category: JeomsinSummarizeCatagory.Lucky, order:22 , pageInfo:JeomsinPageInfo.D10056},
    { title: '천생복덕운', isFavorite: false, category: JeomsinSummarizeCatagory.Lucky, order:23 , pageInfo:JeomsinPageInfo.D10085},
    { title: '행운의 번호', isFavorite: false, category: JeomsinSummarizeCatagory.Lucky, order:24 , pageInfo:JeomsinPageInfo.D10070},

    { title: '띠 운세', isFavorite: false, category: JeomsinSummarizeCatagory.Birth, order:25 , pageInfo:JeomsinPageInfo.D10050},
    { title: '별자리 운세', isFavorite: false, category: JeomsinSummarizeCatagory.Birth, order:26 , pageInfo:JeomsinPageInfo.D10053},
    { title: '생년월일 운세', isFavorite: false, category: JeomsinSummarizeCatagory.Birth, order:27 , pageInfo:JeomsinPageInfo.D10107},
    { title: '태어난 계절운', isFavorite: false, category: JeomsinSummarizeCatagory.Birth, order:28 , pageInfo:JeomsinPageInfo.D10103},
    { title: '전생운', isFavorite: false, category: JeomsinSummarizeCatagory.Birth, order:29 , pageInfo:JeomsinPageInfo.D10099},
    { title: '탄생석', isFavorite: false, category: JeomsinSummarizeCatagory.Birth, order:30 , pageInfo:JeomsinPageInfo.D10079},

    { title: '고민구슬', isFavorite: false, category: JeomsinSummarizeCatagory.Counsel, order:31 , pageInfo:JeomsinPageInfo.NONE},
    { title: '꿈해몽', isFavorite: false, category: JeomsinSummarizeCatagory.Counsel, order:32 , pageInfo:JeomsinPageInfo.D10089},
    { title: '소원담벼락', isFavorite: false, category: JeomsinSummarizeCatagory.Counsel, order:33 , pageInfo:JeomsinPageInfo.NONE},
    { title: '태몽', isFavorite: false, category: JeomsinSummarizeCatagory.Counsel, order:34 , pageInfo:JeomsinPageInfo.D10090},
    { title: '포춘쿠키', isFavorite: false, category: JeomsinSummarizeCatagory.Counsel, order:35 , pageInfo:JeomsinPageInfo.NONE},
    { title: '피해야 할 상대', isFavorite: false, category: JeomsinSummarizeCatagory.Counsel, order:36 , pageInfo:JeomsinPageInfo.NONE},
];

export type JeomsinSearchMenu = {
    name:string,
    pageInfo:JeomsinPageInfo,
}

export const JeomsinSearchMenuList:JeomsinSearchMenu[] = [
    { name: '부적구매', pageInfo:JeomsinPageInfo.NONE },
    { name: '나의인연은', pageInfo:JeomsinPageInfo.D10095 },
    { name: '별자리궁합', pageInfo:JeomsinPageInfo.D10076 },
    { name: '연예인궁합', pageInfo:JeomsinPageInfo.D10059 },
    { name: '정통궁합', pageInfo:JeomsinPageInfo.D10031 },
    { name: '짝궁합', pageInfo:JeomsinPageInfo.D10137 },
    { name: '혈액형궁합', pageInfo:JeomsinPageInfo.D10073 },
    { name: '관상', pageInfo:JeomsinPageInfo.NONE },
    { name: '취업운', pageInfo:JeomsinPageInfo.D10039 },
    { name: '능력평가', pageInfo:JeomsinPageInfo.D10043 },
    { name: '사주심리분석', pageInfo:JeomsinPageInfo.D10091 },
    { name: '오늘의운세', pageInfo:JeomsinPageInfo.D10003 },
    { name: '내일의운세', pageInfo:JeomsinPageInfo.D10007 },
    { name: '지정일운세', pageInfo:JeomsinPageInfo.D10011 },
    { name: '행운의코디', pageInfo:JeomsinPageInfo.NONE },
    { name: '바이오리듬', pageInfo:JeomsinPageInfo.NONE },
    { name: '시간대별운세', pageInfo:JeomsinPageInfo.D10015 },
    { name: '신년운세', pageInfo:JeomsinPageInfo.D10019 },
    { name: '정통사주', pageInfo:JeomsinPageInfo.D10027 },
    { name: '토정비결', pageInfo:JeomsinPageInfo.D10023 },
    { name: '부적처방', pageInfo:JeomsinPageInfo.D10082 },
    { name: '살풀이', pageInfo:JeomsinPageInfo.D10035 },
    { name: '이사택일', pageInfo:JeomsinPageInfo.D10056 },
    { name: '천생복덕운', pageInfo:JeomsinPageInfo.D10085 },
    { name: '행운의번호', pageInfo:JeomsinPageInfo.D10070 },
    { name: '띠운세', pageInfo:JeomsinPageInfo.D10050 },
    { name: '별자리운세', pageInfo:JeomsinPageInfo.D10053 },
    { name: '생년월일운', pageInfo:JeomsinPageInfo.D10107 },
    { name: '태어난계절운', pageInfo:JeomsinPageInfo.D10103 },
    { name: '전생운', pageInfo:JeomsinPageInfo.D10099 },
    { name: '탄생석', pageInfo:JeomsinPageInfo.D10079 },
    { name: '고민구슬', pageInfo:JeomsinPageInfo.NONE },
    { name: '꿈해몽', pageInfo:JeomsinPageInfo.D10089 },
    { name: '소원담벼락', pageInfo:JeomsinPageInfo.NONE },
    { name: '태몽', pageInfo:JeomsinPageInfo.D10090 },
    { name: '포춘쿠키', pageInfo:JeomsinPageInfo.NONE },
    { name: '피해야할상대', pageInfo:JeomsinPageInfo.NONE },
    { name: '다시보는신년운세', pageInfo:JeomsinPageInfo.NONE },
    { name: '다시보는토정비결', pageInfo:JeomsinPageInfo.NONE },
    { name: '오늘의타로', pageInfo:JeomsinPageInfo.NONE },
    { name: '이달의타로', pageInfo:JeomsinPageInfo.NONE },
    { name: '연애타로', pageInfo:JeomsinPageInfo.NONE },
    { name: '취업타로', pageInfo:JeomsinPageInfo.NONE },
    { name: '짝사랑타로', pageInfo:JeomsinPageInfo.NONE },
    { name: '운세상담', pageInfo:JeomsinPageInfo.E10003 },
    { name: '타로상담', pageInfo:JeomsinPageInfo.E10004 },
    { name: '신점상담', pageInfo:JeomsinPageInfo.E10005 },
    { name: '심리상담', pageInfo:JeomsinPageInfo.E10051 },
    { name: '무료고민상담', pageInfo:JeomsinPageInfo.E10006 },
    { name: '3분무료상담', pageInfo:JeomsinPageInfo.E10008 },
    { name: '상담이용가이드', pageInfo:JeomsinPageInfo.E10015 },
    { name: '무료상담가이드', pageInfo:JeomsinPageInfo.E10030 },
    { name: '심리상담가이드', pageInfo:JeomsinPageInfo.E10048 },
    { name: '인맥보고서', pageInfo:JeomsinPageInfo.NONE },
    { name: '운세', pageInfo:JeomsinPageInfo.UnseHome },
    // { name: '타로', pageInfo:JeomsinPageInfo.TaroHome },
    { name: '주제별 타로', pageInfo:JeomsinPageInfo.NONE },
    { name: '상담', pageInfo:JeomsinPageInfo.CounselHome },
];