import { useMemberIntegratedState } from "@Views/main_tab/fortune/use_welcome_modal_popup";
import { useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router";

export interface LoginHandlerResponse {
  code?: string;
  state?: string;
  error?: string;
  error_description?: string;
  access_token?: string;
  token_type?: string;
  id?: string;
  expires_in?: string;
}

const isEmpty = (obj: any) => {
  return !obj || !Object.values(obj).some((x) => x !== void 0);
};

export function useLoginHandler() {
  const location = useLocation();

  useEffect(() => {
    let queryObject: LoginHandlerResponse = {};

    //KAKAO
    location.search.split(/[?&]/).forEach((each) => {
      const eachSplitted = each.split("=");
      const key = eachSplitted[0] as keyof LoginHandlerResponse;
      const value = eachSplitted[1];
      queryObject[key] = value;
    });

    //Naver
    location.hash.split(/[#&]/).forEach((each) => {
      const eachSplitted = each.split("=");
      const key = eachSplitted[0] as keyof LoginHandlerResponse;
      const value = eachSplitted[1];
      queryObject[key] = value;
    });

    // Query Object가 비어있을 경우, 네이버나 카카오가 호출한 콜백이 아니라고 확신할 수 있음.
    // 이런 경우엔 윈도우를 닫지 않음.
    if (isEmpty(queryObject)) {
      return;
    }

    const broadcast = new BroadcastChannel("jeomsin-react-sns-login");
    broadcast.postMessage(queryObject);
    window.close();
    broadcast.close();
  }, [location]);
}

export default function LoginHandler() {
  useLoginHandler();
  return <></>;
}
