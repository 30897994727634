import { FontScales, getCurrentFontScale, setFontScale } from '@Config/jeomsin_fonts';
import { JeomsinUserInfo } from '@DataDefine/jeomsin_user_info';
import { getRelationName } from '@DataDefine/relation_info_model';
import useImageShare from '@Hooks/use_image_share';
import apiLunarCalendar from '@Network/request/api_lunar_calendar';
import CalendarSelection from '@Views/common_component/calendar_selection/calendar_selection';
import { ColoredRectangleButton } from '@Views/common_component/colored_rectangle_button/colored_rectangle_button';
import IosStylePicker, { IosStylePickerItem } from '@Views/common_component/ios_style_picker/ios_style_picker';
import JeomsinSelectBox from '@Views/common_component/select_box/select_box';
import { Spacer, SpacerColor } from '@Views/common_component/spacer/spacer';
import { useToastPopupStore } from '@Views/common_component/toast_popup/toast_popup_manager';
import { SajuMultiUserInfomationModel } from '@Views/mypage/saju_multi_infomation/model/saju_multi_infomation';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { CSSTransition } from "react-transition-group";
import css from './modal_popup.module.css';
import { CounselorAlarmBox } from './sub_component/counselor_alarm_box/counselor_alarm_box';
import { PaymentCompleteBox } from './sub_component/payment_complete_box/payment_complete_box';
import { WheelPicker } from './sub_component/wheel_picker_box.tsx/wheel_picker_box';
import { scrollPrevent } from '@Util/scroll_prevent';

type ModalPopupProps = {
  isOpen: boolean;
  type: ModalPopupType;
  title?: string;
  contents?: any;
  helptext?: string;
  left?:number;
  data?:any;
  firstButtonTitle?: string;
  onClickFirstButton?: () => void;
  secondButtonTitle?: string;
  onClickSecondButton?: () => void;
  onClose: (returnData?:any) => void;
}

const space_text = " ";

const ModalPopup:React.FC<ModalPopupProps>  = ( props ) => {
  const popupRef = useRef(null);
  const [returnData, setReturnData] = useState<any>(undefined);
  const [isPopupClose, setPopupClose] = useState(false);

  useEffect(() => {
    if(isPopupClose)
      props.onClose(returnData);
    else { //팝업을 
      setReturnData(undefined);
    }

  }, [isPopupClose]);

  return (
    <CSSTransition
      nodeRef={popupRef}
      in={props.isOpen}
      timeout={{
        enter: 400,
        exit: 400,
      }}
      classNames="fade"
      mountOnEnter
      unmountOnExit
    >
      <section className={css.modal}>
        <div
          ref={popupRef} 
          className={css.dim}
          onClick={() => setPopupClose(true)}
          style={{
            left: props.left ?? undefined
          }}
        />
        <div className={css.modal_container}>
        {PopupType(props, setReturnData, setPopupClose)}
        </div>
      </section>
    </CSSTransition>
  )
};

export const ModalPopupType = {
  Normal: "Normal",
  TwoButtonRow: "TwoButtonRow",
  BirthTypeSelect: "CalenderTypeSelect",
  SajuInfoSelect: "SajuInfoSelect",
  FontSizeChange: "FontSizeChange",
  CalendarSelect: "CalendarSelect",
  SharePopup: "SharePopup",
  OhangGraphPopup: "OhangGraphPopup",
  SajuInfoPopup: "SajuInfoPopup",
  CounselorRegisterAlarm: "CounselorRegisterAlarm",
  WheelPicker: "WheelPicker",
  PaymentComplete: "PaymentComplete"
} as const;
export type ModalPopupType = (typeof ModalPopupType)[keyof typeof ModalPopupType];

const PopupType = (
  props: ModalPopupProps,
  setReturnData: React.Dispatch<React.SetStateAction<any>>,
  setPopupClose: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [isShowHelpText, setShowHelpText] = useState(false);
  const [tempData, setTempData] = useState<any>();
  const [chosenFontScale, setChosenFontScale] = useState<string>(getCurrentFontScale());
  const { setToastPopupContent } = useToastPopupStore();
  let returnValue: ReactNode;
  const { initiateShare } = useImageShare(css.modal_content, [css.share, css.close]);
  
  useEffect(() => {
    setPopupClose(false);
    if(props.isOpen) {
      scrollPrevent(true);
    }

    return () => {
      scrollPrevent(false);
    }
  }, [props.isOpen])

  switch (props.type) {
    case ModalPopupType.Normal:
      returnValue = (
        <div className={`${css.modal_content} ${css.modal_small_padding} ${css.cornerRadius25}`}>
          <div className={css.title}><span>{props.title}</span></div>
          <div><span className={css.contents_description}>{props.contents}</span></div>
          <div className={'pt40'} />
          <ColoredRectangleButton
            title="확인" 
            cornerRadius={true} 
            height="50px" 
            width="100%" 
            clickHandler={() => {
                if(props.onClickFirstButton) {
                  props.onClickFirstButton();
                }
                setPopupClose(true);
              }
            } 
          />
          <div 
            className={`${css.second_button} ts14 pt7 pb10`} 
            onClick={()=> { 
              if(props.onClickSecondButton)
                  props.onClickSecondButton();
              }
            }
          >
            {props.secondButtonTitle}
          </div>
        </div>
      );
      break;
    case ModalPopupType.TwoButtonRow:
        returnValue = (
          <div className={`${css.modal_content} ${css.cornerRadius25}`}>
            <div className={css.title}><span>{props.title}</span></div>
            <div><span className={css.contents_description}>{props.contents}</span></div>
            <div className={'pt40'} />
            <div className={css.two_button_wrap}>
              <div className={css.button} onClick={()=> {
                if(props.onClickFirstButton) {
                  props.onClickFirstButton();
                }
                setPopupClose(true);
              }}>{props.firstButtonTitle}</div>
              <div className={css.button} onClick={()=> {
                if(props.onClickSecondButton) {
                  props.onClickSecondButton();
                }
                setPopupClose(true);
              }}>{props.secondButtonTitle}</div>
            </div>
          </div>
        );
        break;
    case ModalPopupType.BirthTypeSelect:
      returnValue = (
        <div className={`${css.modal_content} ${css.help_content} ${css.cornerRadius20}`}>
          <div className={css.title}>음력 선택</div>
          <JeomsinSelectBox 
            options={[{label:"평달", value:"0"},{label:"윤달", value:"1"}]} 
            width={100} 
            gap={20}
            onSelect={async (event, index) => {
              const selectValue = event?.currentTarget?.value;
              if(selectValue === "1") { //평달 0, 윤달 1
                const isLeapMonth = await apiLunarCalendar(props.data);
                if(isLeapMonth === "Y") {
                  setShowHelpText(false);
                  setReturnData({value:selectValue, text:"(윤달)"});
                  setPopupClose(true);
                } else {
                  setReturnData({value:"-1", text:undefined});
                  setShowHelpText(true);
                }
              } else {
                setShowHelpText(false);
                setReturnData({value:selectValue, text:""});
                setPopupClose(true);
              }
            }}
          />
          <div className={`${css.help_text} ${isShowHelpText ? "" : space_text+"none"} ${css.margin_top_20}`}>입력한 날짜는 윤달 선택이 불가해요.</div>
        </div>
      );
      break;
      case ModalPopupType.SajuInfoSelect:

        const data = props.data;
        if (data && data.length > 0) {
          const dataList = data.map((user:JeomsinUserInfo, index:number) => {
            return {
              text: user.name + " (" + getRelationName(user.relation_type ?? "0") + ")",
              value: user
            }
          });
          returnValue = (
            <div className={`${css.modal_content} ${css.saju_info_select} ${css.cornerRadius25}`}>
              <div className={`${css.spacer}`} />
              <IosStylePicker 
                data={dataList} 
                selectionHandler={(selection) => {
                  setTempData(selection.value);
                }}
                prepareUI={props.isOpen}
              />
              <div className={`${css.spacer}`} />
              <ColoredRectangleButton title="확인" cornerRadius={true} height="50px" width="100%" clickHandler={() => {
                  setReturnData(tempData);
                  setPopupClose(true);
              }} />
              <div className={`${css.button} ts14 pt7 ${css.opacity40}`} onClick={()=> { 
                if(props.onClickFirstButton)
                  props.onClickFirstButton();
              }}>사주정보 추가하기</div>
            </div>
          );
        } else {
          returnValue = (
            <div className={`${css.modal_content} ${css.saju_info_select} ${css.cornerRadius25}`}>
              <div className={css.title}>알림</div>
              <Spacer height={40}/>
              <div className={`ts12 pt7`}>사주정보 추가 후 이용가능합니다.</div>
              <Spacer height={90}/>
              <ColoredRectangleButton title="사주정보 추가하기" cornerRadius={true} height="50px" width="100%" clickHandler={() => {
                  // setPopupClose(true);
                  if(props.onClickFirstButton)
                    props.onClickFirstButton();
              }} />
            </div>
          );
        }

        break;
    case ModalPopupType.FontSizeChange:
      returnValue = (
        <div className={`${css.modal_content} ${css.font_size_change_modal_content}`}>
          <div className={`${css.font_size_change_description_stack}`}>
            <span className={`${css.font_size_change_title}`}>글자 크기</span>
            <div className={`${css.spacer_height_30}`} />
            <span>사주분석 결과 페이지의</span>
            <span>글자 크기가 변경돼요.</span>
            <div className={`${css.font_size_change_title}`} />
            <div className={`${css.spacer_height_40}`} />
            <div className={`${css.font_size_change_font_chooser_h_stack}`}>
              <div onClick={() => {
                  const scale = FontScales.Small;
                  setFontScale(scale);
                  setChosenFontScale(scale);
                }} className={`${css.font_size_change_font_chooser_v_stack}`}>
                <div className={`${css.font_size_change_button}`}>
                  <span className={`${css.font_size_change_font_small_example}`}>Aa</span>
                </div>
                <div className={`${css.font_size_change_checkbox_and_description_container}`}>
                  <img className={`${css.font_size_change_checkbox_button_image}`}
                    src={`/images/member_integrated/member/3.0x/checkbox_24${chosenFontScale === FontScales.Small ? "_on" : ""}.png`}
                    alt="checkbox"
                  />
                  <span className={`${css.font_size_change_description}`}>작게</span>
                </div>
              </div>
              <div onClick={() => {
                  const scale = FontScales.Medium;
                  setFontScale(scale);
                  setChosenFontScale(scale);
                }} className={`${css.font_size_change_font_chooser_v_stack}`}>
                <div className={`${css.font_size_change_button}`}>
                  <span className={`${css.font_size_change_font_medium_example}`}>Aa</span>
                </div>
                <div className={`${css.font_size_change_checkbox_and_description_container}`}>
                  <img className={`${css.font_size_change_checkbox_button_image}`}
                    src={`/images/member_integrated/member/3.0x/checkbox_24${chosenFontScale === FontScales.Medium ? "_on" : ""}.png`}
                    alt="checkbox"
                  />
                  <span className={`${css.font_size_change_description}`}>보통</span>
                </div>
              </div>
              <div onClick={() => {
                  const scale = FontScales.Large;
                  setFontScale(scale);
                  setChosenFontScale(scale);
                }} className={`${css.font_size_change_font_chooser_v_stack}`}>
                <div className={`${css.font_size_change_button}`}>
                  <span className={`${css.font_size_change_font_big_example}`}>Aa</span>
                </div>
                <div className={`${css.font_size_change_checkbox_and_description_container}`}>
                  <img className={`${css.font_size_change_checkbox_button_image}`}
                    src={`/images/member_integrated/member/3.0x/checkbox_24${chosenFontScale === FontScales.Large ? "_on" : ""}.png`}
                    alt="checkbox"
                  />
                  <span className={`${css.font_size_change_description}`}>크게</span>
                </div>
              </div>
            </div>
            <div className={`${css.spacer_height_30}`} />
            <div onClick={() => {
              if (props.onClose) {
                props.onClose();
              }
            }} className={`${css.confirmation_button}`}>
                <span>확인</span>
            </div>
          </div>
        </div>
      );
      break;
    case ModalPopupType.CalendarSelect:
      returnValue = (
        <div className={css.calendar_selection_container}>
          <CalendarSelection 
            postSelectionAction={(result) => {
              props.onClose(result);
            }}
            targetDate={props.contents}
          />
        </div>
      );
      break;
    case ModalPopupType.SharePopup:
      returnValue = (
        <div className={`${css.modal_content} ${css.cornerRadius25}`}>
          <img
            className={css.close_button}
            src="images/member_integrated/mypage/setting/close.png"
            onClick={()=> {
              if (props.onClose) {
                props.onClose();
              }
            }}
          />
          <div className={css.share_popup_title}>{props.title}</div>
          <div className={css.contents}>
            <div className={css.share_popup_contents}>
              {props.contents}
            </div>
          </div>
          <Spacer height={30} />
          <div className={css.share_popup_button} onClick={()=> { 
                if(props.onClickFirstButton)
                  props.onClickFirstButton();
          }}>
            {props.firstButtonTitle}
          </div>
        </div>
      );
      break;
    case ModalPopupType.OhangGraphPopup:
      if(props.contents) {
        returnValue = (
          <div className={`${css.modal_content} ${css.cornerRadius25}`}>
            <img
              className={css.close_button}
              src="images/member_integrated/mypage/setting/close.png"
              onClick={()=> {
                if (props.onClose) {
                  props.onClose();
                }
              }}
            />
            <div className={css.ohang_graph_popup_title}>오행 유형 분포도</div>
            <Spacer height={24}/>
            <div className={css.contents}>
              <div className={css.ohang_graph_container}>
                {props.contents.ohangGraphPercentList.map((value:number, index:number) => (
                    <div className={`${css.bar} ${(value > 0) ? css.active : ""}`}>
                      <div className={css.stick}>
                        <div className={css.fill} style={{height:value+"%", backgroundColor:props.contents.ohangColorInfo[index].bgColor}}>
                          <div className={css.fill_end_point}/>
                        </div>
                      </div>
                      <span className={css.label}>{props.contents.ohangTextInfo[index].text2}</span>
                    </div>
                ))}
              </div>
              <Spacer height={24}/>
              <div className={css.ohang_circle_container}>
                <div className={css.ohang_item_wrap}>
                  <span className={css.ohang_circle_title}>잘 맞는 오행</span>
                  <div className={css.ohang_circle} style={{backgroundColor:props.contents.ohangColorInfo[props.contents.fitOnOhangIdx].bgColor}}>
                    <span className={css.ohang_circle_text}>{props.contents.ohangTextInfo[props.contents.fitOnOhangIdx].text2}</span>
                  </div>
                </div>
                <div className={css.ohang_item_wrap}>
                  <span className={css.ohang_circle_title}>안 맞는 오행</span>
                  <div className={css.ohang_circle} style={{backgroundColor:props.contents.ohangColorInfo[props.contents.fitOffOhangIdx].bgColor}}>
                    <span className={css.ohang_circle_text}>{props.contents.ohangTextInfo[props.contents.fitOffOhangIdx].text2}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      break;
    case ModalPopupType.SajuInfoPopup:
      if(props.contents) {
        const contentsData = props.contents.userData as SajuMultiUserInfomationModel;
        returnValue = (
          <div className={`${css.modal_content} ${css.cornerRadius25}`}>
            <div className={css.top_button_wrap}>
            <img
              className={css.share}
              src="images/saju_multi_information/share.png"
              onClick={() => initiateShare()}
              alt={"Share Button"}
            />
            <img
              className={css.close}
              src="images/personal_connection/close.png"
              onClick={()=> {
                if (props.onClose) {
                  props.onClose();
                }
              }}
              alt={"Close Button"}
            />
            </div>
            <div className={css.contents}>
              <div className={css.saju_info_popup_profile}>
                <img className={css.ohang_img} src={props.contents.myOhangImagePath} alt="ohang"/>
                <img className={`${css.profile_img} ${props.contents.userData.isSelected ? css.selected : ""}`} src={contentsData.profileIco} alt="profile"/>
              </div>
              <Spacer height={10}/>
              <div className={css.saju_info_popup}>
                <div className={css.name}>{contentsData.name}</div>
                <Spacer height={5}/>
                <div className={css.birth}>{contentsData.birthday} ({contentsData.relationshipTitle})</div>
              </div>
              <Spacer height={30}/>
              <Spacer height={1} color={SpacerColor.GrayUnderLine}/>
              <Spacer height={30}/>
              <div className={css.saju_info_popup}>
                <div className={css.day}>
                  {props.contents.isNextDay ? "내일의 운세" : "오늘의 운세"} <span className={css.score}>{props.contents.unseScore}</span>
                </div>
                <Spacer height={10}/>
                <div className={css.unse_text}>{props.contents.unseContent}</div>
              </div>
            </div>
            <Spacer height={20}/>
            <div className={css.saju_info_popup_button} onClick={()=> { 
                if(props.onClickFirstButton)
                  props.onClickFirstButton();
            }}>
              대표 사주 변경
            </div>
          </div>
        );
      }
        break;
      case ModalPopupType.WheelPicker:
        if (props?.contents?.length > 0) {
          returnValue = (
            <div className={`${css.modal_content} ${css.cornerRadius25}`}>
              <WheelPicker 
                data={props.contents} 
                onPicked={(item: IosStylePickerItem | undefined) => {
                  setReturnData(item);
                  setPopupClose(true);
              }}/>
            </div>
          )
        }
        break;
    case ModalPopupType.CounselorRegisterAlarm:
      returnValue = (
        <div className={`${css.modal_content} ${css.cornerRadius25}`}>
          <CounselorAlarmBox contents={props.contents}/>
        </div>
      )
      break;
    case ModalPopupType.PaymentComplete:
      returnValue = (
        <div className={`${css.modal_content} ${css.cornerRadius25}`}>
          <PaymentCompleteBox 
            contents={props.contents} 
            onClick={() => {
              if(props.onClose) {
                props.onClose()
              }
            }}
          />
        </div>
      )
      break;
    default:
      returnValue = <></>;
      break;
  }

  return returnValue;
};

export default ModalPopup;