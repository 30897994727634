
import { checktJeomsinFirstVisit } from "@Config/jeomsin_application_info";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface ZFortune {
    //TODO fortune.tsx에 선언된 state 요소들을 추후 zustand로 이관

    initState:() => void,
    doScrollTo:boolean,
    setDoScrollTo:(value:boolean) => void,
    scrollYPosition:number,
    saveScrollYPosition:(reset?:boolean) => void,
    getPrevScrollYPostion:() => number,
}

const useFortuneStore = create(
    persist<ZFortune>(
        (set, get) => ({
            initState:() => {
                set({
                    doScrollTo:false,
                    scrollYPosition:0,
                })
            },
            doScrollTo:false,
            setDoScrollTo:(value:boolean) => set({doScrollTo:value}),
            scrollYPosition:0,
            saveScrollYPosition:(reset?:boolean) => {
                const posY = reset ? 0 : window.scrollY;
                set({ scrollYPosition: posY ?? 0 });
            },
            getPrevScrollYPostion:() => {
                //포지션 유지는 1회
                const posY:number = checktJeomsinFirstVisit() ? 0 : get().scrollYPosition;
                set({ scrollYPosition: 0, doScrollTo:false });
                
                return posY;
            }
        }),
        { 
            name: 'fortune-home-storage',
            storage: createJSONStorage(() => sessionStorage) //탭 종료 시 스토리지에 저장된 데이터 Clear
        }
    )
);

export { useFortuneStore };
