
type GroupsInfo = {
  [key:string]:GroupInfo
}

type GroupInfo = {
  groupName:string,
  groupIdx:string,
}

type RelationInfo = {
  groupName:string,
  groupIdx:string,
  relationName:string,
  relationIdx:string,
}


export const GroupTypes:GroupsInfo = {
  my:{groupName:'본인', groupIdx:'0'},
  family:{groupName:'가족', groupIdx:'1'},
  friend:{groupName:'친구', groupIdx:'2'},
  company:{groupName:'회사', groupIdx:'3'},
  none:{groupName:'미설정', groupIdx:'999'},
}


export const RelationTypes:RelationInfo[] = [
  {groupName:GroupTypes.my.groupName, groupIdx:GroupTypes.my.groupIdx, relationName:'본인', relationIdx:'0'},

  {groupName:GroupTypes.family.groupName, groupIdx:GroupTypes.family.groupIdx, relationName:'할머니', relationIdx:'13'},
  {groupName:GroupTypes.family.groupName, groupIdx:GroupTypes.family.groupIdx, relationName:'할아버지', relationIdx:'14'},
  {groupName:GroupTypes.family.groupName, groupIdx:GroupTypes.family.groupIdx, relationName:'아버지', relationIdx:'1'},
  {groupName:GroupTypes.family.groupName, groupIdx:GroupTypes.family.groupIdx, relationName:'어머니', relationIdx:'2'},
  {groupName:GroupTypes.family.groupName, groupIdx:GroupTypes.family.groupIdx, relationName:'배우자', relationIdx:'3'},
  {groupName:GroupTypes.family.groupName, groupIdx:GroupTypes.family.groupIdx, relationName:'아들', relationIdx:'4'},
  {groupName:GroupTypes.family.groupName, groupIdx:GroupTypes.family.groupIdx, relationName:'딸', relationIdx:'5'},
  {groupName:GroupTypes.family.groupName, groupIdx:GroupTypes.family.groupIdx, relationName:'형제', relationIdx:'6'},
  {groupName:GroupTypes.family.groupName, groupIdx:GroupTypes.family.groupIdx, relationName:'자매', relationIdx:'7'},

  {groupName:GroupTypes.friend.groupName, groupIdx:GroupTypes.friend.groupIdx, relationName:'애인', relationIdx:'8'},
  {groupName:GroupTypes.friend.groupName, groupIdx:GroupTypes.friend.groupIdx, relationName:'친구', relationIdx:'10'},

  {groupName:GroupTypes.company.groupName, groupIdx:GroupTypes.company.groupIdx, relationName:'선배', relationIdx:'15'},
  {groupName:GroupTypes.company.groupName, groupIdx:GroupTypes.company.groupIdx, relationName:'동기', relationIdx:'9'},
  {groupName:GroupTypes.company.groupName, groupIdx:GroupTypes.company.groupIdx, relationName:'후배', relationIdx:'16'},

  {groupName:GroupTypes.none.groupName, groupIdx:GroupTypes.none.groupIdx, relationName:'기타', relationIdx:'11'},
  {groupName:GroupTypes.none.groupName, groupIdx:GroupTypes.none.groupIdx, relationName:'선택안함', relationIdx:'12'},
];

export const getRelationName = (relationNumber:string) => {
  const result = RelationTypes.filter((value) => 
    value.relationIdx == relationNumber
  );

  return result[0].relationName;
}

export const getGroupType = (relationNumber:string) => {
  const result = RelationTypes.filter((value) => 
    value.relationIdx == relationNumber
  );

  return result[0].groupIdx;
}

export const familyTitle = [
  ['할머니', '13'],
  ['할아버지', '14'],
  ['아버지', '1'],
  ['어머니', '2'],
  ['배우자', '3'],
  ['아들', '4'],
  ['딸', '5'],
  ['형제', '6'],
  ['자매', '7'],
];
export const companyTitle = [
  ['선배', '15'],
  ['동기', '9'],
  ['후배', '16'],
];
export const relationshipTitle = [
  ['애인', '8'],
  ['친구', '10'],
];
export const noneTitle = [
  ['선택안함', '12'],
  ['기타', '11'],
];

export const relationGroups = [
  [
    '본인',
    '0',
    [
      'assets/images/personal_connection/emoji120/logo_white.png',
      'assets/images/personal_connection/emoji120/logo_black.png',
    ],
    [
      'assets/images/personal_connection/emoji80/logo_white.png',
      'assets/images/personal_connection/emoji120/logo_black.png',
    ],
    [
      'assets/images/personal_connection/emoji70/logo_white.png',
      'assets/images/personal_connection/emoji120/logo_black.png',
    ],
    [
      'assets/images/personal_connection/emoji60/logo_white.png',
      'assets/images/personal_connection/emoji120/logo_black.png',
    ],
  ],
  [
    '아버지',
    '1',
    'assets/images/personal_connection/emoji120/emoji3.png',
    'assets/images/personal_connection/emoji80/emoji3.png',
    'assets/images/personal_connection/emoji70/emoji3.png',
    'assets/images/personal_connection/emoji60/emoji3.png',
  ],
  [
    '어머니',
    '2',
    'assets/images/personal_connection/emoji120/emoji4.png',
    'assets/images/personal_connection/emoji80/emoji4.png',
    'assets/images/personal_connection/emoji70/emoji4.png',
    'assets/images/personal_connection/emoji60/emoji4.png',
  ],
  [
    '배우자',
    '3',
    'assets/images/personal_connection/emoji120/emoji7.png',
    'assets/images/personal_connection/emoji80/emoji7.png',
    'assets/images/personal_connection/emoji70/emoji7.png',
    'assets/images/personal_connection/emoji60/emoji7.png',
  ],
  [
    '아들',
    '4',
    'assets/images/personal_connection/emoji120/emoji8.png',
    'assets/images/personal_connection/emoji80/emoji8.png',
    'assets/images/personal_connection/emoji70/emoji8.png',
    'assets/images/personal_connection/emoji60/emoji8.png',
  ],
  [
    '딸',
    '5',
    'assets/images/personal_connection/emoji120/emoji9.png',
    'assets/images/personal_connection/emoji80/emoji9.png',
    'assets/images/personal_connection/emoji70/emoji9.png',
    'assets/images/personal_connection/emoji60/emoji9.png',
  ],
  [
    '형제',
    '6',
    'assets/images/personal_connection/emoji120/emoji5.png',
    'assets/images/personal_connection/emoji80/emoji5.png',
    'assets/images/personal_connection/emoji70/emoji5.png',
    'assets/images/personal_connection/emoji60/emoji5.png',
  ],
  [
    '자매',
    '7',
    'assets/images/personal_connection/emoji120/emoji6.png',
    'assets/images/personal_connection/emoji80/emoji6.png',
    'assets/images/personal_connection/emoji70/emoji6.png',
    'assets/images/personal_connection/emoji60/emoji6.png',
  ],
  [
    '애인',
    '8',
    'assets/images/personal_connection/emoji120/emoji16.png',
    'assets/images/personal_connection/emoji80/emoji16.png',
    'assets/images/personal_connection/emoji70/emoji16.png',
    'assets/images/personal_connection/emoji60/emoji16.png',
  ],
  [
    '동기',
    '9',
    ['assets/images/personal_connection/emoji120/emoji11.png', 'assets/images/personal_connection/emoji120/emoji14.png'],
    ['assets/images/personal_connection/emoji80/emoji11.png', 'assets/images/personal_connection/emoji80/emoji14.png'],
    ['assets/images/personal_connection/emoji70/emoji11.png', 'assets/images/personal_connection/emoji70/emoji14.png'],
    ['assets/images/personal_connection/emoji60/emoji11.png', 'assets/images/personal_connection/emoji60/emoji14.png'],
  ],
  [
    '친구',
    '10',
    ['assets/images/personal_connection/emoji120/emoji17.png', 'assets/images/personal_connection/emoji120/emoji18.png'],
    ['assets/images/personal_connection/emoji80/emoji17.png', 'assets/images/personal_connection/emoji80/emoji18.png'],
    ['assets/images/personal_connection/emoji70/emoji17.png', 'assets/images/personal_connection/emoji70/emoji18.png'],
    ['assets/images/personal_connection/emoji60/emoji17.png', 'assets/images/personal_connection/emoji60/emoji18.png'],
  ],
  [
    '기타',
    '11',
    [
      'assets/images/personal_connection/emoji120/logo_white.png',
      'assets/images/personal_connection/emoji120/logo_black.png',
    ],
    [
      'assets/images/personal_connection/emoji80/logo_white.png',
      'assets/images/personal_connection/emoji120/logo_black.png',
    ],
    [
      'assets/images/personal_connection/emoji70/logo_white.png',
      'assets/images/personal_connection/emoji120/logo_black.png',
    ],
    [
      'assets/images/personal_connection/emoji60/logo_white.png',
      'assets/images/personal_connection/emoji120/logo_black.png',
    ],
  ],
  [
    '선택안함',
    '12',
    [
      'assets/images/personal_connection/emoji120/logo_white.png',
      'assets/images/personal_connection/emoji120/logo_black.png',
    ],
    [
      'assets/images/personal_connection/emoji80/logo_white.png',
      'assets/images/personal_connection/emoji120/logo_black.png',
    ],
    [
      'assets/images/personal_connection/emoji70/logo_white.png',
      'assets/images/personal_connection/emoji120/logo_black.png',
    ],
    [
      'assets/images/personal_connection/emoji60/logo_white.png',
      'assets/images/personal_connection/emoji120/logo_black.png',
    ],
  ],
  [
    '할머니',
    '13',
    'assets/images/personal_connection/emoji120/emoji1.png',
    'assets/images/personal_connection/emoji80/emoji1.png',
    'assets/images/personal_connection/emoji70/emoji1.png',
    'assets/images/personal_connection/emoji60/emoji1.png',
  ],
  [
    '할아버지',
    '14',
    'assets/images/personal_connection/emoji120/emoji2.png',
    'assets/images/personal_connection/emoji80/emoji2.png',
    'assets/images/personal_connection/emoji70/emoji2.png',
    'assets/images/personal_connection/emoji60/emoji2.png',
  ],
  [
    '선배',
    '15',
    ['assets/images/personal_connection/emoji120/emoji10.png', 'assets/images/personal_connection/emoji120/emoji13.png'],
    ['assets/images/personal_connection/emoji80/emoji10.png', 'assets/images/personal_connection/emoji80/emoji13.png'],
    ['assets/images/personal_connection/emoji70/emoji10.png', 'assets/images/personal_connection/emoji70/emoji13.png'],
    ['assets/images/personal_connection/emoji60/emoji10.png', 'assets/images/personal_connection/emoji60/emoji13.png'],
  ],
  [
    '후배',
    '16',
    ['assets/images/personal_connection/emoji120/emoji12.png', 'assets/images/personal_connection/emoji120/emoji15.png'],
    ['assets/images/personal_connection/emoji80/emoji12.png', 'assets/images/personal_connection/emoji80/emoji15.png'],
    ['assets/images/personal_connection/emoji70/emoji12.png', 'assets/images/personal_connection/emoji70/emoji15.png'],
    ['assets/images/personal_connection/emoji60/emoji12.png', 'assets/images/personal_connection/emoji60/emoji15.png'],
  ],
];

export const relationAlls = [
  '본인',
  '아버지',
  '어머니',
  '배우자',
  '아들',
  '딸',
  '형제',
  '자매',
  '애인',
  '동료',
  '친구',
  '기타',
  '선택안함',
  '할머니',
  '할아버지',
  '선배',
  '후배',
];

export const relations = [
  '아버지',
  '어머니',
  '배우자',
  '아들',
  '딸',
  '형제',
  '자매',
  '애인',
  '동료',
  '친구',
  '기타',
  '선택안함',
  '할머니',
  '할아버지',
  '선배',
  '후배',
];