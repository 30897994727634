import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";
import { IdType } from "./api_combine_member_login";
import {
  CombineMemberModel,
  JeomsinUserInfo,
} from "@DataDefine/jeomsin_user_info";
import { MemberManseInfo } from "./api_get_saju_list";
import { getDeviceId, getStoreType, getVersion } from "@Config/jeomsin_application_info";

interface CombineMemberSignResponse {
  mem_info?: CombineMemberModel;
  mem_item: {
    mem_no: string;
    use_count: string;
    basic_count: string;
    bonus_count: string;
    pop_free_3min?: "Y" | "N";
  };
  mem_saju: MemberManseInfo;
  result: boolean;
  msg?: string;
}

/**
 * @throws {Error}
 */
const apiCombineMemberSign = async (
  idCode: string,
  idType: IdType,
  phoneNumber: string,
  userInfo: JeomsinUserInfo
): Promise<CombineMemberSignResponse> => {
  let btimeCode = "1";
  if (userInfo.isBirthTimeUnknown === true) {
    btimeCode = "1";
  } else {
    btimeCode = "0";
  }

  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.CombineMemberSign,
    params: {
      device_id: getDeviceId(),
      store: getStoreType(),
      app_ver: getVersion(),
      ad_id: "",
      id_code: idCode,
      id_type: `${idType.sendableIdType}`,
      mem_mobile: phoneNumber.replaceAll("-", ""),
      myname: userInfo.name,
      myyear: userInfo.birthYear,
      mymonth: userInfo.birthMonth,
      myday: userInfo.birthDay,
      myhour: userInfo.birthTime,
      btime: userInfo.birthMinute,
      btime_code: btimeCode,
      mycalendar: `${userInfo.calendarType}`,
      myleap: userInfo.isLeapMonth ? "1" : "0",
      mygender: userInfo.gender,
    },
  };

  const inspect = (await JeomsinRequest(request)) as CombineMemberSignResponse;

  if (inspect.msg !== undefined) {
    throw TypeError(inspect.msg);
  }

  return inspect;
};

export default apiCombineMemberSign;
