import CookieKeys from "@Storage/cookie_keys";
import CookieManager from "@Storage/cookie_manager";

export const UiThemeType = {
    LightTheme: "LightTheme",
    DarkTheme: "DarkTheme",
} as const;
export type UiThemeType = typeof UiThemeType[keyof typeof UiThemeType];

export let SelectedTheme:string|undefined = CookieManager.GetString(CookieKeys.jeomsinTheme) ?? UiThemeType.LightTheme;

export const setTheme = (theme:UiThemeType) => {
    SelectedTheme = theme;
    CookieManager.SetString(CookieKeys.jeomsinTheme, theme);
}

export const getThemeIconSuffix = ():string => {
    return (SelectedTheme == UiThemeType.LightTheme) ? "" : "_b";
}
