import { getDeviceId } from "@Config/jeomsin_application_info";
import { initializeColors } from "@Config/jeomsin_colors";
import {
  initializeFontScale,
  initializeFontType,
  initializeFontWeight,
} from "@Config/jeomsin_fonts";
import {
  initializeHeightScale,
  initializeScaleOption,
  updateHeightScale,
  updateScaleOption,
} from "@Config/jeomsin_scale";
import useRunOnce from "@Hooks/use_run_once";
import { apiGetFieldV3 } from "@Network/request/api_get_field_v3";
import {
  apiGetSNSContentCount,
  useSNSCountStore,
} from "@Network/request/api_get_sns_content_count";
import {
  apiGetTalismanPage,
  useTalismanInfoStore,
} from "@Network/request/api_get_talisman_page";
import { useARSAndTextPricing } from "@Network/request/local_api_check_ars_and_text_price";
import { useAppConfigStore } from "@State/use_app_config_store";
import CookieKeys from "@Storage/cookie_keys";
import CookieManager from "@Storage/cookie_manager";
import { useModalPopup } from "@Views/common_component/async_modal_popup/async_modal_popup";
import useHandleUserAutoLogin from "@Views/main_tab/fortune/use_handle_auto_login";
import {
  checkCsvVersion,
  downloadDreamData,
} from "@Views/unse/dream/model/dream_model";
import { useCoupangAdViewStore } from "@Views/web_ad/use_coupang_ad_view_store";
import Box from "@mui/material/Box";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useCallback, useEffect, useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { AppContainer } from "./AppContainer";

function App() {
  const { setModalPopupContent } = useModalPopup();
  const autoLoginHandler = useRef(useHandleUserAutoLogin());
  const setModalPopupContentWrapper = useRef(setModalPopupContent);

  useEffect(() => {
    setModalPopupContentWrapper.current = setModalPopupContent;
  }, [setModalPopupContent]);

  const initAction = useCallback(async () => {
    getDeviceId();

    //TODO 더 이상 appConfig를 CookieManager로 관리하지 마세요. 이미 적용된 소스는 추후 Zustand로 통합합니다.
    const appConfig = await useAppConfigStore.getState().loadAppConfig();
    const newSNSCount = await apiGetSNSContentCount();
    useSNSCountStore.getState().setSnsCount(newSNSCount);

    useCoupangAdViewStore.getState().initState();
    useCoupangAdViewStore.getState().loadCoupangConfig();
    
    const newTalismanPageResponse = await apiGetTalismanPage();
    useTalismanInfoStore
      .getState()
      .setTalismanPageResponse(newTalismanPageResponse);

    if (appConfig) {
      CookieManager.SetString(
        CookieKeys.fortuneUrl,
        appConfig?.info?.app_url ?? ""
      );
      CookieManager.SetString(
        CookieKeys.stateUrl,
        appConfig?.info?.stats_url ?? ""
      );
      CookieManager.SetString(
        CookieKeys.statsApiUrl,
        appConfig?.info?.stats_file ?? ""
      );
      CookieManager.SetString(
        CookieKeys.recordSampleUrl,
        appConfig?.ars_record_sample_url ?? ""
      );

      useARSAndTextPricing
        .getState()
        .setTextPricing(Number(appConfig.call_text_ars));
      useARSAndTextPricing
        .getState()
        .setARSPricing(Number(appConfig.call_price_ars));
      useARSAndTextPricing
        .getState()
        .setARSSecPricing(Number(appConfig.call_price_ars_s));

      if (checkCsvVersion(appConfig.dream_csv_ver)) {
        await downloadDreamData(
          appConfig.dream_csv_ver,
          appConfig.dream_csv_file
        );
      }
    }

    const fieldList = await apiGetFieldV3();
    if (fieldList) {
      CookieManager.SetJSON(CookieKeys.fieldv3, fieldList);
    }
  }, []);

  const loginAction = useCallback(async () => {
    const { isSuccess, message } =
      await autoLoginHandler.current.handleUserLogin();

    if (isSuccess === false) {
      autoLoginHandler.current.wipeout();

      await setModalPopupContentWrapper.current({
        title: "자동 로그인 해제",
        description:
          message ??
          "일시적인 네트워크 오류가 발생했습니다. 잠시 후 다시 시도해 주시길 바랍니다.",
        buttonTitles: ["확인"],
      });
    }
  }, []);

  //오른쪽 키 방지 이벤트 추가
   // 기존 useEffect로 초기화 작업
   useEffect(() => {
    const handleContextMenu = (e: { preventDefault: () => void; }) => {
      e.preventDefault(); // 오른쪽 버튼 이벤트 방지
    };

    // document에 오른쪽 버튼 비활성화 이벤트 추가
    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      // 컴포넌트 언마운트 시 이벤트 제거
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  
  useEffect(() => {
    initializeScaleOption();
    initializeFontWeight();
    initializeFontScale();
    initializeFontType();
    initializeColors();
    initializeHeightScale();

    window.addEventListener("resize", function () {
      updateScaleOption();
      updateHeightScale();
    });
    
  }, []);

  // Initialization of stored information will be done for each refresh
  useEffect(() => {
    initAction();
  }, []);

  // Login will be checked ONCE during the session
  useRunOnce(() => {
    loginAction();
  });

  return (
    <Box className="main-container">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}>
          <AppContainer />
        </GoogleOAuthProvider>
      </BrowserRouter>
    </Box>
  );
}

export default App;
