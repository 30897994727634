import CookieKeys from "@Storage/cookie_keys";
import CookieManager from "@Storage/cookie_manager";
import { SelectedScaleOption } from "@Config/jeomsin_scale";

export const FontScales = {
    Large:"6px",
    Medium:"3px",
    Small:"0px"
} as const;

export const initializeFontScale = () => {
    const scale = CookieManager.GetString(CookieKeys.fontScale) ?? FontScales.Medium.toString();
    if (scale) {
        const style = document.createElement('style');
        style.id = "FontScale";
    
        style.innerHTML = `:root {
                --font-scale:${scale}
            }
        `;
        document.head.appendChild(style);
    }
}

export const setFontScale = (fontScale:string) => {
    const targetStyles = document.getElementById('FontScale') as HTMLStyleElement;
    const styleSheet = targetStyles.sheet as CSSStyleSheet;
    
    for (const rule of styleSheet.cssRules) {
      if (rule instanceof CSSStyleRule && rule.selectorText === ':root') {
        rule.style.setProperty(`--font-scale`, `${fontScale}`);
      }
    }

    CookieManager.SetString(CookieKeys.fontScale, fontScale.toString());
}

export const getCurrentFontScale = (): string => {
    const rootStyle = getComputedStyle(document.documentElement);
    const scaleString = rootStyle.getPropertyValue("--font-scale");

    return scaleString ?? FontScales.Medium
}

export const JeomsinFontWeight = {
    Thin: 100,
    ExtraLight: 200,
    Light: 300,
    Regular: 400,
    Medium: 500,
    SemiBold: 600,
    Bold: 700,
    ExtraBold: 800,
    Black: 900
}
export type JeomsinFontWeight = (typeof JeomsinFontWeight)[keyof typeof JeomsinFontWeight];

export const initializeFontWeight = () => {
    const style = document.createElement('style');
    style.id = "FontWeight";

    style.innerHTML = `:root {\n`;

    Object.entries(JeomsinFontWeight).forEach(([key, value]) => {
        style.innerHTML += `--FontWeight${key}: ${value};\n`;
    });

    style.innerHTML += `}`;
    document.head.appendChild(style);
}

//TODO 피그마등 디자인에서 추가된 값이 있으면 추가해서 사용
export const JeomsinFontType = {
    HeadLine1: {fontWeight:JeomsinFontWeight.Bold, fontSize: 27, className:"head-line-font1"},
    HeadLine2: {fontWeight:JeomsinFontWeight.Bold, fontSize: 25, className:"head-line-font2"},
    HeadLine3: {fontWeight:JeomsinFontWeight.Bold, fontSize: 22, className:"head-line-font3"},
    HeadLine4: {fontWeight:JeomsinFontWeight.Bold, fontSize: 19, className:"head-line-font4"},
    HeadLine5: {fontWeight:JeomsinFontWeight.Regular, fontSize: 19, className:"head-line-font5"},

    SubTitle1: {fontWeight:JeomsinFontWeight.ExtraBold, fontSize: 17, className:"sub-title-font1"},
    SubTitle2: {fontWeight:JeomsinFontWeight.SemiBold, fontSize: 17, className:"sub-title-font2"},
    SubTitle3: {fontWeight:JeomsinFontWeight.SemiBold, fontSize: 15, className:"sub-title-font3"},
    SubTitle4: {fontWeight:JeomsinFontWeight.Medium, fontSize: 14, className:"sub-title-font4"},
    SubTitle5: {fontWeight:JeomsinFontWeight.Bold, fontSize: 13, className:"sub-title-font5"},
    SubTitle6: {fontWeight:JeomsinFontWeight.Medium, fontSize: 13, className:"sub-title-font6"},

    Body1: {fontWeight:JeomsinFontWeight.Regular, fontSize: 16, className:"body-font1"},
    Body2: {fontWeight:JeomsinFontWeight.Medium, fontSize: 14, className:"body-font2"},
    Body3: {fontWeight:JeomsinFontWeight.Bold, fontSize: 13, className:"body-font3"},

    Button1: {fontWeight:JeomsinFontWeight.SemiBold, fontSize: 18, className:"button-font1"},
    Button2: {fontWeight:JeomsinFontWeight.ExtraBold, fontSize: 16, className:"button-font2"},
    Button3: {fontWeight:JeomsinFontWeight.SemiBold, fontSize: 16, className:"button-font3"},
    Button4: {fontWeight:JeomsinFontWeight.Bold, fontSize: 13, className:"button-font4"},

    Caption1: {fontWeight:JeomsinFontWeight.Medium, fontSize: 12, className:"caption-font1"},
    Caption2: {fontWeight:JeomsinFontWeight.ExtraBold, fontSize: 12, className:"caption-font2"}
}
export type JeomsinFontType = (typeof JeomsinFontType)[keyof typeof JeomsinFontType];

export const initializeFontType = () => {
    const style = document.createElement('style');
    style.id = "FontType";

    let rootProperty = `:root {\n`;

    Object.entries(JeomsinFontType).forEach(([key, value]) => {
        //css에 변수 주입
        const fontSize = value.fontSize + SelectedScaleOption;
        rootProperty += `--FontTypeW${key}: ${value.fontWeight};\n`;
        rootProperty += `--FontTypeS${key}: ${fontSize}px;\n`;

        //css class 주입
        style.innerHTML += `
            .${value.className} {
                font-weight: ${value.fontWeight};
                font-size: ${fontSize}px;
            }
        `;
    });

    rootProperty += `}`;
    style.append(rootProperty);
    document.head.appendChild(style);
}

export const getFontSize = (size:string|number) => {
    let calc:number = 0;
    if(typeof size == "number") {
        calc = size;
    } else {
        const sizeString = size.replace("px", "");
        calc = Number(sizeString);
    }

    const result = calc ? calc + SelectedScaleOption : 0;

    return result+"px";
}