import { JeomsinLoginMemberInfo, JeomsinUserInfo } from "@DataDefine/jeomsin_user_info";
import CookieKeys from "@Storage/cookie_keys";
import CookieManager from "@Storage/cookie_manager";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface UserInfo {
  representativeUserInfo?: JeomsinUserInfo;
  setRepresentativeUserInfo: (value: JeomsinUserInfo | undefined) => void;
  changeRepresentativeUserInfo:(value:JeomsinUserInfo) => void;
  getRepresentativeUserName:() => string;
  getRepresentativeUserAge:() => number;
  getRepresentativeUserAgeRange:() => number;
  getRepresentativeUserGender:() => string;

  defaultUserInfo: () => JeomsinUserInfo;
  userInfoList?: JeomsinUserInfo[];
  setUserInfoList: (value: JeomsinUserInfo[] | undefined) => void;
  addUserInfoList: (value: JeomsinUserInfo | undefined) => void;
  removeUserInfo:(value:JeomsinUserInfo) => void;

  loginMemberInfo?: JeomsinLoginMemberInfo;
  setLoginMemberInfo: (value: JeomsinLoginMemberInfo | undefined) => void;
  isFullySignedIn: boolean;
  setFullySignedIn: (value: boolean) => void;

  initOneTimeUseInfoList: () => void;
  oneTimeUseInfoList?: JeomsinUserInfo[];
  addOneTimeUseInfoList: (value: JeomsinUserInfo | undefined) => void;
  deleteOneTimeUseInfoList: (value: JeomsinUserInfo | undefined) => void;


  //Counselor Home에 호출되는 Counselor List View에서 Counselor 정보 호출 시 갱신
  useCoin:number;
  setUseCoin:(value:number) => void;
}

const useUserInfoStore = create(
  persist<UserInfo>(
    (set, get) => ({
      representativeUserInfo: CookieManager.GetJSON(CookieKeys.representative_user_info),
      setRepresentativeUserInfo: (value: JeomsinUserInfo | undefined) => {
        if (value === undefined) {
          set({ representativeUserInfo: undefined });
          CookieManager.RemoveKeyValue(CookieKeys.representative_user_info);
        }

        if(value) {
          set({ representativeUserInfo: value });
          CookieManager.SetJSON(CookieKeys.representative_user_info, value);
        }
      },
      changeRepresentativeUserInfo: (value:JeomsinUserInfo) => {
        if(value) {
          set({ representativeUserInfo: value });
          CookieManager.SetJSON(CookieKeys.representative_user_info, value);
        }
      },
      getRepresentativeUserName: () => {
        return get().representativeUserInfo?.name ?? "게스트"
      },
      getRepresentativeUserAge: () => {
        const today = new Date();
        const age = today.getFullYear() - Number(get().representativeUserInfo?.birthYear ?? "1998");
        return age;
      },
      getRepresentativeUserAgeRange: () => {
        return (Math.floor(get().getRepresentativeUserAge() / 10) * 10);
      },
      getRepresentativeUserGender: () => {
        return get().representativeUserInfo?.genderText ?? "남성"
      },

      defaultUserInfo : () => CookieManager.GetJSON(CookieKeys.user_info_list)?.[0] ?? undefined, //본인 정보
      userInfoList: CookieManager.GetJSON(CookieKeys.user_info_list),
      setUserInfoList: (value: JeomsinUserInfo[] | undefined) => {
        if(value) {
          set({ userInfoList: value });
          CookieManager.SetJSON(CookieKeys.user_info_list, value);
          
        } else {
          set({ userInfoList: undefined });
          CookieManager.RemoveKeyValue(CookieKeys.user_info_list);
        }
      },
      addUserInfoList: (value: JeomsinUserInfo | undefined) => {
        if(value) {
          let dataList:JeomsinUserInfo[] = CookieManager.GetJSON(CookieKeys.user_info_list) ?? [];
          
          const existUser = dataList.find((user, index) => user.localId === value.localId);

          if(existUser) {
            dataList = dataList.map((user, index) => (user.localId === value.localId) ? value : user);
            if(value.localId == get().representativeUserInfo?.localId) {
              get().setRepresentativeUserInfo(value);
            }
          } else {
            dataList.push(value);
          }

          set({ userInfoList: dataList });
          CookieManager.SetJSON(CookieKeys.user_info_list, dataList);
        }
      },
      removeUserInfo: (value:JeomsinUserInfo) => {
        let dataList:JeomsinUserInfo[] = CookieManager.GetJSON(CookieKeys.user_info_list) ?? [];

        const newUserList = dataList.filter((user, index) => user.localId !== value.localId);

        set({ userInfoList: newUserList });
        CookieManager.SetJSON(CookieKeys.user_info_list, newUserList);
      },

      loginMemberInfo: CookieManager.GetJSON(CookieKeys.combineMemData),
      setLoginMemberInfo: (value: JeomsinLoginMemberInfo | undefined) => {
        set({ loginMemberInfo: value });
      },
      isFullySignedIn: false,
      setFullySignedIn: (isFullySignedIn: boolean) => set({ isFullySignedIn }),

      initOneTimeUseInfoList: () => {
          set({ oneTimeUseInfoList: undefined });
      },
      oneTimeUseInfoList: undefined,
      addOneTimeUseInfoList: (value: JeomsinUserInfo | undefined) => {
        if(value) {
          let dataList:JeomsinUserInfo[] = get().oneTimeUseInfoList ?? [];
          
          const existUser = dataList.find((user, index) => user.localId === value.localId);

          if(existUser) {
            dataList = dataList.map((user, index) => (user.localId === value.localId) ? value : user);
          } else {
            dataList.push(value);
          }

          set({ oneTimeUseInfoList: dataList });
        }
      },
      deleteOneTimeUseInfoList: (value: JeomsinUserInfo | undefined) => {
        if(value) {
          let dataList:JeomsinUserInfo[] = get().oneTimeUseInfoList ?? [];
          
          const existUser = dataList.indexOf(value);
          
          if(existUser > -1) {
            dataList.splice(existUser, 1);
            set({ oneTimeUseInfoList: dataList });
          }
        }
      },

      useCoin:0,
      setUseCoin:(value:number) => set({useCoin:value}),

    }),
    { 
        name: 'user-info-storage',
        storage: createJSONStorage(() => sessionStorage) //탭 종료 시 스토리지에 저장된 데이터 Clear
    }
  )
);

export { useUserInfoStore };
