/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Box } from "@mui/material";
import useAsyncPage from "@Views/common_component/async_page/async_page_setter";
import MagnifyingGlass from "@Views/common_component/icons/magnifying_glass";
import { QRCodeCanvas } from "qrcode.react";
import { useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";

export default function SmallQRCode() {
  const { setPageContent } = useAsyncPage();

  const show = useCallback(() => {
    const removalId = v4();

    setPageContent(
      removalId,
      <CustomModalPopup removalId={removalId} />,
      false,
      true
    );
  }, [setPageContent]);

  return (
    <Box className="qrcode-wrap" onClick={() => show()}>
      <Box className="qrcode">
        <div className="aside_page_qr_code_container">
          <QRCodeCanvas size={43} value="https://onelink.to/4npf9k" />
        </div>
        <div className="aside_page_qr_code_description_stack">
          <span className="aside_page_qr_code_description_stack_top">
            오늘의 운세?
          </span>
          <br />
          <span className="aside_page_qr_code_description_stack_bottom">
            앱으로 편하게 보세요!
          </span>
        </div>
        <MagnifyingGlass className="aside_page_magnifying_glass" />
      </Box>
    </Box>
  );
}

function CustomModalPopup({ removalId }: { removalId: string }) {
  const [animatedShow, setAnimatedShow] = useState(false);
  const { removePageByIdAndReturn } = useAsyncPage();

  useEffect(() => setAnimatedShow(true), []);

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 40%);
        opacity: ${animatedShow ? 100 : 0}%;
        transition: opacity 500ms;
      `}
      onClick={async () => {
        setAnimatedShow(false);

        await new Promise<void>((resolve) =>
          setTimeout(() => {
            resolve();
          }, 500)
        );

        removePageByIdAndReturn(removalId, undefined);
      }}
    >
      <div
        css={css`
          width: 327px;
          height: 400px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: var(--SubBg01);
          border-radius: 32px;
          gap: 24px;
          scale: ${animatedShow ? 100 : 80}%;
          transition: scale 500ms;
        `}
        onClick={(e) => e.stopPropagation()}
      >
        <span
          css={css`
            font-weight: 700;
            font-size: 22px;
            color: var(--MainText);
          `}
        >
          QR를 스캔해 보세요!
        </span>
        <span
          css={css`
            font-weight: 500;
            font-size: 18px;
            white-space: pre-wrap;
            line-height: 27px;
            text-align: center;
          `}
        >
          {"앱에서만 제공되는\n인기 운세를 볼 수 있어요."}
        </span>
        <QRCodeCanvas size={144} value="https://onelink.to/4npf9k" />
        <span
          css={css`
            font-size: 14px;
            font-weight: 500;
            color: var(--SubText01);
            cursor: pointer;
          `}
          onClick={() => setAnimatedShow(false)}
        >
          웹으로 볼래요.
        </span>
      </div>
    </div>
  );
}
