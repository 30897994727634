import {
  JeomsinDomain,
  JeomsinEndPoint,
  JeomsinMethod,
  JeomsinRequest,
  RequestProps,
} from "@Network/network";

interface Response {
  errmsg: string,
  msg: string,
  result: boolean;
  TTL: string;
}

const apiAutopaymentDel = async (
  memberNo: string
): Promise<Response> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.AutopaymentDel,
    params: {
      mem_no: memberNo
    },
  };

  const inferred = (await JeomsinRequest(request)) as Response;
  return inferred
};

export { apiAutopaymentDel };
export type {
  Response as apiAutopaymentDelResponse
};
