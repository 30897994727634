import { getAppType, getDeviceId, getVersion } from "@Config/jeomsin_application_info";
import { JeomsinUserInfo } from "@DataDefine/jeomsin_user_info";
import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";


interface MZKeyVisualBannerListModel {
  idx: string;
  num: string;
  state: string;
  page_name: string;
  page_code: string;
  image: string;
  contents: string;
  keyword: string;
  reg_date: string;
  landing_type: string;
}

interface RecommendUnseListModel {
  versionname: string;
  title: string;
  page_code: string;
}

interface DreamHaemongListModel {
  versionname: string;
  tagname: string;
  org_txt: string;
  new_txt: string;
  page_code: string;
  org_data: string;
}

interface AllMenuListModel {
  idx: string;
  page_name: string;
  page_code: string;
  state: string;
  reg_date: string;
  }

interface MainMenuListModel {
  page_code_name: string;
  page_code: string;
  image: string;
}


interface Response {
  banner_list: MZKeyVisualBannerListModel[];
  recommend_unse_list: RecommendUnseListModel[];
  dream_haemong_list: DreamHaemongListModel[];
  all_menu_list: AllMenuListModel[];
  main_menu_list: MainMenuListModel[];
  result: boolean;
  TTL: string;
}

function EmptyMZMainListResponse(): Response {
  return {
    banner_list: [],
    recommend_unse_list: [],
    dream_haemong_list: [],
    all_menu_list: [],
    main_menu_list: [],
    result: false,
    TTL: "",
  };
}

const apiGetMZMainList = async (
  userinfo?: JeomsinUserInfo,
): Promise<Response | undefined> => {
  let request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetMZMainList,
    params: {
      device_id: getDeviceId(),
      mybirth: userinfo ? userinfo.birthYear : '1998',
      mygender: userinfo ? userinfo.gender : "0",
      ver: getVersion(),
      type: getAppType(),
    },
  };

  try {
    const inferred = (await JeomsinRequest(request)) as Response;
    return inferred;
  } catch (e) {
    return undefined;
  }
};

export default apiGetMZMainList;
export type {
  Response as MZMainListResponse,
  MZKeyVisualBannerListModel,
  RecommendUnseListModel,
  DreamHaemongListModel,
  AllMenuListModel,
  MainMenuListModel,
};
export { EmptyMZMainListResponse };
