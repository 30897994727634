import { SimplePayPwCheckServerModel } from "@Models/simple_pay_model";
import {
  JeomsinDomain,
  JeomsinMethod,
  RequestProps,
  ShopByEndPoint,
  ShopByRequest
} from "@Network/network";

interface Response {
  mem_info: SimplePayPwCheckServerModel,
  msg: string,
  result: boolean;
  TTL: string;
}

const apiShopByDormancy = async (): Promise<Response | undefined> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.ShopBy,
    endpoint: ShopByEndPoint.Dormancy,
    params: {
      authType: "NONE",
    },
  };

  try {
    const inferred = (await ShopByRequest(request)) as Response;
    return inferred;
  } catch {
    return undefined;
  }
};

export { apiShopByDormancy };
export type {
  Response as ShopByDormancyResponse
};

