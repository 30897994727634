import { useRef, ReactNode, CSSProperties, useMemo } from "react";
import css from "./dim_layer.module.css";
import "@CSS/animation/fade.css";
import { CSSTransition } from "react-transition-group";
import { create } from "zustand";
import { useGeometryReader } from "@Hooks/use_geometry_reader";

// 상태와 함수 타입 정의
interface DimmedStoreState {
  isDimLayerOpen: boolean; // boolean 상태
  setDimLayerOpen: (value: boolean) => void; // boolean 값을 받아 상태를 설정하는 함수
}

export const useDimmedStore = create<DimmedStoreState>((set) => ({
  isDimLayerOpen: false,
  setDimLayerOpen: (value: boolean) => set(() => ({ isDimLayerOpen: value })),
}));

type DimLayerProps = {
  children?: ReactNode;
  onLayerEnter?: () => void;
  onLayerExit?: () => void;
  onClose?: () => void;
  blurred?: boolean;
};

const DimLayer: React.FC<DimLayerProps> = (props) => {
  const dimLayerRef = useRef(null);
  const { isDimLayerOpen } = useDimmedStore();

  const { getSize } = useGeometryReader();

  return (
    <CSSTransition
      nodeRef={dimLayerRef}
      in={isDimLayerOpen}
      timeout={{
        enter: 400,
        exit: 400,
      }}
      classNames="fade"
      mountOnEnter
      unmountOnExit
      onEnter={props.onLayerEnter}
      onExit={props.onLayerExit}
    >
      <section
        ref={dimLayerRef}
        className={`${css.dim} ${
          (props.blurred ?? false) ? css.blurred : css.unblurred
        }`}
        onClick={props.onClose}
        style={{
          width: `${getSize().width}px`,
        }}
      >
        {props.children}
      </section>
    </CSSTransition>
  );
};

export default DimLayer;
