import { ForwardedRef, RefObject, useEffect, useState } from "react";

export const useResizeObserver = (targetNodeRef: RefObject<HTMLElement> | ForwardedRef<HTMLDivElement> | undefined) => {
    const [reSize, setReSize] = useState({
      width: 0, 
      height: 0,
      top: 0,
      left: 0,
    });
  
    const ref = targetNodeRef ? targetNodeRef as React.MutableRefObject<HTMLDivElement> : undefined;
    useEffect(() => {
      if (!ref?.current) return;
      
      const resizeObserver = new ResizeObserver(([entry]) => {
        const { width, height } = entry.contentRect;

        setReSize({ 
          width:width, 
          height:height, 
          top:reSize.top, 
          left:reSize.left, 
        });

      });
  
      resizeObserver.observe(ref.current);
  
      return () => resizeObserver.disconnect();
    }, [ref?.current]);
  
    return { reSize };
};