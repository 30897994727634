import { FC, useEffect, useState } from "react";
import SajuAnalyzingCouapngAdBox from "./sub_component/saju_analyzing_couapng_ad_box/saju_analyzing_couapng_ad_box";
import { CoupangRectAdDataModel } from "@Models/coupang_config/coupang_ad_data_model";
import { useCoupangAdViewStore } from "../use_coupang_ad_view_store";
import FristRunnginCoupangAdBox from "./sub_component/first_running_coupang_ad_box/first_running_coupang_ad_box";
import { AdPageType, getCoupangAdSize, JeomsinAdType, NetworkAdTags } from "../web_ad_data";
import NetworkAdView from "../network_ad_view/network_ad_view";
import { CoupangPageCode } from "@Models/coupang_config/coupang_config_model";
import { AdStatus, checkAdStatus } from "@Storage/coupang_ad_time_manager";

interface CoupangRectAdViewProps {
    className?:string,
    coupangPageCode?:CoupangPageCode
    onLoad?:(isLoaded:boolean) => void;
    onClose?:() => void;
}

const CoupangRectAdView:FC<CoupangRectAdViewProps> = ({
    className,
    coupangPageCode,
    onLoad,
    onClose,
}) => {

    const [adData, setAdData] = useState<CoupangRectAdDataModel>();
    const [isLoaded, setLoaded] = useState<boolean>(false);

    const {
      coupangConfig,
      loadRectCoupangAd,
      checkAdType,
  } = useCoupangAdViewStore();

    const [adType, setAdType] = useState<string>(checkAdType(coupangConfig?.coupangRectAd?.ad_type));

    useEffect(() => {
          //CoupangAd
          if(
            coupangPageCode
            && (
              adType === JeomsinAdType.CojiCoupang 
              || adType === JeomsinAdType.TechlabsCoupang
            )
          ) {
            loadRectCoupangAd(getCoupangAdSize(adType, coupangPageCode), coupangPageCode).then((response) => {
                setAdData(response);
                setLoaded(true);

                if(onLoad) onLoad(true);
            }).catch(() => {
                setLoaded(false);
                if(onLoad) onLoad(false);
            });
        }
    },[]);

    return (
      <div className={className}>
        {
          coupangPageCode === AdPageType.A00001.pageCode ? (
            <SajuAnalyzingCouapngAdBox
              adType={adType}
              adData={adData}
              isLoaded={isLoaded}
            />
          ) : coupangPageCode === AdPageType.A00000.pageCode ? (
            <FristRunnginCoupangAdBox
              adType={adType}
              adData={adData}
              isLoaded={isLoaded}
              onClose={() => {if(onClose) onClose()}}
            />
          ) : <></>
        }
      </div>
    )
}


export default CoupangRectAdView;