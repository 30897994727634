import { JeomsinUserInfo } from "@DataDefine/jeomsin_user_info";
import { JeomsinDomain, JeomsinEndPoint, RequestProps } from "@Network/network_constants";
import { JeomsinRequest } from "@Network/network_manager";
import { json2xml } from "xml-js";
import { Buffer } from "buffer";

// JSON2XML requires the following component
Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

export const SharableContents = [
  "꿈해몽",
  "태몽",
  "정통사주",
  "오늘의 운세--총운",
  "오늘의 운세--오전",
  "오늘의 운세--오후",
  "오늘의 운세--밤",
  "연예인 궁합",
  "혈액형 궁합",
  "혈액형 연애운세",
  "내일의 운세",
  "지정일 운세",
  "별자리 운세",
  "띠 운세",
  "별자리 궁합",
  "별자리 다이어트",
  "정통궁합",
  "짝궁합",
  "신년운세",
  "토정비결",
  "취업 운세",
  "능력 평가",
  "살풀이",
  "타로--카드설명",
  "타로--오늘의타로",
  "타로--이달의타로",
  "타로--궁금한타로",
] as const;
export type SharableContent = (typeof SharableContents)[number];

export interface SharableResponse {
  sns_title: string;
  sns_url: string;
}

//화면에서 받아서 처리해야하는 경우가 있어 추가
export interface SharableParams {
  kind1: number;
  kind2: number;
  kind3: number;
  title: string;
}

export default async function apiSetSNSContentV3(
  userInfo: JeomsinUserInfo | undefined,
  contentKind: SharableContent,
  data: object | string,
  extraData?:SharableParams
): Promise<SharableResponse | undefined> {
  // If the data is string typed,
  // we will consider the underlying data to be XML
  let xml: string;

  if (typeof data === "object") {
    xml = json2xml(JSON.stringify(data), {
      compact: true,
    });
  } else {
    xml = data;
  }

  let kind1: number;
  let kind2: number | undefined = undefined;
  let kind3: number | undefined = undefined;
  let title: string = contentKind;
  switch (contentKind) {
    case "꿈해몽":
    case "태몽":
      kind1 = 12;
      kind2 = 1;
      break;
    case "정통사주":
      kind1 = 3;
      kind2 = 1;
      break;
    case "오늘의 운세--총운":
      kind1 = 7;
      kind2 = 1;
      break;
    case "오늘의 운세--오전":
      kind1 = 7;
      kind2 = 6;
      break;
    case "오늘의 운세--오후":
      kind1 = 7;
      kind2 = 6;
      break;
    case "오늘의 운세--밤":
      kind1 = 7;
      kind2 = 6;
      break;
    case "연예인 궁합":
      kind1 = 96;
      kind2 = 1;
      break;
    case "혈액형 궁합":
      kind1 = 9;
      kind2 = 1;
      break;
    case "혈액형 연애운세":
      kind1 = 9;
      kind2 = 2;
      title = "혈액형 궁합";
      break;
    case "내일의 운세":
      kind1 = 7;
      kind2 = 4;
      break;
    case "지정일 운세":
      kind1 = 7;
      kind2 = 5;
      break;
    case "별자리 운세":
      kind1 = 7;
      kind2 = 3;
      break;
    case "띠 운세":
      kind1 = 7;
      kind2 = 2;
      break;
    case "별자리 궁합":
      kind1 = 13;
      kind2 = 1;
      break;
    case "별자리 다이어트":
      kind1 = 13;
      kind2 = 2;
      break;
    case "정통궁합":
      kind1 = extraData?.kind1 ?? 0;
      kind2 = extraData?.kind2 ?? 0;
      kind3 = extraData?.kind3 ?? 0;
      title = extraData?.title ?? "";
      break;
    case "짝궁합":
      kind1 = 3
      break;
    case "신년운세":
      kind1 = extraData?.kind1 ?? 0;
      kind2 = extraData?.kind2 ?? 0;
      title = extraData?.title ?? "";
      break;
    case "토정비결":
      kind1 = extraData?.kind1 ?? 0;
      kind2 = extraData?.kind2 ?? 0;
      title = extraData?.title ?? "";
      break;
    case "취업 운세":
      title = "취업운세"
      kind1 = 6
      kind2 = 1;
      break;
    case "능력 평가":
      title = "능력 평가"
      kind1 = 5;
      kind2 = 1;
      break;
    case "살풀이":
        kind1 = 15;
        kind2 = 1;
        break;
    case "타로--카드설명":
      kind1 = 10;
      kind2 = 6;
      break;
    case "타로--오늘의타로":
      kind1 = 10;
      kind2 = 1;
      break;
    case "타로--이달의타로":
      kind1 = 10;
      kind2 = 2;
      break;
      case "타로--궁금한타로":
      kind1 = 10;
      kind2 = 10;
      break;
    default:
      kind1 = 1;
      kind2 = 1;
      break;
  }

  let sharable: SharableResponse | undefined;
  try {
    const request:RequestProps = {
      domain: JeomsinDomain.Jeomsin,
      endpoint: JeomsinEndPoint.SetSNSContentV3,
      params: {
        kind1:`${kind1}`,
        title: title.split("--").at(0) ?? "",
        name: userInfo?.name ?? "게스트",
        content: xml.replace("<?xml/>", ""),
      },
    };

    if(kind2) {
      request.params["kind2"] = `${kind2}`;
    }

    if(kind3) {
      request.params["kind3"] = `${kind2}`;
    }
    
    sharable = (await JeomsinRequest(request)) as SharableResponse;
  } catch {
    sharable = undefined;
  }

  return sharable;
}
