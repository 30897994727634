import { getJeomsinColor, JeomsinColors } from "@Config/jeomsin_colors";
import { useResizeObserver } from "@Hooks/use_reisze_observer";
import { Spacing, SpacingType } from "@Views/common_component/spacer/spacing";
import { forwardRef, useEffect, useRef, useState } from "react";
import css from "./page_body.module.css";

export const PageBodyType = {
  mainTab: "page-type-main",
  subPage: "page-type-sub",
  subPageNoneBottom: "page-type-sub-half",
} as const;
export type PageBodyType = (typeof PageBodyType)[keyof typeof PageBodyType];

export type JeomsinPageBodyProps = {
  children: React.ReactNode;
  type?: PageBodyType;
  height?:number;
  class?: string;
  backgroundColor?:JeomsinColors;
  bottomBlank?:number;
  scrollTo?:number;
  bodyColor?:JeomsinColors;
  onClick?: () => void;
};

const PageBody = forwardRef<HTMLDivElement, JeomsinPageBodyProps>(
  (props, ref) => {
    //class는 props으로 가져오지 않으면 에러가 발생하므로 Default가 필요한 데이터들만 아래와 같이 정의함
    const { 
      type = PageBodyType.mainTab,
    } = props;

    const bottombarRef = useRef<HTMLElement>(document.getElementById("bottom_bar"));
    const { reSize } = useResizeObserver(bottombarRef); //bottom_navigation의 height
    const [hide, setHide] = useState(props.scrollTo ? true : false); //0.2초 후 스크롤이 이동한 후 화면 노출출

    useEffect(() => {
      setTimeout(() => {
        //다른 화면에서 복귀 시 스크롤 초기화
        window.scrollTo(0, props.scrollTo ?? 0);
        setHide(false);
      }, 200);
    }, [props.scrollTo]);
    
    return (
      <>
      <div 
        ref={ref} 
        className={`${props.class} ${type}`}
        onClick={props.onClick}
        style={{
          height: props.height,
          opacity:hide ? "0" : "1",
          backgroundColor: props.bodyColor ? getJeomsinColor(props.bodyColor) : undefined
        }}
      >
        {props.children}
        <Spacing direction={SpacingType.vertical} gap={props.bottomBlank ?? reSize.height}/>
      </div>
      <div 
        className={css.content_body_background} 
        style={{
          backgroundColor: props.backgroundColor ? getJeomsinColor(props.backgroundColor) : getJeomsinColor(JeomsinColors.SubBg01)
        }}/>
      </>
    );
  }
);

export default PageBody;
