import { getDeviceId } from "@Config/jeomsin_application_info";
import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";
import { useUserInfoStore } from "@State/user_info_store";

interface MyPageV3ItemResponse {
  mem_item: {
    mem_no_ars: string;
    use_count: string;
    basic_count: string;
    bonus_count: string;
    del_coin: string;
    use_auto_pay: "Y" | "N";
    use_free_3min: "Y" | "N";
    cardName: string;
  };
  mem_coupon: Array<{
    coupon_no: string;
    coupon_name: string;
    coupon_cnt: string;
  }>;
  result: boolean;
  TTL: string;
}

const apiMyPageV3Item = async (
  memberNumber: string
): Promise<MyPageV3ItemResponse | undefined> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.MyPageV3Item,
    params: {
      mem_no_ars: memberNumber,
      deviceId: getDeviceId()
    },
  };

  const response = (await JeomsinRequest(request)) as MyPageV3ItemResponse;

  try {
    const _ = response.mem_item.basic_count;
    
    //멤버 정보 갱신
    const { loginMemberInfo, setLoginMemberInfo } = useUserInfoStore.getState();
    const memberinfo = loginMemberInfo;
    if(memberinfo) {
      memberinfo.mem_item = response.mem_item;
      setLoginMemberInfo(memberinfo);
    }
  } catch {
    return undefined;
  }

  return response;
};

export default apiMyPageV3Item;
