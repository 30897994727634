interface ColoredRectangleButtonProps {
  title?: string;
  cornerRadius?: string | number | boolean;
  width?: string | number;
  height?: string | number;
  fontSize?: string | number;
  fontWeight?: string | number;
  color?: string;
  clickHandler?: () => void;
}

const ColoredRectangleButton = ({
  title = "",
  cornerRadius = "12px",
  width = "90%",
  height = "56px",
  fontSize = "16px",
  fontWeight = "600",
  color = "#FFF854",
  clickHandler = undefined,
}: ColoredRectangleButtonProps) => {
  let cr: string;
  if (cornerRadius === true) {
    cr = "1000px";
  } else if (cornerRadius === false) {
    cr = "0px";
  } else if (typeof cornerRadius === "number") {
    cr = `${cornerRadius}px`;
  } else {
    cr = cornerRadius;
  }

  let w: string;
  if (typeof width === "number") {
    w = `${width}px`;
  } else {
    w = width;
  }

  let h: string;
  if (typeof height === "number") {
    h = `${height}px`;
  } else {
    h = height;
  }

  let f: string;
  if (typeof fontSize === "number") {
    f = `${fontSize}px`;
  } else {
    f = fontSize;
  }

  let fw: string;
  if (typeof fontWeight === "number") {
    fw = `${fontWeight}`;
  } else {
    fw = fontWeight;
  }

  return (
    <div
      style={{
        width: w,
        height: h,
        borderRadius: cr,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: fw,
        fontSize: f,
        backgroundColor: color,
        cursor: "pointer"
      }}
      onClick={() => {
        if (clickHandler) {
          clickHandler();
        }
      }}
    >
      {title}
    </div>
  );
};

export { ColoredRectangleButton };
export type { ColoredRectangleButtonProps };
