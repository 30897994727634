export const CounselMenu = {
    All: {id:'-1', title:'전체'}, // ALL은 menu_id를 세팅하지 않음
    Traditional: {id:'0', title:'신점'},
    Unse: {id:'1', title:'운세'},
    Taro: {id:'2', title:'타로'},
    Simli: {id:'3', title:'심리'}
}

export const getMenuById = (menuId:number) => {
    let result;
    const menuIdString = String(menuId);

    switch(menuIdString) {
        case CounselMenu.Traditional.id:
            result = CounselMenu.Traditional;
            break;
        case CounselMenu.Unse.id:
            result = CounselMenu.Unse;
            break;
        case CounselMenu.Taro.id:
            result = CounselMenu.Taro;
            break;
        case CounselMenu.Simli.id:
            result = CounselMenu.Simli;
            break;
        default:
            result = CounselMenu.All;
            break;
    }

    return result.title;
}

export const SortOption = {
    // Default: {id:'default', title:'기본순'}, //단순 텍스트
    Recommend: {id:'recomm', title:'추천순'},
    Popular: {id:'yn_popular', title:'인기순'},
    New: {id:'yn_new', title:'신규순'},
    Score: {id:'score_cnt', title:'별점 높은 순'},
    Review: {id:'review_cnt', title:'후기 많은 순'},
    Favorite: {id:'favorite_cnt', title:'찜 많은 순'}
} as const;
export type SortOption = (typeof SortOption)[keyof typeof SortOption];

export const CounselorState = {
    Default: {id:'', title:'기본'},
    Live: {id:'1', title:'실시간 Live'},
    Coupon: {id:'2', title:'할인 쿠폰 있는'},
    LiveAndCoupon: {id:'1,2', title:'실시간 Live & 할인 쿠폰 있는'},
}

export const getCounselorState = (isLive:boolean, isDiscount:boolean) => {
    let result = CounselorState.Default;

    if(isLive && isDiscount) {
        result = CounselorState.LiveAndCoupon;
    } else if(isLive && !isDiscount) {
        result = CounselorState.Live;
    } else if(!isLive && isDiscount) {
        result = CounselorState.Coupon;
    }

    return result;
}

export const CounselorField = {
  All: {title:'전체', code:''},
  Unse: {title:'종합운세', code:'A'},
  Gunghap: {title:'궁합', code:'B'},
  Employment: {title:'취업', code:'E'},
  Business: {title:'사업', code:'F'},
  Dream: {title:'꿈해몽', code:'G'},
  ChooseDay: {title:'택일', code:'C'},
  Health: {title:'건강', code:'H'},
  Family: {title:'가족', code:'I'},
  Academic: {title:'학업', code:'D'},
  Emotion: {title:'정서', code:'J'},
  SelfUnderstanding: {title:'자기이해', code:'K'},
  Feeding: {title:'섭식', code:'P'},
  MarriedCouple: {title:'부부', code:'L'},
  WorkPlace: {title:'직장', code:'M'},
  Relationships: {title:'대인관계', code:'N'},
  Gender: {title:'성', code:'O'},
  Children: {title:'자녀', code:'Q'},
  Romance: {title:'연애', code:'R'},
  Toxicosis: {title:'중독', code:'S'},
  Riches: {title:'재물', code:'T'},
  FengShui: {title:'풍수', code:'U'},
} as const;
export type CounselorField = (typeof CounselorField)[keyof typeof CounselorField];

export const CounselorCallState = {
  Available : {id:"1", name:"상담가능"},
  Unavailable : {id:"2", name:"부재중"},
  Counseling : {id:"3", name:"상담중"}
} as const;
export type CounselorCallState = (typeof CounselorCallState)[keyof typeof CounselorCallState];

export const WorryCategory = [
    '전체', '종합운세', '학업/취업/직장', '사업/재물', '궁합/연애', '건강', '가족/부부/자녀', '기타'
  
];

export const WorryWriteCategory = [
    '종합운세', 
    '학업/취업/직장', 
    '사업/재물', 
    '궁합/애정', 
    '건강', 
    '가족/부부/자녀', 
    '기타'
];