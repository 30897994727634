/** @jsxImportSource @emotion/react */
import { getJeomsinColor, JeomsinColors } from "@Config/jeomsin_colors";
import { css } from "@emotion/react";
import { CircularProgress } from "@mui/material";
import useAsyncPage from "@Views/common_component/async_page/async_page_setter";
import BackButtonIcon from "@Views/common_component/icons/back_button_icon";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { v1 } from "uuid";

export default function useShowExternalWeb() {
  const { setPageContent } = useAsyncPage();
  const location = useLocation();
  const nav = useNavigate();

  return {
    showExternalWeb: async (title: string, url: string) => {
      const removalId = v1();

      nav(location.pathname);

      await new Promise<void>((resolve) =>
        setTimeout(() => {
          resolve();
        }, 100)
      );

      await setPageContent(
        removalId,
        <ExternalWebUI title={title} url={url} removalId={removalId} />
      );
    },
  };
}

function ExternalWebUI({
  title,
  url,
  removalId,
}: {
  title: string;
  url: string;
  removalId: string;
}) {
  const { removePageByIdAndReturn } = useAsyncPage();
  const [isLoading, setLoading] = useState(true);
  const location = useLocation();
  const nav = useNavigate();
  const changeDetection = useRef(-1);

  useEffect(() => {
    if (changeDetection.current === -1) {
      changeDetection.current += 1;
      return;
    }

    // 백버튼이나 뒤로가기를 할 경우, 아래 코드가 호출될 것입니다.
    removePageByIdAndReturn(removalId, undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 10000;
      `}
    >
      <div
        css={css`
          position: relative;
          width: 100%;
          height: 56px;
          background-color: var(--SubBg01);
          display: flex;
          flex-direction: row;
          text-align: center;
          justify-content: center;
          align-items: center;
        `}
      >
        <h2
          css={css`
            margin: 0;
            font-size: calc(16px + var(--CurrentScaleOption));
            font-weight: var(--FontWeightExtraBold);
            color: var(--MainText);
          `}
        >
          {title}
        </h2>

        <div
          css={css`
            position: absolute;
            left: 0;
            top: 0;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
          onClick={() => {
            nav(-1);
          }}
        >
          <div
            css={css`
              width: 4px;
              height: 100%;
            `}
          />
          <BackButtonIcon color={getJeomsinColor(JeomsinColors.MainText)} />
        </div>
      </div>
      <div
        css={css`
          width: 100%;
          height: calc(100% - 56px);
          background-color: var(--SubBg01);
        `}
      >
        <iframe
          onLoad={() => setLoading(false)}
          css={css`
            opacity: ${isLoading ? 0 : 1};
            width: 100%;
            height: 100%;
            border: 0;
            scrollbar-width: 0;
            ::-webkit-scrollbar {
              display: none;
            }
            transition: opacity 300ms linear;
          `}
          title={title}
          src={url}
        />
      </div>
      {isLoading && (
        <div
          css={css`
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: calc(100% - 56px);
            background-color: transparent;
            pointer-events: none;
          `}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
