import styled from "@emotion/styled";
import CustomButton from "../custom_button/custom_button";
import css from "./bottom_button.module.css";

enum CharType {
  regularText,
  boldedText,
}

function split(fromString: string, boldedPart?: string): [CharType, string][] {
  const searchable = boldedPart ?? fromString;

  if (searchable === "") {
    return [[CharType.regularText, fromString]];
  }

  let source = fromString;
  let result: [CharType, string][] = [];

  while (source !== "") {
    const index = source.indexOf(searchable);

    if (index === 0) {
      const boldedText = source.substring(0, searchable.length);
      source = source.substring(searchable.length);
      result.push([CharType.boldedText, boldedText]);
    } else {
      if (index === -1) {
        result.push([CharType.regularText, source]);
        source = "";
      } else {
        const regularText = source.substring(0, index);
        source = source.substring(index);
        result.push([CharType.regularText, regularText]);
      }
    }
  }

  return result;
}

interface BottomButtonProps {
  className?: string;
  title: React.ReactElement | string;
  boldedPart?: string;
  disable?: boolean;
  color?: string;
  position?: string;
  onClick?: (disabled: boolean) => void;
}

const BottomButton = ({
  className,
  title,
  boldedPart,
  disable = false,
  color,
  position = "fixed",
  onClick,
}: BottomButtonProps) => {
  return (
    <CustomButton
      className={className}
      buttonDisabled={disable}
      sx={{ position: position }}
      customBackgroundColor={color}
      onClick={(_) => {
        if (onClick) {
          onClick(disable);
        }
      }}
    >
      {typeof title === "string" ? split(title, boldedPart).map((splitted) => {
        switch (splitted[0]) {
          case CharType.boldedText:
            return (
              <span
                className={`bold7 ${css.preserve_whitespace}`}
                key={splitted[0]}
              >
                {splitted[1]}
              </span>
            );
          case CharType.regularText:
            return (
              <span className={`${css.preserve_whitespace}`} key={splitted[0]}>
                {splitted[1]}
              </span>
            );
        }
      }) : title}
    </CustomButton>
  );
};

export default BottomButton;
