import CryptoJS from 'crypto-js';

class EncryptAesHelper {
  // 싱글턴 인스턴스
  private static instance: EncryptAesHelper | null = null;

  // AES 키 저장
  private storageKey: string;

  // 싱글턴 인스턴스 반환
  static getInstance(): EncryptAesHelper {
    if (!EncryptAesHelper.instance) {
      EncryptAesHelper.instance = new EncryptAesHelper();
    }
    return EncryptAesHelper.instance;
  }

  // 생성자: 환경 변수에서 키 가져오기
  private constructor() {
    const key = process.env.REACT_APP_AES_STORAGE_KEY;
    if (!key || key.length !== 32) {
      throw new Error("Invalid AES storage key. Ensure it is 32 bytes long.");
    }
    this.storageKey = key;
  }

  // 16자리 랜덤 IV 생성
  generateRandomIV(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({ length: 16 }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
  }

  // AES 암호화
  async encryptAES(plainText: string): Promise<{ encrypt: string; iv: string }> {
    const randomIV = this.generateRandomIV();
    const key = CryptoJS.enc.Utf8.parse(this.storageKey); // AES 키
    const iv = CryptoJS.enc.Utf8.parse(randomIV); // IV

    // 암호화 실행
    const encrypted = CryptoJS.AES.encrypt(plainText, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    }).toString();

    return { encrypt: encodeURIComponent(encrypted), iv: randomIV };
  }

  // AES 복호화 (옵션)
  async decryptAES(encryptedText: string, iv: string): Promise<string> {
    const key = CryptoJS.enc.Utf8.parse(this.storageKey); // AES 키
    const ivParsed = CryptoJS.enc.Utf8.parse(iv); // IV

  // `decodeURIComponent`로 디코딩
  const decodedEncryptedText = decodeURIComponent(encryptedText);

  // 복호화 실행
  const decrypted = CryptoJS.AES.decrypt(decodedEncryptedText, key, {
    iv: ivParsed,
    mode: CryptoJS.mode.CBC,
  });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}

export default EncryptAesHelper;
