import { createContext, useContext } from "react";

export const GeometryReaderContext = createContext({
  getSize: () => ({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  }),
});

export const useGeometryReader = () => useContext(GeometryReaderContext);
