import { getVersion } from "@Config/jeomsin_application_info";
import { CoupangConfigDefaultModel } from "@Models/coupang_config/coupang_config_default_model";
import { CoupangConfigDefaultV4Model } from "@Models/coupang_config/coupang_config_default_v4_model";
import { CoupangConfigModel } from "@Models/coupang_config/coupang_config_model";
import { CoupangConfigNoAdidModel } from "@Models/coupang_config/coupang_config_no_adid_model";
import { CoupangConfigNoAdidV4Model } from "@Models/coupang_config/coupang_config_no_adid_model_v4";
import { CoupangConfigV2Model } from "@Models/coupang_config/coupang_config_v2_model";
import { CoupangConfigV4Model } from "@Models/coupang_config/coupang_config_v4_model";
import { CoupangSetupModel } from "@Models/coupang_config/coupang_setup_model";
import { PoomangConfigDefaultModel } from "@Models/coupang_config/poomang_config_default_model";
import {
  JeomsinDomain,
  JeomsinEndPoint,
  JeomsinMethod,
  JeomsinRequest,
  RequestProps,
} from "@Network/network";

interface Response {
  coupang_setup:CoupangSetupModel,
  coupang_config:CoupangConfigModel[],
  coupang_config_v2_default:CoupangConfigDefaultModel,
  coupang_config_v2:CoupangConfigV2Model,
  coupang_config_no_adid:CoupangConfigNoAdidModel,
  poomang_config_default:PoomangConfigDefaultModel,
  coupang_config_no_adid_v4:CoupangConfigNoAdidV4Model,
  coupang_config_v4_default:CoupangConfigDefaultV4Model,
  coupang_config_v4:CoupangConfigV4Model,
  result: boolean;
  TTL: string;
}

const apiGetCoupangConfigV2 = async (): Promise<Response | undefined> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetCoupangConfigV2,
    params: {
      ver: getVersion(),
    },
  };

  try {
    const inferred = (await JeomsinRequest(request)) as Response;
    return inferred;
  } catch {
    return undefined;
  }
};

export { apiGetCoupangConfigV2 };
export type {
  Response as GetCoupangConfigV2Response
};

