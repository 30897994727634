import { useEffect, useRef } from "react";
import Slider from "react-slick";
import "./custom_slider.css"

type CustomSliderProps = {
    className?:string;
    infinite?:boolean;
    speed?:number;
    dots?:boolean;
    aspectRatio?:string;
    slidesPerRow?:number;
    rows?:number;
    centerPadding?:string;
    onDrag?:(isDragging:boolean) => void
    onSlideChange?:(slideIndex:number) => void
    children: React.ReactNode;
}

const CustomSlider:React.FC<CustomSliderProps>  = ( props ) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if(!props.aspectRatio) return;

    const slickList = sliderRef?.current?.querySelector<HTMLDivElement>(".slick-list");
    if (slickList) {
      slickList.style.aspectRatio = props.aspectRatio;
    }
  }, []);
  
  const dragEvent = (isDragging:boolean) => {
    if(props.onDrag)
        props.onDrag(isDragging);
  };

  const afterSlideChange = () => {
    dragEvent(false)
  };
  
  return (
    <div ref={sliderRef}>
    <Slider
        className={props.className}
        arrows={false} 
        dots={props.dots ?? true} 
        dotsClass="slider-dots" 
        infinite={props.infinite} 
        speed={props.speed} 
        swipeEvent={(swipeDirection)=> dragEvent(true)}
        beforeChange={(currentSlide: number, nextSlide: number)=> {
          if(props.onSlideChange)
            props.onSlideChange(nextSlide+1);
        }}
        afterChange={()=> afterSlideChange()}
    >
        {props.children}
  </Slider>
  </div>
  );
};

export { CustomSlider };
