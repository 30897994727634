import { getDeviceId, getVersion } from "@Config/jeomsin_application_info";
import { CombineMemberModel } from "@DataDefine/jeomsin_user_info";
import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

const CombineMemberLoginErrors = [
  "정회원 로그인이 실패했어요.\n다시 시도해주세요.",
] as const;
type CombineMemberLoginError = (typeof CombineMemberLoginErrors)[number];

const IdTypes = {
  Google: { title: "Google", sendableIdType: 5 },
  Naver: { title: "Naver", sendableIdType: 2 },
  Kakao: { title: "Kakao", sendableIdType: 1 },
  Apple: { title: "Apple", sendableIdType: 4 },
} as const;
type IdType = (typeof IdTypes)[keyof typeof IdTypes];

interface Response {
  mem_info: CombineMemberModel;
  mem_item: {
    mem_no: string;
    use_count: string;
    basic_count: string;
    bonus_count: string;
  };
  result: boolean;
  TTL: number;
}

const apiCombineMemberLogin = async (
  idCode: string,
  idType: IdType
): Promise<Response | undefined> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.CombineMemberLogin,
    params: {
      app_ver: getVersion(),
      device_id: getDeviceId(),
      id_code: idCode,
      id_type: idType.sendableIdType,
    },
  };

  const inferred = (await JeomsinRequest(request)) as Response;
  try {
    const _ = inferred.mem_item.basic_count; // type check
    return inferred;
  } catch {
    return undefined;
  }
};

export { apiCombineMemberLogin, IdTypes };
export type { CombineMemberLoginError, IdType };
