import { apiGetAppConfig, AppConfigResponse } from "@Network/request/api_get_app_config";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface ZAppConfig {
    initState:()=>void;
    appConfig:AppConfigResponse|undefined;
    loadAppConfig:()=>Promise<AppConfigResponse | undefined>;
}

const useAppConfigStore = create(
    persist<ZAppConfig>(
        (set, get) => ({
            initState:() => {
                set({
                    appConfig: undefined
                })
                
            },
            appConfig:undefined,
            loadAppConfig:async () => {
                const response = await apiGetAppConfig();
                set({ appConfig:response })
                return response;
            },
        }),
        { 
            name: 'app-config-storage',
            storage: createJSONStorage(() => sessionStorage) //탭 종료 시 스토리지에 저장된 데이터 Clear
        }
    )
);

export { useAppConfigStore };
