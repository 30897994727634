import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface ZSimplePayCardChangeBox {
    showAlert:boolean,
    showAlertData:string,
    
    initState:()=>void,
    setShowAlert:(value:boolean)=>void,
    setShowAlertData:(value:string)=>void,
}

const useSimplePayCardChangeBoxStore = create(
  persist<ZSimplePayCardChangeBox>(
        (set, get) => ({
            showAlert:false,
            showAlertData:"",

            initState:() => {
                set({
                    showAlert:false,
                    showAlertData:"",
                })
            },
            setShowAlert:(value:boolean)=> {
              set({ showAlert:value });
            },
            setShowAlertData:(value:string)=> {
              set({ showAlertData:value });
            }
        }),
        { 
          name: 'simple-card-change-storage',
          storage: createJSONStorage(() => sessionStorage) //탭 종료 시 스토리지에 저장된 데이터 Clear
        }
    )
);

export { useSimplePayCardChangeBoxStore };
