import Weather from "@Models/weather";
import {
  JeomsinDomain,
  JeomsinEndPoint,
  JeomsinMethod,
  RequestProps,
} from "@Network/network_constants";
import { JeomsinRequest } from "@Network/network_manager";

export default async function apiGetWeatherCoordinate(
  lat: number,
  long: number
): Promise<{ weather: Weather }> {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetWeatherCoordinate,
    params: {
      lat: `${lat}`,
      lng: `${long}`,
    },
  };

  return (await JeomsinRequest(request)) as { weather: Weather };
}
