import { SelectedScaleOption } from "@Config/jeomsin_scale";
import Lottie from "lottie-react";
import { FC, useEffect, useRef, useState } from "react";

interface LottieLoaderProp {
    path:string;
    className?:string;
    width?:number;
    hieght?:number;
}

const LottieLoader:FC<LottieLoaderProp> = ({
    path,
    className,
    width,
    hieght,
}) => {
    const [lottieData, setLottieData] = useState<any>(undefined)

    const viewWidth = width ? width + SelectedScaleOption : 0;
    const viewHieght = hieght ? hieght + SelectedScaleOption : 0;

    useEffect(() => {
        fetch(path)
        .then((response) => response.json())
        .then((data) => setLottieData(data))
        .catch((error) => console.log(error));
    }, []);

    return (
        lottieData ? 
        <Lottie
            className={className ?? undefined}
            animationData={lottieData} 
            autoplay={false}
            style={{
                width:viewWidth ? viewWidth+"px" : undefined,
                height:viewHieght ? viewHieght+"px" : undefined
            }}
        /> : <></>
    );
}

export default LottieLoader;