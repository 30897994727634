import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface SendAuthMobileResponse {
  result: boolean;
  msg?: string;
  id_type?: string;
}

const apiSendAuthMobile = async (
  phoneNumber: string,
  type: "join_member" | "change_member" | "change_mobile" | "find_id",
  isV2: boolean
): Promise<SendAuthMobileResponse> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: isV2
      ? JeomsinEndPoint.SendAuthMobileV2
      : JeomsinEndPoint.SendAuthMobile,
    params: {
      mem_mobile: phoneNumber.replaceAll("-", ""),
      auth_type: type,
    },
  };

  const response = (await JeomsinRequest(request)) as SendAuthMobileResponse;
  return response;
};

export default apiSendAuthMobile;
