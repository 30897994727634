import { CoupangRectAdDataModel } from "@Models/coupang_config/coupang_ad_data_model";
import { Stack } from "@mui/material";
import { FC } from "react";
import css from "./couapng_rect_ad_box.module.css"

interface CoupangRectAdBoxProps {
    adData:CoupangRectAdDataModel,
}

const CoupangRectAdBox:FC<CoupangRectAdBoxProps> = ({
    adData,
}) => {
    return (
      <Stack 
        className={css.coupang_rect_image_wrap}
        alignItems={"center"}
        direction="column" 
        spacing="20px"
      >
        <img
            className={css.coupang_rect_title_image}
            src={adData?.topImage}
            alt="jeomsin-coupang partnership"
          />
        <img 
            className={css.coupang_rect_content_image}
            src={adData?.productImage} 
            alt="coupangImage"
        />
      </Stack>
    )
}


export default CoupangRectAdBox;