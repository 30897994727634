import { getAppType, getDeviceId, getStoreType } from "@Config/jeomsin_application_info";
import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface Response {
  info?: AppConfigInfoModel;
  img_info?: AppConfigImgInfoModel;
  retention_info?: AppConfigRetentionInfoModel;
  yn_dormant: string;
  lucky_coordi?:LuckyCoordiModel[];
  fortuneReportChipList: ModelFortuneReportViewChip[];
  connect: string;
  mem_info?: MemberModel;
  p_msg_info?: AppConfigPMsgInfoModel;
  b_banner?: AppConfigBBannerModel;
  sns_text?: AppConfigSnsTextModel;
  ver_chk?: AppConfigVerChkModel;
  handaad?: AppConfigHandaadModel;
  config: ConfigModel;
  patch?:CsvListData[];
  call_text: string;
  shop_url: string;
  analyse_time: string;
  talisman_ver: string;
  history_back_full_ad_max_cnt: string;
  pf_event_yn: string;
  banner_event_yn: string;
  TV_my_yn: string;
  crystalball_sub_yn: string;
  tv_my_unse: string;
  r_day: string;
  call_text_ars: string;
  call_price_ars: string;
  call_price_ars_s: string;
  ars_record_sample_url: string;
  agree_url: string;
  policy_url: string;
  yn_unse_top_banner: string;
  yn_did_update: string;
  ttl_version: string;
  secret_store_banner_url: string;
  secret_store_btn_yn: string;
  dream_csv_file:string;
  dream_csv_ver:string;
}

interface AppConfigInfoModel {
  app_scheme: string;
  app_url: string;
  stats_url: string;
  stats_file: string;
}

interface AppConfigImgInfoModel {
  android_img1: string;
  android_img2: string;
  android_img3: string;
  ios_img1: string;
  ios_img2: string;
  ios_img3: string;
}

interface AppConfigRetentionInfoModel {
  idx: string;
  group_id: string;
  ratio: string;
  front_ad: string;
  line_ad: string;
  reg_date: string;
}

interface LuckyCoordiModel {
  idx: string;
  title: string;
  event_date: string;
  display_date: string;
  bg_color: string;
  btn_color: string;
  toast_msg_display_time: string;
  event_name: string;
  state: string;
  state_pro: string;
  state_ios: string;
  state_ios_pro: string;
  img_black_theme: string;
  img_white_theme: string;
  img_bg: string;
  img_top_right: string;
  img_top_left: string;
  img_text: string;
  ico_event: string;
  reg_date: string;
  dday: string;
  line_color: string;
  notice_color: string;
}

interface ModelFortuneReportViewChip {
  idx: string;
  priority: string;
  statisticCode: string;
  moveType: string;
  pageCode: string;
  url: string;
  title: string;
  tilte: string; //백엔드의 오타
  isCurMenu: string;
}

interface MemberModel {
  mem_no: string;
  mem_nick: string;
  i_type: string;
  ad_remove_item: string;
  item_name: string;
  start_date: string;
  end_date: string;
}

interface AppConfigPMsgInfoModel {
  p_msg1: string;
  p_msg2: string;
  p_msg3: string;
  p_msg4: string;
  p_msg5: string;
  p_msg6: string;
  p_msg_type: string;
  p_msg1_a: string;
  p_msg1_b: string;
  p_msg1_c: string;
  p_msg2_a: string;
  p_msg2_b: string;
  p_msg2_c: string;
  p_msg3_a: string;
  p_msg3_b: string;
  p_msg3_c: string;
  p_msg4_a: string;
  p_msg4_b: string;
  p_msg4_c: string;
  p_msg7: string; //골프
  p_msg7_a: string; //골프 70 미만
  p_msg7_b: string; //골프 70-80
  p_msg7_c: string; //골프 85 이상
}

interface AppConfigBBannerModel {
  b_type: string;
  android: string;
  ios: string;
  text: string;
  text_sub: string;
  img_b: string;
  img_w: string;
  img_sub_b: string;
  img_sub_w: string;
}

interface AppConfigSnsTextModel {
  sns_text_ios: string;
  sns_text_android: string;
  sns_text_tstore: string;
}

interface AppConfigVerChkModel {
  ver: string;
  ver_content: string;
  ver_store: string;
  package_name: string;
  ver_yn: string;
  ver_img1: string;
  ver_img2: string;
  ver_img3: string;
  ver_view_period: string;
}

interface AppConfigHandaadModel {
  banner_url: string;
  link: string;
}

interface ConfigModel {
  fortune_click_count: string;
}

interface CsvListData {
  type: string;
  version: string;
  url: string;
  row: string;
  isNew: boolean;
}

const apiGetAppConfig = async (): Promise<Response | undefined> => {
  let request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetAppConfig,
    params: {
      store: getStoreType(),
      type: getAppType(),
      device_id: getDeviceId(),
      ad_id: ""
    },
  };

  const response: any = await JeomsinRequest(request);

  try {
    const inferred = response as Response;
    return inferred;
  } catch (_) {
    return undefined;
  }
};

export { apiGetAppConfig };
export type { Response as AppConfigResponse };