import { CSSProperties } from "react";

export default function MagnifyingGlass({
  style,
  className,
  color,
}: {
  style?: CSSProperties;
  className?: string;
  color?: string;
}) {
  const presentableColor = color ?? "#BCC3D3";

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <g clipPath="url(#clip0_1072_552)">
        <path
          d="M13.9756 13.8574L15.8339 15.8333"
          stroke={presentableColor}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M9.52 15.04C12.5686 15.04 15.04 12.5686 15.04 9.52C15.04 6.47139 12.5686 4 9.52 4C6.47139 4 4 6.47139 4 9.52C4 12.5686 6.47139 15.04 9.52 15.04Z"
          stroke={presentableColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 9.5H12"
          stroke={presentableColor}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M9.5 12L9.5 7"
          stroke={presentableColor}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1072_552">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
