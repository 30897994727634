import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface CheckMemberMobileResponse {
  chk_mem_mobile: "N" | "Y";
}

const apiCheckMemberMobile = async (
  phoneNumber: string,
  birth: string
): Promise<CheckMemberMobileResponse> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.CheckMemberMobile,
    params: {
      mem_mobile: phoneNumber.replaceAll("-", ""),
      mem_birth: birth.replaceAll(".", "-"),
    },
  };

  const response = (await JeomsinRequest(request)) as CheckMemberMobileResponse;
  return response;
};

export default apiCheckMemberMobile;
