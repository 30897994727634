import {
  JeomsinDomain,
  JeomsinMethod,
  RequestProps,
  ShopByEndPoint,
  ShopByRequest
} from "@Network/network";

interface Response {
  status:number,
  code:string,
  error:string,
  message:string,
  path:string,
  result: boolean;
}

const apiShopByRegister = async (
  memberId: string,
  memberPassword: string,
  memberName: string,
): Promise<Response | undefined> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.ShopBy,
    endpoint: ShopByEndPoint.Register,
    params: {
      password: memberPassword,
      memberId: memberId,
      memberName: memberName,
    },
  };

  try {
    const inferred = (await ShopByRequest(request)) as Response;
    return inferred;
  } catch {
    return undefined;
  }
};

export { apiShopByRegister };
export type {
  Response as ShopByRegisterResponse
};

