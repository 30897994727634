import { SharableContent, SharableResponse } from "@Network/request/api_set_sns_content_v3";
import { ModalPopupUIProps } from "@Views/common_component/async_modal_popup/async_modal_popup";
import { GunghapJeongtongModel } from "@Views/unse/gunghap_jeongtong/model/gunghap_jeongtong_model";
import { GunghapMZModel } from "@Views/unse/gunghap_mz/model/gunghap_mz_model";
import { UnseSimpleResultModel } from "@Views/unse/unse_simple_result/model/unse_simple_result_model";

export async function triggerCopyingShareURL(
  sharable: SharableResponse,
  modalPopupTrigger: (content: ModalPopupUIProps) => Promise<number>,
  titleReplace: boolean = false
) {
  if (navigator.clipboard === undefined) {
    await modalPopupTrigger({
      title: "알림",
      description: "url 복사를 실패했습니다.",
      buttonTitles: ["확인"],
      buttonBorderRadius: 1000,
      customVerticalGaps: [30, undefined, undefined, 30],
    });
    return;
  }

  const choices = ["url 복사하기"] as const;

  const userDecision = await modalPopupTrigger({
    title: "공유하기",
    description: titleReplace
      ? sharable.sns_title
      : "url을 복사하여 공유해 주세요.",
    buttonTitles: [...choices],
    buttonBorderRadius: 1000,
    customVerticalGaps: [30, undefined, undefined, 30],
  });

  if (choices.at(userDecision) !== "url 복사하기") {
    return;
  }

  await navigator.clipboard.writeText(sharable.sns_url);
}

export function snsContentXMLDataDream(
    category: string | undefined,
    title: string,
    description: string
  ): Object {
    return {
      drmwork_data: {
        result_position: "category1",
        title: category ?? "",
        menu1: {
          title: category ?? "",
          category1: {
            title: title,
            text: description,
          },
        },
      },
    };
}
  
export function snsContentXMLDataJeongtongSaju(
    data: { title: string; description: string }[]
    ): Object {
    let categories: { [key: string]: any } = {
        title: "능력",
    };

    data.forEach((each, index) => {
        categories[`depth${index + 1}`] = {
        title: each.title,
        text: each.description,
        };
    });

    const requestable = {
        drmwork_data: {
        menu1: {
            category1: categories,
        },
        },
    };

    return requestable;
}

interface TimeSpecifics {
    score: number;
    text: string;
}

export function snsContentXMLDataTodayUnse(
    time: "총운" | "오전" | "오후" | "밤",
    score: number,
    text: string,
    themeScores: number[],
    dailyScores: number[],
    timeSpecifics: [TimeSpecifics, TimeSpecifics, TimeSpecifics],
    textPerTheme: { title: string; text: string }[],
    whatBringsLuck: { title: string; text: string }[],
    coordiFashionAdvice: { title: string; text: string },
    birthYearCeleb: { birthYear: number; name: string; position: string }[],
    similarCeleb: { birthYear: number; name: string; position: string }[],
    type: SharableContent,
  ) {
    const categories = textPerTheme
      .map((each, index) => {
        return {
          [`category${index + 1}`]: {
            depth1: {
              title: each.title,
              text: each.text,
            },
          },
        };
      })
      .reduce((acc, cur) => {
        return { ...acc, ...cur };
      }, {});
  
    const themeScoresMapped = themeScores
      .map((each, index) => ({
        [`num${index + 1}`]: each,
      }))
      .reduce((acc, cur) => ({ ...acc, ...cur }));
  
    const dailyScoresMapped = dailyScores
      .map((each, index) => ({
        [`num${index + 1}`]: each,
      }))
      .reduce((acc, cur) => ({ ...acc, ...cur }));
  
    const luckAttractions = whatBringsLuck
      .map((each, index) => {
        return {
          [`category${index + 1}`]: {
            depth1: {
              title: each.title,
              text: each.text,
            },
          },
        };
      })
      .reduce((acc, cur) => {
        return { ...acc, ...cur };
      }, {});
  
    const byCeleb = birthYearCeleb
      .map((each, index) => ({
        [`depth${index + 1}`]: {
          text1: each.birthYear,
          text2: each.name,
          text3: each.position,
        },
      }))
      .reduce((acc, cur) => ({ ...acc, ...cur }), {});
  
    const sCeleb = similarCeleb
      .map((each, index) => ({
        [`depth${index + 1}`]: {
          text1: each.birthYear,
          text2: each.name,
          text3: each.position,
        },
      }))
      .reduce((acc, cur) => ({ ...acc, ...cur }), {});
  
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
  
    return {
      drmwork_data: {
        time_index: `${["총운", "오전", "오후", "밤"].indexOf(time)}`,
        reg_date: time !== "총운" && type.includes("오늘의 운세") ? `${year}.${month}.${day}` : undefined,
        menu1: {
          title: "총운",
          category1: {
            title: "총운",
            depth1: {
              title: "총운",
              num: score,
              text: text,
            },
            theme_category: type.includes("오늘의 운세") ? {
              ...themeScoresMapped,
            } : undefined,
            today_num: type.includes("오늘의 운세") ? {
              ...dailyScoresMapped,
            } : undefined,
            depth2: type.includes("오늘의 운세") ? {
              title: "오전",
              num: timeSpecifics[0].score,
              text: timeSpecifics[0].text,
            } : undefined,
            depth3: type.includes("오늘의 운세") ? {
              title: "오후",
              num: timeSpecifics[1].score,
              text: timeSpecifics[1].text,
            } : undefined,
            depth4: type.includes("오늘의 운세") ? {
              title: "밤",
              num: timeSpecifics[2].score,
              text: timeSpecifics[2].text,
            } : undefined,
          },
        },
        menu2: {
          title: "테마별 상세풀이",
          ...categories,
        },
        menu3: {
          title: "행운을 가져오는 것들",
          ...luckAttractions,
        },
        menu4: {
          title: "행운의 코디",
          category1: {
            depth1: {
              title: coordiFashionAdvice.title,
              text: coordiFashionAdvice.text,
            },
          },
        },
        menu5: {
          category1: {
            ...byCeleb,
          },
          category2: {
            ...sCeleb,
          },
        },
      },
    };
}

export function snsContentXMLDataCelbrityGunghap(
  data: UnseSimpleResultModel | undefined,
): Object {
  //TODO 데이터 파싱쪽 이슈로 데이터 매칭 작업 필요 승섭님이 Fix예정(Depth2)
  return {
    drmwork_data: {
      menu1: {
        title: data?.titleList[0],
        category1: {
          title: data?.titleList[0],
          depth1: {
            title: data?.subTitleList[0],
            text: data?.contentList[0],
          },
          depth2: {
            title: data?.subTitleList[1],
            text: data?.contentList[1],
          }
        },
      },
    },
  };
}

export function snsContentXMLDataBloodTypeGunghap(
  data: UnseSimpleResultModel | undefined,
):string|undefined {
  const xmlData = data?.shareXmlContent;
  if(!data || !xmlData) {
    return undefined;
  }

  let xmlString = `<?xml version=\"1.0\" encoding=\"UTF-8\" ?> <drmwork_data> <title>${data.titleList[0]}</title>`;
  for(var i=0; i<data?.subTitleList.length;i++){
    var start = xmlData.indexOf(`<menu${i+1}>`) + (`<menu${i+1}>`).length;
    var end = xmlData.indexOf(`</menu${i+1}>`);
    xmlString+= `<menu${i+1}>${xmlData.substring(start, end)}</menu${i+1}>`;
  }
  xmlString+='</drmwork_data>';

  return xmlString;
}

export function snsContentXMLDataBloodTypeRelationship(
  data: UnseSimpleResultModel | undefined,
):string|undefined {
  const xmlData = data?.shareXmlContent;
  if(!data || !xmlData) {
    return undefined;
  }

  let xmlString = `<?xml version=\"1.0\" encoding=\"UTF-8\" ?> <drmwork_data> <title>${data.titleList[0]}</title>`;
  for(var i=0; i<data?.subTitleList.length;i++){
    var start = xmlData.indexOf(`<menu${i+1}>`) + (`<menu${i+1}>`).length;
    var end = xmlData.indexOf(`</menu${i+1}>`);
    xmlString+= `<menu${i+1}>${xmlData.substring(start, end)}</menu${i+1}>`;
  }
  xmlString+='</drmwork_data>';

  return xmlString;
}

export function snsContentXMLDataZodiacGunghap(
  data: UnseSimpleResultModel | undefined,
):string|undefined {
  const xmlData = data?.shareXmlContent;
  if(!data || !xmlData) {
    return undefined;
  }

  let xmlString = `<?xml version=\"1.0\" encoding=\"UTF-8\" ?> <drmwork_data> <title>${data.titleList[0]}</title>`;

  var start = xmlData.indexOf(`<menu>`) + (`<menu>`).length;
  var end = xmlData.indexOf(`</menu>`);
  xmlString+= `<menu1>${xmlData.substring(start, end)}</menu1>`;
  xmlString+='</drmwork_data>';

  return xmlString;
}

export function snsContentXMLDataZodiacDiet(
  data: UnseSimpleResultModel | undefined,
):string|undefined {
  const xmlData = data?.shareXmlContent;
  if(!data || !xmlData) {
    return undefined;
  }

  let xmlString = `<?xml version=\"1.0\" encoding=\"UTF-8\" ?> <drmwork_data> <title>${data.titleList[0]}</title>`;

  for(var i=0; i<data?.subTitleList.length;i++){
    var start = xmlData.indexOf(`<menu${i+1}>`) + (`<menu${i+1}>`).length;
    var end = xmlData.indexOf(`</menu${i+1}>`);
    xmlString+= `<menu${i+1}>${xmlData.substring(start, end)}</menu${i+1}>`;
  }
  xmlString+='</drmwork_data>';

  return xmlString;
}

export function snsContentXMLDataGunghapJeontong(
  data: GunghapJeongtongModel | undefined,
):string|undefined {
  if(!data) {
    return undefined;
  }

  let xmlString = `<?xml version=\"1.0\" encoding=\"UTF-8\" ?> <drmwork_data>`;

  xmlString+= `<menu1><title>${data.selectSubMenuTitle}</title>`;

  for (var i=0 ; i< data.titleList.length; i++) {
    xmlString += `<category${i + 1}><title>${data.titleList[i]}</title>`;
    for (var j =0; j<  data.subTitleList[i].length ; j++) {
      xmlString += `<depth${j + 1}><title>${data.subTitleList[i][j]}</title>`;
      xmlString += `<text>${data.contentList[i][j]}</text></depth${j + 1}>`;
    }
    xmlString += `</category${i + 1}>`;
  }

  xmlString+= "</menu1>";
  xmlString+='</drmwork_data>';

  return xmlString;
}

export function snsContentXMLDataGunghapMz(
  data: GunghapMZModel | undefined,
  mainOhangColorIndex:number, 
  partnerOhangColorIndex:number,
  gunghapMZRecommendMenuIndex:number,
):string|undefined {
  if(!data) {
    return undefined;
  }

  const title = data.titleList[gunghapMZRecommendMenuIndex];
  
  let xmlString = "<?xml version=\"1.0\" encoding=\"UTF-8\" ?> <drmwork_data>";
  xmlString += `<title>${title}</title>`;
  xmlString += `<myohang>${mainOhangColorIndex}</myohang>`;
  xmlString += `<youohang>${partnerOhangColorIndex}</youohang>`;
  xmlString += `<cate>${data.cate}</cate>`;
  xmlString += `<sub_menu_cate>${gunghapMZRecommendMenuIndex}</sub_menu_cate>`;
  xmlString += `<category><title>${title}</title>`;
  if (gunghapMZRecommendMenuIndex == 0) {
    xmlString +=
        `<num1>${data.pointList[0]}</num1>${(Number(data.pointList[1]) > 0) ? `<num2>${data.pointList[1]}</num2>` : ""}${(Number(data.pointList[2]) > 0) ? `<num3>${data.pointList[2]}</num3>` : ""}`;
  }

  xmlString += "<depth>";
  const subTitleList = data.subTitleList.slice(data.subTitleStartIndex[gunghapMZRecommendMenuIndex], data.subTitleEndIndex[gunghapMZRecommendMenuIndex]);
  const contentsList = data.contentList.slice(data.contentStartIndex[gunghapMZRecommendMenuIndex], data.contentEndIndex[gunghapMZRecommendMenuIndex]);
  for (var i = 0; i < subTitleList.length; i++) {
    var strSubTitle = subTitleList[i];
    if (strSubTitle) {
      xmlString += `<headcopy${i + 1}>${strSubTitle.trim()}</headcopy${i + 1}>`;
    }

    xmlString += `<text${i + 1}>${contentsList[i]?.trim()}</text${i + 1}>`;
  }

  xmlString += "</depth></category></drmwork_data>";

  return xmlString;
}

export function snsContentXMLDataSalpuri(
  data: string | undefined,
):string|undefined {
  if(!data) {
    return undefined;
  }

  var start = data.indexOf(`<menu1>`);
  var end = data.indexOf(`</menu1>`);
  
  let xmlString = `<?xml version=\"1.0\" encoding=\"UTF-8\" ?> <drmwork_data>${data.substring(start, end)}</menu1> </drmwork_data>`;

  return xmlString;
}