import { JeomsinPagePath } from "@Config/jeomsin_page_path";
import { CounselMenu } from "./counselor_data";
import { GuideType } from "@Views/main_tab/app_usage_guide/app_usage_guide_data";

//TODO 점신 앱의 common_page_code에서 필요한 페이지 코드 추가
export const JeomsinPageInfo:{[key: string]: JeomsinPageInfo} = {
    NONE: {pageName:"웹 미노출 메뉴", pageCode:"NONE"},
    
    JeomsinHome: {pageName:"점신", pageCode:"", pagePath: JeomsinPagePath.AppUsageGuide, category:GuideType.JeomsinApp},
    UnseHome: {pageName:"운세", pageCode:"", pagePath: JeomsinPagePath.Home},
    TaroHome: {pageName:"타로", pageCode:"", pagePath: JeomsinPagePath.TarotHome, category:GuideType.Taro},
    CounselHome: {pageName:"상담", pageCode:"", pagePath: JeomsinPagePath.CounselHome},
    JeomsinMallHome: {pageName:"점신몰몰", pageCode:"", pagePath: JeomsinPagePath.AppUsageGuide, category:GuideType.JeomsinMall},

    D10076: {pageName:"별자리 궁합", pageCode:"D10076", desc:"별자리로 이어진 너와 나", imagePath:"images/fortune_main/zodiac_lover_icon.png", pagePath: JeomsinPagePath.AstroGunghapResult},
    D10059: {pageName:"연예인 궁합", pageCode:"D10059", desc:"우리가 연인이 되면 어떨까?", imagePath:"images/fortune_main/talent_icon.png", pagePath: JeomsinPagePath.CelebGunghap},
    D10031: {pageName:"정통궁합", pageCode:"D10031", desc:"연애 시작 전 확인 필수!", imagePath:"images/fortune_main/shiny_puzzle_icon.png", pagePath: JeomsinPagePath.GunghapJeongtong},
    D10137: {pageName:"짝궁합", pageCode:"D10137", desc:"쉽고 자세한 해석을 원한다면!", imagePath:"images/fortune_main/heart_eye.png", pagePath: JeomsinPagePath.GunghapMZ},
    D10073: {pageName:"혈액형 궁합", pageCode:"D10073", desc:"같은 혈액형 vs 다른 혈액형", imagePath:"images/fortune_main/blood_type_icon.png", pagePath: JeomsinPagePath.BloodTypeGunghapResult},
    D10095: {pageName:"나의 인연 운세", pageCode:"D10095", desc:"미래의 배우자 미리 보기", imagePath:"images/fortune_main/love_arrow_icon.png", pagePath: JeomsinPagePath.GonghapIneyun},
    D10039: {pageName:"취업 운세", pageCode:"D10039", desc:"점신의 점략적 취업컨설팅! 여길 눌러 확인하세요!", imagePath:"images/fortune_main/job_icon.png", pagePath: JeomsinPagePath.WorkUnse },
    D10043: {pageName:"능력 평가", pageCode:"D10043", desc:"당신의 히든 능력을 알려줄게요!", imagePath:"images/fortune_main/ability_icon.png", pagePath: JeomsinPagePath.AbilityEvaluation },
    D10091: {pageName:"심리 분석", pageCode:"D10091", desc:"누가 요즘 MBTI보니?! 이젠 사주로 더 정확하게!", imagePath:"images/fortune_main/psychology_icon.png", pagePath: JeomsinPagePath.SajuSimli },
    D10003: {pageName:"오늘의운세", pageCode:"D10003", pagePath: JeomsinPagePath.TodayUnse},
    D10007: {pageName:"내일의운세", pageCode:"D10007", pagePath: JeomsinPagePath.NextDayUnse},
    D10011: {pageName:"지정일운세", pageCode:"D10011", pagePath: JeomsinPagePath.SpecifiedDayUnse},
    D10015: {pageName:"시간대별운세", pageCode:"D10015", pagePath: JeomsinPagePath.TodayUnse},
    D10019: {pageName:"신년운세", pageCode:"D10019", pagePath: JeomsinPagePath.NewYearUnse},
    D10027: {pageName:"정통사주", pageCode:"D10027", pagePath: JeomsinPagePath.JeongtongSaju},
    D10023: {pageName:"토정비결", pageCode:"D10023", pagePath: JeomsinPagePath.TojeongBigyeol},
    D10070: {pageName:"행운의 번호", pageCode:"D10070", desc:"내 인생을 바꿔줄 6개의 숫자", imagePath:"images/fortune_main/dice_icon.png", pagePath: JeomsinPagePath.LottoFortune },
    D10085: {pageName:"천생복덕운", pageCode:"D10085", desc:"타고난 복을 활용해 보세요", imagePath:"images/fortune_main/bok_icon.png", pagePath: JeomsinPagePath.GunghapChunsaeng },
    D10035: {pageName:"살풀이", pageCode:"D10035", desc:"액운 방지에는 살풀이가 최고!", imagePath:"images/fortune_main/kor_hat_icon.png", pagePath: JeomsinPagePath.SalpuriResult },
    D10082: {pageName:"행운의 부적", pageCode:"D10082", desc:"나를 도와주는 영험한 기운", imagePath:"images/fortune_main/charm_icon.png" },
    D10056: {pageName:"이사택일", pageCode:"D10056", desc:"한눈에 보는 이사하기 좋은 날", imagePath:"images/fortune_main/calendar_icon.png", pagePath: JeomsinPagePath.HouseMove },
    D10047: {pageName:"관상", pageCode:"D10047", desc:"얼굴에 적힌 운명 알아보기", imagePath:"images/fortune_main/mask_icon.png" },
    D10050: {pageName:"띠 운세", pageCode:"D10050", imagePath:"images/common/chinese_zodiac/birth_menu_mouse.png", pagePath: JeomsinPagePath.TodayUnse},
    D10053: {pageName:"별자리 운세", pageCode:"D10053", imagePath:"images/common/zodiac/birth_menu_aquarius.png", pagePath: JeomsinPagePath.TodayUnse},
    D10107: {pageName:"생년월일 운세", pageCode:"D10107", imagePath:"images/fortune_main/cake_icon.png", pagePath: JeomsinPagePath.FreeBirth},
    D10103: {pageName:"태어난 계절운", pageCode:"D10103", imagePath:"images/fortune_main/weather_icon.png", pagePath: JeomsinPagePath.SajuGyejeol},
    D10099: {pageName:"전생운", pageCode:"D10099", imagePath:"images/fortune_main/earth_icon.png", pagePath: JeomsinPagePath.SajuJeonSaeng},
    D10079: {pageName:"탄생석", pageCode:"D10079", imagePath:"images/fortune_main/diamond_icon.png", pagePath: JeomsinPagePath.ThemeBirthstone},
    D10089: {pageName:"꿈해몽", pageCode:"D10089", pagePath: JeomsinPagePath.Dream},
    D10090: {pageName:"꿈해몽", alias:"태몽 탭", pageCode:"D10090", pagePath: JeomsinPagePath.Dream, category: "1"}, //D10089와 동일, 태몽 탭

    A50124: {pageName:"사주정보", pageCode:"A50124", pagePath: JeomsinPagePath.SajuMultiInfomation},
    A30100: {pageName:"상담 홈", pageCode:"A30100", pagePath: JeomsinPagePath.CounselHome}, //E10000와 동일
    A22010: {pageName:"별자리 궁합", pageCode:"A22010", pagePath: JeomsinPagePath.AstroGunghapResult}, //D10076 동일
    A22030: {pageName:"별자리 궁합", alias:"별자리 다이어트 탭", pageCode:"A22030", category: '1', pagePath: JeomsinPagePath.AstroGunghapResult}, //D10076 동일, 별자리 다이어트 탭
    A21930: {pageName:"혈액형 궁합", alias:"혈액형 연세운세 탭", pageCode:"A21930", category: '1', pagePath: JeomsinPagePath.BloodTypeGunghapResult}, //D10073 동일, 혈액형 연애운세 탭

    E10000: {pageName:"상담 홈", pageCode:"E10000", pagePath: JeomsinPagePath.CounselHome},
    E10090: {pageName:"전문 상담사", alias:"전체상담 탭", pageCode:"E10090", pagePath: JeomsinPagePath.ProfessionalCounselor, category: CounselMenu.All.id},
    E10009: {pageName:"상담사 검색", pageCode:"E10009", pagePath: JeomsinPagePath.SearchView},
    E10085: {pageName:"작성 가능한 후기", pageCode:"E10085"},
    E10010: {pageName:"쿠폰함", pageCode:"E10010", pagePath: JeomsinPagePath.CouponBox},
    E10016: {pageName:"점신 충전소", pageCode:"E10016"},
    E10027: {pageName:"고민 글쓰기", pageCode:"E10027", pagePath: JeomsinPagePath.CounselMyWorryWrite},
    E10088: {pageName:"쿠폰 받기", pageCode:"E10088", pagePath: JeomsinPagePath.CouponBox, category: '1'},
    E10003: {pageName:"전문 상담사", alias:"운세상담 탭", pageCode:"E10003", pagePath: JeomsinPagePath.ProfessionalCounselor, category: CounselMenu.Unse.id},
    E10004: {pageName:"전문 상담사", alias:"타로상담 탭", pageCode:"E10004", pagePath: JeomsinPagePath.ProfessionalCounselor, category: CounselMenu.Taro.id},
    E10005: {pageName:"전문 상담사", alias:"신점상담 탭", pageCode:"E10005", pagePath: JeomsinPagePath.ProfessionalCounselor, category: CounselMenu.Traditional.id},
    E10051: {pageName:"전문 상담사", alias:"심리상담 탭", pageCode:"E10051", pagePath: JeomsinPagePath.ProfessionalCounselor, category: CounselMenu.Simli.id},
    E10006: {pageName:"무료 고민상담", pageCode:"E10006", pagePath: JeomsinPagePath.CounselWorry},
    E10008: {pageName:"3분 무료상담", pageCode:"E10008", pagePath: JeomsinPagePath.ThreeMinuteCounselor},
    E10015: {pageName:"상담 이용 안내", pageCode:"E10015", pagePath: JeomsinPagePath.RealTimeCounselorGuide},
    E10030: {pageName:"3분 무료 상담 안내", pageCode:"E10030", pagePath: JeomsinPagePath.ThreeMinuteCounselorGuide},
    E10031: {pageName:"상담 이용 안내", pageCode:"E10031", pagePath: JeomsinPagePath.RealTimeCounselorGuide}, //중복코드, 배너 랜딩 코드 매칭을 위해 선언
    E10048: {pageName:"심리상담 안내", pageCode:"E10048", pagePath: JeomsinPagePath.SimliCounselGuide},
};
export type JeomsinPageInfo = {
    pageName: string;
    pageCode: string;
    category?: string;
    alias?: string; //page가 아닌 tab인경우 구분하기 위해 해당 값을 명시
    desc?: string;
    imagePath?: string;
    pagePath?: JeomsinPagePath;
}

export function GetPageInfo(pageCode: string):JeomsinPageInfo {
    const pageInfo = JeomsinPageInfo[pageCode];
    return pageInfo;
}

export function GetPagePath(pageCode: string): string|undefined {
    const pagePath = JeomsinPageInfo[pageCode]?.pagePath;
    return pagePath;
}