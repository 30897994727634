import {
  JeomsinDomain,
  JeomsinEndPoint,
  JeomsinMethod,
  JeomsinRequest,
  RequestProps,
} from "@Network/network";

interface Response {
  Code:string,
  Message:string,
  data:CojiCoupangData[],
}

interface CojiCoupangData {
  productName: string,
  productPrice: number,
  productImage: string,
  landingUrl: string,
  isRocket: boolean,
  productId: string
}

const apiRequestCojiCoupangAd = async (
  imageSize: string,
  channelId?: string,
  adId?:string,
): Promise<Response | undefined> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.CojiCoupangAd,
    endpoint: JeomsinEndPoint.RequestCojiCoupangAd,
    params: {
      adid: channelId ?? '1847', //Advertising ID가 아님
      subid: adId ?? 'jsnoadid',
      size: imageSize
    },
  };

  try {
    const inferred = (await JeomsinRequest(request)) as Response;
    return inferred;
  } catch {
    return undefined;
  }
};

export { apiRequestCojiCoupangAd };
export type {
  Response as RequestCojiCoupangAdResponse
};
