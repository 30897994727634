import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface Response {
  result:boolean,
  TTL:string
}

const apiSetCounselorWaitSMS = async (mobile:string, counselorNo:string, counselorState:string): Promise<Response | undefined> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.SetCounselorWaitSMS,
    params: {
      mem_mobile: mobile,
      CO_NO: counselorNo,
      w_type: counselorState
    },
  };

  try {
    const inferred = (await JeomsinRequest(request)) as Response;
    return inferred;
  } catch {
    return undefined;
  }
};

export { apiSetCounselorWaitSMS }
export type {
  Response as SetCounselorWaitSMSResponse,
}
