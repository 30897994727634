import { JeomsinRequest } from "@Network/network_manager";
import { IdType } from "./api_combine_member_login";
import {
  JeomsinDomain,
  JeomsinEndPoint,
  JeomsinMethod,
  RequestProps,
} from "@Network/network_constants";
import { CombineMemberModel } from "@DataDefine/jeomsin_user_info";
import { getDeviceId, getVersion } from "@Config/jeomsin_application_info";

export interface MemberSNSTokenUpdateResponse {
  mem_info: CombineMemberModel;
  mem_item: {
    mem_no: string;
    use_count: string;
    basic_count: string;
    bonus_count: string;
  };
  result: boolean;
  TTL: number;
}

interface MemberSNSTokenUpdateError {
  msg?: string;
}

/**
 * @throws {Error}
 */
export default async function apiMemberSNSTokenUpdate(
  idCode: string,
  idType: IdType,
  phoneNumber: string
): Promise<MemberSNSTokenUpdateResponse> {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.MemberSNSTokenUpdate,
    params: {
      id_code: idCode,
      id_type: `${idType.sendableIdType}`,
      mem_mobile: phoneNumber.replaceAll("-", ""),
      device_id: getDeviceId(),
      app_ver: getVersion(),
    },
  };

  const raw = (await JeomsinRequest(request)) as any;
  const response: MemberSNSTokenUpdateResponse = raw;
  const error: MemberSNSTokenUpdateError | undefined = raw;

  try {
    return response;
  } catch {
    throw new TypeError(error?.msg ?? "SNS 계정 이관 실패");
  }
}
