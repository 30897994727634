import { RecommendUnseListModel } from "@Network/request/api_get_mz_main_list";
import { HorizontalDragableArea } from "@Views/common_component/horizontal_dragable_area/horizontal_dragable_area";
import FlexBox, { FlexAlignItemsType, FlexDirectionType, FlexJustifyContentType } from "@Views/layer/flex_box/flex_box";
import { FC } from "react";
import css from "./reommend_unse_view_list.module.css"
import { Spacing, SpacingType } from "@Views/common_component/spacer/spacing";
import RecommendUnseView from "../recommend_unse_view/reommend_unse_view";
import { useUserInfoStore } from "@State/user_info_store";

interface RecommendUnseViewListProps {
    dataList:RecommendUnseListModel[]
}

const RecommendUnseViewList: FC<RecommendUnseViewListProps> = ({ 
    dataList
 }) => {
    const { getRepresentativeUserAgeRange, getRepresentativeUserGender } = useUserInfoStore();
    
    return (
        <HorizontalDragableArea className={css.recommend_unse_view_list_container}>
            <FlexBox
                class={css.height_wrap}
                direction={FlexDirectionType.row}
                alignItems={FlexAlignItemsType.center}
                gap={12}
            >
            {
                dataList.map((value, index) => (
                    <>
                    <RecommendUnseView 
                        className={index === 0 ? "ml24" : ""} 
                        order={index+1} 
                        maxCount={dataList.length} 
                        age={getRepresentativeUserAgeRange()} 
                        gender={getRepresentativeUserGender()} 
                        data={value}
                    />
                    {
                        (index === dataList.length-1) ? (
                            <Spacing direction={SpacingType.horizontal} gap={24}/>
                        ) : <></>
                    }
                    </>
                ))
            }
            </FlexBox>
        </HorizontalDragableArea>
    )
}

export default RecommendUnseViewList;