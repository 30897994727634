import FlexBox, { FlexAlignItemsType, FlexDirectionType } from "@Views/layer/flex_box/flex_box";
import css from "./coin_history_filter_box.module.css";
import { Spacing, SpacingType } from "@Views/common_component/spacer/spacing";
import { useState } from "react";

type CoinHistoryFilterBoxProps = {
  selected:number;
  item:string[];
  onClick?: (selectValue:any) => void;
};

const CoinHistoryFilterBox: React.FC<CoinHistoryFilterBoxProps> = (props) => {
  const [selectValue, setSelectValue] = useState<number>(props.selected);
  return (
      <div className={css.modal_content}>
        <Spacing direction={SpacingType.vertical} gap={52}/>
        <FlexBox
          class={css.title_wrap}
          direction={FlexDirectionType.column}
          alignItems={FlexAlignItemsType.center}
          gap={50}
        >
          {
            props.item.map((value, index) => (
              <FlexBox
                class={css.filter_item}
                direction={FlexDirectionType.row}
                alignItems={FlexAlignItemsType.center}
                gap={12}
                onClick={() => {
                  setSelectValue(index);
                }}
              >
                <div className={selectValue === index ? css.select : ""}>{value}</div>
                {
                  selectValue === index ? (<img className={css.filter_check_icon} src="images/mypage/check.png" alt="icon"/>) : <></>
                }
              </FlexBox>
            ))
          }
        </FlexBox>
        <Spacing direction={SpacingType.vertical} gap={54}/>
        <div 
          className={css.close_button} 
          onClick={() => {
            if(props.onClick)
              props.onClick(selectValue)
          }}
        >
          <span>닫기</span>
        </div>
      </div>
    );
};

export { CoinHistoryFilterBox };
