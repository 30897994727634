import { CSSProperties } from "react";

export default function CloseIcon({
  color,
  style,
  className,
  width,
}: {
  color?: string;
  style?: CSSProperties;
  className?: string;
  width?: number;
}) {
  return (
    <svg
      className={className}
      style={style}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_437_348)">
        <g clipPath="url(#clip1_437_348)">
          <path
            d="M3.75 3.75L11.25 11.25"
            stroke={color ?? "#BCC3D3"}
            strokeWidth={width ? `${width}px` : "1.5"}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.75 11.25L11.25 3.75"
            stroke={color ?? "#BCC3D3"}
            strokeWidth={width ? `${width}px` : "1.5"}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}
