const CookieKeys = {
  representative_user_info: "representative_user_info",
  user_info_list: "user_info_list",
  ttl_version: "ttl_version",
  coupangAdRejectionTime: "coupangAdRejectionTime",
  coupangAdAcceptTime: "coupangAdAcceptTime",
  fontScale: "font_scale",
  fortuneUrl: "fortuneUrl",
  stateUrl: "stateUrl",
  statsApiUrl: "statsApiUrl",
  recordSampleUrl: "recordSampleUrl",
  dreamCSVData: "dreamCSVData",
  dreamSortedCSVData: "dreamSortedCSVData",
  dreamCSVVersion: "dreamCSVVersion",
  jeomsinTheme: "jeomsinTheme",
  fieldv3: "fieldv3",
  favoriteMenuKey: "_favoriteMenuKey",
  searchMenuHistoryKey: "_searchMenuHistoryKey",
  toggleSearchMenuHistoryKey: "_toggleSearchMenuHistoryKey",
  searchCounselorHistoryKey: "_searchCounselorHistoryKey",
  toggleSearchCounselroHistoryKey: "_toggleSearchCounselroHistoryKey",
  deviceId: "user_unique_id",
  statusCombineMember: "status_combine_member",
  combineMemData: "combine_mem_data",
  mypageMemberData: "mypage_member_data",
  isChkAppCombineAuth: "is_chk_app_combine_auth",
  isCounselAlarmPossible: "isCounselAlarmPossible",
  isNewCoupon: "isNewCoupon",
  counselConnectionCautionHidden: "counselConnectionCautionHidden",
} as const;

export default CookieKeys;
