import { FC, useEffect, useRef, useState } from "react";
import css from "./unse_circle_view.module.css"

export const TimeType = {
    Dawn: 0,
    Moring: 1,
    Afternoon: 2,
    Night: 3
} as const
export type TimeType = (typeof TimeType)[keyof typeof TimeType];

type UnseCircleProps = {
    unseScore:number|undefined;
    color?:string;
    timeType?:TimeType;
    customTitle?:string|undefined;
    weakShadow?: boolean;
};

const UnseCircleView: FC<UnseCircleProps> = ({ 
    weakShadow = true, 
    color = "#00d4ff",
    ...props 
}: UnseCircleProps) => {
    const targetScore = props.unseScore ?? 0;
    const circleRadius = 35;
    const circleSize = 2  * Math.PI * circleRadius
    const centerX = 45;
    const centerY = 45;

    const progressTotalAnimationTime = 1 * 1000;
    const minFps = 41.6;
    const calculatedFps = (progressTotalAnimationTime / targetScore) //전체 1초 이내에 frame 할당
    const fps = (calculatedFps > minFps) ? minFps : calculatedFps; //frame 간격이 너무 긴 경우 minfps(24fps)로 대체

    let typeName;
    let typeTime;

    if( props.timeType == TimeType.Afternoon ) {
        typeName = "오후";
        typeTime = "12:00~17:59";
    } else if(props.timeType == TimeType.Night) {
        typeName = "밤";
        typeTime = "18:00~23:59";
    } else {
        typeName = "오전";
        typeTime = "07:00~11:59";
    }

  
    const circleRef = useRef(null);
    const [dashoffset, setDashoffset] = useState(circleSize);
    // State for cap position
    const [score, setScore] = useState(0);
    const [capPosition, setCapPosition] = useState({ x: centerX, y: centerY });

    const updateCapPosition = (score:number) => {
        const percentage = score / 100;
        const angle = (percentage * 360) * (Math.PI / 180); // Progress angle in degrees
        const theta = (0 * Math.PI / 180) + angle; // Convert angle to radians, starting from 0deg

        // Calculate the new X and Y coordinates
        const newX = centerX + circleRadius * Math.cos(theta);
        const newY = centerY + circleRadius * Math.sin(theta);

        // Update component view
        setTimeout(() =>{
            setCapPosition({ x: newX, y: newY });
            setDashoffset(circleSize * (1-percentage));
            if(score < targetScore)
                setScore(score+1);
        }, fps); //설정된 fps 간격으로 업데이트
    };

    useEffect(() => {
        updateCapPosition(score);
    }, [score]);


  return (
    <div className={css.circle_wrap}>
        { (props.timeType ? checkMyTime(props.timeType) : true) ?
        <div className={`${css.circle} ${weakShadow ? css.weak_shadow : css.strong_shadow}`}>
            <div className={css.pie}>
                <svg>
                    <circle className={css.circle} ref={circleRef} cx={centerX} cy={centerY} r={circleRadius} style={{strokeDasharray:circleSize, strokeDashoffset:dashoffset, stroke:color}}></circle>
                    <circle r="2" cx={capPosition.x} cy={capPosition.y} style={{fill:color}}></circle>
                </svg>
            </div>
            <div className={css.wrap_txt}>{score}</div>
        </div>
        :
        <div className={`${css.unse_deactivate} ${weakShadow ? css.weak_shadow : css.strong_shadow}`}>
            <img className={`${css.unse_deactivate} ${weakShadow ? css.weak_shadow : css.strong_shadow}`} src='images/saju_analyzing/profile.png' alt=""></img>
        </div>
        }
        {props.timeType !== undefined ? (
            <>
            <div className={`${css.circle_title} ${css.type}`}>{typeName}</div>
            <div className={`${css.circle_title} ${css.time}`}>{typeTime}</div>
            </>
        ) : (
            <>
            <div className={`${css.circle_title}`}>{props.customTitle ?? ""}</div>
            </>
        )}
    </div>
  );
}

const checkMyTime = (timeType:TimeType) => {
    let result = false;
    const date = new Date();

    const hour = date.getHours();
    const minutes = date.getMinutes();
    switch(timeType) {
        case TimeType.Moring:
            result = (hour >= 7 && hour <= 23); //00시부터는 일자가 넘어가 초기화
            break;
        case TimeType.Afternoon:
            result = (hour >= 12 && hour <= 23); //00시부터는 일자가 넘어가 초기화
            break;
        case TimeType.Night:
            result = (hour >= 18 && hour <= 23); //00시부터는 일자가 넘어가 초기화
            break;
    }

    return result
}

export const getTodayTimeUseType = () => {
    let returnTimeType:TimeType = TimeType.Moring;
    const thisTime = new Date();
    const hour = thisTime.getHours();
    const minute = thisTime.getMinutes();
    if (hour >= 0 && minute >= 0 && hour < 7) {
        returnTimeType = TimeType.Dawn;
    } else if (hour >= 7 && minute >= 0 && hour < 12) {
        returnTimeType = TimeType.Moring;
    } else if (hour >= 12 && minute >= 0 && hour < 18) {
        returnTimeType = TimeType.Afternoon;
    } else if (hour >= 18 && minute >= 0 && hour < 24) {
        returnTimeType = TimeType.Night;
    }
    return returnTimeType;
}

export default UnseCircleView;