import { getDeviceId, getVersion } from "@Config/jeomsin_application_info";
import { CombineMemberModel } from "@DataDefine/jeomsin_user_info";
import {
  JeomsinDomain,
  JeomsinEndPoint,
  JeomsinMethod,
  JeomsinRequest,
  RequestProps,
} from "@Network/network";

interface CheckAppCombineAuthV2Response {
  mem_info?: CombineMemberModel;
  mem_item?: {
    mem_no: string;
    use_count: string;
    basic_count: string;
    bonus_count: string;
  };
  msg?: string;
}

const apiCheckAppCombineAuthV2 = async (
  memberNumber: string,
  memberInfo: CombineMemberModel
): Promise<CheckAppCombineAuthV2Response> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.ChkAppCombineAuthV2,
    params: {
      device_id: getDeviceId(),
      mem_no: memberNumber,
      app_ver: getVersion(),
      id_code: memberInfo.id_code,
      id_type: memberInfo.id_type,
    },
  };

  const inferred = (await JeomsinRequest(
    request
  )) as CheckAppCombineAuthV2Response;
  return inferred;
};

export default apiCheckAppCombineAuthV2;
