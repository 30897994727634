import { SimplePayViewModel } from "models/simple_pay_model";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface ZSimplePayCardInfo {
  simplePayCardInfo?: SimplePayViewModel|undefined;
  setSimplePayCardInfo: (value: SimplePayViewModel | undefined) => void;
  getMaskedCardNo:() => string|undefined;
}

const useSimplePayCardInfoStore = create(
  persist<ZSimplePayCardInfo>(
    (set, get) => ({
      simplePayCardInfo: undefined,
      setSimplePayCardInfo: (value: SimplePayViewModel | undefined) => set({ simplePayCardInfo: value }),
      getMaskedCardNo:() => {
        const cardInfo = get().simplePayCardInfo;
    
        if (cardInfo?.cardNo.length === 16) {
          const cardNo = cardInfo?.cardNo;
    
          return cardNo.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/g, '$1-$2-****-****');
        } else {
          return cardInfo?.cardNo;
        }
      },
    }),
    { 
      name: 'simple-pay-card-storage',
      storage: createJSONStorage(() => sessionStorage) //탭 종료 시 스토리지에 저장된 데이터 Clear
    }
));

export { useSimplePayCardInfoStore };
