export const JeomsinScaleOption = {
    ExtraSamll: (320/375 - 1) - 0.2, // width 320px
    Samll: (345/375 - 1),   // width 345px
    Normal: (375/375 - 1),  // width 375px 기준
    Large: (411/375 - 1),  // width 411px
    ExtraLarge: (480/375 - 1)  // width 480px
}
export type JeomsinScaleOption = typeof JeomsinScaleOption[keyof typeof JeomsinScaleOption];

export const initializeScaleOption = () => {
    const style = document.createElement('style');
    style.id = "ScaleOption";

    style.innerHTML = `:root {\n`;

    Object.entries(JeomsinScaleOption).forEach(([key, value]) => {
        style.innerHTML += `--ScaleOption${key}: ${value};\n`;
    });

    style.innerHTML += `}`;
    document.head.appendChild(style);

    updateScaleOption();
}

export const updateScaleOption = () => {
    const width = document.documentElement.offsetWidth;

    if(width >= 480) {
        setScaleOption(JeomsinScaleOption.ExtraLarge);
    } else if(width >= 411) {
        setScaleOption(JeomsinScaleOption.Large);
    } else if(width >= 375) {
        setScaleOption(JeomsinScaleOption.Normal);
    } else if(width >= 345) {
        setScaleOption(JeomsinScaleOption.Samll);
    } else {
        setScaleOption(JeomsinScaleOption.ExtraSamll);
    }
}

export let SelectedScaleOption = JeomsinScaleOption.Normal;

export const setScaleOption = (scaleOption:JeomsinScaleOption) => {
    SelectedScaleOption = scaleOption;

    const targetStyles = document.getElementById('ScaleOption') as HTMLStyleElement;
    const styleSheet = targetStyles.sheet as CSSStyleSheet;
    
    for (const rule of styleSheet.cssRules) {
      if (rule instanceof CSSStyleRule && rule.selectorText === ':root') {
        rule.style.setProperty(`--CurrentScaleOption`, `${scaleOption}px`);
      }
    }
}

//mobile에서 height가 100Vh로 계산되지 않아 보정값을 할당 
export const initializeHeightScale = () => {
    const style = document.createElement('style');
    style.id = "HeightDefined";

    let vh = window.innerHeight * 0.01;

    style.innerHTML = `:root {\n`;
    style.innerHTML += `--vh: ${vh}px;\n`;
    style.innerHTML += `}`;

    document.head.appendChild(style);
}

export const updateHeightScale = () => {
    const targetStyles = document.getElementById('HeightDefined') as HTMLStyleElement;
    const styleSheet = targetStyles.sheet as CSSStyleSheet;

    for (const rule of styleSheet.cssRules) {
        if (rule instanceof CSSStyleRule && rule.selectorText === ':root') {
            let vh = window.innerHeight * 0.01;
            rule.style.setProperty('--vh', `${vh}px`);
        }
    }
}