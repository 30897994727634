import { useRef } from "react";
import css from "./horizontal_dragable_area.module.css"

const DragState = {
  Grab:"Grab",
  ScrollMove:"ScrollMove",
  Release:"Release",
}
type DragState = (typeof DragState)[keyof typeof DragState];

type DragAreaProps = {
    className?: string;
    children: React.ReactNode;
    dragEvent?: ()=>void;
    dragEndEvent?: ()=>void;
}

export const HorizontalDragableArea:React.FC<DragAreaProps>  = ( props ) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const currentDragState = useRef<DragState>(DragState.Release);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    if (scrollContainerRef.current) {
      currentDragState.current = DragState.Grab;
      startX.current = e.pageX - scrollContainerRef.current.offsetLeft;
      scrollLeft.current = scrollContainerRef.current.scrollLeft;

      // 마우스 커서 변경
      scrollContainerRef.current.style.cursor = "grabbing";
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {

    if (currentDragState.current === DragState.Release || !scrollContainerRef.current) return;

    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const dragDistance = x - startX.current; // 드래그 거리

    if(dragDistance === 0) return; //새로고침 시 클릭이 발생하면 Move가 처리되는 이슈가 있어서 거리 이동 체크 추가

    currentDragState.current = DragState.ScrollMove;
    scrollContainerRef.current.scrollLeft = scrollLeft.current - dragDistance;
    
    if(props.dragEvent) {
      props.dragEvent();
    }
  };


  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    if (currentDragState.current !== DragState.Release && scrollContainerRef.current) {
      handleDragEnd(e); // 마우스가 떠날 때 드래그 상태 초기화
    }
  };

  const handleDragEnd = (e: React.MouseEvent<HTMLDivElement>) => {
    if(!scrollContainerRef.current) return;

    if (currentDragState.current === DragState.ScrollMove) {
      e.preventDefault(); // 드래그 상태에서 클릭 방지
      e.stopPropagation();
    }

    currentDragState.current = DragState.Release;
    scrollContainerRef.current.style.cursor = "grab"; // 마우스커 커서 복구

    if(props.dragEndEvent) {
      props.dragEndEvent();
    }
  };

  return (
    <div 
      className={`${props.className} ${css.scroll_x_container}`}
      ref={scrollContainerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClickCapture={handleDragEnd}
    >
      {props.children}
    </div>
  );
};