import { useEffect, useState } from "react";
import ToastPopup from "./toast_popup";
import { create } from "zustand";

const ToastPopupManager = () => {
  const [isDisplayed, setDisplayed] = useState(false);
  const [content, setContent] = useState<string | undefined>();

  const { toastPopupContents, removeFirstToastPopupContent } = useToastPopupStore();

  useEffect(() => {
    if (isDisplayed === false) {
      return;
    }

    const timer = setInterval(() => {
      setDisplayed(false);
    }, 3500);

    return () => {
      clearInterval(timer);
    };
  }, [isDisplayed]);

  useEffect(() => {
    if (toastPopupContents.length === 0 || isDisplayed === true) {
      return;
    }

    setContent(toastPopupContents.at(0)!);
    setDisplayed(true);
  }, [toastPopupContents, isDisplayed]);

  return (
    <>
      {ToastPopup(content, () => {
        removeFirstToastPopupContent();
        setContent(undefined);
      })}
    </>
  );
};

interface ZToastInfo {
  toastPopupContents: string[];
  setToastPopupContent: (value: string) => void;
  removeFirstToastPopupContent: () => void;
}

const useToastPopupStore = create<ZToastInfo> ((set) => ({
  toastPopupContents: [],
  setToastPopupContent: (value) => {
    set((state) => ({ ...state, toastPopupContents: [...state.toastPopupContents, value] }))
  },
  removeFirstToastPopupContent: () => {
    set((state) => ({ ...state, toastPopupContents: state.toastPopupContents.slice(1) }))
  },
}));

export { ToastPopupManager, useToastPopupStore };
