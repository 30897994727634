import { SelectedTheme, UiThemeType } from "@Config/jeomsin_theme";

export const JeomsinColors = {
    MainBg: {light:"#F4F7FE", dark:"#171819"},
    SubBg01: {light:"#FFFFFF", dark:"#232527"},
    SubBg02: {light:"#F8F8F8", dark:"#2B2E31"},
    SubBg03: {light:"#FFFFFF", dark:"#0D0E0F"},
    SubBg04: {light:"#FFFFFF", dark:"#171819"},
    SubBg05: {light:"#FFFFFF", dark:"#2B2E31"},

    SajuBg: {light:"#F4F7FE", dark:"#2B2E31"},
    SajuBg2: {light:"#F4F7FE", dark:"#F4F7FE"},
    SajuBg3: {light:"#F4F7FE", dark:"#232527"},
    SajuBg6: {light:"#1F2024", dark:"#373940"},
    SajuBg7: {light:"#BCC3D3", dark:"#676F76"},

    PointColor01: {light:"#FFF854", dark:"#FFF854"},
    PointColor02: {light:"#20DEE5", dark:"#20DEE5"},
    PointColor03: {light:"#3448FF", dark:"#20DEE5"},
    PointColor04: {light:"#F7F061", dark:"#F7F061"},
    PointColor05: {light:"#3448FF", dark:"#3448FF"},

    UnderLine: {light:"#FFF854FF", dark:"#FFF85400"},
    UnderLine2: {light:"#FCEC6F", dark:"#FCEC6F"},
    UnderLine3: {light:"#EAEEF7", dark:"#EAEEF7"},

    RatingBarBg: {light:"#DFE5F0", dark:"#404040"},
    
    MainText: {light:"#1F2024", dark:"#FAFAFA"},
    MainText02: {light:"#FFFFFF", dark:"#1F2024"},
    SubText01: {light:"#818C9A", dark:"#B4BDC6"},
    SubText02: {light:"#BCC3D3", dark:"#676F76"},
    ImageText: {light:"#F2F2F2", dark:"#F2F2F2"},

    Line01: {light:"#E4E9F4", dark:"#2A2C2E"},
    Line02: {light:"#E4E9F4", dark:"#33363B"},
    Line03: {light:"#BCC3D3", dark:"#676F76"},
    Line04: {light:"#33363B", dark:"#33363B"},
    Line05: {light:"#EAEEF7", dark:"#33363B"},
    Line06: {light:"#E4E9F4", dark:"#424549"},

    SecondButton: {light:"#1F2024", dark:"#FFFFFF"},
    ThirdButton: {light:"#E9EBF4", dark:"#404040"},

    ButtonText01: {light:"#1F2024", dark:"#1F2024"},
    ButtonText02: {light:"#FFFFFF", dark:"#1F2024"},
    ButtonText03: {light:"#BCC3D3", dark:"#5D5D5D"},

    GraphRed: {light:"#FC4A44", dark:"#FC4A44"},
    GraphGreen: {light:"#6DD658", dark:"#6DD658"},
    GraphBlue: {light:"#27AEE0", dark:"#27AEE0"},
    GraphOrange: {light:"#FFA121", dark:"#FFA121"},
    GraphPurple: {light:"#575E8D", dark:"#575E8D"},

    TextRed: {light:"#FC4A44", dark:"#FC4A44"},
    TextGreen: {light:"#EEF8C1", dark:"#EEF8C1"},
    TextBlue: {light:"#A4E5F8", dark:"#A4E5F8"},
    TextOrange: {light:"#FFCD89", dark:"#FFCD89"},
    TextPurple: {light:"#AFB5E5", dark:"#AFB5E5"},

    ContentErrorNoti: {light:"#F72B3A", dark:"#F72B3A"},
    ContentErrorNoti2: {light:"#FB959D", dark:"#FB959D"},

    UiYellow: {light:"#F7F061", dark:"#F7F061"},
    UiBlack: {light:"#1F2024", dark:"#1F2024"},
    UiBlackButton: {light:"#1F2024", dark:"#FFFFFF"},

    BgF4: {light:"#F4F4F4", dark:"#F4F4F4"},

    MainButton: {light:"#FFF854", dark:"#FFF854"},
    boxShadow: {light:"#5259740C", dark:"#5259740C"},

    White: {light:"#FFFFFF", dark:"#FFFFFF"},
    Yellow: {light:"#FAF350", dark:"#FAF350"},
}
export type JeomsinColors = (typeof JeomsinColors)[keyof typeof JeomsinColors];

export const initializeColors = () => {
    const style = document.createElement('style');
    style.id = "Colors";

    style.innerHTML = `:root {\n`;

    Object.entries(JeomsinColors).forEach(([key, value]) => {
        style.innerHTML += `--${key}: ${value.light};\n`;
    });

    style.innerHTML += `}`;
    document.head.appendChild(style);
}

export const getJeomsinColor = (value:JeomsinColors) => {
    let result:string;

    result = (SelectedTheme == UiThemeType.LightTheme) ? value.light : value.dark;

    return result
}