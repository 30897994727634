import "@CSS/animation/fade.css";
import "@CSS/animation/slide_in_bottom.css";
import { GroupTypes, RelationTypes } from "@DataDefine/relation_info_model";
import { useGeometryReader } from "@Hooks/use_geometry_reader";
import { IdTypes } from "@Network/request/api_combine_member_login";
import { useUserInfoStore } from "@State/user_info_store";
import CloseIcon from "@Views/common_component/icons/close_icon";
import SajuMyeongsikSpecifics from "@Views/unse/saju_myeongsik_specifics/saju_myeongsik_specifics";
import { Dispatch, ReactNode, SetStateAction, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import css from "./bottom_popup.module.css";
import { CoinHistoryFilterBox } from "./sub_component/coin_history_filter_box/coin_history_filter_box";
import { CounselorFilterBox } from "./sub_component/counselor_filter_box/counselor_filter_box";
import { FirstRunningCoupangAdPopupBox } from "./sub_component/first_running_coupang_ad_box/first_running_coupang_ad_popup_box";
import { GuideBox } from "./sub_component/guide_box/guide_box";
import SimplePayCardChangeBox from "./sub_component/simple_pay_card_change_box/simple_pay_card_change_box";
import useShowExternalWeb from "@Hooks/use_show_external_web";
import styled from "@emotion/styled";

type BottomPopupProps = {
  isOpen: boolean;
  type: BottomPopupType;
  title?: string;
  contents?: any;
  defaultValue?: any;
  firstButtonTitle?: string;
  onClickFirstButton?: () => void;
  secondButtonTitle?: string;
  onClickSecondButton?: () => void;
  onClose?: (returnValue?:any) => void;
  blurred?: boolean;
  notDimmed?: boolean;
  children?: ReactNode;
  lazyShow?: boolean;
};

export const BottomPopupType = {
  OneButton: "OneButton",
  TwoButton: "TwoButton",
  Onboarding: "Onboarding",
  Login: "Login",
  SnsIntegration: "SnsIntegration",
  SajuMyeongsikSpecifics: "SajuMyeongsikSpecifics",
  TermsOfUse: "TermsOfUse",
  PrivacyUsage: "PrivacyUsage",
  RelationSelcet: "RelationSelcet",
  DreamCategory: "DreamCategory",
  DreamDetailCategory: "DreamDetailCategory",
  CounselorListFilterSelect: "CounselorListFilterSelect",
  SimpleCardRegistGuide: "SimpleCardRegistGuide",
  CoinHistoryFilterSelect: "CoinHistoryFilterSelect",
  SimplePayCardChange: "SimplePayCardChange",
  FirstRunningCoupangAd: "FirstRunningCoupangAd",
} as const;
export type BottomPopupType = (typeof BottomPopupType)[keyof typeof BottomPopupType];

const BottomPopup: React.FC<BottomPopupProps> = (props) => {
  const popupWrapRef = useRef(null);
  const popupContentRef = useRef(null);
  const [showText, setShowText] = useState(true);
  const { getSize } = useGeometryReader();
  const geometry = getSize();
  const [lazyShow, setLazyShow] = useState<boolean>(props.lazyShow ?? false);
  const [opacity, setOpacity] = useState(1);

  return (
    <CSSTransition
      nodeRef={popupWrapRef}
      in={props.isOpen}
      timeout={{
        enter: 400,
        exit: 400,
      }}
      classNames="fade"
      mountOnEnter
      unmountOnExit
      onEnter={() => setShowText(true)}
      onExit={() => setShowText(false)}
    >
      <section
        className={`${lazyShow ? "none" : css.modal} ${
          props.blurred ?? false ? css.blurred : css.unblurred
        }`}
        style={{
          width: `${geometry.width}px`,
          opacity,
          transition: "opacity linear 300ms",
          pointerEvents: opacity === 1 ? "all" : "none",
        }}
      >
        <div
          className={`${css.dim} ${props.notDimmed ? "none" : css.dim_color}`}
          onClick={()=> {
            if (props.onClose) {
              props.onClose();
            }
          }}
          ref={popupWrapRef}
        >
        </div>
        <CSSTransition
          nodeRef={popupContentRef}
          in={showText}
          timeout={{
            enter: 200,
            exit: 200,
          }}
          classNames="slide-in-bottom"
        >
          {PopupType(props, popupContentRef, setLazyShow, setOpacity)}
        </CSSTransition>
      </section>
    </CSSTransition>
  );
};

const PopupType = (
  props: BottomPopupProps,
  ref: React.MutableRefObject<null>,
  setLazyShow: Dispatch<SetStateAction<boolean>>,
  setOpacity: Dispatch<SetStateAction<number>>,
) => {
  let returnValue: ReactNode;
  const [selectValue, setSelectValue] = useState<string | null>(props.defaultValue ?? null);
  const [selectText, setSelectText] = useState<string | null>(null);
  const { representativeUserInfo } = useUserInfoStore();

  switch (props.type) {
    case BottomPopupType.OneButton:
      returnValue = (
        <div className={css.modal_content} ref={ref}>
          <div className={css.close_button_wrap}>
          </div>
          <div className={css.title}>{props.title}</div>
          <div className={`${css.contents} mb40`}>{props.contents}</div>
          <div className={css.button_wrap}>
          <div
              className={`${css.button} bgcolor-yellow`}
              onClick={() => {
                if (props.onClickFirstButton) {
                  props.onClickFirstButton();
                }

                if (props.onClose) {
                  props.onClose();
                }
              }}
            >
              {props.firstButtonTitle}
            </div>
          </div>
        </div>
      );
      break;
    case BottomPopupType.TwoButton:
      returnValue = (
        <div className={css.modal_content} ref={ref}>
          <div className={css.close_button_wrap}>
          </div>
          <div className={css.title}>{props.title}</div>
          <div className={`${css.contents} mb40`}>{props.contents}</div>
          <div className={css.button_wrap_row}>
          <FirstButton
              className={`${css.button} bgcolor-gray`}
              onClick={() => {
                if (props.onClickFirstButton) {
                  props.onClickFirstButton();
                }

                if (props.onClose) {
                  props.onClose();
                }
              }}
            >
              {props.firstButtonTitle}
            </FirstButton>
            <SecondButton
              className={`${css.button} bgcolor-yellow`}
              onClick={() => {
                if (props.onClickSecondButton) {
                  props.onClickSecondButton();
                }

                if (props.onClose) {
                  props.onClose();
                }
              }}
            >
              {props.secondButtonTitle}
            </SecondButton>
          </div>
        </div>
      );
      break;
    case BottomPopupType.Onboarding:
      returnValue = (
        <div className={css.modal_content} ref={ref}>
          <div className={css.close_button_wrap}>
            <img
              className={css.close_button}
              src="images/member_integrated/mypage/setting/close.png"
              onClick={()=> {
                if (props.onClose) {
                  props.onClose();
                }
              }}
            />
          </div>
          <div className={css.title}>{props.title}</div>
          <div className={`${css.contents} mb40`}>{props.contents}</div>
          <div className={css.button_wrap}>
            <div
              className={`${css.button} bgcolor-yellow`}
              onClick={props.onClickFirstButton}
            >
              점신 시작하기
            </div>
            {/* TODO SNS 연동 기능 추가 후 해당 버튼 활성화 */}
            <div
              className={`${css.button} bgcolor-gray`}
              onClick={props.onClickSecondButton}
            >
              기존 회원 로그인하기
            </div>
          </div>
        </div>
      );
      break;
    case BottomPopupType.Login:
      returnValue = (
        <div className={css.modal_content}>
          <div className={css.close_button_wrap}>
            <img
              className={css.close_button}
              src="images/member_integrated/mypage/setting/close.png"
              onClick={()=> {
                if (props.onClose) {
                  props.onClose();
                }
              }}
            />
          </div>
          <div className={css.title}>{props.title}</div>
          <div className={css.login_text_wrap}>
            <div className={css.login_text}>
              <span className={css.login_main_text}>SNS 계정으로 로그인</span>
              <span className={`${css.login_sub_text} mt5`}>
                연동했던 SNS계정을 선택해주세요
              </span>
            </div>
            <div className={`${css.find_account} ${css.login_sub_text}`}>
              계정찿기
            </div>
          </div>
          <div className={`${css.sns_wrap} mt20 mb48`}>
            <img src="images/member_integrated/member/naver.png" alt="Connect Naver" />
            <img src="images/member_integrated/member/kakao.png" alt="Connect Kakao" />
            <img src="images/member_integrated/member/google.png" onClick={() => {
              if (props.onClose) {
                props.onClose(IdTypes.Google);
              }
            }} alt="Connect Googe" />
          </div>
        </div>
      );
      break;
    case BottomPopupType.SnsIntegration:
      returnValue = (
        <div className={css.modal_content}>
          <div className={css.close_button_wrap}>
            <img
              className={css.close_button}
              src="images/member_integrated/mypage/setting/close.png"
              onClick={()=> {
                if (props.onClose) {
                  props.onClose();
                }
              }}
            />
          </div>
          <div className={css.title}>{props.title}</div>
          <div className={`${css.contents} mb40`}>{props.contents}</div>
          <div className={`${css.sns_wrap} mt20 mb48`}>
            <img src="images/member_integrated/member/naver.png" alt="Connect Naver" />
            <img src="images/member_integrated/member/kakao.png" alt="Connect Kakao" />
            <img src="images/member_integrated/member/google.png" onClick={() => {
              if (props.onClose) {
                props.onClose(IdTypes.Google);
              }
            }} alt="Connect Googe" />
          </div>
        </div>
      );
      break;
    case BottomPopupType.SajuMyeongsikSpecifics:
      const info = props.contents;
      if (info) {
        const definition = info.definition;
        const characteristics = info.characteristics;
        const buttonTitle = info.buttonTitle;

        returnValue = (
          <div className={css.modal_content} style={{
            backgroundColor: "transparent",
          }}>
            <SajuMyeongsikSpecifics
              title={props.title}
              definition={definition}
              characteristics={characteristics}
              buttonTitle={buttonTitle}
              shouldClose={props.isOpen === false}
              clickAction={() => {
                if (props.onClickFirstButton) {
                  props.onClickFirstButton();
                }
              }}
              closeAction={() => {
                if (props.onClose) {
                  props.onClose();
                }
              }}
            />
          </div>
        );
      } else {
        returnValue = <></>;
      }
      break;
    case BottomPopupType.TermsOfUse:
      returnValue = (
        <AnimatedBottomPopupForTermsOfUse
         onClickFirstButton={props.onClickFirstButton}
         onClose={props.onClose}
         setOpacity={setOpacity}
        />
      );
      break;
    case BottomPopupType.PrivacyUsage:
      returnValue = (
        <div className={css.modal_content}>
        <div className={css.close_button_wrap}>
          <img
            className={css.close_button}
            src="images/member_integrated/mypage/setting/close.png"
            onClick={()=> {
              if (props.onClose) {
                props.onClose();
              }
            }}
          />
        </div>
        <div className={css.privacy_usage}>
          <div className={css.title}>개인정보 수집 · 이용 동의 (혜택 알림)</div>
          <table>
            <tr>
              <th>수집 및<br/>이용 목적</th>
              <td>각종 이벤트, 회원 혜택, 쿠폰(알림) 등의 사전 안내 및 이벤트 기반한 알림</td>
            </tr>
            <tr>
              <th>수집 항목</th>
              <td>이름, 휴대전화, 생년월일, 성별, 상품 구매정보, 서비스 이용 내역, 광고 정보 수신 채널 (문자, 앱 푸시, 카카오톡)</td>
            </tr>
            <tr>
              <th>보유 및<br/>이용기간</th>
              <td>회원 탈퇴 후 30일까지 또는 해당 서비스 등의 철회 요청 종료 시까지</td>
            </tr>
          </table>
          <p className={css.note}>* 회원은 본 서비스 이용 동의에 대한 거부를 할 수 있으며, 미동의 시 본 서비스에 대한 혜택을 받을 수 없습니다.</p>
        </div>
      </div>
      );
      break;
    case BottomPopupType.RelationSelcet:
      returnValue = (
        <div className={css.modal_content} ref={ref}>
        <div className={css.close_button_wrap}>
        </div>
        <div className={css.title}>{representativeUserInfo?.name}님과 관계</div>
        <div className={`${css.contents} mb40 pl24 pr24`}>
          <div className={css.relation_title}>가족</div>
          <div className={css.relation_container}>
            {
              RelationTypes.filter((value, index) => value.groupIdx == GroupTypes.family.groupIdx).map((value, index) => (
                <button
                  key={index}
                  className={`${css.relation_button} ${(selectValue == value.relationIdx) ? css.selected : ""}`} 
                  value={value.relationIdx} 
                  onClick={()=> {
                    setSelectText(value.relationName);
                    setSelectValue(value.relationIdx);
                  }}
                >
                  {value.relationName}
                </button>
              ))
            }
          </div>
          <div className={css.relation_title}>회사</div>
          <div className={css.relation_container}>
          {
              RelationTypes.filter((value, index) => value.groupIdx == GroupTypes.company.groupIdx).map((value, index) => (
                <button
                  key={index}
                  className={`${css.relation_button} ${(selectValue == value.relationIdx) ? css.selected : ""}`} 
                  value={value.relationIdx} 
                  onClick={()=> {
                    setSelectText(value.relationName);
                    setSelectValue(value.relationIdx);
                  }}
                >
                  {value.relationName}
                </button>
              ))
            }
          </div>
          <div className={css.relation_title}>친구</div>
          <div className={css.relation_container}>
          {
              RelationTypes.filter((value, index) => value.groupIdx == GroupTypes.friend.groupIdx).map((value, index) => (
                <button
                  key={index}
                  className={`${css.relation_button} ${(selectValue == value.relationIdx) ? css.selected : ""}`} 
                  value={value.relationIdx} 
                  onClick={()=> {
                    setSelectText(value.relationName);
                    setSelectValue(value.relationIdx);
                  }}
                >
                  {value.relationName}
                </button>
              ))
            }
          </div>
          <div className={css.relation_title}>미설정</div>
          <div className={css.relation_container}>
            {
              RelationTypes.filter((value, index) => value.groupIdx == GroupTypes.none.groupIdx).map((value, index) => (
                <button
                  key={value.relationIdx}
                  className={`${css.relation_button} ${(selectValue == value.relationIdx) ? css.selected : ""}`} 
                  value={value.relationIdx} 
                  onClick={()=> {
                    setSelectText(value.relationName);
                    setSelectValue(value.relationIdx);
                  }}
                >
                  {value.relationName}
                </button>
              ))
            }
          </div>
        </div>
        <div className={css.button_wrap}>
          <div
            className={`${css.button} bgcolor-yellow`}
            onClick={()=> {
              if (props.onClose) {
                props.onClose({text: selectText, value:selectValue});
              }
            }}
          >
            등록하기
          </div>
        </div>
      </div>
      );
      break;
    case BottomPopupType.DreamCategory:
        returnValue = (
          <div className={css.modal_content} ref={ref}>
          <div className={css.close_button_wrap}>
            <img
              className={css.close_button}
              src="images/member_integrated/mypage/setting/close.png"
              onClick={()=> {
                if (props.onClose) {
                  props.onClose();
                }
              }}
            />
          </div>
          <div className={css.title}>주제를 선택해 주세요.</div>
          <div className={`${css.contents} mb40 pl24 pr24`}>
            <div className={css.dream_category_container}>
              {
                props.contents?.map((value:any, index:number) => (
                  <button
                    key={index}
                    value={value} 
                    onClick={()=> {
                      if (props.onClose) {
                        props.onClose({text: value, value:index});
                      }
                    }}
                  >
                    {value}
                  </button>
                ))
              }
            </div>
          </div>
        </div>
        );
        break;
      case BottomPopupType.DreamDetailCategory:
          returnValue = (
            <div className={css.modal_content} ref={ref}>
            <div className={css.close_button_wrap}>
              <img
                className={css.close_button}
                src="images/member_integrated/mypage/setting/close.png"
                onClick={()=> {
                  if (props.onClose) {
                    props.onClose();
                  }
                }}
              />
            </div>
            <div className={css.title}>{props.title}</div>
            <div className={`${css.contents} mb20 pl24 pr24`}>
              <div className={css.dream_detail_category_container}>
                <div className={css.dream_detail_contents_container}>
                {
                  props.contents?.map((value:any, index:number) => (
                    <button
                      key={index}
                      value={value} 
                      onClick={()=> {
                        if (props.onClose) {
                          props.onClose({text: value, value:index});
                        }
                      }}
                    >
                      {value}
                    </button>
                  ))
                }
                </div>
              </div>
            </div>
          </div>
          );
          break;
        case BottomPopupType.CounselorListFilterSelect:
          returnValue = (
            <CounselorFilterBox 
              contents={props.contents}
              onClick={(response) => {
                if (props.onClose) {
                  props.onClose(response);
                }
              }
            }
            />
          );
          break;
        case BottomPopupType.SimpleCardRegistGuide:
          returnValue = (
            <GuideBox 
              onClick={() => {
                if (props.onClose) {
                  props.onClose();
                }
              }
            }
            />
          );
          break;
        case BottomPopupType.CoinHistoryFilterSelect:
          returnValue = (
            <CoinHistoryFilterBox
              selected={props.defaultValue}
              item={props.contents}
              onClick={(selectValue) => {
                if (props.onClose) {
                  props.onClose(selectValue);
                }
              }
            }
            />
          );
          break;
        case BottomPopupType.SimplePayCardChange:
          returnValue = (
            <SimplePayCardChangeBox onClick={props.onClose}/>
          );
          break;
        case BottomPopupType.FirstRunningCoupangAd:
          returnValue = (
            <FirstRunningCoupangAdPopupBox 
            onLoad={(isLoaded) => setLazyShow(!isLoaded)}
            onClose={() => {
              if (props.onClose) {
                props.onClose();
              }
            }}/>
          );
          break;
    default:
      returnValue = <></>;
      break;
  }

  return returnValue;
};

function AnimatedBottomPopupForTermsOfUse(props: {
  onClose?: (returnValue?: any) => void;
  onClickFirstButton?: () => void;
  setOpacity: Dispatch<SetStateAction<number>>;
}) {
  const { showExternalWeb } = useShowExternalWeb();

  return (
    <div className={`${css.modal_content} pb0 ${css.terms_of_use_container} ${css.terms_of_use_show}`} style={{
      viewTransitionName: "bottom_popup_transitionable",
    }}>
      <div
        className={`${css.terms_of_use__close_btn_container}`}
        onClick={() => {
          props.onClose?.();
        }}
      >
        <CloseIcon
          className={css.terms_of_use__close_btn}
          color="var(--MainText)"
          width={1}
        />
      </div>
      <span className={`${css.terms_of_use__title}`}>이용약관 동의</span>
      <span className={`${css.terms_of_use__description}`}>
        아래 시작하기를 누르면 점신의{" "}
        <u onClick={async () => {
          props.setOpacity(0);
          await showExternalWeb("서비스 이용약관", "https://www.jeomsin.co.kr/agree/");
          props.setOpacity(1);
        }}>
          서비스 이용약관
        </u>
        <br />및{" "}
        <u onClick={async () => {
          props.setOpacity(0);
          await showExternalWeb("개인정보처리방침", "https://www.jeomsin.co.kr/policy/");
          props.setOpacity(1);
        }}>
          개인정보 처리방침
        </u>
        에 동의하는 것으로 간주합니다.
      </span>
      <div
        className={css.terms_of_use__start_btn}
        onClick={async () => {
          props.onClickFirstButton?.();
        }}
      >
        <span className={css.terms_of_use__start_btn__title}>시작하기</span>
      </div>
    </div>
  );
}

const FirstButton = styled("div")`
  cursor: pointer;
`;

const SecondButton = styled("div")`
  cursor: pointer;
`

export default BottomPopup;