import CookieManager from "@Storage/cookie_manager";
import CookieKeys from "@Storage/cookie_keys";
import { CombineMemberModel } from "@DataDefine/jeomsin_user_info";
import apiCheckCombineMemClass from "@Network/request/api_check_combine_mem_class";
import apiCheckAppCombineAuthV2 from "@Network/request/api_check_app_combine_auth_v2";
import apiGetSajuList from "@Network/request/api_get_saju_list";
import { memberManseInfoToJeomsinUserInfo } from "./use_welcome_modal_popup";
import { useUserInfoStore } from "@State/user_info_store";

export default function useHandleUserAutoLogin() {
  const {
    setUserInfoList,
    setRepresentativeUserInfo,
    setFullySignedIn,
    setLoginMemberInfo,
  } = useUserInfoStore();

  return {
    handleUserLogin: async (): Promise<{
      isSuccess: boolean;
      memNo?: string;
      message?: string;
    }> => {
      const savedMemberData = CookieManager.GetJSON(
        CookieKeys.combineMemData
      ) as any | undefined;

      let savedMemberInfo: CombineMemberModel | undefined = undefined;

      if (savedMemberData) {
        savedMemberInfo = savedMemberData.mem_info;
      }

      if (savedMemberInfo) {
        const isSignedInUser = savedMemberInfo.mem_class === "2";

        if (isSignedInUser) {
          // 정회원일 경우, 회원번호를 가져온다.
          const memNo = savedMemberInfo.mem_no;
          const memberClassInfo = await apiCheckCombineMemClass(memNo);

          if (memberClassInfo.msg !== undefined) {
            return {
              isSuccess: false,
              message: memberClassInfo.msg,
            };
          }

          CookieManager.SetJSON(CookieKeys.mypageMemberData, memberClassInfo);

          if (memberClassInfo.mem_class === "2") {
            // 서버를 통해서도, 정회원임을 확인을 완료함
            // 이제 chk.app.combine.auth.model API로 Auth 상태 확인
            const authInfo = await apiCheckAppCombineAuthV2(
              memNo,
              savedMemberInfo
            );

            if (authInfo.mem_info) {
              CookieManager.SetBoolean(CookieKeys.statusCombineMember, true);
              CookieManager.SetBoolean(CookieKeys.isChkAppCombineAuth, true);

              const syncSajuList = await apiGetSajuList(memNo);

              if (syncSajuList.length > 0) {
                // 서버 사주 리스트랑 싱크 맞춤
                const userList = syncSajuList.map((info) =>
                  memberManseInfoToJeomsinUserInfo(info)
                ).sort((a, b) => a.localId - b.localId);

                setUserInfoList(userList);
                setRepresentativeUserInfo(userList.at(0));
                setLoginMemberInfo({
                  mem_info: authInfo.mem_info,
                  mem_item: authInfo.mem_item,
                });
                setFullySignedIn(true);

                return {
                  isSuccess: true,
                  memNo: memNo,
                };
              } else {
                return {
                  isSuccess: true,
                  message: "사주정보가 존재하지 않습니다.",
                };
              }
            } else {
              CookieManager.SetBoolean(CookieKeys.isChkAppCombineAuth, false);
              const errorMessage =
                authInfo.msg ??
                "기존에 저장된 정보와 일치하지 않습니다.\n다시 로그인 해주세요.";

              return {
                isSuccess: false,
                memNo: memNo,
                message: errorMessage,
              };
            }
          }
        }
      }

      // 비회원 케이스
      return {
        isSuccess: true,
      };
    },
    wipeout: () => {
      setFullySignedIn(false);
      setLoginMemberInfo(undefined);
      setRepresentativeUserInfo(undefined);
      setUserInfoList(undefined);
      CookieManager.RemoveKeyValue(CookieKeys.combineMemData);
    },
  };
}
