/** @jsxImportSource @emotion/react */

import { SelectedTheme, UiThemeType } from "@Config/jeomsin_theme";
import { css } from "@emotion/react";
import Weather from "@Models/weather";
import apiGetWeatherCoordinate from "@Network/request/api_get_weather_coordinate";
import apiGetWeatherDefault from "@Network/request/api_get_weather_default";
import { useModalPopup } from "@Views/common_component/async_modal_popup/async_modal_popup";
import CloseIcon from "@Views/common_component/icons/close_icon";
import LocationIcon from "@Views/common_component/icons/location_icon";
import { useEffect, useState } from "react";

interface UsableWeather {
  address: string;
  fineDust: "좋음" | "보통" | "나쁨" | "매우 나쁨";
  fineDustValue: number;
  ultraFindDust: "좋음" | "보통" | "나쁨" | "매우 나쁨";
  ultraFindDustValue: number;
  dataOrigin: "기상청, 한국환경공단";
  celsius: number;
  weatherStatus:
    | "맑음"
    | "맑고 비"
    | "맑음 비/눈"
    | "맑음 눈"
    | "구름 조금"
    | "구름조금 비"
    | "구름 비/눈"
    | "구름조금 눈"
    | "구름 많음"
    | "구름많고 비"
    | "구름 비/눈"
    | "구름많고 눈"
    | "흐림"
    | "흐리고 비"
    | "흐리고 눈"
    | "흐림 비/눈";
  updateDate: string;
  updateHour: number;
  smallWeatherImageSrc: string;
  weatherImageSrc: string;
}

function getPm25Grade(value: number): "좋음" | "보통" | "나쁨" | "매우 나쁨" {
  let returnValue: "좋음" | "보통" | "나쁨" | "매우 나쁨" = "보통";
  if (value >= 0 && value <= 15) {
    returnValue = "좋음";
  } else if (value >= 16 && value <= 35) {
    returnValue = "보통";
  } else if (value >= 36 && value <= 75) {
    returnValue = "나쁨";
  } else if (value >= 76) {
    returnValue = "매우 나쁨";
  }
  return returnValue;
}

function getPm10Grade(value: number): "좋음" | "보통" | "나쁨" | "매우 나쁨" {
  let returnValue: "좋음" | "보통" | "나쁨" | "매우 나쁨" = "보통";
  if (value >= 0 && value <= 30) {
    returnValue = "좋음";
  } else if (value >= 31 && value <= 80) {
    returnValue = "보통";
  } else if (value >= 81 && value <= 150) {
    returnValue = "나쁨";
  } else if (value >= 151) {
    returnValue = "매우 나쁨";
  }
  return returnValue;
}

function weatherToUsable(response: Weather): UsableWeather {
  const mapper = {
    "1": "좋음",
    "2": "보통",
    "3": "나쁨",
    "4": "매우 나쁨",
  } as const;

  const weatherStatusMapper = [
    ["맑음", "맑고 비", "맑음 비/눈", "맑음 눈"],
    ["구름 조금", "구름조금 비", "구름 비/눈", "구름조금 눈"],
    ["구름 많음", "구름많고 비", "구름 비/눈", "구름많고 눈"],
    ["흐림", "흐리고 비", "흐리고 눈", "흐림 비/눈"],
  ] as const;

  let skyIndex = Number(response.SKY);
  let ptyIndex = Number(response.PTY);
  switch (skyIndex) {
    case 1:
    case 2:
    case 3:
    case 4:
      skyIndex = skyIndex - 1;
      break;
    default:
      skyIndex = 0;
      break;
  }
  switch (ptyIndex) {
    case 1:
    case 2:
    case 3:
      break;
    case 5:
    case 6:
    case 7:
      ptyIndex = ptyIndex - 4;
      break;
    default:
      ptyIndex = 0;
      break;
  }

  const weatherImageIndex = skyIndex * 4 + (ptyIndex + 1);

  return {
    address: response.village_name,
    fineDust: getPm10Grade(Number(response.pm10Value)),
    fineDustValue: Number(response.pm10Value),
    ultraFindDust: getPm25Grade(Number(response.pm25Value)),
    ultraFindDustValue: Number(response.pm25Value),
    dataOrigin: "기상청, 한국환경공단",
    celsius: Number(response.T1H),
    weatherStatus: weatherStatusMapper[skyIndex][ptyIndex],
    updateDate: response.str_date,
    updateHour: Number(response.hour),
    smallWeatherImageSrc: `weather_small${weatherImageIndex}`,
    weatherImageSrc: `weather${weatherImageIndex}`,
  };
}

let cachedDefaultWeather: UsableWeather | undefined;

async function getDefaultWeather(): Promise<UsableWeather | undefined> {
  if (cachedDefaultWeather) {
    return cachedDefaultWeather;
  }

  let firstWeather: Weather | undefined;

  try {
    firstWeather = (await apiGetWeatherDefault()).weather.at(0);
  } catch {
    return undefined;
  }

  if (firstWeather) {
    cachedDefaultWeather = weatherToUsable(firstWeather);
    return cachedDefaultWeather;
  }

  return undefined;
}

async function isGeoAvailable(): Promise<boolean> {
  const serviceEnabled = "geolocation" in navigator;

  if (serviceEnabled === false) {
    return false;
  }

  const result = await navigator.permissions.query({ name: "geolocation" });

  if (result.state === "denied") {
    return false;
  }

  return true;
}

async function getWeather(): Promise<{
  isGeoAvailable: boolean;
  weatherData?: UsableWeather;
}> {
  if ((await isGeoAvailable()) === false) {
    return {
      isGeoAvailable: false,
      weatherData: await getDefaultWeather(),
    };
  }

  let currentPosition: GeolocationPosition;
  try {
    currentPosition = await new Promise<GeolocationPosition>(
      (resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            resolve(pos);
          },
          (error) => {
            reject(error);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
          }
        );
      }
    );
  } catch (e) {
    return {
      isGeoAvailable: false,
      weatherData: await getDefaultWeather(),
    };
  }

  const coordinate = currentPosition.coords;
  const response = await apiGetWeatherCoordinate(
    coordinate.latitude,
    coordinate.longitude
  );

  return {
    isGeoAvailable: true,
    weatherData: weatherToUsable(response.weather),
  };
}

const WeatherDivider = () => (
  <div
    css={css`
      height: 8px;
      max-height: 8px;
      min-height: 8px;
      border-left-style: solid;
      border-left-width: 1px;
      border-left-color: var(--Line03);
    `}
  />
);

const Showables = ["미세먼지", "초미세먼지", "출처"];
type Showable = (typeof Showables)[number];

export default function WeatherAppBar({ width }: { width: number }) {
  const [weatherInfo, setWeatherInfo] = useState<UsableWeather | undefined>();
  const [showable, setShowable] = useState<Showable>("미세먼지");
  const [readyToShowDetail, setReadyToShowDetail] = useState(false);
  const [display, setDisplay] = useState(false);
  const { setModalPopupContent } = useModalPopup();

  useEffect(() => {
    const action = async () => {
      setWeatherInfo(await getDefaultWeather());
    };

    action();
  }, []);

  useEffect(() => {
    const cancellable = setTimeout(() => {
      setShowable(
        (original) =>
          Showables[(Showables.indexOf(showable) + 1) % Showables.length]
      );
    }, 6000);

    return () => {
      clearTimeout(cancellable);
    };
  }, [showable]);

  return (
    <>
      <div
        css={css`
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
          color: var(--MainText);
          font-weight: 500;
          font-size: 9px;
          cursor: pointer;
        `}
        onClick={async () => {
          if (readyToShowDetail) {
            setDisplay((original) => !original);
            return;
          }

          const r = await getWeather();

          if (r.isGeoAvailable) {
            setWeatherInfo(r.weatherData);
            setReadyToShowDetail(true);
            setDisplay(true);
          } else {
            setWeatherInfo(r.weatherData);
            await setModalPopupContent({
              title: "점신",
              description:
                "현재위치 승인이 거부되어\n위치정보를 찾을 수 없습니다.",
              buttonTitles: ["확인"],
              customVerticalGaps: [undefined, undefined, undefined, 30],
            });
          }
        }}
      >
        {weatherInfo && (
          <>
            {showable === "미세먼지" ? (
              <>
                <span>{weatherInfo.address}</span>
                <WeatherDivider />
                <span>미세먼지 • </span>
                <span>{weatherInfo.fineDust}</span>
              </>
            ) : showable === "초미세먼지" ? (
              <>
                <span>{weatherInfo.address}</span>
                <WeatherDivider />
                <span>초미세먼지 • </span>
                <span>{weatherInfo.ultraFindDust}</span>
              </>
            ) : (
              <>
                <span>출처: {weatherInfo.dataOrigin}</span>
              </>
            )}
            <WeatherDivider />
            <span>{weatherInfo.celsius}℃</span>
            <img
              src={`/images/common/weather_${
                SelectedTheme === UiThemeType.DarkTheme ? "black" : "white"
              }/${weatherInfo.smallWeatherImageSrc}.png`}
              alt="weather"
            />
          </>
        )}
      </div>

      {display && weatherInfo && (
        <WeatherInterstitial
          width={width}
          weatherInfo={weatherInfo}
          onClickClose={() => {
            setDisplay(false);
          }}
        />
      )}
    </>
  );
}

function WeatherInterstitial({
  width,
  weatherInfo,
  onClickClose,
}: {
  width: number;
  weatherInfo: UsableWeather;
  onClickClose: () => void;
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 99999;
        width: ${width + 48}px;
        backdrop-filter: blur(10px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: ${show ? "100%" : "0"};
        transform: translateX(-24px);
        transition: opacity 500ms;
      `}
    >
      <div
        css={css`
          width: 48px;
          height: 48px;
          background-color: var(--SecondButton);
          border-radius: 1000px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-bottom: 60px;
        `}
        onClick={() => {
          setShow(false);
          setTimeout(() => {
            onClickClose();
          }, 500);
        }}
      >
        <CloseIcon
          color="var(--MainText02)"
          width={2}
          css={css`
            object-fit: contain;
            width: 20px;
            height: 20px;
          `}
        />
      </div>
      <span
        css={css`
          font-size: 30px;
          color: var(--MainText);
          margin-bottom: 28px;
        `}
      >
        <span
          css={css`
            font-weight: 200;
          `}
        >
          오늘의
        </span>{" "}
        <span
          css={css`
            font-weight: 700;
          `}
        >
          날씨
        </span>
      </span>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 6px;
          align-items: center;
          margin-bottom: 30px;
        `}
      >
        <LocationIcon
          css={css`
            width: 12px;
            height: 12px;
            object-fit: contain;
          `}
        />
        <span
          css={css`
            font-size: 12px;
            color: var(--MainText);
            font-weight: 400;
          `}
        >
          {weatherInfo.address}
        </span>
      </div>
      <img
        css={css`
          width: 160px !important;
          max-width: 160px;
          min-width: 160px;
          height: 160px !important;
          max-height: 160px;
          min-height: 160px;
          object-fit: contain;
          margin-bottom: 8px;
        `}
        src={`images/common/weather_${
          SelectedTheme === UiThemeType.DarkTheme ? "black" : "white"
        }/${weatherInfo.weatherImageSrc}.png`}
        alt={`${weatherInfo.weatherStatus} icon`}
      />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          color: var(--MainText);
          margin-bottom: 36px;
        `}
      >
        <span
          css={css`
            font-size: 33px;
            font-weight: 700;
          `}
        >
          {weatherInfo.celsius}
        </span>
        <span
          css={css`
            font-size: 15px;
            font-weight: 700;
            margin-right: 15px;
          `}
        >
          °C
        </span>
        <span
          css={css`
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
          `}
        >
          {weatherInfo.weatherStatus}
        </span>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          height: 38px;
          background-color: var(--SubBg01);
          align-items: center;
          border-radius: 1000px;
          font-size: 9px;
          font-weight: 500;
          justify-content: space-around;
          width: 344px;
          margin-bottom: 26px;
          padding: 0 24px 0 24px;
        `}
      >
        <span>미세 먼지</span>
        <span>·</span>
        <span
          css={css`
            font-size: 11px;
          `}
        >
          {weatherInfo.fineDust} {weatherInfo.fineDustValue}
        </span>
        <span>초미세 먼지</span>
        <span>·</span>
        <span
          css={css`
            font-size: 11px;
          `}
        >
          {weatherInfo.ultraFindDust} {weatherInfo.ultraFindDustValue}
        </span>
      </div>
      <span
        css={css`
          white-space: pre-wrap;
          line-height: 16.5px;
          font-size: 11px;
          color: var(--MainText);
          text-align: center;
          margin-bottom: 50px;
        `}
      >
        {
          "관측된 자료는 현지 사정이나 수신상태에 의해\n 차이가 발생할 수 있습니다."
        }
      </span>
      <div
        css={css`
          font-weight: 500;
          font-size: 11px;
          color: var(--MainText02);
          background-color: var(--MainText);
          padding: 6px 14px 6px 14px;
          border-radius: 1000px;
          margin-bottom: 13px;
        `}
      >
        {weatherInfo.dataOrigin} 제공
      </div>
      <div
        css={css`
          font-size: 11px;
          font-weight: 700;
          color: var(--MainText);
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 14px;
        `}
      >
        <span>{weatherInfo.updateDate}</span>
        <span>{weatherInfo.updateHour}:00</span>
      </div>
    </div>
  );
}
