import { CSSProperties, useEffect, useRef, useState } from "react";
import css from "./ios_style_picker.module.css";

export type IosStylePickerItem = {
  text: string;
  value: any;
};

type IosStylePickerProps = {
  style?: CSSProperties;
  className?: string;
  data: IosStylePickerItem[];
  selectionHandler: (selection: IosStylePickerItem) => void;
  prepareUI: boolean;
};

const CARD_HEIGHT = 25;

const IosStyleDragState = {
  Grab:"Grab",
  ScrollMove:"ScrollMove",
  Release:"Release",
}
type IosStyleDragState = (typeof IosStyleDragState)[keyof typeof IosStyleDragState];

const IosStylePicker = ({
  style,
  className,
  data,
  selectionHandler,
  prepareUI,
}: IosStylePickerProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [selectedItemPosition, setSelectedItemPosition] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const displayable = data.map((item, index) => {
    const distanceFromSelected = index - selectedItemPosition;

    let rotationDeg = -30 * distanceFromSelected;
    let scale = 1 / (Math.abs(distanceFromSelected) / 10 + 1);

    const text = item.text;

    if (rotationDeg < -90) {
      rotationDeg = -90;
    } else if (rotationDeg > 90) {
      rotationDeg = 90;
    }

    if (selectedIndex === index) {
      rotationDeg = 0;
    }

    const yOffset = distanceFromSelected * (CARD_HEIGHT / 3);

    return (
      <div key={index} className={css.list_element_container} onClick={(e) => scrollToSelectItem(index)}>
        <div
          className={css.list_element_stackable}
          style={{
            height: `${CARD_HEIGHT}px`,
          }}
        >
          <div className={css.list_element_y_centered}>
            <div
              style={{
                transform: `rotateX(${rotationDeg}deg)`,
              }}
              className={selectedIndex === index ? css.selected : css.unselected}
            >
              <div
                style={{
                  transform: `translateY(${yOffset}px)`,
                }}
              >
                <div style={{ transform: `scale(${scale})` }}>
                  <span>{text}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  useEffect(() => {
    //팝업 시 첫 데이터 선택
    selectionHandler(data[selectedIndex]);
  }, []);
  
  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const scrollAmount = scrollRef.current.scrollTop;
        const positioning = scrollAmount / CARD_HEIGHT;
        const index = Math.round(positioning);
        setSelectedItemPosition(positioning);

        if (selectedIndex !== index) {
          setSelectedIndex(index);
          selectionHandler(data[index]);
        }
      }
    };

    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [selectedIndex, selectedItemPosition, data, selectionHandler]);

  useEffect(() => {
    if (prepareUI === false) {
      return;
    }

    scrollToSelectItem();
  }, [prepareUI]);

  const scrollToSelectItem = (targetIndex?:number) => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      const scrollAmount = scrollContainer.scrollTop;
      const positioning = scrollAmount / CARD_HEIGHT;
      const index = targetIndex ?? 0;
      setSelectedItemPosition(positioning);

      if (selectedIndex !== index) {
        setSelectedIndex(index);
        selectionHandler(data[index]);

        scrollContainer.scrollTo({
          top: index * CARD_HEIGHT,
          behavior: 'smooth',
        });
      }
    }
  }


  const currentIosStyleDragState = useRef<IosStyleDragState>(IosStyleDragState.Release);
  const startY = useRef(0);
  const scrollTop = useRef(0);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    if (scrollRef.current) {
      currentIosStyleDragState.current = IosStyleDragState.Grab;
      startY.current = e.pageY - scrollRef.current.offsetTop;
      scrollTop.current = scrollRef.current.scrollTop;

      // 마우스 커서 변경
      scrollRef.current.style.cursor = "grabbing";
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (currentIosStyleDragState.current === IosStyleDragState.Release || !scrollRef.current) return;

    currentIosStyleDragState.current = IosStyleDragState.ScrollMove;
    const x = e.pageY - scrollRef.current.offsetTop;
    const dragDistance = x - startY.current; // 드래그 거리
    scrollRef.current.scrollTop = scrollTop.current - dragDistance;
  };


  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    if (currentIosStyleDragState.current !== IosStyleDragState.Release && scrollRef.current) {
      handleDragEnd(e); // 마우스가 떠날 때 드래그 상태 초기화
    }
  };

  const handleDragEnd = (e: React.MouseEvent<HTMLDivElement>) => {
    if(!scrollRef.current) return;

    if (currentIosStyleDragState.current === IosStyleDragState.ScrollMove) {
      e.preventDefault(); // 드래그 상태에서 클릭 방지
      e.stopPropagation();
    }

    currentIosStyleDragState.current = IosStyleDragState.Release;
    scrollRef.current.style.cursor = "grab"; // 마우스커 커서 복구
  };

  return (
    <div className={[css.list_container, className].join(" ")} style={style}>
      <div 
        ref={scrollRef} 
        className={css.list_style}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onClickCapture={handleDragEnd}
      >
        {[0, 1, 2].map((index) => (
          <Placeholder key={index} />
        ))}
        {displayable}
        {[3, 4, 5].map((index) => (
          <Placeholder key={index} />
        ))}
      </div>
      <div
        className={css.selection_indicator_rect}
        style={{
          height: `${CARD_HEIGHT + 10}px`,
        }}
      />
    </div>
  );
};

function Placeholder() {
  return (
    <div
      style={{
        height: `${CARD_HEIGHT}px`,
      }}
    />
  );
}

export default IosStylePicker;
