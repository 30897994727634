import { Box } from '@mui/material';
import "./aside_page.css";
import AsidePageMenu from './aside_page_menu';
import SmallQRCode from './small_qr_code';

const JeomsinAsidePage = (props:any) => {
    return (
        <Box className="aside-wrap">
            <div className="growable_140" />
            <div className="logo-wrap">
                <img className="logo-wrap--img" src='/web_only/aside_page/jeomsin_logo.png' alt="Jeomsin Logo" />
            </div>
            <Box className="content-wrap">
                <Box className="main-content-wrap">
                    <span className="aside_page_top_intro_font">
                        매일이 특별해지는 점신에서 만나요!
                    </span>
                    <div className="aside_page_top_big_title">
                        내 인생의 큐레이터 <br/>
                        <span className='aside_page_top_big_title_blue'>맞춤 운세 점신</span>
                    </div>
                    <AsidePageMenu />
                </Box>
            </Box>
            <div className="growable_90" />
            <Box className="footer-wrap">
                <Box 
                    className="banner-wrap"
                    onClick={() => {
                        window.open("https://jeomsin.co.kr/ars/");
                    }}
                >
                    <div className="banner-text-wrap">
                        <div className="banner-text">
                            <span className="ts20 bold7">점신과 함께할 상담사를 찾고 있어요!</span>
                            <br/>
                            <span className="ts14 bold6">원하는 시간에, 원하는 만큼 수익을 창출하세요.</span>
                        </div>
                    </div>
                    <img src='/web_only/aside_page/asset_icon.png'></img>
                </Box>
                <Box className="app-link-wrap">
                    <Box className="store-wrap">
                        <Box className="play-stroe mr24" onClick={() => {
                            window.open("https://play.google.com/store/apps/details?id=handasoft.mobile.divination&hl=ko");
                        }}>
                            <img className="mr8" src='/web_only/aside_page/playstore_logo.png'></img>
                            <span className="ts16 bold5">Google Play</span>
                        </Box>
                        <Box className="app-stroe" onClick={() => {
                            window.open("https://apps.apple.com/kr/app/2025-점신-을사년-신년운세-사주-타로-상담/id960571015");
                        }}>
                            <img className="mr8" src='/web_only/aside_page/appstore_logo.png'></img>
                            <span className="ts16 bold5">App Store</span>
                        </Box>
                    </Box>
                    <SmallQRCode />
                </Box>
            </Box>
        </Box>
    );
}

export default JeomsinAsidePage;