import $ from "jquery";

export const get_naver_userprofile = async function (access_token) {
  return new Promise((resolve) => {
    $.ajax({
      url: "https://openapi.naver.com/v1/nid/getUserProfile.json?response_type=json",
      type: "GET",
      data: { access_token: access_token },
      dataType: "jsonp",
      jsonp: "oauth_callback",
      success: function (result) {
        resolve(result.response.id);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        alert(xhr.status);
        alert(thrownError);
      },
    });
  });
};
