import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

export interface MemberManseInfo {
  mem_myyear: string;
  mem_mymonth: string;
  mem_myday: string;
  mem_myhour: string;
  mem_btime: string;
  idx: string;
  mem_no: string;
  local_idx: string;
  relation: string;
  group_type: string;
  yn_bookmark: string;
  mem_myname: string;
  mem_birthday: string;
  mem_btime_code: string;
  mem_mycalendar: string;
  mem_myleap: string;
  mem_mygender: string;
  myNum: string;
  chunNum1: string;
  chunOhang1: string;
  chunNum2: string;
  chunOhang2: string;
  chunNum3: string;
  chunOhang3: string;
  chunNum4: string;
  chunOhang4: string;
  jiNum1: string;
  jiOhang1: string;
  jiNum2: string;
  jiOhang2: string;
  jiNum3: string;
  jiOhang3: string;
  jiNum4: string;
  jiOhang4: string;
  chunGanShipSeongNum1: string;
  chunGanShipSeongNum2: string;
  chunGanShipSeongNum3: string;
  chunGanShipSeongNum4: string;
  jiJiShipSeongNum1: string;
  jiJiShipSeongNum2: string;
  jiJiShipSeongNum3: string;
  jiJiShipSeongNum4: string;
  shipYiUnSeongNum1: string;
  shipYiUnSeongNum2: string;
  shipYiUnSeongNum3: string;
  shipYiUnSeongNum4: string;
  yn_migrate: string;
  yn_del: string;
  reg_date: string;
  update_date: string;
  cronjob_status: string;
  myText: string;
  chunText1: string;
  jiText1: string;
  chunText2: string;
  jiText2: string;
  chunText3: string;
  jiText3: string;
  chunText4: string;
  jiText4: string;
}

const apiGetSajuList = async (
  memberNumber: string
): Promise<MemberManseInfo[]> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetSajuList,
    params: {
      mem_no: memberNumber,
    },
  };

  const raw = (await JeomsinRequest(request)) as any;
  const response: { mem_saju_list?: MemberManseInfo[] } = raw;
  const error: { msg?: string } | undefined = raw;

  try {
    const _ = (response.mem_saju_list ?? []).at(0)?.idx;
    return response.mem_saju_list ?? [];
  } catch {
    throw TypeError(error?.msg ?? "사주 리스트 전달 실패");
  }
};

export default apiGetSajuList;
