import { GetCoupangConfigV2Response } from "@Network/request/api_get_coupang_config_v2";
import { CoupangConfigV2ViewModel } from "./coupang_config_v2_model";
import { CoupangConfigV4ViewModel } from "./coupang_config_v4_model";
import { CoupangConfigModel, CoupangPageCode } from "./coupang_config_model";

export type CoupangConfigViewModel = {
    coupangRectAd:CoupangConfigV2ViewModel|undefined
    coupangLineAd:CoupangConfigV4ViewModel|undefined
    coupangPageConfigList:CoupangConfigModel[]
}

export const CoupangConfigViewModel = () => {
    const getAdConfig = (configData:GetCoupangConfigV2Response|undefined) => {
        let model:CoupangConfigViewModel = {
            coupangRectAd:undefined,
            coupangLineAd:undefined,
            coupangPageConfigList:[]
        }

        // if (!configData) return undefined;
      
        // 새로 등록되는 필드값으로 셋팅
        /**
         * 25.02.06 : Web에서는 ADID 취득 불가로 ADID가 없는 경우에 대해서만 처리
         */
        if (configData?.coupang_config_no_adid) {
            const rectAdConfig:CoupangConfigV2ViewModel = {
                idx: '1',
                ad_type: configData.coupang_config_no_adid!.noADid_ad_type_web,
                coupang_id: configData.coupang_config_no_adid!.noADid_coupang_id_web,
                coupang_subid: configData.coupang_config_no_adid!.noADid_coupang_subid_web,
                coupang_widget_id: configData.coupang_config_no_adid!.noADid_coupang_widget_id_web,
                ad_wait_time: configData.coupang_config_no_adid!.noADid_ad_wait_time_web,
                btn_name: '무료운세 보기',
            };

            model.coupangRectAd = rectAdConfig;
        }

        if (configData?.coupang_config_no_adid_v4) {
            const lineAdConfig:CoupangConfigV4ViewModel = {
                idx: '1',
                ad_type: configData.coupang_config_no_adid_v4.noADid_ad_type_web,
                coupang_id: configData.coupang_config_no_adid_v4.noADid_coupang_id_web,
                coupang_subid: configData.coupang_config_no_adid_v4.noADid_coupang_subid_web,
                coupang_widget_id: configData.coupang_config_no_adid_v4.noADid_coupang_widget_id_web,
                ad_wait_time: configData.coupang_config_no_adid_v4.noADid_ad_wait_time_web,
                btn_name: '무료운세 보기',
                coupangV4Img: configData.coupang_config_v4.coupang_img,
                lineHeight_v4_page01: configData.coupang_config_v4.page01,
                lineHeight_v4_page02: configData.coupang_config_v4.page02,
                lineHeight_v4_page03: configData.coupang_config_v4.page03,
            };

            model.coupangLineAd = lineAdConfig;
        }

        if(configData?.coupang_config) {
            model.coupangPageConfigList = configData?.coupang_config;
        }
        
        return model;
    }

    const getPageAdConfig = (pageConfigData:CoupangConfigModel[], pageCode:CoupangPageCode) => {
        let model:CoupangConfigModel | undefined;

        pageConfigData?.forEach((value, index) => {
            if(value.page_code === pageCode) {
                model = value;
            }
        });
        
        return model;
    }

    return {
        getAdConfig,
        getPageAdConfig,
    };
}