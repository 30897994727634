import { useNavigate } from "react-router";
import styles from "./aside_page_menu.module.css";
import { JeomsinPagePath } from "@Config/jeomsin_page_path";
import { useUserInfoStore } from "@State/user_info_store";
import apiMyPageV3Item from "@Network/request/api_my_page_v3_item";
import {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import useShowMemberIntegratedWelcomeDialog, {
  useMemberIntegratedState,
} from "@Views/main_tab/fortune/use_welcome_modal_popup";
import { useShallow } from "zustand/react/shallow";

// create a ZUstand that sets the property that controls the landing page, called "destination"
// if logged in and used the 3 min, we will not show the button
// if logged in, we will go to the coupon tab
// if logged out, set the destination and show the onboarding modal.

export default function AsidePageMenu() {
  const navigate = useNavigate();
  const { loginMemberInfo } = useUserInfoStore();
  const memberNumber = loginMemberInfo?.mem_info?.mem_no;
  const { showMemberIntegratedWelcomeDialog } =
    useShowMemberIntegratedWelcomeDialog();
  const [couponButtonTitle, setCouponButtonTitle] = useState<
    "쿠폰받기" | "사용하기" | undefined
  >();
  const setDestination = useMemberIntegratedState(
    useShallow((state) => state.setDestination)
  );

  const couponButtonAction = useCallback(() => {
    if (couponButtonTitle === "쿠폰받기") {
      // 게스트 혹은 비회원으로 로그인이 필요함.
      // Destination을 설정해, 로그인이후 쿠폰함으로 이동하도록 함.
      setDestination(JeomsinPagePath.CouponBox);
      showMemberIntegratedWelcomeDialog();
    } else if (couponButtonTitle === "사용하기") {
      // 로그인 상태이며, 3분 무료 쿠폰을 사용할 수 있는 상태
      // 상담 탭으로 이동해 사용할 수 있도록 유도
      navigate(JeomsinPagePath.CounselHome);
    }
  }, [
    couponButtonTitle,
    setDestination,
    showMemberIntegratedWelcomeDialog,
    navigate,
  ]);

  useEffect(() => {
    if (memberNumber === undefined) {
      setCouponButtonTitle("쿠폰받기");
      return;
    }

    apiMyPageV3Item(memberNumber).then((response) => {
      if (response) {
        if (response.mem_item.use_free_3min === "Y") {
          setCouponButtonTitle(undefined);
        } else {
          setCouponButtonTitle("사용하기");
        }
      }
    });
  }, [memberNumber]);

  return (
    <div className={styles.aside_page__menu_container}>
      {(
        [
          [
            `/img/fan-icon.svg`,
            `<b>매달 업데이트 </b>되는 신규 운세`,
            undefined,
            undefined,
          ],
          [
            `/img/time-icon.svg`,
            `전문 상담사 <b>24시 상시 대기</b>`,
            `상담하기`,
            () => navigate(JeomsinPagePath.CounselHome),
          ],
          [
            `/img/discount-icon.svg`,
            `가입만 해도 <b>무료 상담 쿠폰 증정</b>`,
            couponButtonTitle,
            () => couponButtonAction(),
          ],
        ] as [string, string, string | undefined, () => void][]
      ).map(([icon, title, buttonTitle, buttonAction], index) => {
        let textElements: ReactElement[] = [];
        let consumable = "";
        let bolded = false;

        for (let i = 0; i < title.length; i++) {
          const char = title[i];

          if (
            char === "<" &&
            title.at(i + 1) === "b" &&
            title.at(i + 2) === ">"
          ) {
            i += 2;
            textElements[textElements.length] = <span>{consumable}</span>;
            consumable = "";
            bolded = true;
            continue;
          } else if (
            char === "<" &&
            title.at(i + 1) === "/" &&
            title.at(i + 2) === "b" &&
            title.at(i + 3) === ">"
          ) {
            i += 3;
            textElements[textElements.length] = (
              <span className={styles.aside_page__menu__title_bolded}>
                {consumable}
              </span>
            );
            consumable = "";
            bolded = false;
            continue;
          } else {
            consumable = consumable.concat(char);
            continue;
          }
        }

        if (consumable.length > 0) {
          textElements[textElements.length] = (
            <span
              className={
                bolded ? styles.aside_page__menu__title_bolded : undefined
              }
            >
              {consumable}
            </span>
          );
        }

        return (
          <div key={index} className={styles.aside_page__menu}>
            <img
              className={styles.aside_page__menu__icon_image}
              src={icon}
              alt={icon}
            />
            <span className={styles.aside_page__menu__title}>
              {textElements.map((each, idx) => (
                <Fragment key={idx}>{each}</Fragment>
              ))}
            </span>
            {buttonTitle && (
              <span
                className={styles.aside_page__menu__button}
                onClick={() => buttonAction()}
              >
                {buttonTitle}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
}
