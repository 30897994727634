import { useEffect, useRef } from "react";

const useRunOnce = (
  fn: () => void,
  sessionKey:
    | "jeomsin-run-once-storage"
    | "Jeomsin-AppContainer-Run-Once" = "jeomsin-run-once-storage"
) => {
  const triggered = useRef(false);

  useEffect(() => {
    const hasBeenTriggered =
      sessionStorage.getItem(sessionKey) ?? triggered.current;

    if (!hasBeenTriggered) {
      fn();
      triggered.current = true;
      sessionStorage.setItem(sessionKey, "true");
    }
  }, [fn, sessionKey]);

  return null;
};

export default useRunOnce;
