import { dataURLtoFile } from "@Util/utility";
import { useToastPopupStore } from "@Views/common_component/toast_popup/toast_popup_manager";
import html2canvas from "html2canvas";

export default function useImageShare(
  className: string,
  hidableClassNames: string[] = [],
  shareSheetTitle?: string
) {
  const { setToastPopupContent } = useToastPopupStore();

  return {
    initiateShare: async () => {
      const invisibleItems = hidableClassNames.map((className) =>
        document.getElementsByClassName(className).item(0)
      );

      for (const item of invisibleItems) {
        if (item === null || item === undefined) {
          return;
        }

        (item as HTMLImageElement).style.opacity = "0";
      }

      const found = document.getElementsByClassName(className).item(0);

      if (found) {
        const htmlDiv = found as HTMLDivElement;
        const canvas = await html2canvas(htmlDiv);

        for (const item of invisibleItems) {
          if (item === null || item === undefined) {
            return;
          }

          (item as HTMLImageElement).style.opacity = "1";
        }

        const image = canvas.toDataURL("png");
        const file = dataURLtoFile(image, "sharable_image.png");
        const shareData: ShareData = {
          title: shareSheetTitle,
          files: [file],
        };

        if (navigator === undefined || navigator === null) {
          // 공유 및 복사 API는 항상 실패할 수 있는 가능성이 존재
          setToastPopupContent(`공유를 실패했습니다`);
          return;
        }

        if (
          navigator.share !== undefined &&
          navigator.share !== null &&
          navigator.canShare(shareData)
        ) {
          // 공유 기능 또한 API 접근이 제한되는 가능성이 존재
          try {
            await navigator.share(shareData);
          } catch (error) {
            const managedError = error as Error;

            if (
              managedError.message === "Abort due to cancellation of share."
            ) {
              setToastPopupContent(`공유를 취소했습니다.`);
            } else {
              setToastPopupContent(
                `공유를 실패했습니다. [${managedError.message}]`
              );
            }
          }
          return;
        }

        if (
          navigator.permissions !== undefined &&
          navigator.permissions !== null &&
          (
            await navigator.permissions.query({
              name: "clipboard-write" as PermissionName,
            })
          ).state === "granted"
        ) {
          // 복사 기능 또한 API 접근이 제한되는 가능성이 존재
          navigator.clipboard.write([
            new ClipboardItem({
              "image/png": file,
            }),
          ]);
          setToastPopupContent(
            shareSheetTitle
              ? `${shareSheetTitle}가 클립보드에 복사되었습니다.`
              : "클립보드에 복사되었습니다."
          );
          return;
        }

        setToastPopupContent(`공유를 실패했습니다`);
      }
    },
  };
}
