import { useEffect, useMemo, useState } from "react";
import styles from "@Views/common_component/toast_popup/toast_popup.module.css";
import { useGeometryReader } from "@Hooks/use_geometry_reader";
import { NetworkAdTags } from "@Views/web_ad/web_ad_data";
import { useLocation } from "react-router";

const ToastPopup = (content?: string, completion?: () => void) => {
  const [retainedContent, setRetainedContent] = useState<string | undefined>();
  const { getSize } = useGeometryReader();

  useEffect(() => {
    if (content === undefined) {
      return;
    }

    setRetainedContent(content);

    const timer = setInterval(() => {
      if (completion) {
        completion();
      }
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, [content, completion]);

  const [rect, setRect] = useState(getSize());

  useEffect(() => {
    setRect(getSize());

    const resizeEvent = () => {
      setRect(getSize());
    };

    window.addEventListener("resize", resizeEvent);

    return () => {
      window.removeEventListener("resize", resizeEvent);
    };
  }, [getSize]);

  const location = useLocation();
  const [dynamicHeight, setDynamicHeight] = useState(0);

  useEffect(() => {
    // URL Path가 변경될 때 마다, 광고뷰들의 Height 값을 체크해 팝업의 높이를 변경합니다
    const action = async () => {
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 100));

      // bottom_bar의 높이값과 footer의 높이값을 모두 가져와 비교해보고,
      // 가장 높은 Height 값을 기준으로 ToastPopup 의 위치를 정합니다.

      const footerCollection = document.getElementsByClassName("footer");
      let footers: HTMLElement[] = [];
      let index = 0;

      while (footerCollection.item(index) !== null) {
        footers.push(footerCollection.item(index) as HTMLElement);
        index += 1;
      }

      const bottom = document.getElementById("bottom_bar");

      if (bottom !== null) {
        const resizeEvent = (entries: ResizeObserverEntry[]) => {
          const entry = entries
            .sort((a, b) => b.contentRect.height - a.contentRect.height)
            .at(0);

          if (entry !== undefined) {
            setDynamicHeight(entry.contentRect.height);
          }
        };

        const observer = new ResizeObserver(resizeEvent);
        observer.observe(bottom!);

        for (const footer of footers) {
          observer.observe(footer);
        }

        return () => {
          observer.disconnect();
        };
      }
    };

    action();
  }, [location]);

  return (
    <div
      className={styles.wrapper}
      style={{
        width: rect.width,
        height: `${112 + dynamicHeight}px`,
        left: rect.x,
      }}
    >
      <div
        className={`${styles.main_content} ${
          content ? styles.visible : styles.invisible
        }`}
      >
        {retainedContent ? retainedContent : <></>}
      </div>
    </div>
  );
};

export default ToastPopup;
