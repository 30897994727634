import { JeomsinPagePath } from "@Config/jeomsin_page_path";
import { useNavigate } from "react-router";
import "./appbar.css"
import { Box } from '@mui/material';
import useShowMemberIntegratedWelcomeDialog from "@Views/main_tab/fortune/use_welcome_modal_popup";
import { useUserInfoStore } from "@State/user_info_store";
import { BottomTabMenu } from "../bottom_navigation/bottom_navigation";
import WeatherAppBar from "./weather_app_bar/weather_app_bar";
import { useRef } from "react";
import { SearchType } from "@Views/mypage/search_view/data_define/search_view_data";

type AppbarBarProp = {
    showAppbar : Boolean,
    tabIndex: number,
    appbarRightType? : AppbarRightType
}

const Appbar: React.FC<AppbarBarProp> = ({
    showAppbar,
    appbarRightType = AppbarRightType.None,
    tabIndex
}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <Box className={showAppbar ? "statusbar" : "none"} ref={containerRef}>
            <Box className='statusbar-wrap'>
                <Box className="statusbar-left">
                    <WeatherAppBar
                     width={containerRef.current?.getBoundingClientRect().width ?? 100}
                    />
                </Box>
                {AppbarType(appbarRightType, tabIndex)}
            </Box>
        </Box>
    );
}

enum AppbarRightType {
    MainTab,
    None
}

const AppbarType = (type:AppbarRightType, tabIndex:number) => {
    const navigation = useNavigate();
    const { representativeUserInfo } = useUserInfoStore();
    const { showMemberIntegratedWelcomeDialog } = useShowMemberIntegratedWelcomeDialog();

    var returnValue:JSX.Element;
    
    switch(type) {
        case AppbarRightType.MainTab:
            returnValue = (
                <Box className="statusbar-right">
                    <img 
                        src='images/app_bar_menu/app_bar_search_icon.png' 
                        onClick={() => {
                            let tabType = SearchType.Menu;
                            if(tabIndex === BottomTabMenu.CouonselHome.index){
                                tabType = SearchType.Counselor;
                            }

                            navigation(JeomsinPagePath.SearchView, { state: {tabType:tabType}});
                        }}
                    />
                    {/* 알림 기능 활성화 전까지 제거 */}
                    <img src='images/common/ic_app_alarm.png' className="none"/>
                    <div className="statusbar-right--circular-container" onClick={() => {
                        if(representativeUserInfo) {
                            navigation(JeomsinPagePath.MypageHome);
                        } else {
                            showMemberIntegratedWelcomeDialog(true);
                        }
                    }}>
                        <img 
                            className="statusbar-right--circular-container--img"
                            src='images/common/ic_user.png'
                        />
                    </div>
                </Box>
            );
            break;
        default:
            returnValue = (<span></span>);
            break;

    }

    return returnValue;
};

export {
    Appbar,
    AppbarRightType
};