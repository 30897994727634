export type CoupangConfigModel = {
    page_code:string,
    page_name:string,
    ad_yn_aos:string,
    ad_type_aos:string,
    ad_yn_ios:string,
    ad_type_ios:string,
    img1:string,
    img2:string,
    btn_text_aos:string,
    btn_text_ios:string,
    able_min:string,
    ad_age:string,
    ad_position_aos:string,
    ad_position_ios:string,
}

export const CoupangPageCode = {
    FirstRunning:"A00000",
    SajuAnalyzing:"A00001",
    UnseReport:"C10000",
    TodayUnse:"A20320",
    InterstitialBanner:"ZZZZZ1",
    LineBanner:"ZZZZZ2",
}
export type CoupangPageCode = (typeof CoupangPageCode)[keyof typeof CoupangPageCode];