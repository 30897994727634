import { v4 } from "uuid";
import { useAsyncPageStoreInternal } from "./async_page";
import { ReactElement } from "react";

export default function useAsyncPage() {
  const { setPageContent, removePageByIdAndReturn } =
    useAsyncPageStoreInternal();

  return {
    setPageContent: (
      removalId: string,
      element: ReactElement,
      isAnimated?: boolean,
      isScreenFilled?: boolean
    ) => {
      return setPageContent(removalId, element, isAnimated, isScreenFilled);
    },
    removePageByIdAndReturn: (removalId: string, returned: any) => {
      removePageByIdAndReturn(removalId, returned);
    },
  };
}
