import Papa from 'papaparse';

interface CsvData {
  [key: string]: string;
}

const parseCsv = async (data:string) => {
    const reuslt = Papa.parse<CsvData>(data, { header: true });

    return reuslt.data;
};

const downloadCsv = async (path:string) => {
  let result:CsvData[]|undefined;

  try {
    const response = await fetch(path, {
        method: 'GET',
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    if (!response.ok) {
      throw new Error('Failed to download file');
    }

    result = await parseCsv(await response.text());
  } catch (err:any) {
    console.log("csv error : " + JSON.stringify(err.message));
  }

  return result;
};
  
export { 
  downloadCsv,
  parseCsv
};
