import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";
import { IdType } from "./api_combine_member_login";
import { getDeviceId, getVersion } from "@Config/jeomsin_application_info";

export interface CheckCombineSNSSignResponse {
  sign: "N" | "Y";
  mem_myname: string;
  yn_dormant: "N" | "Y";
  yn_migrate: "N" | "Y";
  result: boolean;
}

const apiCheckCombineSnsSign = async (
  idCode: string,
  idType: IdType
): Promise<CheckCombineSNSSignResponse | undefined> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.CheckCombineSnsSign,
    params: {
      app_ver: getVersion(),
      device_id: getDeviceId(),
      id_code: idCode,
      id_type: `${idType.sendableIdType}`,
    },
  };

  const inferred = (await JeomsinRequest(
    request
  )) as CheckCombineSNSSignResponse;
  try {
    const test = inferred.sign;

    // Test if the parsing completed
    if (test === undefined) {
      return undefined;
    }

    return inferred;
  } catch {
    return undefined;
  }
};

export default apiCheckCombineSnsSign;
