import { apiSetCounselorWaitSMS } from "@Network/request/api_set_counselor_wait_sms";
import { apiUpdateMemberAlarmStatus } from "@Network/request/api_update_member_alarm_status";
import CookieKeys from "@Storage/cookie_keys";
import CookieManager from "@Storage/cookie_manager";
import { goToAppStore } from "@Util/link_app_store";
import css from "./counselor_alarm_box.module.css";
import { Spacer } from "@Views/common_component/spacer/spacer";
import { useAppLandingPopup } from "@Views/app_landing_button/app_landing_button";

type CounselorAlarmBoxData = {
  mobile:string,
  counselorNo:string,
  counselorState:string,
  memberId:string
}

type CounselorAlarmBoxProps = {
  contents: CounselorAlarmBoxData|undefined;
};

const registerAlarm = (props:CounselorAlarmBoxProps) => {
  //2025.2.11 기록, 웹은 Psuh 알람이 없어 문자 알람으로 대체하려했으나, 서버에서 미구현된 사항이므로 아래 기능은 실질적으로 동작하지 않음
  const data = props.contents;
  if(data && data.mobile) {
    // 알람 Y면 SMS요청으로 바로, 아니면 서버에 Y등록
    const isCounselAlarmPossible = CookieManager.GetBoolean(CookieKeys.isCounselAlarmPossible);

    if(!isCounselAlarmPossible) {
      const alarmType = "5"; //TODO 나중에 알림 타입이 추가되면 별도 type으로 정의하여 사용
      const alarmYN = true;

      apiUpdateMemberAlarmStatus(data.memberId, alarmType, alarmYN).then(() => {
        // 현재 알람화면이 없으므로 임의로 여기서 Y로 세팅
        // TODO 추후 알람 화면 추가 후 구현 필요
        CookieManager.SetBoolean(CookieKeys.isCounselAlarmPossible, true);
        apiSetCounselorWaitSMS(data.mobile, data.counselorNo, data.counselorState);
      });
    } else {
      apiSetCounselorWaitSMS(data.mobile, data.counselorNo, data.counselorState);
    }
  
  }
}

const CounselorAlarmBox: React.FC<CounselorAlarmBoxProps> = (props) => {
  const { showQRCodePopup } = useAppLandingPopup();

  return (
    <div className={css.counselor_alarm_container}>
      <div className={css.title}>상담 가능 시 알림</div>
      <Spacer height={24}/>
      <div className={css.contents}>
        <div className={css.alert_info_container}>
          <img src="images/member_integrated/speaker.png" alt="speaker"/>
          <span>알림 발송은 점신 앱 PUSH 또는 휴대폰 문자 메시지로 발송 가능해요.</span>
        </div>
        <Spacer height={8}/>
        <div className={css.label}>*회원가입 시 등록한 휴대폰 번호로 발송돼요.</div>
        <Spacer height={24}/>
        <div className={css.button_container}>
          <div className={css.button} onClick={() => {
              const { didFail } = goToAppStore();
              if (!didFail) {
                  return;
              }
      
              showQRCodePopup();
          }}>
            앱으로 알림 받기
          </div>
          <div className={css.button} onClick={() => registerAlarm(props)}>
            문자로 알림 받기
          </div>
        </div>
      </div>
    </div>
  );
};

export { CounselorAlarmBox };
export type {
  CounselorAlarmBoxData
};

