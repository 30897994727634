import { apiAutopaymentDel } from "@Network/request/api_autopayment_del";
import { useSimplePayCardInfoStore } from "@State/use_simple_pay_card_store";
import { useUserInfoStore } from "@State/user_info_store";
import { useSimplePayCardChangeBoxStore } from "./use_simple_pay_card_change_box_store";

const useSimplePayCardChangeBoxController = () => {
  const removeSimplePayCardInfo = async () => {
    const { loginMemberInfo } = useUserInfoStore.getState();
    if(loginMemberInfo?.mem_info?.mem_no) {
        const response = await apiAutopaymentDel(loginMemberInfo?.mem_info?.mem_no);

        if(response.result) {
          const { setSimplePayCardInfo } = useSimplePayCardInfoStore.getState();
          setSimplePayCardInfo(undefined);

          return true;
        } else {
          const {
            setShowAlert,
            setShowAlertData 
          } = useSimplePayCardChangeBoxStore.getState();

          setShowAlert(true);
          setShowAlertData(response.errmsg);

          return false;
        }
    }
  };

  

  return {
    removeSimplePayCardInfo
  }
}

export default useSimplePayCardChangeBoxController;