/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./require_phone_number.module.css";
import apiSendAuthMobile from "@Network/request/api_send_auth_mobile";
import apiSendAuthMobileConfirm from "@Network/request/api_send_auth_mobile_confirm";
import { useLocation, useNavigate } from "react-router";
import { IdType } from "@Network/request/api_combine_member_login";
import { useUserInfoStore } from "@State/user_info_store";
import { JeomsinPagePath } from "@Config/jeomsin_page_path";
import {
  useModalPopup,
  useModalPopupStoreInternal,
} from "@Views/common_component/async_modal_popup/async_modal_popup";
import apiCheckMemberMobile from "@Network/request/api_check_member_mobile";
import { useMemberIntegratedState } from "@Views/main_tab/fortune/use_welcome_modal_popup";
import { useDimmedStore } from "@Views/common_component/dim_layer/dim_layer";
import { useToastPopupStore } from "@Views/common_component/toast_popup/toast_popup_manager";
import useAsyncPage from "@Views/common_component/async_page/async_page_setter";
import CheckBox from "@Views/common_component/icons/checkbox";

function dateAction(from: HTMLInputElement): string {
  const textField = from;
  const pureNumber = textField.value.match(/\d/g)?.join("") ?? "";

  let formattedDate = "";
  for (let i = 0; i < pureNumber.length; i++) {
    if (i >= 8) {
      break; // yyyy.mm.dd format cannot exceed 8 digits
    }

    const toAppend = pureNumber.at(i);
    if (toAppend) {
      if (i === 4 || i === 6) {
        formattedDate = `${formattedDate}.${toAppend}`;
      } else {
        formattedDate = `${formattedDate}${toAppend}`;
      }
    }
  }

  textField.value = formattedDate;
  return formattedDate;
}

function numberAction(
  from: HTMLInputElement,
  forPhoneNumber: boolean
): [string, string | undefined] {
  const textField = from;
  const pureNumber = textField.value.match(/\d/g)?.join("") ?? "";

  if (forPhoneNumber) {
    let appendable = "";
    for (let i = 0; i < pureNumber.length; i++) {
      // korean phone numbers cannot be greater than 11
      if (i >= 11) {
        continue;
      }

      const toAppend = pureNumber.at(i);
      if (toAppend) {
        if ([3, 7].includes(i)) {
          appendable = `${appendable}-${toAppend}`;
        } else {
          appendable = `${appendable}${toAppend}`;
        }
      }
    }

    textField.value = appendable;
    return [pureNumber, appendable];
  } else {
    // confirmation number is exactly 5-length
    textField.value = pureNumber.slice(0, 5);
    return [pureNumber, undefined];
  }
}

function isKoreanPhoneNumberForamt(phoneNumber: string): boolean {
  if (phoneNumber === "") {
    return false;
  }

  const phoneRule = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;
  return phoneRule.test(phoneNumber);
}

export default function RequirePhoneNumber({
  removalId,
}: {
  removalId: string;
}) {
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState<string>("");
  const [isAgeAppropriate, setAgeAppropriate] = useState<boolean>(false);

  const [isSent, setSent] = useState<boolean>(false);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [confirmNum, setConfirmNum] = useState<string>("");
  const [remainingTime, setRemainingTime] = useState<number>(180);

  const { representativeUserInfo } = useUserInfoStore();

  const sent = useLocation().state;
  const { setMemMobile } = useMemberIntegratedState();
  const setMemMobileWrap = useRef(setMemMobile);

  const navigate = useNavigate();
  const [showGuide, setShowGuide] = useState<DOMRect | undefined>();

  const { setToastPopupContent } = useToastPopupStore();
  const { setModalPopupContent } = useModalPopup();

  const navigateWrap = useRef(navigate);
  const setModalPopupContentWrap = useRef(setModalPopupContent);
  const setToastPopupContentWrap = useRef(setToastPopupContent);

  const background = document
    .getElementsByClassName("background-area")
    .item(0) as HTMLDivElement;
  background.style.backgroundColor = "white";

  const { setDimLayerOpen } = useDimmedStore();

  useEffect(() => {
    setDimLayerOpen(false);
  }, [setDimLayerOpen]);

  useEffect(() => {
    if (isSent === true && remainingTime > 0) {
      const timer = setInterval(() => {
        setRemainingTime((original) => original - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isSent, remainingTime]);

  const phoneNumberTextFieldRef = useRef<HTMLInputElement>(null);
  const confirmNumberTextFieldRef = useRef<HTMLInputElement>(null);

  let minuteLeft = String(Math.floor(remainingTime / 60));
  if (minuteLeft.length === 1) {
    minuteLeft = `0${minuteLeft}`;
  }

  let secondLeft = String(remainingTime % 60);
  if (secondLeft.length === 1) {
    secondLeft = `0${secondLeft}`;
  }

  const ageCheckbox = (
    <div
      className={styles.check_box_container}
      onClick={() => {
        setAgeAppropriate((original) => original === false);
      }}
    >
      <CheckBox isChecked={isAgeAppropriate} />
      <span>만 14세 이상 확인 (필수)</span>
    </div>
  );

  useEffect(() => {
    let buttonDisableCondition = true;

    if (isSent) {
      buttonDisableCondition = confirmNum.length < 5;
    } else {
      buttonDisableCondition =
        isKoreanPhoneNumberForamt(phoneNumber ?? "") === false ||
        isAgeAppropriate === false;
    }

    setButtonDisabled(buttonDisableCondition);
  }, [confirmNum.length, isAgeAppropriate, isSent, phoneNumber]);

  const { removePageByIdAndReturn } = useAsyncPage();

  const buttonClickAction = useCallback(
    async (isDisabled: boolean) => {
      if (isDisabled === true) {
        if (isAgeAppropriate === false) {
          setToastPopupContentWrap.current("필수 항목에 동의가 필요해요.");
        } else {
          setToastPopupContentWrap.current("인증번호를 기입해주세요.");
        }
        return;
      }

      if (isSent === false) {
        // 인증번호를 아직 보내지 않은 경우
        const response = await apiSendAuthMobile(
          phoneNumber!,
          "join_member",
          false
        );

        if (response.result === true) {
          setSent(true);
        } else {
          const snsType = response.id_type ?? "";
          let errorMessage = response.msg ?? "";

          if (snsType.length > 0) {
            // TODO: "가입한 적이 없으신가요? 계정문의" 추가

            const snsReconnectDecision = await setModalPopupContentWrap.current(
              {
                title: "SNS 인증 갱신",
                description:
                  "다른 계정으로 가입했거나 연동이 만료되었어요.\n간편 로그인 이용을 위해 본인 인증을 해 주세요.",
                buttonTitles: ["본인 인증하기"],
                anchor: "bottom",
              }
            );

            if (snsReconnectDecision === -1) {
              return;
            }

            const inputClass = "RequirePhoneNumberVertificationInput";

            const setter = (
              e: React.KeyboardEvent<HTMLInputElement>,
              isKeyDown: boolean
            ) => {
              const inputElement = document
                .getElementsByClassName(inputClass)
                .item(0) as HTMLInputElement;

              // 엔터 시, 다음으로 갈 수 있도록 처리
              if (
                isKeyDown === false &&
                (e.key === "Enter" || e.keyCode === 13)
              ) {
                inputElement?.blur();

                const modal = useModalPopupStoreInternal
                  .getState()
                  .modalPopupContents.filter(
                    (eachContent) =>
                      eachContent.content.title === "본인 인증하기"
                  )
                  .at(0);
                const decisionHandler = modal?.content.decisionHandler;

                if (decisionHandler !== undefined) {
                  const result = decisionHandler(1);

                  if (result === true) {
                    inputElement.blur();

                    modal!.resolver(1);
                    useModalPopupStoreInternal
                      .getState()
                      .removeModalPopupById(modal!.removalId);
                  }
                }

                return;
              }

              dateAction(inputElement);

              const checkerButton = document
                .getElementsByClassName("CheckerButton")
                .item(0) as HTMLSpanElement;
              if (inputElement.value.length === 10) {
                checkerButton.style.backgroundColor = "var(--MainButton)";
              } else {
                checkerButton.style.backgroundColor = "var(--ThirdButton)";
              }
            };

            const getter = () => {
              const inputElement = document
                .getElementsByClassName(inputClass)
                .item(0) as HTMLInputElement;
              return inputElement.value;
            };

            // TODO: "로그인에 어려움이 있으신가요? 계정문의" 추가

            await setModalPopupContentWrap.current({
              title: "본인 인증하기",
              description: (
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {"기존에 사용하던 계정의\n내 사주정보를 입력해 주세요."}
                </span>
              ),
              buttonTitles: [
                <BottomModalInput inputClass={inputClass} setter={setter} />,
                <span
                  className="CheckerButton"
                  style={{
                    width: "100%",
                    backgroundColor: "var(--ThirdButton)",
                    borderRadius: "12px",
                    height: "60px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  인증번호 받기
                </span>,
              ],
              decisionHandler: (clickedIndex) => {
                if (clickedIndex === 0) {
                  return false;
                }

                if (getter().length > 9) {
                  return true;
                } else {
                  setToastPopupContentWrap.current(
                    "숫자 8자리를 입력해 주세요."
                  );
                  return false;
                }
              },
              anchor: "bottom",
            });

            const birth = getter();
            const response = await apiCheckMemberMobile(phoneNumber!, birth);

            if (response.chk_mem_mobile === "Y") {
              const secondAttempt = await apiSendAuthMobile(
                phoneNumber!,
                "join_member",
                true
              );
              setToastPopupContentWrap.current("인증번호 발송이 완료되었어요!");

              if (secondAttempt.result === true) {
                setSent(true);
              } else {
                await setModalPopupContentWrap.current({
                  title: "알림",
                  description: (
                    <ErrorDescriptionUI description={secondAttempt.msg ?? ""} />
                  ),
                  buttonTitles: ["확인"],
                });

                const textField = confirmNumberTextFieldRef.current;
                if (textField) {
                  textField.value = "";
                }
              }
            } else {
              setToastPopupContentWrap.current("본인 인증 실패");
            }
          } else {
            if (
              errorMessage ===
              "이미 인증번호를 발송하였습니다. 재발송은 3분뒤 가능합니다."
            ) {
              errorMessage =
                "이미 인증번호를 발송하였습니다.\n재발송은 3분뒤 가능합니다.";
            }

            await setModalPopupContentWrap.current({
              title: "알림",
              description: <ErrorDescriptionUI description={errorMessage} />,
              buttonTitles: ["확인"],
            });

            const textField = confirmNumberTextFieldRef.current;
            if (textField) {
              textField.value = "";
            }
          }
        }

        return;
      }

      // 인증번호를 보낸 경우

      if (confirmNum.length > 0) {
        try {
          let connectedSNS: IdType | undefined;

          try {
            connectedSNS = await apiSendAuthMobileConfirm(
              phoneNumber!,
              "join_member",
              confirmNum,
              true
            );
          } catch (e) {
            setToastPopupContentWrap.current((e as Error).message);
            return;
          }

          if (connectedSNS === undefined) {
            // SNS 계정 이관이 필요없는 상태. 이전 화면으로 돌아가 회원가입을 진행시키거나,
            // 사주명식 기입이 되지 않은자들은 사주명식 기입을 먼저 시킨다.
            if (representativeUserInfo === undefined) {
              setMemMobileWrap.current(phoneNumber);
              navigateWrap.current(JeomsinPagePath.SajuInput, {
                state: {
                  ...sent,
                },
              });
              removePageByIdAndReturn(removalId, undefined);
              return;
            }

            // 사주명식 기입 완료. 이제 회원가입 바로 고!
            removePageByIdAndReturn(removalId, {
              representativeUserInfo,
              phoneNumber,
              isYnIdType: false,
            });
            return;
          } else {
            // 이미 연동된 계정의 SNS와 연동할 SNS에 차이가 있을 경우이다. 기존 계정을 연동할 SNS로 이관
            // isYnIdType을 true로 설정하면, welcome_modal_popup 에서 이관 시작.
            removePageByIdAndReturn(removalId, {
              representativeUserInfo,
              phoneNumber,
              isYnIdType: true,
            });
            return;
          }
        } catch (e) {
          await setModalPopupContentWrap.current({
            title: "알림",
            description: (
              <ErrorDescriptionUI description={(e as Error).message} />
            ),
            buttonTitles: ["확인"],
          });
        }
      }
    },
    [
      confirmNum,
      isAgeAppropriate,
      isSent,
      phoneNumber,
      removalId,
      removePageByIdAndReturn,
      representativeUserInfo,
      sent,
    ]
  );

  const phoneNumberKeyBinding = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>, isKeyDown: boolean = true) => {
      const textField = phoneNumberTextFieldRef.current;

      if (isKeyDown === false && (e.key === "Enter" || e.keyCode === 13)) {
        if (isButtonDisabled === false) {
          textField?.blur();
        }

        buttonClickAction(isButtonDisabled);
        return;
      }

      if (textField) {
        const [pureNumber, formattedPhoneNumber] = numberAction(
          textField,
          true
        );
        setPhoneNumber(pureNumber);
        setFormattedPhoneNumber(formattedPhoneNumber!);
      }
    },
    [buttonClickAction, isButtonDisabled]
  );

  const confirmNumberKeyBinding = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>, isKeyDown: boolean = true) => {
      const textField = confirmNumberTextFieldRef.current;

      if (isKeyDown === false && (e.key === "Enter" || e.keyCode === 13)) {
        if (isButtonDisabled === false) {
          textField?.blur();
        }

        buttonClickAction(isButtonDisabled);
        return;
      }

      if (textField) {
        const [pureNumber] = numberAction(textField, false);
        setConfirmNum(pureNumber);
      }
    },
    [buttonClickAction, isButtonDisabled]
  );

  const headerElement = useMemo(
    () => (
      <div className={`${styles.titlebar}`}>
        <span>휴대폰 번호 인증</span>
        <img
          className={`${styles.goback_btn}`}
          src="images/common/app_bar_previous.png"
          onClick={() => {
            removePageByIdAndReturn(removalId, undefined);
          }}
          alt="Go back"
        />
      </div>
    ),
    [removalId, removePageByIdAndReturn]
  );

  const bottomElement = useMemo(
    () => (
      <div
        className={`${styles.bottombar}`}
        style={{
          backgroundColor: isButtonDisabled
            ? "var(--ThirdButton)"
            : "var(--MainButton)",
          color: isButtonDisabled
            ? "var(--ButtonText03)"
            : "var(--ButtonText01)",
        }}
        onClick={async () => {
          await buttonClickAction(isButtonDisabled);
        }}
      >
        {isSent ? "인증번호 확인" : "인증번호 받기"}
      </div>
    ),
    [buttonClickAction, isButtonDisabled, isSent]
  );

  return isSent === false ? (
    <div className={`${styles.require_phone_container}`}>
      {headerElement}
      <h1>{`계정 보안 강화를 위해\n휴대폰 번호를 인증해 주세요.`}</h1>
      <h2>{`상담 및 일부 서비스는 휴대폰번호를 기준으로 제공돼요. 실제 사용중인 번호를 입력해 주세요.`}</h2>
      <section>
        <div className={styles.phone_number_title_and_guide}>
          <h3>휴대폰 번호</h3>
          <button
            onClick={() => {
              const button = document
                .getElementsByClassName(styles.phone_number_title_and_guide)
                .item(0)
                ?.getElementsByTagName("button")
                .item(0)
                ?.getElementsByTagName("span")
                .item(0);

              const container = document
                .getElementsByClassName(styles.require_phone_container)
                .item(0);

              if (button && container) {
                setShowGuide((original) => {
                  if (original) {
                    return undefined;
                  } else {
                    const rect = button.getBoundingClientRect();
                    const containerRect = container.getBoundingClientRect();

                    return {
                      x: rect.x - containerRect.x,
                      y: rect.y - containerRect.y,
                      width: rect.width,
                      height: rect.height,
                      top: rect.top,
                      bottom: rect.bottom,
                      left: rect.left,
                      right: rect.right,
                    } as DOMRect;
                  }
                });
              }
            }}
          >
            <span>?</span>
          </button>
        </div>
        <div className={styles.input_container}>
          <span className={styles.flag}>🇰🇷</span>
          <span>+82</span>
          <div />
          <input
            css={css`
              ::placeholder {
                color: var(--SubText02);
              }
            `}
            type="tel"
            autoComplete="off"
            placeholder="01012341234"
            ref={phoneNumberTextFieldRef}
            onKeyDown={(e) => phoneNumberKeyBinding(e)}
            onKeyUp={(e) => phoneNumberKeyBinding(e, false)}
          />
        </div>
        <div className={styles.divider} />
        <span
          className={styles.tip}
        >{`‘-’하이픈 없이 숫자만 입력해 주세요.`}</span>
        {ageCheckbox}
      </section>
      {showGuide && (
        <div
          className={styles.guide}
          style={{
            left: `${showGuide.x - showGuide.width / 2 - 25}px`,
            top: `${showGuide.y - showGuide.height / 2 - 90}px`,
          }}
        >
          <span className={styles.guide__upper}>국내 휴대폰 인증</span>
          <span
            className={styles.guide__bottom}
          >{`현재 점신은 국내 번호로만 서비스 이용이 가능합니다.\n추후 해외 번호로도 이용 가능하도록 개선될 예정입니다.`}</span>
        </div>
      )}
      {bottomElement}
    </div>
  ) : (
    <div className={styles.require_confirm_container}>
      {headerElement}
      <h1>{`${formattedPhoneNumber} 번호로\n전송된 인증번호를 입력해 주세요.`}</h1>
      <h2>{`상담 및 일부 서비스는 휴대폰번호를 기준으로 제공돼요.\n실제 사용중인 번호를 입력해 주세요.`}</h2>
      <button
        onClick={() => {
          setSent(false);
          setPhoneNumber(undefined);
        }}
      >
        <span>번호 재입력</span>
      </button>
      <section>
        <h3>인증번호</h3>
        <div className={styles.input_container_2}>
          <input
            css={css`
              ::-webkit-inner-spin-button,
              ::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              appearance: textfield;
              ::placeholder {
                color: var(--SubText02);
              }
            `}
            className={styles.confirm_number_input}
            type="number"
            placeholder="00000"
            ref={confirmNumberTextFieldRef}
            onKeyUp={(e) => confirmNumberKeyBinding(e, false)}
            onKeyDown={(e) => confirmNumberKeyBinding(e)}
          />
          <button
            className={styles.clear_confirm_num_button}
            onClick={() => {
              const tf = confirmNumberTextFieldRef.current;

              if (tf) {
                tf.value = "";
              }

              setConfirmNum("");
              setRemainingTime(180);
            }}
          >
            <img
              src="/images/member_integrated/member/delete_incircle.png"
              alt="clear text field"
            />
          </button>
          <span>{`${minuteLeft}:${secondLeft}`}</span>
        </div>
        <div className={styles.divider} />
        <span
          className={styles.tip}
          css={css`
            line-height: 16px;
          `}
        >
          {`인증번호 5자리를 입력해 주세요.\n인증번호는 3분이 지나면 다시 받을 수 있어요.`}
        </span>
        {ageCheckbox}
      </section>
      {bottomElement}
    </div>
  );
}

function BottomModalInput({
  inputClass,
  setter,
}: {
  inputClass: string;
  setter: (
    e: React.KeyboardEvent<HTMLInputElement>,
    isKeyDown: boolean
  ) => void;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        gap: "10px",
        cursor: "auto",
        marginBottom: "30px",
      }}
    >
      <span
        style={{
          fontSize: "16px",
          fontWeight: "700",
          color: "var(--SubText01)",
        }}
      >
        생년월일
      </span>
      <input
        css={css`
          ::-webkit-inner-spin-button,
          ::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          ::placeholder {
            color: var(--SubText02);
          }
          appearance: textfield;
          outline: none;
        `}
        type="text"
        autoComplete="off"
        placeholder="1998.01.01"
        style={{
          fontSize: "18px",
          fontWeight: "600",
          width: "100%",
          height: "40px",
          border: "0",
          padding: "0",
          color: "var(--MainText)",
          borderBottom: "1px",
          borderBottomStyle: "solid",
          borderBottomColor: "var(--SubText02)",
        }}
        className={inputClass}
        onKeyDown={(e) => setter(e, true)}
        onKeyUp={(e) => setter(e, false)}
      />
      <span
        style={{
          fontSize: "14px",
          fontWeight: "500",
          color: "var(--SubText02)",
        }}
      >
        숫자 8자리를 입력해 주세요.
      </span>
    </div>
  );
}

function ErrorDescriptionUI({ description }: { description: string }) {
  return (
    <div
      style={{
        width: "250px",
      }}
    >
      {description}
    </div>
  );
}
