import { JeomsinPagePath } from "@Config/jeomsin_page_path";
import { JeomsinPageInfo } from "@DataDefine/page_code";
import { MainMenuListModel } from "@Network/request/api_get_mz_main_list";
import { CounselorProfileArguments } from "@Views/counsel/reuse_component/counselor_profile/use_counselor_profile";
import { useNavigate } from "react-router";
import { useCallback, useRef } from "react";
import { useAppLandingPopup } from "@Views/app_landing_button/app_landing_button";
import { useAsyncCalendarPopup } from "@Views/common_component/async_calendar_popup/async_calendar_popup";
import { useUserInfoStore } from "@State/user_info_store";
import useShowMemberIntegratedWelcomeDialog from "@Views/main_tab/fortune/use_welcome_modal_popup";
import { triggerCopyingShareURL } from "@Util/sns_share_xml_util";
import { useModalPopup } from "@Views/common_component/async_modal_popup/async_modal_popup";
import { getTodayTimeUseType, TimeType } from "@Views/unse/reuse_component/unse_circle_view/unse_circle_view";
import { useToastPopupStore } from "@Views/common_component/toast_popup/toast_popup_manager";

type NextPageType = {
    to:JeomsinPagePath|undefined,
    state:any
}

export const LandingType = {
    Normal:0,
    CounselorProfile:1,
    AllMenu:2,
    CounselMenu:3,
    WebView:4,
}
export type LandingType = (typeof LandingType)[keyof typeof LandingType];

export interface LandingProps {
    landingType:LandingType,
    pageInfo:JeomsinPageInfo,
    landingUrl?:string,
    referrer?:string
    extraData?:any
}

const usePageLanding = () => {
    const navigate = useNavigate();
    const { showAppLandingPopup } = useAppLandingPopup();
    const { setCalendarPopupContent } = useAsyncCalendarPopup();
    const { isFullySignedIn, representativeUserInfo } = useUserInfoStore();
    const { showMemberIntegratedWelcomeDialog } = useShowMemberIntegratedWelcomeDialog();
    const modalPopupRef = useRef(useModalPopup());
    const { setToastPopupContent } = useToastPopupStore();

    const landingPage = useCallback(({
        landingType,
        pageInfo,
        landingUrl,
        referrer,
        extraData,
    }:LandingProps) => {
        switch(Number(landingType)) {
            case LandingType.Normal:
                if(landingUrl) {
                    if (landingUrl.startsWith('share://')) {
                        const data:string = landingUrl.replaceAll('share://', '');
                        const shareData = data.split('|');
                        if (shareData) {
                            triggerCopyingShareURL({
                                sns_title: "",
                                sns_url: String(shareData[1]),
                            }, modalPopupRef.current.setModalPopupContent);
                        }
                    } else {
                        window.open(landingUrl);
                    }
                }
    
                break;
            case LandingType.CounselorProfile:
                navigate(JeomsinPagePath.CounselorProfile, {
                    state : {
                        counselorNumber: extraData.CO_NO,
                    } as CounselorProfileArguments
                });
                break;
            case LandingType.AllMenu:
                const {
                    verifyUserInfoBeforeLanding,
                    verifyLoginBeforeLanding,
                    toastMessage,
                    nextPageInfo,
                } = getNextPageInfo(pageInfo?.pageCode); //메뉴별 이동

                if (verifyLoginBeforeLanding === true
                    && isFullySignedIn === false) {
                    showMemberIntegratedWelcomeDialog();
                    return;
                }

                if (verifyUserInfoBeforeLanding === true
                    && representativeUserInfo === undefined) {
                    showMemberIntegratedWelcomeDialog(true);
                    return;
                }
                
                // 지정일 운세의 경우, 캘린더에서 데이터를 먼저 전달받는다
                if (nextPageInfo.to !== undefined &&
                    nextPageInfo.state !== undefined &&
                    (nextPageInfo.state.menuInfo as MainMenuListModel)?.page_code === JeomsinPageInfo.D10011.pageCode) {
                    setCalendarPopupContent?.()?.then((selectedDate) => {
                        if (selectedDate) {
                            navigate(nextPageInfo.to!, {
                                state: {
                                    ...nextPageInfo.state,
                                    selectedDate
                                }
                            });
                        }
                    });
                    return;
                }

                if(nextPageInfo.to) {
                    navigate(nextPageInfo.to, {
                        state: nextPageInfo.state
                    });
                } else if(toastMessage) {
                    setToastPopupContent(toastMessage);
                } else {
                    showAppLandingPopup();
                }
                break;
            case LandingType.CounselMenu:
                switch (pageInfo?.pageCode) {
                    case JeomsinPageInfo.E10031.pageCode:
                        navigate(JeomsinPagePath.RealTimeCounselorGuide, {
                            state : {
                                tabIndex: 1,
                            }
                        });
                        break;
                    case JeomsinPageInfo.E10015.pageCode:
                        navigate(JeomsinPagePath.RealTimeCounselorGuide, {
                            state : {
                                tabIndex: 0,
                            }
                        });
                        break;
                    case JeomsinPageInfo.E10030.pageCode:
                        navigate(JeomsinPagePath.ThreeMinuteCounselorGuide);
                        break;
                    case JeomsinPageInfo.E10048.pageCode:
                        navigate(JeomsinPagePath.SimliCounselGuide);
                        break;
                    // case JeomsinPageInfo.E10049.pageCode:
                    //     // context.pushNamed('counsel_service_guide_screen');//상담 서비스 개편 가이드

                    //     break;
                }
                break;
            case LandingType.WebView:
                window.open(landingUrl);
                break;
        }
    }, [
        isFullySignedIn, 
        navigate, 
        setCalendarPopupContent, 
        showAppLandingPopup, 
        showMemberIntegratedWelcomeDialog,
        setToastPopupContent
    ]);

    return {
        landingPage
    }
}

const getNextPageInfo = (pageCode: string): {
    verifyUserInfoBeforeLanding: boolean;
    verifyLoginBeforeLanding: boolean;
    toastMessage: string;
    nextPageInfo: NextPageType;
} => {
    const nextPageInfo:NextPageType = {
        to:undefined,
        state:""
    };

    const convertedData:MainMenuListModel = {
        page_code_name: "",
        page_code: "",
        image: ""
    }

    let returnedValue = {
        verifyUserInfoBeforeLanding: false,
        verifyLoginBeforeLanding: false,
        toastMessage:""
    }

    switch (pageCode) {
        case JeomsinPageInfo.A30100.pageCode:
        case JeomsinPageInfo.E10000.pageCode:
            nextPageInfo.to = JeomsinPageInfo.E10000.pagePath;
            break;
        case JeomsinPageInfo.E10090.pageCode:
            nextPageInfo.to = JeomsinPageInfo.E10090.pagePath;
            nextPageInfo.state = { menuId: JeomsinPageInfo.E10090.category }
            break;
        case JeomsinPageInfo.E10009.pageCode:
            nextPageInfo.to = JeomsinPageInfo.E10009.pagePath;
            break;
        case JeomsinPageInfo.E10085.pageCode:
            returnedValue.verifyLoginBeforeLanding = true;
            nextPageInfo.to = JeomsinPageInfo.E10085.pagePath;
            break;
        case JeomsinPageInfo.E10010.pageCode:
            returnedValue.verifyLoginBeforeLanding = true;
            nextPageInfo.to = JeomsinPageInfo.E10010.pagePath;
            break;
        case JeomsinPageInfo.E10016.pageCode:
            returnedValue.verifyLoginBeforeLanding = true;
            nextPageInfo.to = JeomsinPageInfo.E10016.pagePath;
            break;
        case JeomsinPageInfo.E10088.pageCode:
            returnedValue.verifyLoginBeforeLanding = true;
            nextPageInfo.to = JeomsinPageInfo.E10088.pagePath;
            nextPageInfo.state = { tabIndex: JeomsinPageInfo.E10088.category };
            break;
        case JeomsinPageInfo.D10095.pageCode: //나의 인연은
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10095.pageName;
            convertedData.page_code = JeomsinPageInfo.D10095.pageCode;
            convertedData.image = JeomsinPageInfo.D10095.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            
            break;
        case JeomsinPageInfo.D10076.pageCode:
        case JeomsinPageInfo.A22010.pageCode: //별자리 궁합
            returnedValue.verifyUserInfoBeforeLanding = true;

            convertedData.page_code_name = JeomsinPageInfo.D10076.pageName;
            convertedData.page_code = JeomsinPageInfo.D10076.pageCode;
            nextPageInfo.to = JeomsinPagePath.AstroGunghap;
            nextPageInfo.state = {
                menuInfo: convertedData,
            }
            break;
        case JeomsinPageInfo.A22030.pageCode: // 별자리 궁합 - 별자리 다이어트
            returnedValue.verifyUserInfoBeforeLanding = true;
            convertedData.page_code_name = JeomsinPageInfo.D10076.pageName;
            convertedData.page_code = JeomsinPageInfo.D10076.pageCode;
            nextPageInfo.to = JeomsinPagePath.AstroGunghap;
            nextPageInfo.state = {
                tabIndex: JeomsinPageInfo.A22030.category,
                menuInfo: convertedData,
            };
            break;
        case JeomsinPageInfo.D10059.pageCode: //연예인 궁합
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPageInfo.D10059.pagePath;

            convertedData.page_code_name = JeomsinPageInfo.D10059.pageName;
            convertedData.page_code = JeomsinPageInfo.D10059.pageCode;
            convertedData.image = JeomsinPageInfo.D10059.imagePath ?? "";

            nextPageInfo.state = { menuInfo: convertedData };

            break;
        case JeomsinPageInfo.D10031.pageCode:
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsikCouple;

            convertedData.page_code_name = JeomsinPageInfo.D10031.pageName;
            convertedData.page_code = JeomsinPageInfo.D10031.pageCode;
            convertedData.image = JeomsinPageInfo.D10031.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10137.pageCode: //짝궁합
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsikCouple;

            convertedData.page_code_name = JeomsinPageInfo.D10137.pageName;
            convertedData.page_code = JeomsinPageInfo.D10137.pageCode;
            convertedData.image = JeomsinPageInfo.D10137.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10073.pageCode: //혈액형-궁합
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.BloodTypeGunghap;

            convertedData.page_code_name = JeomsinPageInfo.D10073.pageName;
            convertedData.page_code = JeomsinPageInfo.D10073.pageCode;

            nextPageInfo.state = {
                menuInfo: convertedData,
            };

            break;
        case JeomsinPageInfo.A21930.pageCode: //혈액형-연애운세
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.BloodTypeGunghap;

            convertedData.page_code_name = JeomsinPageInfo.D10073.pageName;
            convertedData.page_code = JeomsinPageInfo.D10073.pageCode;

            nextPageInfo.state = {
                menuInfo: convertedData,
                tabIndex: JeomsinPageInfo.A21930.category,
            }

            break;
        case JeomsinPageInfo.D10039.pageCode: //취업운세
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10039.pageName;
            convertedData.page_code = JeomsinPageInfo.D10039.pageCode;
            convertedData.image = JeomsinPageInfo.D10039.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10043.pageCode: //능력평가
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10043.pageName;
            convertedData.page_code = JeomsinPageInfo.D10043.pageCode;
            convertedData.image = JeomsinPageInfo.D10043.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10091.pageCode: //심리분석
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10091.pageName;
            convertedData.page_code = JeomsinPageInfo.D10091.pageCode;
            convertedData.image = JeomsinPageInfo.D10091.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10003.pageCode: //오늘의 운세
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10003.pageName;
            convertedData.page_code = JeomsinPageInfo.D10003.pageCode;
            convertedData.image = JeomsinPageInfo.D10003.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10015.pageCode: //시간대별 운세
            returnedValue.verifyUserInfoBeforeLanding = true;
            
            if(getTodayTimeUseType() === TimeType.Dawn) {
                returnedValue.toastMessage = "오전 운세는 7시 이후에 풀이가 나옵니다";
            } else {
                nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

                convertedData.page_code_name = JeomsinPageInfo.D10015.pageName;
                convertedData.page_code = JeomsinPageInfo.D10015.pageCode;
    
                nextPageInfo.state = { 
                    menuInfo:convertedData,
                    timeUnseTabIndex:getTodayTimeUseType().valueOf() + 1
                }
            }
            break;
        case JeomsinPageInfo.D10007.pageCode: //내일의 운세
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10007.pageName;
            convertedData.page_code = JeomsinPageInfo.D10007.pageCode;
            convertedData.image = JeomsinPageInfo.D10007.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10011.pageCode: //지정일 운세
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;
            convertedData.page_code_name = JeomsinPageInfo.D10011.pageName;
            convertedData.page_code = JeomsinPageInfo.D10011.pageCode;
            convertedData.image = JeomsinPageInfo.D10011.imagePath ?? "";
            nextPageInfo.state = { menuInfo: convertedData };
            break;
        case JeomsinPageInfo.D10019.pageCode: //신년운세
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10019.pageName;
            convertedData.page_code = JeomsinPageInfo.D10019.pageCode;
            convertedData.image = JeomsinPageInfo.D10019.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10027.pageCode: //정통사주
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10027.pageName;
            convertedData.page_code = JeomsinPageInfo.D10027.pageCode;
            convertedData.image = JeomsinPageInfo.D10027.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10023.pageCode: //토정비결
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10023.pageName;
            convertedData.page_code = JeomsinPageInfo.D10023.pageCode;
            convertedData.image = JeomsinPageInfo.D10023.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10035.pageCode: //살풀이
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.Salpuri;

            convertedData.page_code_name = JeomsinPageInfo.D10035.pageName;
            convertedData.page_code = JeomsinPageInfo.D10035.pageCode;
            convertedData.image = JeomsinPageInfo.D10035.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10056.pageCode: //이사택일
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.HouseMove;

            convertedData.page_code_name = JeomsinPageInfo.D10056.pageName;
            convertedData.page_code = JeomsinPageInfo.D10056.pageCode;
            convertedData.image = JeomsinPageInfo.D10056.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10085.pageCode: //천생복덕운
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10085.pageName;
            convertedData.page_code = JeomsinPageInfo.D10085.pageCode;
            convertedData.image = JeomsinPageInfo.D10085.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10070.pageCode: //행운의 번호
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.LottoFortuneIntro;

            convertedData.page_code_name = JeomsinPageInfo.D10070.pageName;
            convertedData.page_code = JeomsinPageInfo.D10070.pageCode;
            convertedData.image = JeomsinPageInfo.D10070.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10050.pageCode: //띠 운세
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10050.pageName;
            convertedData.page_code = JeomsinPageInfo.D10050.pageCode;
            convertedData.image = JeomsinPageInfo.D10050.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10053.pageCode: //별자리 운세
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10053.pageName;
            convertedData.page_code = JeomsinPageInfo.D10053.pageCode;
            convertedData.image = JeomsinPageInfo.D10053.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10107.pageCode: //생년월일운
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10107.pageName;
            convertedData.page_code = JeomsinPageInfo.D10107.pageCode;
            convertedData.image = JeomsinPageInfo.D10107.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10103.pageCode: //태어난 계절운
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10103.pageName;
            convertedData.page_code = JeomsinPageInfo.D10103.pageCode;
            convertedData.image = JeomsinPageInfo.D10103.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10099.pageCode: //전생운
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10099.pageName;
            convertedData.page_code = JeomsinPageInfo.D10099.pageCode;
            convertedData.image = JeomsinPageInfo.D10099.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10079.pageCode: //탄생석
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPagePath.SajuMyeongsik;

            convertedData.page_code_name = JeomsinPageInfo.D10079.pageName;
            convertedData.page_code = JeomsinPageInfo.D10079.pageCode;
            convertedData.image = JeomsinPageInfo.D10079.imagePath ?? "";

            nextPageInfo.state = { menuInfo:convertedData } 
            break;
        case JeomsinPageInfo.D10089.pageCode: //꿈해몽
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPageInfo.D10089.pagePath;

            convertedData.page_code = JeomsinPageInfo.D10089.pageCode;
            convertedData.page_code_name = JeomsinPageInfo.D10089.pageName;

            nextPageInfo.state = {
                menuInfo: convertedData,
            };

            break;
        case JeomsinPageInfo.D10090.pageCode: //꿈해몽 - 태몽탭
            returnedValue.verifyUserInfoBeforeLanding = true;
            nextPageInfo.to = JeomsinPageInfo.D10090.pagePath;

            convertedData.page_code = JeomsinPageInfo.D10089.pageCode;
            convertedData.page_code_name = JeomsinPageInfo.D10089.pageName;

            nextPageInfo.state = {
                menuInfo: convertedData,
                tabIndex: JeomsinPageInfo.D10090.category,
            }
            break;
        case JeomsinPageInfo.E10003.pageCode:
            nextPageInfo.to = JeomsinPageInfo.E10003.pagePath;
            nextPageInfo.state = { menuId: JeomsinPageInfo.E10003.category }
            break;
        case JeomsinPageInfo.E10004.pageCode:
            nextPageInfo.to = JeomsinPageInfo.E10004.pagePath;
            nextPageInfo.state = { menuId: JeomsinPageInfo.E10004.category }
            break;
        case JeomsinPageInfo.E10005.pageCode:
            nextPageInfo.to = JeomsinPageInfo.E10005.pagePath;
            nextPageInfo.state = { menuId: JeomsinPageInfo.E10005.category }
            break;
        case JeomsinPageInfo.E10051.pageCode:
            nextPageInfo.to = JeomsinPageInfo.E10051.pagePath;
            nextPageInfo.state = { menuId: JeomsinPageInfo.E10051.category }
            break;
        case JeomsinPageInfo.E10006.pageCode:
            nextPageInfo.to = JeomsinPageInfo.E10006.pagePath;
            break;
        case JeomsinPageInfo.E10008.pageCode:
            nextPageInfo.to = JeomsinPageInfo.E10008.pagePath;
            break;
        case JeomsinPageInfo.E10031.pageCode:
            nextPageInfo.to = JeomsinPageInfo.E10015.pagePath;
            nextPageInfo.state = { tabIndex: 1 }
            break;
        case JeomsinPageInfo.E10015.pageCode:
            nextPageInfo.to = JeomsinPageInfo.E10015.pagePath;
            break;
        case JeomsinPageInfo.E10030.pageCode:
            nextPageInfo.to = JeomsinPageInfo.E10030.pagePath;

            break;
        case JeomsinPageInfo.E10048.pageCode:
            nextPageInfo.to = JeomsinPageInfo.E10048.pagePath;

            break;
        // case JeomsinPageInfo.E10049.pageCode: //서비스 개편 확인 중
        //     nextPageInfo.to = JeomsinPageInfo.E10049.pagePath;
        //     break;
    }

    return {
        ...returnedValue,
        nextPageInfo: nextPageInfo,
    };
};

export default usePageLanding; 