import { styled } from "@mui/system";
import Button from "@mui/material/Button";

interface CustomButtonProps {
  maxWidth?: string;
  bottom?: string;
  width?: string;
  height?: string;
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
}

const CustomButton = styled(Button)<{
  styleProps?: CustomButtonProps;
  buttonDisabled?: boolean;
  clickHandler?: () => void;
  customBackgroundColor?: string | undefined;
}>(({ styleProps, buttonDisabled, clickHandler, customBackgroundColor }) => ({
  "&.MuiButtonBase-root": {
    maxWidth: styleProps?.maxWidth ?? "480px",
    bottom: styleProps?.bottom ?? "0",
    width: styleProps?.width ?? "100%",
    minWidth: styleProps?.width ?? "320px",
    height: styleProps?.height ?? "56px",
    color: buttonDisabled ? "#BCC3D3" : styleProps?.color ?? "#000000",
    backgroundColor:
      customBackgroundColor !== undefined
        ? customBackgroundColor
        : buttonDisabled
        ? "#E9EBF4"
        : styleProps?.backgroundColor ?? "#FFF854",
    fontSize: styleProps?.fontSize ?? "16px",
    onclick: (_: any) => {
      if (clickHandler) {
        clickHandler();
      }
    },
    borderRadius: 0,
  },
}));

export default CustomButton;
