import { useSimplePayCardInfoStore } from "@State/use_simple_pay_card_store";
import { Spacing, SpacingType } from "@Views/common_component/spacer/spacing";
import FlexBox, { FlexAlignItemsType, FlexDirectionType, FlexJustifyContentType } from "@Views/layer/flex_box/flex_box";
import { FC } from "react";
import css from "./simple_pay_card_change_box.module.css";
import useSimplePayCardChangeBoxController from "./use_simple_pay_card_change_box_controller";

type SimplePayCardChangeBoxProps = {
    onClick?: () => void;
};

const SimplePayCardChangeBox:FC<SimplePayCardChangeBoxProps> = ( props ) => {
    const { removeSimplePayCardInfo } = useSimplePayCardChangeBoxController();

    return (
        <div className={css.modal_content}>
            <Spacing direction={SpacingType.vertical} gap={40}/>
            <FlexBox
                direction={FlexDirectionType.column}
                alignItems={FlexAlignItemsType.center}
            >
                <span className={css.title}>알림</span>
                <Spacing direction={SpacingType.vertical} gap={40}/>
                <span className={`${css.normal_text} ${css.bold}`}>카드는 최대 1장만 등록 가능합니다.</span>
                <Spacing direction={SpacingType.vertical} gap={24}/>
                <span className={css.normal_text}>카드를 변경하시려면 기존 카드를 삭제하고 재 등록해야합니다.</span>
                <Spacing direction={SpacingType.vertical} gap={30}/>
                <FlexBox
                    class={css.button_wrap}
                    direction={FlexDirectionType.row}
                    alignItems={FlexAlignItemsType.center}
                    justifyContent={FlexJustifyContentType.center}
                    gap={7}
                >
                    <div className={`${css.button} ${css.gray}`} onClick={props.onClick}>유지하기</div>
                    <div 
                        className={`${css.button} ${css.yellow}`} 
                        onClick={async ()=> {
                            if(await removeSimplePayCardInfo() && props?.onClick) {
                                props.onClick();
                            }
                        }
                    }>삭제하기</div>
                </FlexBox>
                <Spacing direction={SpacingType.vertical} gap={65}/>
            </FlexBox>
        </div>
    )
}

export default SimplePayCardChangeBox;