import { getJeomsinColor, JeomsinColors } from "@Config/jeomsin_colors";
import PageRoutes from "@Config/jeomsin_page_routes";
import {
  JeomsinLoginMemberInfo,
  JeomsinUserInfo,
} from "@DataDefine/jeomsin_user_info";
import useChangeTheme from "@Hooks/use_change_theme";
import { GeometryReaderContext } from "@Hooks/use_geometry_reader";
import { useResizeObserver } from "@Hooks/use_reisze_observer";
import useRunOnce from "@Hooks/use_run_once";
import { Box } from "@mui/material";
import { useUserInfoStore } from "@State/user_info_store";
import useShowIntroPopup from "@Util/show_intro_popup";
import JeomsinAsidePage from "@Views/aside_page/aside_page";
import AsyncModalPopupManager from "@Views/common_component/async_modal_popup/async_modal_popup";
import AsyncPageManager from "@Views/common_component/async_page/async_page";
import { ToastPopupManager } from "@Views/common_component/toast_popup/toast_popup_manager";
import {
  BottomNavigationProps,
  BottomNavigationType,
  JeomsinBottomNavigation,
} from "@Views/layer/bottom_navigation/bottom_navigation";
import BottomPopup, { BottomPopupType } from "@Views/layer/bottom_popup/bottom_popup";
import {
  HeaderProps,
  JeomsinHeader,
  JeomsinPageType,
} from "@Views/layer/header/header";
import { useFortuneStore } from "@Views/main_tab/fortune/use_fortune_store";
import {
  ShowMemberIntegratedWelcomeDialogContext,
  useShowMemberIntegratedWelcomeDialogInternal,
} from "@Views/main_tab/fortune/use_welcome_modal_popup";
import { useCoupangAdViewStore } from "@Views/web_ad/use_coupang_ad_view_store";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { v1 } from "uuid";

export function AppContainer() {
  const [headerType, setHeaderType] = useState<HeaderProps>({
    type: JeomsinPageType.MainTab,
  });

  const [bottomNavParam, setBottomNavParam] = useState<BottomNavigationProps>({
    type: BottomNavigationType.Tab,
  });

  const memberIntegratedFlow = useShowMemberIntegratedWelcomeDialogInternal();
  const { representativeUserInfo } = useUserInfoStore();
  const { coupangConfig } = useCoupangAdViewStore();
  const containerRef = useRef<HTMLDivElement>(null);
  const { reSize } = useResizeObserver(containerRef);
  const [showFirstRunningCoupangPopup, setShowFirstRunningCoupangPopup] = useState<boolean>(false);
  // 아이폰에서 상·하단 컬러를 하얀색으로 바꿈
  useChangeTheme(getJeomsinColor(JeomsinColors.SubBg01));
  
  const { initState } = useFortuneStore(); //메인 페이지에 대한 초기화 작업
  useEffect(() => {
    if(performance?.navigation?.type === 0) { //일반 방문 0, 새로고침 1, Appcontainer는 react내 이동으로 변경되지 않음
      initState();
    } 
  }, []);

  // const { showPopup } = useShowIntroPopup(); //2025.03.17 사업부 요청으로 임시 제거
  const location = useLocation();

  useRunOnce(() => {
    // 만약 공유용 페이지에 접속했을 경우, 오프닝 팝업을 띄우지 않습니다.
    if (location.pathname.toLowerCase().includes("sharable")) {
      return;
    }

    // showPopup();

    if (representativeUserInfo) {
      setShowFirstRunningCoupangPopup(true);
    }
  }, "Jeomsin-AppContainer-Run-Once");

  useEffect(() => {
    const senderId = v1();
    let stop = false;
    let previous: unknown = undefined;

    const bc = new BroadcastChannel("user-info-storage-sync");

    // 👨‍⚕️👂 수신자: 다른 탭에서 유저 정보에 다름이 있음을 감지.
    const onMessageEvent = async (
      e: MessageEvent<{
        sender: string;
        userInfo: string;
      }>
    ) => {
      stop = true;

      const sender = e.data.sender;
      const data: {
        state: {
          representativeUserInfo?: JeomsinUserInfo;
          userInfoList?: JeomsinUserInfo[];
          loginMemberInfo?: JeomsinLoginMemberInfo;
          isFullySignedIn?: boolean;
          useCoin?: number;
        };
      } = JSON.parse(e.data.userInfo);

      if (sender === senderId) {
        // 수신자와 송신자가 동일할 경우, 수신할 필요 없음.
        return;
      }

      if (data !== undefined && data !== null) {
        if (
          data.state.representativeUserInfo !== undefined &&
          data.state.representativeUserInfo !== null
        ) {
          useUserInfoStore
            .getState()
            .setRepresentativeUserInfo(data.state.representativeUserInfo);
        } else {
          useUserInfoStore.getState().setRepresentativeUserInfo(undefined);
        }

        if (
          data.state.userInfoList !== undefined &&
          data.state.userInfoList !== null
        ) {
          useUserInfoStore.getState().setUserInfoList(data.state.userInfoList);
        } else {
          useUserInfoStore.getState().setUserInfoList(undefined);
        }

        if (
          data.state.loginMemberInfo !== undefined &&
          data.state.loginMemberInfo !== null
        ) {
          useUserInfoStore
            .getState()
            .setLoginMemberInfo(data.state.loginMemberInfo);
        } else {
          useUserInfoStore.getState().setLoginMemberInfo(undefined);
        }

        if (
          data.state.isFullySignedIn !== undefined &&
          data.state.isFullySignedIn !== null
        ) {
          useUserInfoStore
            .getState()
            .setFullySignedIn(data.state.isFullySignedIn);
        } else {
          useUserInfoStore.getState().setFullySignedIn(false);
        }

        if (data.state.useCoin !== undefined && data.state.useCoin !== null) {
          useUserInfoStore.getState().setUseCoin(data.state.useCoin);
        } else {
          useUserInfoStore.getState().setUseCoin(0);
        }
      }

      const freshData = JSON.stringify(data);
      sessionStorage.setItem("user-info-storage", freshData);
      previous = freshData;

      stop = false;
    };

    bc.addEventListener("message", onMessageEvent);

    const action = async () => {
      while (stop === false) {
        await new Promise<void>((resolve) =>
          setTimeout(() => {
            resolve();
          }, 1000)
        );

        const freshData = sessionStorage.getItem("user-info-storage");

        // 👨‍⚕️👄 송신자: 로그인 혹은 로그아웃과 같은 유저 정보 액션에 대한 변경사항을 송신
        if (String(previous) !== String(freshData)) {
          bc.postMessage({
            sender: senderId,
            userInfo: freshData,
          });
          previous = freshData;
        }
      }
    };

    action();

    return () => {
      stop = true;
      bc.removeEventListener("message", onMessageEvent);
      bc.close();
    };
  }, []);

  return (
    <ShowMemberIntegratedWelcomeDialogContext.Provider
      value={{
        showMemberIntegratedWelcomeDialog:
          memberIntegratedFlow.showMemberIntegratedWelcomeDialog,
        showSNSConnectionDialog: memberIntegratedFlow.showSNSConnectionDialog,
        triggerSecondStep: memberIntegratedFlow.triggerSecondStep,
        logout: memberIntegratedFlow.logout,
      }}
    >
      <JeomsinAsidePage />
      <GeometryReaderContext.Provider
        value={{
          getSize: () => {
            const rect = containerRef.current?.getBoundingClientRect();

            return {
              width: reSize?.width ?? 390,
              height: reSize?.height ?? 750,
              x: rect?.x ?? 0,
              y: rect?.y ?? 0,
            };
          },
        }}
      >
        <Box className="main-page" ref={containerRef}>
          <div className="background-area"></div>
          <JeomsinHeader
            type={headerType.type}
            title={headerType.title}
            rightIcons={headerType.rightIcons}
            pageGuide={headerType.pageGuide}
            tabIndex={headerType.tabIndex}
            pageBackEvent={headerType.pageBackEvent}
            fontSizeButtonPressed={headerType.fontSizeButtonPressed}
            shareButtonPressed={headerType.shareButtonPressed}
          />
          <PageRoutes
            headerType={setHeaderType}
            bottomNavParam={setBottomNavParam}
          />
          <JeomsinBottomNavigation
            type={bottomNavParam.type}
            title={bottomNavParam.title}
            selectedTab={bottomNavParam.selectedTab}
            boldedPart={bottomNavParam.boldedPart}
            buttonDisable={bottomNavParam.buttonDisable}
            hide={bottomNavParam.hide}
            color={bottomNavParam.color}
            onClick={bottomNavParam.onClick}
            isAdShow={bottomNavParam.isAdShow}
          />
          <AsyncPageManager />
          <AsyncModalPopupManager />
          <ToastPopupManager />
          <BottomPopup 
            isOpen={showFirstRunningCoupangPopup} 
            type={BottomPopupType.FirstRunningCoupangAd}
            notDimmed={true}
            lazyShow={true}
            onClose={() => setShowFirstRunningCoupangPopup(false)}
          />
        </Box>
      </GeometryReaderContext.Provider>
    </ShowMemberIntegratedWelcomeDialogContext.Provider>
  );
}
