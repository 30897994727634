import { useEffect, useState } from "react";
import css from "./counselor_filter_box.module.css";
import { CounselorDefaultField } from "@Views/counsel/professional_counselor/data_define/professional_counselor_data";
import { SortOption } from "@DataDefine/counselor_data";

export type CounselorFilterBoxData = {
  sortedList:[{title:string, id:string}];
  selectedSortOption:{title:string, id:string}|undefined;
  fieldList:[{title:string, code:string}];
  selectedFieldOption:{title:string, code:string}|undefined;
}

export type CounselorFilterBoxResponse = {
  selectedSortOption:{title:string|undefined, id:string|undefined};
  selectedFieldOption:{title:string|undefined, code:string|undefined};
}

type CounselorFilterBoxProps = {
  contents: CounselorFilterBoxData;
  onClick?: (response:CounselorFilterBoxResponse) => void;
};

const CounselorFilterBox: React.FC<CounselorFilterBoxProps> = (props) => {
  const sortedList:any[]|undefined = props.contents.sortedList;
  const fieldList:any[]|undefined = props.contents.fieldList;
  const [selectSortValue, setSelectSortValue] = useState<string | undefined>(undefined);
  const [selectSortText, setSelectSortText] = useState<string | undefined>(undefined);
  const [selectFieldValue, setSelectFieldValue] = useState<string | undefined>(undefined);
  const [selectFieldText, setSelectFieldText] = useState<string | undefined>(undefined);

  useEffect(() => {
    setSelectSortValue(props.contents.selectedSortOption?.id ?? SortOption.Recommend.id);
    setSelectSortText(props.contents.selectedSortOption?.title ?? "");
    setSelectFieldValue(props.contents.selectedFieldOption?.code ?? CounselorDefaultField[0].code);
    setSelectFieldText(props.contents.selectedFieldOption?.title ?? CounselorDefaultField[0].title);
  }, [])

  const reset = () => {
    setSelectSortValue(SortOption.Recommend.id);
    setSelectSortText(SortOption.Recommend.title);
    setSelectFieldValue(CounselorDefaultField[0].code);
    setSelectFieldText(CounselorDefaultField[0].title);
  }

  return (
      <div className={css.modal_content}>
        <div className={css.close_button_wrap}>
        </div>
        <div className={css.title}>필터</div>
        <div className={`${css.contents} mb40 pl24 pr24`}>
          <div className={css.counselor_filter_title}>정렬</div>
          <div className={css.counselor_filter_container}>
            {
               sortedList ? sortedList.map((value, index) => (
                <button
                  key={index}
                  className={`${css.counselor_filter_button} ${(selectSortValue == value.id) ? css.selected : ""}`} 
                  value={value.id} 
                  onClick={()=> {
                    setSelectSortText(value.title);
                    setSelectSortValue(value.id);
                  }}
                >
                  {value.title}
                </button>
              )) : <></>
            }
          </div>
          <div className={css.counselor_filter_title}>전문 상담 분야</div>
          <div className={css.counselor_filter_container}>
          {
              fieldList ? fieldList.map((value, index) => (
                <button
                  key={index}
                  className={`${css.counselor_filter_button} ${(selectFieldValue == value.code) ? css.selected : ""}`} 
                  value={value.code} 
                  onClick={()=> {
                    setSelectFieldText(value.title);
                    setSelectFieldValue(value.code);
                  }}
                >
                  {value.title}
                </button>
              )) : <></>
            }
          </div>
        </div>
        <div className={`${css.button_wrap} pl24 pr24`}>
          <div
            className={css.button_small}
            onClick={()=> reset()}
          >
            초기화
          </div>
          <div
            className={css.button_large}
            onClick={()=> {
              if (props.onClick) {
                const response:CounselorFilterBoxResponse = {
                  selectedSortOption:{title:selectSortText, id:selectSortValue},
                  selectedFieldOption:{title:selectFieldText, code:selectFieldValue},
                }
                props.onClick(response);
              }
            }}
          >
            상담사 보기
          </div>
        </div>
      </div>
    );
};

export { CounselorFilterBox };
