/** @jsxImportSource @emotion/react */

import { Stack } from "@mui/material";
import { ColoredRectangleButton } from "@Views/common_component/colored_rectangle_button/colored_rectangle_button";
import { css } from "@emotion/react";
import { useEffect, useState } from "react";

const SajuMyeongsikSpecifics = ({
  title,
  definition,
  characteristics,
  buttonTitle,
  shouldClose,
  clickAction,
  closeAction,
}: {
  title?: string;
  definition?: string;
  characteristics?: string;
  buttonTitle?: string;
  shouldClose?: boolean;
  clickAction?: () => void;
  closeAction?: () => void;
}) => {
  const [animatedShow, setAnimatedShow] = useState(false);

  useEffect(() => {
    setAnimatedShow(true);
  }, []);

  useEffect(() => {
    if (shouldClose === true) {
      setAnimatedShow(false);

      setTimeout(() => {
        closeAction?.();
      }, 500);
    }
  }, [shouldClose, closeAction]);

  return (
    <div
      css={css`
        transform: translateY(40px);
      `}
    >
      <div
        css={css`
          background-color: var(--SubBg01);
          border-radius: 40px 40px 0 0;
          padding: 36px 24px 40px 24px;
          display: flex;
          flex-direction: column;
          gap: 35px;
          transform: translateY(${animatedShow ? 0 : 100}%);
          transition: transform 500ms;
        `}
      >
        <div
          css={css`
            width: 100%;
            height: 100%;
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <span className="ts23 bold6">{title ?? ""}</span>
          <img
            css={css`
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              width: 30px;
              height: 30px;
              object-fit: contain;
            `}
            src="images/mypage/3.0x/app_bar_close.png"
            alt="close button"
            onClick={() => {
              setAnimatedShow(false);

              setTimeout(() => {
                closeAction?.();
              }, 500);
            }}
          />
        </div>

        <Stack
          className="pl20 pr20"
          spacing="10px"
          sx={{ alignItems: "flex-start" }}
        >
          <span className="ts20 bold6">{characteristics ? "단어 뜻" : ""}</span>
          <span
            className="ts17"
            css={css`
              text-align: start;
            `}
          >
            {definition ?? ""}
          </span>
        </Stack>

        {characteristics ? (
          <Stack
            className="pl20 pr20"
            spacing="10px"
            sx={{ alignItems: "flex-start" }}
          >
            <span className="ts20 bold6">특징</span>
            <span className="ts17">{characteristics}</span>
          </Stack>
        ) : (
          <></>
        )}

        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
          `}
          onClick={() => {
            if (clickAction) {
              clickAction();
            }
          }}
        >
          <ColoredRectangleButton
            title={buttonTitle ?? "자세히보기"}
            cornerRadius={true}
            width="100%"
            height="65px"
          />
        </div>
      </div>
    </div>
  );
};

export default SajuMyeongsikSpecifics;
