import { RequestProps } from "@Network/network_constants";
import { apiShopByDormancy } from "@Network/shopby/api_shop_by_dormancy";
import { apiShopByLogin } from "@Network/shopby/api_shop_by_login";
import { apiShopByRegister } from "@Network/shopby/api_shop_by_register";
import { useUserInfoStore } from "@State/user_info_store";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface ShopByEvent {
    token?: string,
    shopByPass: string,
    loginJeomsinMall: (
        props: RequestProps
    )=> Promise<any>,
}
  
const useShopByEventStore = create(
    persist<ShopByEvent>(
        (set, get) => ({
            token: undefined,
            shopByPass: process.env.REACT_APP_JEOMSINMALL_CLIEND_PASS ?? "",
            loginJeomsinMall: (props) => {
                return new Promise(async (resolve, reject) => { 
                    //로그아웃 - 토큰 제거
                    set({token: undefined});
                    //회원정보 체크
                    const { loginMemberInfo, representativeUserInfo } = useUserInfoStore.getState();

                    const memberNo = loginMemberInfo?.mem_info?.mem_no ?? "0";
                    if( Number(memberNo) > 0 && representativeUserInfo ) {
                        const loginResponse = await apiShopByLogin(
                            memberNo,
                            get().shopByPass,
                        );

                        if(loginResponse?.result) {
                            if(loginResponse?.dormantMemberResponse) {
                                //휴면
                                apiShopByDormancy();
                            }

                            set({token: loginResponse.accessToken});
                            resolve(true);
                        } else {
                            if(!representativeUserInfo?.name) {
                                reject("회원가입에 필요한 이름이 없습니다.");
                            }

                            // 등록
                            const resigterResponse = await apiShopByRegister(
                                memberNo,
                                get().shopByPass,
                                representativeUserInfo?.name ?? ""
                            )
                            
                            if (resigterResponse?.result) {
                                //등록 후 재 로그인
                                const response = await get().loginJeomsinMall(props);
                                if(response === true) {
                                    resolve(true);
                                }
                            } else {
                                reject(resigterResponse?.message);
                            }
                        }
                    } else {
                        reject("로그인 정보가 없습니다.");
                    }
                });
            },
        }),
        { 
            name: 'shopby-event-storage',
            storage: createJSONStorage(() => sessionStorage) //탭 종료 시 스토리지에 저장된 데이터 Clear
        }
    )
);

export { useShopByEventStore };