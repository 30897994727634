import { JeomsinPagePath } from "@Config/jeomsin_page_path";
import { Box } from '@mui/material';
import { useNavigate } from "react-router";
import "./titlebar.css";
import { Spacing, SpacingType } from "@Views/common_component/spacer/spacing";

type TitlebarProps = {
    type: TitlebarType,
    title: string,
    children?: React.ReactNode;
    pageGuide?: JeomsinPagePath;
    pageBackEvent?:()=> void,
    fontSizeButtonPressed?: () => void,
    shareButtonPressed?: () => void,
}

const TitleBar: React.FC<TitlebarProps> = (
    props
) => {
    var returnValue:JSX.Element;
    const navigate = useNavigate();
    const histroyBack = () => {
        props.pageBackEvent ? props.pageBackEvent() : navigate(-1);
    }
    
    switch(props.type) {
        case TitlebarType.SubPageNoButton:
            returnValue = (
                <>
                <Box className='titlebar sub'>
                    <div className='left-icon'>
                        <img src='/images/common/app_bar_previous.png' onClick={histroyBack}></img>
                    </div>
                    <div className="text-area">
                        <span>{props.title}</span>
                        {
                            props.pageGuide ? (
                                <img width="17px" height="17px" className="guide-btn" src="images/three_minute_counselor_list/info.png" alt="guid" onClick={() => navigate(props.pageGuide!)}/>
                            ) : <></>
                        }
                    </div>
                    <Box className='right-icon'>
                    </Box>
                </Box>
                <Spacing direction={SpacingType.vertical} gap={56}/>
                </>
            );
            break;
        case TitlebarType.SubPageOneButton:
            returnValue = (
                <>
                <Box className='titlebar sub'>
                    <div className='left-icon'>
                        <img src='/images/common/app_bar_previous.png' onClick={histroyBack}></img>
                    </div> 
                    <div className="text-area">
                        <span>{props.title}</span>
                        {
                            props.pageGuide ? (
                                <img width="17px" height="17px" className="guide-btn" src="images/three_minute_counselor_list/info.png" alt="guid" onClick={() => navigate(props.pageGuide!)}/>
                            ) : <></>
                        }
                    </div>
                    <Box className='right-icon'>
                        <div className="trailing-icon" onClick={() => {
                            if (props.fontSizeButtonPressed) {
                                props.fontSizeButtonPressed();
                            }
                        }}>
                            <img src='img/font.png' alt="font size change"/>
                        </div>
                    </Box>
                </Box>
                <Spacing direction={SpacingType.vertical} gap={56}/>
                </>
            );
            break;
        case TitlebarType.SajuMyeongsik:
            returnValue = (
                <>
                <Box className='titlebar sub'>
                    <div className='left-icon'>
                        <img src='/images/common/app_bar_previous.png' onClick={histroyBack}></img>
                    </div> 
                    <div className="text-area">
                        <span>{props.title}</span>
                        {
                            props.pageGuide ? (
                                <img width="17px" height="17px" className="guide-btn" src="images/three_minute_counselor_list/info.png" alt="guide" onClick={() => navigate(props.pageGuide!)}/>
                            ) : <></>
                        }
                    </div>
                    <Box className='right-icon'>
                        <div className="trailing-icon" onClick={() => {
                            if (props.shareButtonPressed) {
                                props.shareButtonPressed();
                            }
                        }}>
                            <img src='images/common/app_bar_share.png' alt="share contents"/>
                        </div>
                    </Box>
                </Box>
                <Spacing direction={SpacingType.vertical} gap={56}/>
                </>
            );
            break;
        case TitlebarType.SubPageTwoButton:
            returnValue = (
                <>
                <Box className='titlebar sub'>
                    <div className='left-icon'>
                        <img src='/images/common/app_bar_previous.png' onClick={histroyBack}></img>
                    </div> 
                    <div className="text-area">
                        <span>{props.title}</span>
                        {
                            props.pageGuide ? (
                                <img width="17px" height="17px" className="guide-btn" src="images/three_minute_counselor_list/info.png" alt="guide" onClick={() => navigate(props.pageGuide!)}/>
                            ) : <></>
                        }
                    </div>
                    <Box className='right-icon'>
                        <div className="trailing-icon" onClick={() => {
                            if (props.fontSizeButtonPressed) {
                                props.fontSizeButtonPressed();
                            }
                        }}>
                            <img src='/images/common/font.png' alt="font size change"/>
                        </div>
                        <div className="trailing-icon" onClick={() => {
                            if (props.shareButtonPressed) {
                                props.shareButtonPressed();
                            }
                        }}>
                            <img src='/images/common/app_bar_share.png' alt="share contents"/>
                        </div>
                    </Box>
                </Box>
                <Spacing direction={SpacingType.vertical} gap={56}/>
                </>
            );
            break;
        case TitlebarType.SubPageRightCustom:
            returnValue = (
                <>
                <Box className='titlebar sub'>
                    <div className='left-icon'>
                        <img src='/images/common/app_bar_previous.png' onClick={histroyBack}></img>
                    </div> 
                    <div className="text-area">
                        <span>{props.title}</span>
                        {
                            props.pageGuide ? (
                                <img width="17px" height="17px" className="guide-btn" src="images/three_minute_counselor_list/info.png" alt="guid" onClick={() => navigate(props.pageGuide!)}/>
                            ) : <></>
                        }
                    </div>
                    <Box className='right-icon'>
                        {props.children}
                    </Box>
                </Box>
                <Spacing direction={SpacingType.vertical} gap={56}/>
                </>
            );
            break;
        case TitlebarType.SubOnlyRightButton:
            returnValue = (
                <>
                <Box className='titlebar sub'>
                    <div className='left-icon'>
                    </div> 
                    <div className="text-area">
                        <span>{props.title}</span>
                        {
                            props.pageGuide ? (
                                <img width="17px" height="17px" className="guide-btn" src="images/three_minute_counselor_list/info.png" alt="guid" onClick={() => navigate(props.pageGuide!)}/>
                            ) : <></>
                        }
                    </div>
                    <Box className='right-icon'>
                        {props.children}
                    </Box>
                </Box>
                <Spacing direction={SpacingType.vertical} gap={56}/>
                </>
            );
            break;
        case TitlebarType.FullPopup:
            returnValue = (
                <>
                <Box className='titlebar sub'>
                    <div className='left-icon'></div> 
                    <div className="text-area">
                        <span>{props.title}</span>
                        {
                            props.pageGuide ? (
                                <img width="17px" height="17px" className="guide-btn" src="images/three_minute_counselor_list/info.png" alt="guid" onClick={() => navigate(props.pageGuide!)}/>
                            ) : <></>
                        }
                    </div>
                    <Box className='right-icon'>
                        <div className="big-icon" onClick={histroyBack}>
                            <img src='images/common/app_bar_close.png'/>
                        </div>
                    </Box>
                </Box>
                <Spacing direction={SpacingType.vertical} gap={56}/>
                </>
            );
            break;
        case TitlebarType.NoneButton:
            returnValue = (
                <>
                <Box className='titlebar sub'>
                    <div className="text-area fullWidth">
                        <span>{props.title}</span>
                        {
                            props.pageGuide ? (
                                <img width="17px" height="17px" className="guide-btn" src="images/three_minute_counselor_list/info.png" alt="guid" onClick={() => navigate(props.pageGuide!)}/>
                            ) : <></>
                        }
                    </div>
                </Box>
                <Spacing direction={SpacingType.vertical} gap={56}/>
                </>
            );
            break;
        default:
            returnValue = (<span></span>);
            break;

    }

    return returnValue;
};

enum TitlebarType {
    SubPageNoButton,
    SubPageOneButton,
    SubPageTwoButton,
    SubPageRightCustom,
    SubOnlyRightButton,
    FullPopup,
    NoneButton,
    SajuMyeongsik,
    None
}

export {
    TitleBar,
    TitlebarType
};
