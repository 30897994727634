/** @jsxImportSource @emotion/react */

import { v4 } from "uuid";
import { create } from "zustand";
import CalendarSelection from "@Views/common_component/calendar_selection/calendar_selection";
import { css } from "@emotion/react";
import { useEffect, useState } from "react";

export default function AsyncCalendarPopupManager({
  width,
}: {
  width: number;
}) {
  const { calendarPopupContents, removeCalendarPopupById } =
    useAsyncCalendarPopupStoreInternal();
  const shouldShow = calendarPopupContents.length > 0;

  return (
    <>
      {calendarPopupContents.map((eachContent, contentIndex) => (
        <EachCalendar
          key={eachContent.removalId}
          width={width}
          contentIndex={contentIndex}
          eachContent={eachContent}
          removeCalendarPopupById={removeCalendarPopupById}
        />
      ))}
      <div
        css={css`
          position: fixed;
          left: 0;
          top: 0;
          width: 100vw;
          height: 100vh;
          background-color: rgb(0, 0, 0);
          transition: opacity linear 300ms;
          opacity: ${shouldShow ? 50 : 0}%;
          pointer-events: ${shouldShow ? "all" : "none"};
          z-index: ${1_000};
        `}
        onClick={() => {
          if (calendarPopupContents.length === 0) {
            return;
          }

          const popup = calendarPopupContents.at(
            calendarPopupContents.length - 1
          );
          const removalId = popup?.removalId;

          if (removalId && popup) {
            popup.resolver();
            removeCalendarPopupById(removalId);
          }
        }}
      />
    </>
  );
}

function EachCalendar({
  width,
  eachContent,
  contentIndex,
  removeCalendarPopupById,
}: {
  width: number;
  eachContent: {
    removalId: string;
    isHidden: boolean;
    resolver: (selectedDate?: Date) => void;
  };
  contentIndex: number;
  removeCalendarPopupById: (removalId: string) => void;
}) {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setDisplay(true);
  }, []);

  return (
    <div
      css={css`
        opacity: ${eachContent.isHidden || display === false ? 0 : 100}%;
        z-index: ${10_000 + contentIndex};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        width: ${width}px;
        height: 100%;
        transition: opacity linear 300ms;
        pointer-events: all;
      `}
    >
      <CalendarSelection
        postSelectionAction={(result) => {
          eachContent.resolver(result);
          removeCalendarPopupById(eachContent.removalId);
        }}
      />
    </div>
  );
}

interface ZAsyncCalendarPopupInfo {
  calendarPopupContents: {
    removalId: string;
    isHidden: boolean;
    resolver: (selectedDate?: Date) => void;
  }[];
  setCalendarPopupContent: (removalId: string) => Promise<Date | undefined>;
  removeCalendarPopupById: (removalId: string) => void;
}

export function useAsyncCalendarPopup() {
  const { setCalendarPopupContent } = useAsyncCalendarPopupStoreInternal();

  return {
    setCalendarPopupContent: () => {
      return setCalendarPopupContent(v4());
    },
  };
}

const useAsyncCalendarPopupStoreInternal = create<ZAsyncCalendarPopupInfo>(
  (set) => ({
    calendarPopupContents: [],
    setCalendarPopupContent: (removalId) => {
      const promise = new Promise((resolve: (selectedDate?: Date) => void) => {
        set((state) => ({
          ...state,
          calendarPopupContents: [
            ...state.calendarPopupContents,
            {
              removalId,
              isHidden: false,
              resolver: resolve,
            },
          ],
        }));
      });

      return promise;
    },
    removeCalendarPopupById: (removalId) => {
      set((state) => {
        let newState = {
          ...state,
        };

        let found = newState.calendarPopupContents.find(
          (each) => each.removalId === removalId
        );

        if (found) {
          found.isHidden = true;
        }

        return newState;
      });

      setTimeout(() => {
        set((state) => {
          const filtered = state.calendarPopupContents.filter(
            (each) => each.removalId !== removalId
          );

          return {
            ...state,
            calendarPopupContents: filtered,
          };
        });
      }, 300);
    },
  })
);
