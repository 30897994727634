import { JeomsinUserInfo } from "@DataDefine/jeomsin_user_info";
import {
  ComponentType,
  createElement,
  forwardRef,
  lazy,
  startTransition,
  useRef,
} from "react";
import { flushSync } from "react-dom";

const HashtagRelation = {
  A: "종합운세",
  B: "궁합",
  E: "취업",
  F: "사업",
  G: "꿈해몽",
  C: "택일",
  H: "건강",
  I: "가족",
  D: "학업",
  J: "정서",
  K: "자기이해",
  P: "섭식",
  L: "부부",
  M: "직장",
  N: "대인관계",
  O: "성",
  Q: "자녀",
  R: "연애",
  S: "중독",
  T: "재물",
  U: "풍수",
} as const;

function formatDate(date: Date) {
  // Remember, Month is zero-indexed.
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

function formatDotDate(date: Date) {
  // Remember, Month is zero-indexed.
  return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;
}

function getMyCalendar(userInfo: JeomsinUserInfo): string {
  return `${userInfo.calendarType}` === "0" ? "0" : "1";
}

function getMyLeap(userInfo: JeomsinUserInfo): string {
  return `${userInfo.calendarType}` === "2" ? "1" : "0";
}

function convertTimeMin(nowSec: number) {
  let strReturn = "";
  let min = (nowSec % 3600) / 60;
  let sec = nowSec % 60;

  if (min > 0) {
    strReturn = `${Math.floor(min)}`;
  } else {
    strReturn = `${sec}`;
  }

  return strReturn;
}

function convertCoNo(coNo: number) {
  let strReturn = `${coNo}`;
  if (coNo < 10) {
    strReturn = `00${coNo}`;
  } else if (coNo >= 10 && coNo < 100) {
    strReturn = `0${coNo}`;
  }
  return strReturn;
}

function addCommas(num: number) {
  return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

const isTSInterface = <T>(
  value: any,
  keys: (keyof T)[],
  requiredKeys: (keyof T)[]
): value is T => {
  if (typeof value !== "object" || value === null) return false;

  return (
    requiredKeys.every((key) => key in value) && //  Ensure all required keys are present
    (Object.keys(value) as (keyof T)[]).every((key) => keys.includes(key)) //  Ensure no undefined keys are present
  );
};

function splitArrayIntoChunks<T>(array: T[], chunkSize: number): T[][] {
  if (chunkSize <= 0) {
    throw new Error("Chunk size must be greater than 0");
  }

  const result: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }

  return result;
}

function dataURLtoFile(dataurl: string, filename: string) {
  let arr = dataurl.split(",");
  let mime = arr
    .at(0)
    ?.match(/:(.*?);/)
    ?.at(1);
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

function isToday(checkable: Date): boolean {
  const today = new Date();
  const todayyear = today.getFullYear();
  const todaymonth = today.getMonth();
  const todaydate = today.getDate();

  const year = checkable.getFullYear();
  const month = checkable.getMonth();
  const date = checkable.getDate();

  if (year === todayyear && month === todaymonth && date === todaydate) {
    return true;
  } else {
    return false;
  }
}

function isTomorrow(checkable: Date): boolean {
  const today = new Date();
  const tomorrow = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1,
    today.getHours(),
    today.getMinutes()
  );
  const tomorrowyear = tomorrow.getFullYear();
  const tomorrowmonth = tomorrow.getMonth();
  const tomorrowdate = tomorrow.getDate();

  const year = checkable.getFullYear();
  const month = checkable.getMonth();
  const date = checkable.getDate();

  if (
    year === tomorrowyear &&
    month === tomorrowmonth &&
    date === tomorrowdate
  ) {
    return true;
  } else {
    return false;
  }
}

function rawCoFieldToReadable(coField: keyof typeof HashtagRelation) {
  return HashtagRelation[coField];
}

function checkMobile() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || (window as any).opera);
  return check;
}

function addZeros(num: number) {
  let raw = `${num}`;

  while (raw.length < 3) {
    raw = "0".concat(raw);
  }

  return raw;
}

function getUserInfoAgeGroup(
  userInfo: JeomsinUserInfo
): 0 | 10 | 20 | 30 | 40 | 50 | 60 | 70 {
  const yearBirth = Number(userInfo.birthYear);

  if (yearBirth >= 0 && yearBirth < 10) {
    return 0;
  } else if (yearBirth >= 10 && yearBirth < 20) {
    return 10;
  } else if (yearBirth >= 20 && yearBirth < 30) {
    return 20;
  } else if (yearBirth >= 30 && yearBirth < 40) {
    return 30;
  } else if (yearBirth >= 40 && yearBirth < 50) {
    return 40;
  } else if (yearBirth >= 50 && yearBirth < 60) {
    return 50;
  } else if (yearBirth >= 60 && yearBirth < 70) {
    return 60;
  } else {
    return 70;
  }
}

function forceUIUpdateTransition(fn: () => void) {
  if (document.startViewTransition) {
    document.startViewTransition(() => {
      flushSync(() => {
        fn();
      });
    });
  } else {
    fn();
  }
}

function allowUIUpdateTransition(fn: () => void) {
  if (document.startViewTransition) {
    document.startViewTransition(() => {
      fn();
    });
  } else {
    fn();
  }
}

type PreloadableComponent<T extends ComponentType<any>> = T & {
  preload: () => Promise<T>;
};

function lazyWithPreload<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): PreloadableComponent<T> {
  const ReactLazyComponent = lazy(factory);
  let PreloadedComponent: T | undefined;
  let factoryPromise: Promise<T> | undefined;

  const Component = forwardRef(function LazyWithPreload(props, ref) {
    const ComponentToRender = useRef(PreloadedComponent ?? ReactLazyComponent);
    return createElement(
      ComponentToRender.current,
      Object.assign(ref ? { ref } : {}, props) as any
    );
  });

  const LazyWithPreload = Component as any as PreloadableComponent<T>;

  LazyWithPreload.preload = () => {
    if (!factoryPromise) {
      factoryPromise = factory().then((module) => {
        PreloadedComponent = module.default;
        return PreloadedComponent;
      });
    }

    return factoryPromise;
  };

  return LazyWithPreload;
}

function getTextWidth(fontSize: number, value: string) {
  const div = document.createElement("div");
  div.innerText = value;
  div.style.fontSize = `${fontSize}px`;
  div.style.width = "auto";
  div.style.display = "inline-block";
  div.style.visibility = "hidden";
  div.style.position = "fixed";
  div.style.overflow = "auto";
  document.body.append(div);
  let width = div.clientWidth;
  div.remove();
  return width;
}

function randomNumbers(max: number, length: number): number[] {
  let randomNumbers: (number | undefined)[] = Array(length).fill(undefined);

  for (let i = 0; i < length; i++) {
    while (randomNumbers.at(i) === undefined) {
      let rand = Math.floor(Math.random() * max);
      if (randomNumbers.includes(rand) === false) {
        randomNumbers[i] = rand;
      }
    }
  }

  return randomNumbers as number[];
}

export {
  getMyCalendar,
  getMyLeap,
  formatDate,
  formatDotDate,
  convertTimeMin,
  convertCoNo,
  addCommas,
  isTSInterface,
  splitArrayIntoChunks,
  dataURLtoFile,
  isToday,
  isTomorrow,
  rawCoFieldToReadable,
  HashtagRelation,
  checkMobile,
  addZeros,
  getUserInfoAgeGroup,
  forceUIUpdateTransition,
  getTextWidth,
  allowUIUpdateTransition,
  lazyWithPreload,
  randomNumbers,
};
