import { useShopByEventStore } from "@Hooks/use_shop_by_event";
import { debugMode, JeomsinEndPointType, JeomsinHeader, JeomsinMethod, RequestProps } from "@Network/network";

async function ShopByRequest( props:RequestProps ) {
    if(props.method === JeomsinMethod.Get) {
        return Get(props)
    } else {
        return Post(props);
    }
};

const Get = ( props:RequestProps ) => {
    var domain:string|undefined; 
    
    if(debugMode) {
        domain = props.domain.debug;
    }else {
        domain = props.domain.release;
    }

    domain += props.domain.value;

    var endPoint:string = props.endpoint.value; 
    var params:string = "";

    Object.entries(props.params).forEach(([key, value]) => {
        var connnector = (params === "") ? "?" : "&"
        params += connnector + key + "=" + value;
    });

    var url = domain + endPoint + params;

    return request(
        url,
        JeomsinMethod.Get,
        undefined,
        props
    )
}

const Post = (props:RequestProps) => {
    var domain:string|undefined; 
    
    if(debugMode) {
        domain = props.domain.debug;
    }else {
        domain = props.domain.release;
    }

    domain += props.domain.value;
    
    var endPoint:string = props.endpoint.value; 

    var url = domain + endPoint;
    
    return request(
        url,
        JeomsinMethod.Post,
        JSON.stringify(props.params),
        props
    )
}

const request = (
    url:string,
    method: JeomsinMethod,
    body: any,
    props:RequestProps
) => {

    const token = useShopByEventStore.getState().token;
    let shopbyHeaders = JeomsinHeader.ShopBy;
    if(token) {
        shopbyHeaders.accessToken = token;
    }

    return new Promise((resolve, reject) => { 
        fetch(
            url,
            {
                method: method,
                headers: shopbyHeaders,
                body: body
            }
        ).then(
            async response => {
                if(response.status >= 200 && response.status < 300) {
                    const responseData = await response.json();
                    responseData.result = true;
                    resolve(responseData);
                    return false;
                } else if(response.status === 401) {
                    return true;
                } else {
                    throw Error("could not fetch the data that resource");
                }
            }
        ).then(async doLoginJeomsinMall => {
            if(doLoginJeomsinMall) {
                const response = await useShopByEventStore.getState().loginJeomsinMall(props);
                if(response === true) {
                    const retryResponse = await request(url, method, body, props);
                    if(retryResponse) {
                        resolve(retryResponse);
                    } else {
                        reject("could not fetch the data that resource");
                    }
                }
            }
        }).catch(error => {
            console.log("error msg : " + error);
            reject(error);
        });
    });
}

export { ShopByRequest };
