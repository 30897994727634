import { ModalPopupUIProps } from "./async_modal_popup";
import styles from "./each_modal_each_button.module.css";

export default function EachModalEachButton({
  eachContent,
  removeModalPopupById,
}: {
  eachContent: {
    removalId: string;
    content: ModalPopupUIProps;
    resolver: (clickedButtonIndex: number) => void;
  };
  removeModalPopupById: (removalId: string) => void;
}) {
  const firstButton = eachContent.content.buttonTitles?.at(0);
  const secondButton = eachContent.content.buttonTitles?.at(1);
  const otherButtons = (eachContent.content.buttonTitles ?? []).slice(2);

  return (
    <div
      className={styles.buttonContainer}
      style={{
        margin: `0 21.5px ${
          eachContent.content.customVerticalGaps?.[3] ?? 50
        }px 21.5px`,
        flexDirection: eachContent.content.buttonStackDirection ?? "column",
        gap:
          eachContent.content.buttonGap !== undefined
            ? `${eachContent.content.buttonGap}px`
            : "12px",
      }}
    >
      {firstButton && (
        <span
          style={{
            width: eachContent.content.buttonWidth ?? "100%",
            height: typeof firstButton === "string" ? "60px" : undefined,
            backgroundColor:
              typeof firstButton === "string"
                ? eachContent.content.isSecondButtonColored === true
                  ? "var(--ThirdButton)"
                  : "var(--MainButton)"
                : "",
            borderRadius: `${eachContent.content.buttonBorderRadius ?? 12}px`,
          }}
          className={styles.button}
          onClick={() => {
            const isAccept = eachContent.content.decisionHandler?.(0) ?? true;

            if (isAccept === false) {
              return;
            }

            eachContent.resolver(0);
            removeModalPopupById(eachContent.removalId);
          }}
        >
          {firstButton}
        </span>
      )}
      {secondButton && (
        <span
          style={{
            width: eachContent.content.buttonWidth ?? "100%",
            height: typeof secondButton === "string" ? "60px" : undefined,
            backgroundColor:
              typeof secondButton === "string"
                ? eachContent.content.isSecondButtonColored === true
                  ? "var(--MainButton)"
                  : "var(--ThirdButton)"
                : "",
            borderRadius: `${eachContent.content.buttonBorderRadius ?? 12}px`,
          }}
          className={styles.button}
          onClick={() => {
            const isAccept = eachContent.content.decisionHandler?.(1) ?? true;

            if (isAccept === false) {
              return;
            }

            eachContent.resolver(1);
            removeModalPopupById(eachContent.removalId);
          }}
        >
          {secondButton}
        </span>
      )}

      {otherButtons.map((button, buttonIndex) => (
        <span
          key={buttonIndex}
          style={{
            width: eachContent.content.buttonWidth ?? "100%",
            height: typeof button === "string" ? "60px" : undefined,
            borderRadius: `${eachContent.content.buttonBorderRadius ?? 12}px`,
          }}
          className={styles.button}
          onClick={() => {
            const realIndex = buttonIndex + 2;
            const isAccept =
              eachContent.content.decisionHandler?.(realIndex) ?? true;

            if (isAccept === false) {
              return;
            }

            eachContent.resolver(realIndex);
            removeModalPopupById(eachContent.removalId);
          }}
        >
          {button}
        </span>
      ))}
    </div>
  );
}
