import { getPlatformType, getVersion } from "@Config/jeomsin_application_info";
import { JeomsinMethod, JeomsinDomain, JeomsinEndPointType, JeomsinReturnValueType, RequestProps, debugMode, JeomsinEndPoint } from "@Network/network";
import CookieKeys from "@Storage/cookie_keys";
import CookieManager from "@Storage/cookie_manager";
import EncryptAesHelper from "../encrypt/encrypt_aes_helper";
import { XMLParser } from 'fast-xml-parser';

async function JeomsinRequest(props: RequestProps) {
    if (props.method === JeomsinMethod.Get) {
        return Get(props)
    } else {
        return Post(props);
    }
};

async function JeomsinMultipartRequest(props: RequestProps) {
    return MultipartPost(props);
};

const Get = (props: RequestProps) => {
    var domain: string | undefined;

    if (debugMode) {
        domain = props.domain.debug;
    } else {
        domain = props.domain.release;
    }

    domain += props.domain.value;

    var endPoint: string = props.endpoint.value;
    var params: string | undefined;

    if (props.endpoint.type === JeomsinEndPointType.Param) {
        params = "?" + props.endpoint.paramKey + "=" + props.endpoint.paramValue;
    }

    Object.entries(props.params).forEach(([key, value]) => {
        var connnector = (params === undefined) ? "?" : "&"
        params += connnector + key + "=" + value;
    });

    if (params === undefined) {
        params = "?os=" + getPlatformType();
    } else {
        params = "&os=" + getPlatformType();
    }

    params = "&ver=" + getVersion();

    var url = domain + endPoint + params;

    fetch(url, {
        method: JeomsinMethod.Get,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    }
    ).then(
        (response) => response.json()
    );
}

const Post = async (props: RequestProps) => {
    var domain: string | undefined;

    if (debugMode) {
        domain = props.domain.debug;
    } else {
        domain = props.domain.release;
    }

    if (props.domain == JeomsinDomain.JeomsinXml) {
        domain += (CookieManager.GetString(CookieKeys.fortuneUrl) ?? props.domain.value) + "/";
    } else {
        domain += props.domain.value;
    }

    var endPoint: string = props.endpoint.value;

    if (props.endpoint.type === JeomsinEndPointType.Param) {
        props.params[props.endpoint.paramKey] = props.endpoint.paramValue;
    }

    props.params["os"] = getPlatformType();
    props.params["ver"] = getVersion();
    var methodStr = props.params["method"];
    var url = domain + endPoint ;

    const isShareV2 = Object.hasOwn(props.endpoint, "paramValue") && (props.endpoint as JeomsinEndPoint).paramValue === "set.sns.content.v2";

    if (
        endPoint.includes('jsp') 
        || endPoint.includes('xml_') 
        || endPoint.includes('api.fortune.report') 
        || endPoint.includes('coupangAdReco') 
        || isShareV2 === true
    ) {
        url = domain + endPoint ;
    } else if(endPoint.includes('adReco/')) {
        url = domain + endPoint + `?adid=${props.params["adid"]}&subid=${props.params["subid"]}&size=${props.params["size"]}`;
    } else {
        url = domain + endPoint + "?method=" + methodStr

        const aesHelper = EncryptAesHelper.getInstance();

        const { method, updatedParams } = processParams(props.params);

        // props.params 업데이트
        props.params = updatedParams;
        // 암호화 예제

        var encryptedData = await aesHelper.encryptAES(JSON.stringify(props.params));
        var data = encryptedData["encrypt"];
        var iv = encryptedData["iv"];

        // 암호화된 data와 iv를 props.params에 남기기
        props.params = {
            data: encryptedData["encrypt"],
            iv: encryptedData["iv"],
        };

        // var decryptedData = await aesHelper.decryptAES(data, iv);
        // console.log(" orignal decrypted Data: 4 ", decryptedData);

        // aesHelper.encryptAES(JSON.stringify(props.params)).then((result) => {
        // console.log("Encrypted Data:", result);
        // });
    }

    return new Promise((resolve, reject) => {
        fetch(url, {
            method: JeomsinMethod.Post,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            body: new URLSearchParams(props.params),
        }
        ).then(
            async response => {
                if (!response.ok) {
                    throw Error("could not fetch the data that resource");
                }

                if (props.endpoint.returnValueType === JeomsinReturnValueType.XML) {
                    const xmlText = await response.text();
                    const parser = new XMLParser();
                    const jsonObj = parser.parse(xmlText);
                    return jsonObj;
                } else {
                    return response.json();
                }
            }
        ).then(data => {
            resolve(data);
        }).catch(error => {
            console.log("error msg : " + error);
            reject(error);
        });
    });
}
function processParams(params: Record<string, any>): { method: string | undefined; updatedParams: Record<string, any> } {
    // method 값 추출
    const { method, ...updatedParams } = params;

    // 로그 출력
    // console.log(`method: ${method}`);
    // console.log(`updatedParams:`, updatedParams);

    // 결과 반환
    return { method, updatedParams };
}
const MultipartPost = async (props: RequestProps) => {
    var domain: string | undefined;

    if (debugMode) {
        domain = props.domain.debug;
    } else {
        domain = props.domain.release;
    }

    if (props.domain == JeomsinDomain.JeomsinXml) {
        domain += (CookieManager.GetString(CookieKeys.fortuneUrl) ?? props.domain.value) + "/";
    } else {
        domain += props.domain.value;
    }

    var endPoint: string = props.endpoint.value;

    if (props.endpoint.type === JeomsinEndPointType.Param) {
        props.params[props.endpoint.paramKey] = props.endpoint.paramValue;
    }

    props.params["os"] = getPlatformType();
    props.params["ver"] = getVersion();

    var url = domain + endPoint;

    var methodStr = props.params["method"];
    var url = domain + endPoint ;

    if (endPoint.includes('jsp') || endPoint.includes('xml_') || endPoint.includes('api.fortune.report') || endPoint.includes('coupangAdReco')) {
        url = domain + endPoint ;
    } else {
        url = url = domain + endPoint + "?method=" + methodStr

        const aesHelper = EncryptAesHelper.getInstance();

        const { method, updatedParams } = processParams(props.params);

        // props.params 업데이트
        props.params = updatedParams;

        // 암호화 예제

        var encryptedData = await aesHelper.encryptAES(JSON.stringify(props.params));

        var data = encryptedData["encrypt"];
        var iv = encryptedData["iv"];

        // 암호화된 data와 iv를 props.params에 남기기
        props.params = {
            data: encryptedData["encrypt"],
            iv: encryptedData["iv"],
        };

        // aesHelper.encryptAES(JSON.stringify(props.params)).then((result) => {
        // console.log("Encrypted Data:", result);
        // });
    }

    const formData = new FormData();

    Object.keys(props.params).forEach((key, index) => {
        formData.append(key, props.params[key]);
    });

    return new Promise((resolve, reject) => {
        fetch(url, {
            method: JeomsinMethod.Post,
            body: formData,
        }
        ).then(
            async response => {
                if (!response.ok) {
                    throw Error("could not fetch the data that resource");
                }

                if (props.endpoint.returnValueType === JeomsinReturnValueType.XML) {
                    const xmlText = await response.text();
                    const parser = new XMLParser();
                    const jsonObj = parser.parse(xmlText);
                    return jsonObj;
                } else {
                    return response.json();
                }
            }
        ).then(data => {
            resolve(data);
        }).catch(error => {
            console.log("error msg : " + error);
            reject(error);
        });
    });
}

const xmlToJson = (response: string) => {
    const parser = new XMLParser();
    const jsonObj = parser.parse(response);

    return "";
}

export { JeomsinRequest, JeomsinMultipartRequest };
