/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ReactElement, useEffect, useState } from "react";

export default function AsyncPageEachPage({
  content,
  contentIndex,
}: {
  content: {
    removalId: string;
    isHidden: boolean;
    isAnimated: boolean;
    isScreenFilled: boolean;
    element: ReactElement;
    resolver: (returned: any) => void;
  };
  contentIndex: number;
}) {
  const [animatedHidden, setAnimatedHidden] = useState(true);
  const hidden = content.isHidden || animatedHidden;

  useEffect(() => {
    setAnimatedHidden(false);
  }, []);

  const nonFullscreen = css`
    position: fixed;
    width: clamp(320px, 100vw, 480px); 
    height: 100%;
    z-index: ${5_000 + contentIndex};
    top: 0;
    overflow: hidden;
    clip-path: polygon(0-1000%, 100%-1000%, 100% 1100%, 0 1100%);
  `;

  const fullscreen = css`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: ${5_000 + contentIndex};
    clip-path: polygon(0-1000%, 100%-1000%, 100% 1100%, 0 1100%);
  `;

  return (
    <div css={content.isScreenFilled ? fullscreen : nonFullscreen}>
      <div
        css={css`
          width: 100%;
          height: 100%;
          transform: translateX(${hidden ? "100%" : "0%"});
          transition: ${content.isAnimated ? "transform 500ms" : "none"};
        `}
      >
        {content.element}
      </div>
    </div>
  );
}
