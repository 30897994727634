import { useCoupangAdViewStore } from "@Views/web_ad/use_coupang_ad_view_store";
import CookieKeys from "./cookie_keys";
import CookieManager from "./cookie_manager";

// TODO: Change the coupang ad time. IMPORTANT!!
const coupangAdTime = 0.083;
const coupangAdFreeTime = 0.083;

enum AdStatus {
  showCoupangAd = "showCoupangAd",
  showNetworkAd = "showNetworkAd",
  showDetailsWithoutInterstitialAd = "showDetailsWithoutInterstitialAd", //미사용
  adFree = "adFree",
}

function checkAdStatus(): AdStatus {
  // IF USER WATCHED COUPANG AD AND PROMISED PARTIAL AD FREE EXPERIENCE FOR CERTAIN DURATION
  // 쿠팡광고를 보고 온 경우
  if (checkCoupangAcceptTimePassedSince() === false) {
    return AdStatus.showNetworkAd;
  }

  // // IF USER REJECTED COUPANG AD, SHOW NETWORK ADS
  // // Close를 눌러서 쿠팡광고를 닫은 경우
  if (checkCoupangRejectionTimePassedSince() === false) {
    return AdStatus.showNetworkAd;
  }

  // IF USER NEITHER REJECTED NOR ACCEPTED THE COUPANG ADS, SHOW COUPANG ADS
  return AdStatus.showCoupangAd;
}

function userRejectedCoupangAd(): void {
  const currentTime = new Date().toISOString();
  CookieManager.SetString(CookieKeys.coupangAdRejectionTime, currentTime);
}

function userAcceptedCoupangAd(): void {
  const currentTime = new Date().toISOString();
  CookieManager.SetString(CookieKeys.coupangAdAcceptTime, currentTime);
}

function checkCoupangRejectionTimePassedSince(): boolean {
  const savedTime = CookieManager.GetString(CookieKeys.coupangAdRejectionTime);
  if (savedTime) {
    return checkTimePassed(savedTime);
  } else {
    return true;
  }
}

function checkCoupangAcceptTimePassedSince(): boolean {
  const savedTime = CookieManager.GetString(CookieKeys.coupangAdAcceptTime);
  if (savedTime) {
    return checkTimePassed(savedTime);
  } else {
    return true;
  }
}

function removeCoupangRejectionSavedTime(): void {
  CookieManager.RemoveKeyValue(CookieKeys.coupangAdRejectionTime);
}

function removeCoupangAcceptSavedTime(): void {
  CookieManager.RemoveKeyValue(CookieKeys.coupangAdAcceptTime);
}

function checkTimePassed(savedTime: string): boolean {
  const currentTime = new Date();
  const savedDate = new Date(savedTime);
  const elapsedHours =
    (currentTime.getTime() - savedDate.getTime()) / (1000 * 60);
  return elapsedHours >= useCoupangAdViewStore.getState().getCoupangWaitTime();
}

export {
  checkAdStatus,
  userRejectedCoupangAd,
  userAcceptedCoupangAd,
  checkCoupangRejectionTimePassedSince,
  checkCoupangAcceptTimePassedSince,
  removeCoupangRejectionSavedTime,
  removeCoupangAcceptSavedTime,
  AdStatus,
};
