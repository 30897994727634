import CookieKeys from "@Storage/cookie_keys";
import CookieManager from "@Storage/cookie_manager";
import { downloadCsv, parseCsv } from "@Util/csv_reader";

type DreamDataModel = {
    idx:string, 
    tab_index:string, 
    depth1:string,
    depth2:string, 
    depth3:string, 
    dream:string, 
    comment:string
}

const saveSortDreamData = () => {
    const data = getAllDreamData();
    const array:any = [];

    data.forEach((value:any, index:number) => {
        if (!array[value.tab_index]) array[value.tab_index] = [];
        if (!array[value.tab_index][value.depth1]) array[value.tab_index][value.depth1] = [];
        if (!array[value.tab_index][value.depth1][value.depth2]) array[value.tab_index][value.depth1][value.depth2] = [];
        if (!array[value.tab_index][value.depth1][value.depth2][value.depth3]) array[value.tab_index][value.depth1][value.depth2][value.depth3] = [];
        
        value.dream = value.dream.replace("@comma", ",");
        value.comment = value.comment.replace("@comma", ",");
        array[value.tab_index][value.depth1][value.depth2][value.depth3][value.idx] = value;
    });

    CookieManager.SetJSON(CookieKeys.dreamSortedCSVData, array);
    return array;
};

const getAllDreamData = () => {
    const data = CookieManager.GetJSON(CookieKeys.dreamCSVData);

    return data;
};

const getSortedDreamData = (tabIndex:number, depth1:number, depth2:number, depth3:number) => {
    const data = CookieManager.GetJSON(CookieKeys.dreamSortedCSVData);

    return data[tabIndex][depth1][depth2][depth3];
};

const downloadDreamData = async (version:string, path:string) => {
    let csvData = await downloadCsv(path); //서버 파일 받아오기

    if(!csvData) { //로컬 파일 읽기
        csvData = await readDefaultDereamCsv();
    }

    if(csvData) {
        CookieManager.SetString(CookieKeys.dreamCSVVersion, version);
        CookieManager.SetJSON(CookieKeys.dreamCSVData, csvData);
        saveSortDreamData();
    }
}

const readDefaultDereamCsv = async () => {
    let result;
    try {
      const response = await fetch('/dream/dream_ver_5.csv');
      if (!response.ok) {
        throw new Error('Failed to read default file');
      }
  
      result = await parseCsv(await response.text());
    } catch (err:any) {
      console.log(JSON.stringify(err.message));
    }

    return result;
};

const searchDreamText = async (searchText:string) => {
    const data:DreamDataModel[] = await getAllDreamData();
    const hitDataList:DreamDataModel[] = [];

    data.forEach((value, index) => {
        if(value.dream.indexOf(searchText) > -1) {
            hitDataList.push(value);
        }
    });

    return hitDataList;
};
  
  const checkCsvVersion = (serverLastVersion:string) => {
        let requireCSVUpdate = false;
        const localLastVersion = CookieManager.GetString(CookieKeys.dreamCSVVersion) ?? 0;
        
        if( (localLastVersion == 0) 
            || (localLastVersion < serverLastVersion)) {
            requireCSVUpdate = true;
        }
        
        return requireCSVUpdate;
  };

export {
    getAllDreamData,
    getSortedDreamData,
    downloadDreamData,
    searchDreamText,
    checkCsvVersion
}

export type {
    DreamDataModel
}