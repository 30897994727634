import { getJeomsinColor, JeomsinColors } from "@Config/jeomsin_colors";
import { CoupangRectAdDataModel } from "@Models/coupang_config/coupang_ad_data_model";
import { Box } from "@mui/material";
import { userAcceptedCoupangAd } from "@Storage/coupang_ad_time_manager";
import { VerticalDivider } from "@Views/common_component/vertical_divider/vertical_divider";
import FlexBox, { FlexAlignItemsType, FlexDirectionType, FlexJustifyContentType } from "@Views/layer/flex_box/flex_box";
import { FC, useEffect, useState } from "react";
import CoupangRectAdBox from "../coupang_rect_ad_box/couapng_rect_ad_box";
import css from "./first_running_coupang_ad.module.css";
import { JeomsinAdType } from "@Views/web_ad/web_ad_data";

interface FristRunnginCoupangAdBoxProps {
    adType:JeomsinAdType,
    adData:CoupangRectAdDataModel|undefined,
    isLoaded:boolean,
    onClose?:() => void;
}

const FristRunnginCoupangAdBox:FC<FristRunnginCoupangAdBoxProps> = ({
    adType,
    adData,
    isLoaded,
    onClose,
}) => {
    const [closeButtonTimer, setCloseButtonTimer] = useState<number>(5);

    useEffect(() => {    
        const timer = setInterval(() => {
          if (closeButtonTimer < 0) {
            return;
          }
    
          setCloseButtonTimer((leftTime) => leftTime - 1);
        }, 1000);
    
        return () => {
          clearInterval(timer);
        };
    }, [closeButtonTimer]);

    return (
      <>
        {
          adData ? (
            <Box className={`${isLoaded ? "" : "none"}`} 
            onClick={(event) => {
                window.open(adData?.productUrl);
                userAcceptedCoupangAd();
                if(onClose) onClose();
            }}
            >
              <CoupangRectAdBox adData={adData}/>
              <FlexBox
                class={css.button_area}
                direction={FlexDirectionType.row}
                alignItems={FlexAlignItemsType.center}
                justifyContent={FlexJustifyContentType.spaceBetween}
              >
                <FlexBox
                  direction={FlexDirectionType.row}
                  alignItems={FlexAlignItemsType.center}
                  justifyContent={FlexJustifyContentType.spaceBetween}
                  gap={30}
                >
                  <div className={css.close_btn} onClick={(event) => { 
                    event.stopPropagation();
                    if(onClose) onClose(); 
                  }}>닫기</div>
                  <VerticalDivider height={15} color={getJeomsinColor(JeomsinColors.Line03)}/>
                </FlexBox>
                <div className={css.coupang_btn}>{adData.btnName ?? "무료운세 보기"}</div>
              </FlexBox>
            </Box>
          ) : <></>
        }
      </>
    )
}


export default FristRunnginCoupangAdBox;