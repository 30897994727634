import { CoupangPageCode } from "@Models/coupang_config/coupang_config_model";
import css from "./first_running_coupang_ad_popup_box.module.css";
import CoupangRectAdView from "@Views/web_ad/counpang_rect_ad_view/coupang_rect_ad_view";
import { useEffect, useState } from "react";
import { useCoupangAdViewStore } from "@Views/web_ad/use_coupang_ad_view_store";

interface FirstRunningCoupangAdPopupBoxProps {
  onLoad:(isLoaded:boolean) => void;
  onClose?: () => void;
};

const FirstRunningCoupangAdPopupBox: React.FC<FirstRunningCoupangAdPopupBoxProps> = ({
  onLoad,
  onClose
}) => {
  const {coupangConfig} = useCoupangAdViewStore();
  return (
      <>
      {
        coupangConfig ? (
          <div className={css.modal_content}>
            <CoupangRectAdView 
              coupangPageCode={CoupangPageCode.FirstRunning} 
              onLoad={(isLoaded) => {
                onLoad(isLoaded);
              }}
              onClose={()=>{ 
                if(onClose) {
                  onClose();
                }
              }}
            />
          </div>
        ) : <></>
      }
      </>
    );
};

export { FirstRunningCoupangAdPopupBox };
