import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface ZARSAndTextPrice {
  textPricing: number | undefined;
  setTextPricing: (pricing: number | undefined) => void;

  arsPricing: number | undefined;
  setARSPricing: (pricing: number | undefined) => void;

  arsSecPricing: number | undefined;
  setARSSecPricing: (pricing: number | undefined) => void;
}

export const useARSAndTextPricing = create(
  persist<ZARSAndTextPrice>(
    (set) => ({
      textPricing: undefined,
      setTextPricing: (pricing: number | undefined) =>
        set({ textPricing: pricing }),

      arsPricing: undefined,
      setARSPricing: (pricing: number | undefined) =>
        set({ arsPricing: pricing }),

      arsSecPricing: undefined,
      setARSSecPricing: (pricing: number | undefined) =>
        set({ arsSecPricing: pricing }),
    }),
    {
      name: "ars-and-text-pricing-store",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
