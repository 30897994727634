import { JeomsinPagePath } from '@Config/jeomsin_page_path';
import { JeomsinPageUIProps } from '@Config/jeomsin_page_routes';
import { AstroDatas, getAstroInfo } from '@DataDefine/astro_info';
import { GetPageInfo, JeomsinPageInfo } from '@DataDefine/page_code';
import { getChineseZodiac, ZodiacDatas } from '@DataDefine/zodiac_info';
import usePageLanding, { LandingType } from '@Hooks/page_landing_util';
import apiGetMZMainList, { AllMenuListModel, DreamHaemongListModel, MainMenuListModel, MZKeyVisualBannerListModel, RecommendUnseListModel } from '@Network/request/api_get_mz_main_list';
import apiGetPoomangList, { PoomangModel } from '@Network/request/api_get_poomang_list';
import apiSetSNSContentV3, { SharableContent, SharableContents } from '@Network/request/api_set_sns_content_v3';
import { useUserInfoStore } from '@State/user_info_store';
import { snsContentXMLDataDream, triggerCopyingShareURL } from '@Util/sns_share_xml_util';
import AppLandingButton, { useAppLandingPopup } from '@Views/app_landing_button/app_landing_button';
import { useModalPopup } from '@Views/common_component/async_modal_popup/async_modal_popup';
import { CompanyInfo } from '@Views/common_component/company_info/company_info';
import { CustomSlider } from '@Views/common_component/custom_slider/custom_slider';
import DimLayer, { useDimmedStore } from '@Views/common_component/dim_layer/dim_layer';
import { Spacing, SpacingType } from '@Views/common_component/spacer/spacing';
import { useToastPopupStore } from '@Views/common_component/toast_popup/toast_popup_manager';
import { BottomNavigationType, BottomTabMenu } from '@Views/layer/bottom_navigation/bottom_navigation';
import { JeomsinPageType } from '@Views/layer/header/header';
import ModalPopup, { ModalPopupType } from '@Views/layer/modal_popup/modal_popup';
import PageBody from '@Views/layer/page_body/page_body';
import { useLoginHandler } from '@Views/member/login_handler/login_handler';
import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useRef, useState } from 'react';
import { NavigateOptions, useNavigate, useNavigationType } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import css from './fortune.module.css';
import RecommendUnseViewList from './sub_component/recommend_unse_view_list/reommend_unse_view_list';
import SimliTestViewList from './sub_component/simli_test_view_list/simli_test_view_list';
import { useFortuneStore } from './use_fortune_store';
import useShowMemberIntegratedWelcomeDialog from './use_welcome_modal_popup';
import FlexBox, { FlexDirectionType } from '@Views/layer/flex_box/flex_box';
import { useGeometryReader } from '@Hooks/use_geometry_reader';
import Slider from 'react-slick';

function Fortune({ headerType, bottomNavParam }: JeomsinPageUIProps) {
  const navigate = useNavigate();
  const {setDimLayerOpen} = useDimmedStore();
  const [isShowHaemongPopup, setShowHaemongPopup] = useState(false);
  const [selectedDreamData, setSelectedDreamData] = useState<DreamHaemongListModel | undefined>();
  
  const [mzKeyVisualBannerList, setMZKeyVisualBannerList] = useState<MZKeyVisualBannerListModel[] | undefined>();
  const [recommendUnseList, setRecommendUnseList] = useState<RecommendUnseListModel[] | undefined>();
  const [dreamHaemongList, setDreamHaemongList] = useState<DreamHaemongListModel[] | undefined>();
  const [allMenuList, setAllMenuList] = useState<AllMenuListModel[] | undefined>();
  const [mainMenuList, setMainMenuList] = useState<MainMenuListModel[] | undefined>();
  const [poomangList, setPoomangList] = useState<PoomangModel[] | undefined>();
  const { showAppLandingPopup } = useAppLandingPopup();

  const prescriptionMenuList = [
    JeomsinPageInfo.D10070,
    JeomsinPageInfo.D10085,
    JeomsinPageInfo.D10035,
    JeomsinPageInfo.D10082,
    JeomsinPageInfo.D10056,
    JeomsinPageInfo.D10047
  ];

  const talentMenuList = [
    JeomsinPageInfo.D10039,
    JeomsinPageInfo.D10043,
    JeomsinPageInfo.D10091,
  ];

  const birthMenuList = [
    JeomsinPageInfo.D10050,
    JeomsinPageInfo.D10053,
    JeomsinPageInfo.D10103,
    JeomsinPageInfo.D10107,
    JeomsinPageInfo.D10099,
    JeomsinPageInfo.D10079,
  ];

  const loveMenuList = [
    JeomsinPageInfo.D10137,
    JeomsinPageInfo.D10031,
    JeomsinPageInfo.D10076,
    JeomsinPageInfo.D10073,
    JeomsinPageInfo.D10059,
    JeomsinPageInfo.D10095,
  ];

  const getZodiacImageInfo = (zodiac:string) => {
    let result:string;

    //Check 호랑이와 범이 나뉜 이유?
    switch(zodiac) {
      case ZodiacDatas.Mouse.key:
        result = 'images/common/chinese_zodiac/2.0x/birth_menu_mouse.png';
        break;
      case ZodiacDatas.Cow.key:
        result = 'images/common/chinese_zodiac/2.0x/birth_menu_cow.png';
        break;
      case ZodiacDatas.Tiger.key:
        result = 'images/common/chinese_zodiac/2.0x/birth_menu_tiger.png';
        break;
      case ZodiacDatas.Rabbit.key:
        result = 'images/common/chinese_zodiac/2.0x/birth_menu_rabbit.png';
        break;
      case ZodiacDatas.Dragon.key:
        result = 'images/common/chinese_zodiac/2.0x/birth_menu_dragon.png';
        break;
      case ZodiacDatas.Snake.key:
        result = 'images/common/chinese_zodiac/2.0x/birth_menu_snake.png';
        break;
      case ZodiacDatas.Horse.key:
        result = 'images/common/chinese_zodiac/2.0x/birth_menu_horse.png';
        break;
      case ZodiacDatas.Sheep.key:
        result = 'images/common/chinese_zodiac/2.0x/birth_menu_sheep.png';
        break;
      case ZodiacDatas.Monkey.key:
        result = 'images/common/chinese_zodiac/2.0x/birth_menu_monkey.png';
        break;
      case ZodiacDatas.Chicken.key:
        result = 'images/common/chinese_zodiac/2.0x/birth_menu_chicken.png';
        break;
      case ZodiacDatas.Dog.key:
        result = 'images/common/chinese_zodiac/2.0x/birth_menu_dog.png';
        break;
      case ZodiacDatas.Pig.key:
        result = 'images/common/chinese_zodiac/2.0x/birth_menu_pig.png';
        break;
      default:
        result = 'images/common/chinese_zodiac/2.0x/birth_menu_mouse.png';
        break;
    }

    return result;
  }

  const getAstroImageInfo = (astro:string) => {
    let result:string;

    //Check 궁수랑 사수가 나뉜 이유는?
    switch(astro) {
      case AstroDatas.WaterBottle.key:
        result = 'images/common/zodiac/birth_menu_aquarius.png';
        break;
      case AstroDatas.Fish.key:
        result = 'images/common/zodiac/birth_menu_pisces.png';
        break;
      case AstroDatas.Sheep.key:
        result = 'images/common/zodiac/birth_menu_aries.png';
        break;
      case AstroDatas.Bull.key:
        result = 'images/common/zodiac/birth_menu_taurus.png';
        break;
      case AstroDatas.Twins.key:
        result = 'images/common/zodiac/birth_menu_gemini.png';
        break;
      case AstroDatas.Crab.key:
        result = 'images/common/zodiac/birth_menu_cancer.png';
        break;
      case AstroDatas.Lion.key:
        result = 'images/common/zodiac/birth_menu_leo.png';
        break;
      case AstroDatas.Virgin.key:
        result = 'images/common/zodiac/birth_menu_virgo.png';
        break;
      case AstroDatas.Balance.key:
        result = 'images/common/zodiac/birth_menu_libra.png';
        break;
      case AstroDatas.Scorpion.key:
        result = 'images/common/zodiac/birth_menu_scorpio.png';
        break;
      case AstroDatas.Archer.key:
        result = 'images/common/zodiac/birth_menu_sagittarius.png';
        break;
      case AstroDatas.Goat.key:
        result = 'images/common/zodiac/birth_menu_capricorn.png';
        break;
      default:
        result = 'images/common/zodiac/birth_menu_aquarius.png';
        break;
    }
    return result;
  }

  const { 
    representativeUserInfo,
    getRepresentativeUserAgeRange,
    defaultUserInfo
  } = useUserInfoStore();
  
  const { 
    scrollYPosition,
    doScrollTo,
    getPrevScrollYPostion, 
    saveScrollYPosition 
  } = useFortuneStore();

  const divRef = useRef<HTMLDivElement>(null);

  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [modalPopup, setModelPopup] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [selectedUnseMenu, setSelectedUnseMenu] = useState<MainMenuListModel | undefined>(undefined);
  const [zodiacImageIcon, setZodiacImageIcon] = useState<string | undefined>();
  const [astroImageIcon, setAstroImageIcon] = useState<string | undefined>();

  const [menuDrag, setMenuDrag] = useState<boolean>(false);
  const navigationType = useNavigationType();
  const isSlideDragging = useRef(false);
  const [scrollTo, setScrollTo] = useState<number>(scrollYPosition);
  const { landingPage } = usePageLanding();
  const { setToastPopupContent } = useToastPopupStore();

  const getData = useCallback(async () => {
    
    let brithDate = new Date();
    if(representativeUserInfo) {
      brithDate.setFullYear(Number(representativeUserInfo.birthYear));
      brithDate.setMonth(Number(representativeUserInfo.birthMonth)-1);
      brithDate.setDate(Number(representativeUserInfo.birthDay));
    } else {
      brithDate.setFullYear(0);
      brithDate.setMonth(0);
      brithDate.setDate(0);
    }

    setZodiacImageIcon(getZodiacImageInfo(getChineseZodiac(brithDate.getFullYear())[3]));
    setAstroImageIcon(getAstroImageInfo(getAstroInfo(brithDate)[4]));

    apiGetMZMainList(representativeUserInfo).then((result) => {
      setMZKeyVisualBannerList(result?.banner_list);
      setRecommendUnseList(result?.recommend_unse_list);
      setDreamHaemongList(result?.dream_haemong_list);
      setAllMenuList(result?.all_menu_list);
      setMainMenuList(result?.main_menu_list.filter((value, index) => !(index == 3 || index == 7)));
    });
    
    /* 2025.03.04 사업부 요청으로 Web버전에서 심리테스트 미노출로 변경 */
    // apiGetPoomangList(representativeUserInfo).then((result) => {
    //   setPoomangList(result.poomang_list);
    // });
  }, [representativeUserInfo]);

  useEffect(() => {
    headerType({type: JeomsinPageType.MainTab});
    bottomNavParam({type:BottomNavigationType.Tab, selectedTab:BottomTabMenu.FortuneHome});

    if(navigationType === "PUSH") { //뒤로가기가 아닌 지정으로 페이지에 진입 시 이전 페이지에서 doScroll여부 체크하여 스크롤
      if(doScrollTo) {
        setScrollTo(getPrevScrollYPostion());
      }
    } else if(navigationType === "POP") { //뒤로가기로 페이지 진입 시 무조건 스크롤 위치로 복귀
      setScrollTo(getPrevScrollYPostion());
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const { showMemberIntegratedWelcomeDialog } = useShowMemberIntegratedWelcomeDialog();

  // 깃헙페이지의 경우, 정적페이지의 특성상 라우팅이 불가능.
  // 그런 경우를 위해 홈페이지에서도 로그인 정보를 받아서 처리할 수 있도록 기능을 추가함.
  useLoginHandler();
  const { setModalPopupContent } = useModalPopup();
  const { getSize } = useGeometryReader();

  const pageMove = (toPage:JeomsinPagePath, option?:NavigateOptions) => {
    if(representativeUserInfo) {
      saveScrollYPosition();
      navigate(toPage, option);
    } else {
      showMemberIntegratedWelcomeDialog(true);
    }
  }

  return (
    <>
    <PageBody 
      ref={divRef} 
      class={`main-contents ${css.fortune_wrap}`} 
      bottomBlank={0}
      scrollTo={scrollTo}
    >
      <div className={css.title_area}>
        <span className={css.title}>운세</span>
        <div className={css.tab_right} onClick={()=> { 
          pageMove(JeomsinPagePath.SajuMultiInfomation);
        }}>
            <div className={css.tab_right_text}>
                <span className="mr5">선택</span>
                <span className="mr5">{representativeUserInfo ? representativeUserInfo.name : "게스트"}님</span>
            </div>
            <img src='images/common/ic_user_change.png'/>
        </div>
      </div>  
      <div 
        className={css.slider_container}
        style={{
          height: `calc(170px + (265 - 170) * ((${getSize().width}px - 320px) / (480 - 320)))`
        }}
      >
        {
        mzKeyVisualBannerList ?
          <CustomSlider 
            infinite={mzKeyVisualBannerList.length > 1} 
            speed={500} 
            aspectRatio='375/230'
            onDrag={(isDragging) => isSlideDragging.current = isDragging}
          >
          {mzKeyVisualBannerList.map((value, index) =>
            <div key={index} className={css.fortune_main_banner} aria-hidden="true" tabIndex={-1} onClick={() => {
              if(!isSlideDragging.current) {
                  saveScrollYPosition();
                  const landingType = value.landing_type === "B" ? LandingType.Normal : LandingType.AllMenu;
                  landingPage({landingType:landingType, pageInfo:GetPageInfo(value.page_code), landingUrl:value.page_code});
              }
            }}>
              <img src={value.image} alt='banner'></img>
            </div> 
          )} 
          </CustomSlider>
          : 
          <div className={css.fortune_main_banner_loading}>
            <CircularProgress />
          </div> 
        }
      </div>
      <div>
        <Grid className={css.menu_box} container columns={18}>
          <Grid className={css.text_area} xs={18} item={true}>
            <Typography className={css.sub_text} component="p">
            소름 돋는 미래 예측
            </Typography>
            <Typography className={css.main_text} component="span">
            가장 정확한 사주 풀이
            </Typography>
          </Grid>
          <Slider
            className={css.menu_box_slide_wrap}
            infinite={false} 
            speed={500} 
            dots={false}
            slidesPerRow={3}
            rows={2}
            centerPadding= {"0px"}
            swipe={((mainMenuList?.length ?? 0) > 6) ? true : false}
            swipeEvent={()=> {
              setMenuDrag(true)
              setTimeout(() => {
                setMenuDrag(false);
              }, 600);
            }}
          >
          {
            
            mainMenuList?
            mainMenuList.map(((value, index) => 
              <Grid className={css.type1} xs={6} item={true} key={index}>
                <div className={css.box_item} onClick={()=> {
                    if(menuDrag) return;

                    if(representativeUserInfo 
                      && (value.page_code === JeomsinPageInfo.D10011.pageCode)) {
                      setSelectedUnseMenu(value);
                      setShowCalendar(true);
                    } else {
                      pageMove(JeomsinPagePath.SajuMyeongsik, {state: { menuInfo:value }});
                    }
                  }}
                >
                  <img src={value.image} alt='menu'/>
                  <span>{value.page_code_name}</span>
                </div>
              </Grid>
            )) :
            Array(6).fill(undefined).map((value, index) => 
              <Grid className={css.type1} xs={6} item={true} key={index}>
              <div className={css.box_item}>
                <CircularProgress />
              </div>
            </Grid>
            )
          }
          </Slider>
        </Grid>
      </div>
      <Spacing direction={SpacingType.vertical} gap={16}/>
      {/* 2025.03.04 사업부 요청으로 Web버전에서 심리테스트 미노출로 변경 */}
      {/* {
        (poomangList && poomangList.length > 0) ? (
          <>
          <div>
            <Grid className={`${css.menu_box} ${css.none_padding}`} container columns={18}>
              <Spacing direction={SpacingType.vertical} gap={16}/>
              <Grid className={`${css.text_area} pl24 pr24`} xs={18} item={true}>
                <FlexBox direction={FlexDirectionType.column}>
                  <Typography className={css.sub_text} component="p">
                  심리테스트
                  </Typography>
                  <Typography className={css.main_text} component="span">
                    <div className={css.app_content_title_wrap}>
                      마음을 분석하는 시간
                      <AppLandingButton/>
                    </div>
                  </Typography>
                </FlexBox>
              </Grid>
              <SimliTestViewList dataList={poomangList}/>  
              <Spacing direction={SpacingType.vertical} gap={16}/>
            </Grid>
          </div>
          <Spacing direction={SpacingType.vertical} gap={16}/>
          </>
        ) : <></>
      } */}
      {
        (
          defaultUserInfo() 
          && recommendUnseList 
          && recommendUnseList.length > 0 
          && getRepresentativeUserAgeRange() >= 10
        ) ? (
          <>
          <RecommendUnseViewList dataList={recommendUnseList ?? []}/>
          <Spacing direction={SpacingType.vertical} gap={16}/>
          </>
        ) : <></>
      }
      <div>
        <Grid className={css.menu_box} container columns={18}>
          <Grid className={css.text_area} xs={18} item={true}>
            <Typography className={css.sub_text} component="p">
            액운 방지
            </Typography>
            <Typography className={css.main_text} component="span">
            손쉽게 행운을 얻는 방법
            </Typography>
          </Grid>
          {
            prescriptionMenuList.map(((value, index) => {
              const convertedData:MainMenuListModel = {
                page_code_name: value.pageName,
                page_code: value.pageCode,
                image: value.imagePath ?? ""
              }

              let toPage:JeomsinPagePath|undefined;

              // 살풀이 및 행운의 번호의 경우, 사주명식 페이지 이전에 소개 페이지 먼저 진입
              if (value.pageCode === JeomsinPageInfo.D10070.pageCode) {
                toPage = JeomsinPagePath.LottoFortuneIntro;
              } else if(value.pageCode === JeomsinPageInfo.D10085.pageCode){
                toPage = JeomsinPagePath.SajuMyeongsik;
              } else if(value.pageCode === JeomsinPageInfo.D10035.pageCode) {
                toPage = JeomsinPagePath.Salpuri;
              } else if(value.pageCode === JeomsinPageInfo.D10056.pageCode) {
                toPage = JeomsinPagePath.HouseMove;
              }

              return <Grid className={css.type2} xs={18} item={true} key={index} onClick={()=> {
                if(toPage) {
                  pageMove(toPage, {state: { menuInfo:convertedData }});
                } else {
                  showAppLandingPopup();
                }
              }}>
                <div className={css.box_item}>
                  <div className={css.img_box}>
                    <img src={value.imagePath} alt="menu"/>
                  </div>
                  <div className={css.text_box}>
                    <div className={css.title}>
                      <span>{value.pageName}</span>
                      {toPage === undefined && <AppLandingButton disableClick={true} />}
                    </div>
                    <span className={css.description}>{value.desc}</span>
                  </div>
                </div>
              </Grid>
            }))
          }
        </Grid>
      </div>
      <Spacing direction={SpacingType.vertical} gap={16}/>
      <div>
        <Grid className={css.menu_box} container columns={18} item={true}>
          <Grid className={css.text_area} xs={18} item={true}>
            <Typography className={css.sub_text} component="p">
            취업과 진로
            </Typography>
            <Typography className={css.main_text} component="span">
            내게 맞는 직업 찾기
            </Typography>
          </Grid>
          {
            talentMenuList.map(((value, index) => 
              <Grid className={css.type1} xs={6} item={true} key={index}>
                <div className={css.box_item} onClick={()=> {
                      const convertedData:MainMenuListModel = {
                        page_code_name: value.pageName,
                        page_code: value.pageCode,
                        image: value.imagePath ?? ""
                      }
                      pageMove(JeomsinPagePath.SajuMyeongsik, {state: { menuInfo:convertedData }});
                  }}
                >
                  <img src={value.imagePath} alt='menu'/>
                  <span>{value.pageName}</span>
                </div>
              </Grid>
            ))
          }
        </Grid>
      </div>
      <Spacing direction={SpacingType.vertical} gap={16}/>
      <div>
        <Grid className={css.menu_box} container columns={18}>
          <Grid className={css.text_area} xs={18} item={true}>
            <Typography className={css.sub_text} component="p">
            소원을 이뤄요
            </Typography>
            <Typography className={css.main_text} component="span">
            지금 필요한 해결책
            </Typography>
          </Grid>
          <Grid className={`${css.type3} pt10 pb5 pr5`} xs={9} item={true}>
            <div className={css.box_item} onClick={() => showAppLandingPopup()}>
              <div className={css.text_box}>
                <span className={css.title}>소원담벼락<AppLandingButton disableClick={true} /></span>
                <span className={css.description}>함께 비는 소원은<br/>더 빨리 이뤄져요</span>
              </div>
              <img src="/images/fortune_main/candle_icon.png"/>
            </div>
          </Grid>
          <Grid className={`${css.type3} pt10 pb5`} xs={9} item={true}>
            <div className={css.box_item} onClick={() => showAppLandingPopup()}>
              <div className={css.text_box}>
                <span className={css.title}>고민구슬<AppLandingButton disableClick={true} /></span>
                <span className={css.description}>머리 아픈 고민!<br/>3초만에 끝내기</span>
              </div>
              <img src="/images/fortune_main/crystal_icon.png"/>
            </div>
          </Grid>
          <Grid className={`${css.type3} pb10 pr5`} xs={9} item={true}>
            <div className={css.box_item} onClick={() => {
                  if(representativeUserInfo) {
                    navigate(BottomTabMenu.CouonselHome.path);
                  } else {
                    showMemberIntegratedWelcomeDialog(true);
                  }
            }}>
              <div className={css.text_box}>
                <span className={css.title}>점신1:1상담</span>
                <span className={css.description}>혼자 해결하기 어려운 문제가 있다면</span>
              </div>
              <img src="/images/fortune_main/chat_bubble_icon.png"/>
            </div>
          </Grid>
          <Grid className={`${css.type3} pb10`}xs={9} item={true}>
            <div className={css.box_item} onClick={() => showAppLandingPopup()}>
              <div className={css.text_box}>
                <span className={css.title}>포춘쿠키<AppLandingButton disableClick={true} /></span>
                <span className={css.description}>오늘의 당신에게<br/>필요한 한마디</span>
              </div>
              <img src="/images/fortune_main/cookie_icon.png"/>
            </div>
          </Grid>
        </Grid>
      </div>
      <Spacing direction={SpacingType.vertical} gap={16}/>
      <div>
        <Grid className={css.menu_box} container columns={18}>
          <Grid className={css.text_area} xs={18} item={true}>
            <Typography className={css.sub_text} component="p">
            타고난 운명
            </Typography>
            <Typography className={css.main_text} component="span">
            나에 대한 모든 것
            </Typography>
          </Grid>
          {
            birthMenuList.map(((value, index) => 
              <Grid className={css.type1} xs={6} item={true} key={index}>
                <div className={css.box_item} onClick={()=> {
                    if(representativeUserInfo) {
                      const convertedData:MainMenuListModel = {
                        page_code_name: value.pageName,
                        page_code: value.pageCode,
                        image: value.imagePath ?? ""
                      }

                      let toPage:JeomsinPagePath;
                      let option:NavigateOptions;

                      if (
                        value.pageCode === JeomsinPageInfo.D10050.pageCode 
                        || value.pageCode === JeomsinPageInfo.D10053.pageCode 
                      ) {
                        toPage = JeomsinPagePath.SajuAnalyzing;
                        option = {
                          state: {
                            menuInfo: convertedData,
                            userInfo: representativeUserInfo,
                            selectedDate: new Date(),
                          }
                        };
                      } else {
                        toPage = JeomsinPagePath.SajuMyeongsik;
                        option = { state: { menuInfo: convertedData }};
                      }

                      pageMove(toPage, option);
                    } else {
                      showMemberIntegratedWelcomeDialog(true);
                    }
                  }}
                >
                  {
                    (index === 0 && zodiacImageIcon) ? <img src={zodiacImageIcon} alt='menu'/>
                    : (index === 1 && astroImageIcon) ? <img src={astroImageIcon} alt='menu'/>
                    : <img src={value.imagePath} alt='menu'/>
                  }
                  <span>{value.pageName}</span>
                </div>
              </Grid>
            ))
          }
        </Grid>
      </div>
      <Spacing direction={SpacingType.vertical} gap={16}/>
      <div>
        <Grid className={css.menu_box} container columns={18}>
          <Grid className={css.text_area} xs={18} item={true}>
            <Typography className={css.sub_text} component="p">
            궁합 파헤치기
            </Typography>
            <Typography className={css.main_text} component="span">
            우리는 어떻게 될까?
            </Typography>
          </Grid>
          {
            loveMenuList.map(((value, index) => 
              <Grid className={css.type2} xs={18} item={true} key={index} onClick={()=> {
                  const convertedData:MainMenuListModel = {
                    page_code_name: value.pageName,
                    page_code: value.pageCode,
                    image: value.imagePath ?? ""
                  }
                  let toPage:JeomsinPagePath;

                  if(value.pageCode == JeomsinPageInfo.D10031.pageCode 
                    || value.pageCode == JeomsinPageInfo.D10137.pageCode) {
                      toPage = JeomsinPagePath.SajuMyeongsikCouple;
                  } else if (value.pageCode === JeomsinPageInfo.D10076.pageCode) {
                    toPage = JeomsinPagePath.AstroGunghap;
                  } else if (value.pageCode === JeomsinPageInfo.D10073.pageCode) {
                    toPage = JeomsinPagePath.BloodTypeGunghap;
                  } else if (value.pageCode === JeomsinPageInfo.D10059.pageCode) {
                    toPage = JeomsinPagePath.CelebGunghap;
                  } else {
                    toPage = JeomsinPagePath.SajuMyeongsik;
                  }
                  
                  pageMove(toPage, {state: { menuInfo:convertedData }});
              }}>
                <div className={css.box_item}>
                  <div className={css.img_box}>
                    <img src={value.imagePath} alt="menu"/>
                  </div>
                  <div className={css.text_box}>
                    <span className={css.title}>{value.pageName}</span>
                    <span className={css.description}>{value.desc}</span>
                  </div>
                </div>
              </Grid>
            ))
          }
        </Grid>
      </div>
      <Spacing direction={SpacingType.vertical} gap={16}/>
      <div>
        <Grid className={`${css.menu_box} p0`} container columns={18}>
          <div className={css.type4_padding_wrap}>
            <Grid className={css.text_area} xs={18} item={true}>
              <Typography className={css.sub_text} component="p">
              꿈해몽
              </Typography>
              <Typography className={css.main_text} component="span">
              그 꿈에 담긴 의미
              </Typography>
            </Grid>
            {
              dreamHaemongList?.map((value, index) => (
                <Grid key={index} className={css.type4} xs={18} item={true}>
                  <div className={css.box_item} onClick={() => {
                      setSelectedDreamData(value);
                      setShowHaemongPopup(true);
                  }}>
                    <span className={css.hash_tag}>{value.tagname}</span>
                    <span className={css.content}>{value.new_txt}</span>
                  </div>
                </Grid>
              ))
            }
          </div>
          <div className={css.type4_button_wrap}>
            <div 
              className={css.button} 
              onClick={() => {
                const convertedData:MainMenuListModel = {
                  page_code_name: JeomsinPageInfo.D10089.pageName,
                  page_code: JeomsinPageInfo.D10089.pageCode,
                  image: ""
                }

                pageMove(JeomsinPagePath.Dream, {state: { menuInfo:convertedData }});
            }}>
              다른 꿈해몽 보러 가기
            </div>
          </div>
        </Grid>
      </div>
      <CompanyInfo/>
      <DimLayer 
        // onLayerEnter={()=> setOnboardingPopupOpen(true)}
        onClose={()=> {setDimLayerOpen(false)}}
      >
      </DimLayer>
    </PageBody>
    <ModalPopup
      isOpen={showCalendar}
      type={ModalPopupType.CalendarSelect}
      onClose={(result: Date | undefined) => {
        if (result) {
          setSelectedDate(result);
          setShowCalendar(false);
          setTimeout(() => setModelPopup(true), 500);
        } else {
          setShowCalendar(false);
        }
      }}
    />
    <ModalPopup
      isOpen={modalPopup}
      type={ModalPopupType.Normal}
      title='지정일 운세보기'
      contents={`${selectedDate?.getFullYear()}년 ${selectedDate ? selectedDate?.getMonth() + 1 : 0}월 ${selectedDate?.getDate()}일 운세를 보시겠습니까?`}
      firstButtonTitle='확인'
      onClickFirstButton={() => {
        setDimLayerOpen(false);

        pageMove(JeomsinPagePath.SajuMyeongsik, {
          state: {
            menuInfo: selectedUnseMenu,
            selectedDate: selectedDate,
          }
        })
      }}
      secondButtonTitle='취소'
      onClickSecondButton={() => {
        setModelPopup(false);
        setDimLayerOpen(false);
      }}
      onClose={() => {
        setModelPopup(false);
        setDimLayerOpen(false);
      }}
    />
    <ModalPopup
      isOpen={isShowHaemongPopup}
      type={ModalPopupType.SharePopup}
      title={selectedDreamData?.new_txt}
      contents={selectedDreamData?.org_data}
      firstButtonTitle='공유하기'
      onClickFirstButton={async () => {
          if (representativeUserInfo === undefined) {
              return;
          }
  
          const category = "꿈해몽";
          if (
              SharableContents.map((each) => String(each)).includes("꿈해몽")
              && selectedDreamData
          ) {
              const sharable = await apiSetSNSContentV3(
                  representativeUserInfo,
                  category as SharableContent,
                  snsContentXMLDataDream(
                      category, 
                      selectedDreamData?.new_txt, 
                      selectedDreamData?.org_data
                  )
              );

              if (sharable) {
                await triggerCopyingShareURL(sharable, setModalPopupContent);
              }

              setShowHaemongPopup(false);
          }
      }}
      onClose={() => {
        setShowHaemongPopup(false);
      }}
    />
    </>
  );
}

export default Fortune;