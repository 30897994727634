import { useEffect, useState } from "react";
import { Spacing } from "@Views/common_component/spacer/spacing";
import { ModalPopupUIProps } from "./async_modal_popup";
import EachModalEachButton from "./each_modal_each_button";
import styles from "./each_modal_popup.module.css";

export function EachModalPopup({
  eachContent,
  contentIndex,
  removeModalPopupById,
  anchor,
  hidden,
}: {
  eachContent: {
    removalId: string;
    content: ModalPopupUIProps;
    resolver: (clickedButtonIndex: number) => void;
  };
  contentIndex: number;
  removeModalPopupById: (removalId: string) => void;
  anchor: "center" | "bottom";
  hidden: boolean;
}) {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (hidden === false) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [hidden]);

  return (
    <div
      className={styles.positionedContainer}
      style={{
        width: "clamp(320px, 100vw, 480px)",
        zIndex: 10_000 + contentIndex,
        justifyContent: anchor === "center" ? "center" : "flex-end",
      }}
    >
      <div
        className={styles.container}
        style={{
          width: anchor === "center" ? 'calc(100% - 75px)' : `100%`,
          margin: anchor === "center" ? "0 37.5px" : undefined,
          borderRadius: anchor === "center" ? "40px" : "40px 40px 0 0",
          opacity: anchor === "center" ? `${display ? 100 : 0}%` : "100%",
          transform:
            anchor === "bottom"
              ? `translateY(${display ? 0 : 100}%)`
              : "translateY(0%)",
        }}
      >
        {eachContent.content.topPadding && (
          <Spacing direction="vertical" gap={eachContent.content.topPadding} />
        )}
        {eachContent.content.showCloseButton === "topRight" && (
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.closeButton}
            onClick={() => {
              const isAccept =
                eachContent.content.decisionHandler?.(-1) ?? true;

              if (isAccept === false) {
                return;
              }

              eachContent.resolver(-1);
              removeModalPopupById(eachContent.removalId);
            }}
          >
            <path
              d="M1 1L13 13"
              stroke="#1F2024"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 13L13 1"
              stroke="#1F2024"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
        {eachContent.content.title !== undefined && (
          <span
            style={{
              padding:
                eachContent.content.showCloseButton === "topRight"
                  ? `${
                      eachContent.content.customVerticalGaps?.[0] ?? 60
                    }px 21.5px ${
                      eachContent.content.customVerticalGaps?.[1] ?? 30
                    }px 21.5px`
                  : `${
                      eachContent.content.customVerticalGaps?.[0] ?? 50
                    }px 21.5px ${
                      eachContent.content.customVerticalGaps?.[1] ?? 30
                    }px 21.5px`,
            }}
            className={styles.title}
          >
            {eachContent.content.title}
          </span>
        )}
        {eachContent.content.description && (
          <span
            className={styles.description}
            style={{
              padding: `0 24px ${
                eachContent.content.customVerticalGaps?.[2] ?? 30
              }px 24px`,
            }}
          >
            {eachContent.content.description}
          </span>
        )}
        <EachModalEachButton
          eachContent={eachContent}
          removeModalPopupById={removeModalPopupById}
        />
      </div>
    </div>
  );
}
