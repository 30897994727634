import { getDeviceId } from "@Config/jeomsin_application_info";
import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface Response {
  result:boolean,
  TTL:string
}

const apiUpdateMemberAlarmStatus = async (
  memberNo:string, 
  alarmType:string, 
  alarmYN:boolean, 
  alarmTime?:string
): Promise<Response | undefined> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.UpdateMemberAlarmStatus,
    params: {
      mem_no: memberNo,
      alarm_type: alarmType,
      yn_alarm: alarmYN ? "Y" : "N",
      device_id: getDeviceId(),
    },
  };

  if(alarmTime) {
    request.params["alarm_time"] = alarmTime;
  }

  try {
    const inferred = (await JeomsinRequest(request)) as Response;
    //TODO 알람 초기화 필요.. 설정화면
    // handlerAlarmSettingSuccess
    return inferred;
  } catch {
    return undefined;
  }
};

export { apiUpdateMemberAlarmStatus }
export type {
  Response as UpdateMemberAlarmStatusResponse,
}
