import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";
import { IdType, IdTypes } from "./api_combine_member_login";

/**
 * @throws {Error}
 */
const apiSendAuthMobileConfirm = async (
  phoneNumber: string,
  type: "join_member" | "change_member" | "change_mobile" | "find_id",
  confirmNum: string,
  isV2: boolean
): Promise<IdType | undefined> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: isV2
      ? JeomsinEndPoint.SendAuthMobileConfirmV2
      : JeomsinEndPoint.SendAuthMobileConfirm,
    params: {
      mem_mobile: phoneNumber,
      auth_type: type,
      rand_num: confirmNum,
    },
  };

  const response = (await JeomsinRequest(request)) as any;

  const rawIdType = response.id_type;
  const result = response.result;

  if (rawIdType === undefined && result === undefined) {
    throw new TypeError(response.msg ?? "인증 실패");
  } else if (result === false) {
    throw new TypeError(response.msg ?? "인증 실패");
  } else if (rawIdType === undefined) {
    return undefined;
  }

  const rawStringIdType = rawIdType as string;
  const rawNumberIdType = Number(rawStringIdType);

  switch (rawNumberIdType) {
    case 0:
      throw new TypeError(response.msg ?? "미지원 SNS");
    case 1:
      return IdTypes.Kakao;
    case 2:
      return IdTypes.Naver;
    case 3:
      throw new TypeError(response.msg ?? "미지원 SNS");
    case 4:
      return IdTypes.Apple;
    case 5:
      return IdTypes.Google;
  }

  throw new TypeError(response.msg ?? "미지원 SNS");
};

export default apiSendAuthMobileConfirm;
