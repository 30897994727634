import css from "./bottom_navigation.module.css"
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from "@mui/material/Box";
import React, { useEffect, useRef, useState } from "react";
import { ToastPopupManager } from "@Views/common_component/toast_popup/toast_popup_manager";
import { useNavigate } from "react-router";
import { JeomsinPagePath } from "@Config/jeomsin_page_path";
import BottomButton from "@Views/common_component/bottom_button/bottom_button";
import { VerticalDivider } from "@Views/common_component/vertical_divider/vertical_divider";
import { GuideType } from "@Views/main_tab/app_usage_guide/app_usage_guide_data";
import LottieLoader from "@Views/layer/lottie_loader/lottie_loader";
import AsyncCalendarPopupManager from "@Views/common_component/async_calendar_popup/async_calendar_popup";
import CoupangLineAdView from "@Views/web_ad/coupang_line_ad_view/coupang_line_ad_view";
import NetworkAdView from "@Views/web_ad/network_ad_view/network_ad_view";
import { NetworkAdTags } from "@Views/web_ad/web_ad_data";

export type BottomNavigationProps = {
    type : BottomNavigationType;
    title? : React.ReactElement | string | React.ReactElement[] | string[];
    selectedTab?: BottomTabMenu,
    boldedPart?: string;
    buttonDisable? : boolean;
    hide? : boolean;
    color? : string;
    onClick? : ((disabled: boolean) => void) | ((disabled: boolean) => void)[];
    isAdShow?: boolean,
}

// Page 추가 시 PageName 추가
enum BottomNavigationType {
    Tab,
    Button,
    TwoButton,
    None,
}

const JeomsinBottomNavigation:React.FC<BottomNavigationProps>  = ( 
    props
) => {
    return (
        <Box>
            {BottomType(
                props.type, 
                props.selectedTab, 
                props.title, 
                props.boldedPart,
                props.buttonDisable,
                props.hide, 
                props.color, 
                props.onClick,
                props.isAdShow,
            )}
        </Box>
    );
}

const space_text = "_";

export const BottomTabMenu = {
    JeomsinHome:{index:0, path:JeomsinPagePath.AppUsageGuide},
    FortuneHome:{index:1, path:JeomsinPagePath.Home},
    TaroHome:{index:2, path:JeomsinPagePath.TarotHome},
    CouonselHome:{index:3, path:JeomsinPagePath.CounselHome},
    JeomsinMallHome:{index:4, path:JeomsinPagePath.Home},
} as const;
export type BottomTabMenu = (typeof BottomTabMenu)[keyof typeof BottomTabMenu];

const BottomType = (
    type: BottomNavigationType,
    selectedTab?: BottomTabMenu,
    buttonTitle?: React.ReactElement | string | React.ReactElement[] | string[],
    boldedPart?: string,
    buttonDisable?: boolean,
    hide?:boolean,
    color?: string,
    onClick?: ((disabled: boolean) => void) | ((disabled: boolean) => void)[],
    isAdShow?: boolean,
) => {
    var returnValue: JSX.Element;
    const [selectedIndex, setSelectedIndex] = useState<number>(selectedTab?.index ?? BottomTabMenu.FortuneHome.index);
    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setSelectedIndex(selectedTab?.index ?? BottomTabMenu.FortuneHome.index);
    }, [selectedTab]);

    switch (type) {
        case BottomNavigationType.Tab:
            returnValue = (
                <BottomNavigation
                    showLabels
                    className={`${css.botttom_nav} ${(hide ? "none" : "")} ${css.bottom_nav_centered} ${css.pointer_event_enabled}`}
                    onChange={(event, value) => {
                        setSelectedIndex(value);

                        switch(value) {
                            case BottomTabMenu.JeomsinHome.index:
                                navigate(BottomTabMenu.JeomsinHome.path);
                                break;
                            case BottomTabMenu.FortuneHome.index:
                                navigate(BottomTabMenu.FortuneHome.path);
                                break;
                            case BottomTabMenu.TaroHome.index:
                                navigate(BottomTabMenu.TaroHome.path);
                                break;
                            case BottomTabMenu.CouonselHome.index:
                                navigate(BottomTabMenu.CouonselHome.path);
                                break;
                            case BottomTabMenu.JeomsinMallHome.index:
                                navigate(BottomTabMenu.JeomsinHome.path, {
                                    state: {
                                        guideType:GuideType.JeomsinMall
                                    }
                                });
                                break;
                            default:
                                break;
                        }
                    }}
                >
                    {/* style={{minWidth:0}}는 BottomNavigationAction에 걸려있는 MinWidth를 없애기 위해 할당 */}
                    <BottomNavigationAction label={<Box className={css.tab_name + " " + (selectedIndex === BottomTabMenu.JeomsinHome.index ? " "+css.on : "")}>점신</Box>} icon={<LottieLoader path={"/lottie/menu_fortune_report.json"} hieght={35}/>} value={BottomTabMenu.JeomsinHome.index} style={{minWidth:0}}/>
                    <BottomNavigationAction label={<Box className={css.tab_name + " " + (selectedIndex === BottomTabMenu.FortuneHome.index ? " "+css.on : "")}>운세</Box>} icon={<LottieLoader path={"/lottie/menu_fortune.json"} hieght={35}/>} value={BottomTabMenu.FortuneHome.index} style={{minWidth:0}}/>
                    <BottomNavigationAction label={<Box className={css.tab_name + " " + (selectedIndex === BottomTabMenu.TaroHome.index ? " "+css.on : "")}>타로</Box>} icon={<LottieLoader path={"/lottie/menu_taro_card_animation.json"} hieght={35}/>} value={BottomTabMenu.TaroHome.index} style={{minWidth:0}}/>
                    <BottomNavigationAction label={<Box className={css.tab_name + " " + (selectedIndex === BottomTabMenu.CouonselHome.index ? " "+css.on : "")}>상담</Box>} icon={<LottieLoader path={"/lottie/menu_counselling.json"} hieght={35}/>} value={BottomTabMenu.CouonselHome.index} style={{minWidth:0}}/>
                    <BottomNavigationAction label={<Box className={css.tab_name + " " + (selectedIndex === BottomTabMenu.JeomsinMallHome.index ? " "+css.on : "")}>점신몰</Box>} icon={<LottieLoader path={"/lottie/menu_bujeok.json"} hieght={35}/>} value={BottomTabMenu.JeomsinMallHome.index} style={{minWidth:0}}/>
                </BottomNavigation>
            );
            break;
        case BottomNavigationType.Button:
            returnValue = (
                <>
                {
                    isAdShow ? (
                        <NetworkAdView tag={NetworkAdTags.CommonLineBanner}/>
                    ) : <></>
                }
                <BottomButton 
                    className={`${hide ? "none" : ""} ${css.pointer_event_enabled}`} 
                    title={Array.isArray(buttonTitle) ? buttonTitle[0] : (buttonTitle ?? "")} 
                    boldedPart={boldedPart} 
                    disable={buttonDisable} 
                    color={color}
                    position={"block"}
                    onClick={Array.isArray(onClick) ? onClick[0] : onClick}  
                />
                {
                    isAdShow ? (
                        <CoupangLineAdView/>
                    ) : <></>
                }
                </>
            );
            break;
        case BottomNavigationType.TwoButton:
            returnValue = (
                <div className={css.button_wrap}>
                    <div 
                        className={css.button}
                        onClick={() => {
                            if(onClick) {
                                Array.isArray(onClick) ? onClick[0](buttonDisable ?? false) : onClick(buttonDisable ?? false)
                            }
                        }}
                    >{Array.isArray(buttonTitle) ? buttonTitle[0] : buttonTitle}</div>
                    <VerticalDivider height={20}/>
                    <div 
                        className={css.button}
                        onClick={() => {
                            if(onClick) {
                                Array.isArray(onClick) ? onClick[1](buttonDisable ?? false) : onClick(buttonDisable ?? false)
                            }
                        }}
                    >{Array.isArray(buttonTitle) ? buttonTitle[1] : buttonTitle}</div>
                </div>
            );
            break;
        case BottomNavigationType.None:
            returnValue = (
                <></>
            );
            break;
        default:
            returnValue = (<span></span>);
            break;

    }

    const fullWidth = containerRef.current?.getBoundingClientRect().width ?? 390;

    return <div id="bottom_bar" className={css.bottom_sticked} ref={containerRef}>
        <div className={css.stackable}>
            {returnValue}
            <div className={css.toast_popup_on_top}>
                <AsyncCalendarPopupManager width={fullWidth} />
            </div>
        </div>
    </div>
};

export { 
    JeomsinBottomNavigation,
    BottomNavigationType
}
