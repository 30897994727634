import { getDeviceId, getVersion } from "@Config/jeomsin_application_info";
import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface CheckCombineMemClassRepsonse {
  mem_class: "0" | "1" | "2";
  mobile_certify: "Y" | "N";
  msg?: string;
}

const apiCheckCombineMemClass = async (
  memberNumber: string
): Promise<CheckCombineMemClassRepsonse> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.CheckCombineMemClass,
    params: {
      app_ver: getVersion(),
      device_id: getDeviceId(),
      mem_no: memberNumber,
    },
  };

  const inferred = (await JeomsinRequest(
    request
  )) as CheckCombineMemClassRepsonse;
  return inferred;
};

export default apiCheckCombineMemClass;
