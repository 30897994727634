import { Spacing, SpacingType } from "@Views/common_component/spacer/spacing";
import FlexBox, { FlexAlignItemsType, FlexDirectionType, FlexJustifyContentType } from "@Views/layer/flex_box/flex_box";
import css from "./payment_complete_box.module.css";

type PaymentCompleteProps = {
    contents:any;
    onClick:()=>void;
}

const PaymentCompleteBox: React.FC<PaymentCompleteProps> = ({
  contents,
  onClick
}) => {
  return (
    <>
    {
      contents?.vBankAccountName ? (
        <>
        <FlexBox
          class={css.close_button_wrap}
          direction={FlexDirectionType.column}
          alignItems={FlexAlignItemsType.end}
          onClick={onClick}
        >
          <img src="images/app_bar_menu/close.png"/>
        </FlexBox>
        <FlexBox
          class={css.payment_complete_popup_container}
          direction={FlexDirectionType.column}
          alignItems={FlexAlignItemsType.center}
        >
            <span className={css.title}>결제완료</span>
            <Spacing direction={SpacingType.vertical} gap={8}/>
            <span className={`${css.normal_text} ${css.bold}`}>{contents?.vBankAccountName}님의 무통장 입금신청이 완료 되었습니다.</span>
            <Spacing direction={SpacingType.vertical} gap={10}/>
            <div className={css.contents}>
              <FlexBox
                  class={css.text_area}
                  direction={FlexDirectionType.column}
                  alignItems={FlexAlignItemsType.start}
                  justifyContent={FlexJustifyContentType.center}
                  gap={6}
              >
                  <span>입금은행 : {contents?.vBankName}</span>
                  <span>입금금액 : {new Intl.NumberFormat('ko-KR').format(contents?.Amt ?? 0)}</span>
                  <span>입금계좌 : {contents?.vBankNum}
                    <span className={css.copy_button} onClick={()=>{
                        if (navigator.clipboard !== undefined) {
                          try {
                            navigator.clipboard.writeText(contents?.vBankNum ?? "");
                          } catch (err) {

                          }
                        }
                      }}
                    >
                      복사하기
                    </span>
                  </span>
                  <span>입금자명 : {contents?.vBankAccountName}</span>
                  <span>입금기한 : {contents?.vBankExpDate}</span>
              </FlexBox>
            </div>
            <Spacing direction={SpacingType.vertical} gap={8}/>
            <FlexBox
                class={css.width100}
                direction={FlexDirectionType.column}
                alignItems={FlexAlignItemsType.start}
            >
              <span className={`${css.normal_text} ${css.bold}`}>충전한 코인은 1분~10분 내 충전이 완료됩니다.</span>
            </FlexBox>
            <Spacing direction={SpacingType.vertical} gap={34}/>
            <div className={css.button_container}>
              <div className={css.button} onClick={onClick}>
                확인
              </div>
            </div>
        </FlexBox>
        </>
      ) : (
        <>
        <FlexBox
          class={css.payment_complete_popup_container}
          direction={FlexDirectionType.column}
          alignItems={FlexAlignItemsType.center}
        >
            <span className={css.big_title}>결제</span>
            <span>{contents?.msg}</span>
            <Spacing direction={SpacingType.vertical} gap={34}/>
            <div className={css.button_container}>
              <div className={css.button} onClick={onClick}>
                확인
              </div>
            </div>
        </FlexBox>
        </>
      )
    }
    </>
  );
};

export { PaymentCompleteBox };
export type {
  PaymentCompleteProps
};

