import { useEffect, useState } from "react";

const useScriptModuleLoader = (src: string): { loaded: boolean; error: boolean } => {
  const [state, setState] = useState({ loaded: false, error: false });

  useEffect(() => {
    const isAddedScript = document.querySelector(`script[src="${src}"]`);
    if (isAddedScript) {
      return;
    } 
    
    const script = document.createElement("script");
    script.src = src;
    script.async = true;

    const onLoad = () => setState({ loaded: true, error: false });
    const onError = () => setState({ loaded: false, error: true });

    script.addEventListener("load", onLoad);
    script.addEventListener("error", onError);

    document.body.appendChild(script);

    return () => {
      // Clean up when the component unmounts
      script.removeEventListener("load", onLoad);
      script.removeEventListener("error", onError);
      document.body.removeChild(script);
    };
  }, [src]);

  return state;
};

export default useScriptModuleLoader;