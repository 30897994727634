import { BottomTabMenu } from "@Views/layer/bottom_navigation/bottom_navigation";

type AppGuideItem = {
    title:string;
    subText:string;
    image:string;
}

const JeomsinGuide:AppGuideItem[] = [
    {title:"오늘의 운세 분석 완료!", subText:"매일매일 행복할 수 있도록 당신만의 맞춤형\n운세 보고서를 제공해드려요.", image:"web_only/app_usage_guide/unse_report.webp"},
    {title:"함께라서 더 좋은날!", subText:"인맥보고서를 통해 우리의 운이\n조화로운 날을 알아보세요.", image:"web_only/app_usage_guide/personal_connection_report.webp"},
    {title:"데일리 맞춤 운세", subText:"행운보고서와 함께라면 오늘의 고민 끝!\n코디부터 요리, 부동산, 골프까지", image:"web_only/app_usage_guide/lucky_report.webp"},
    {title:"더 편리한 전화 상담!", subText:"고민으로 잠 못이룬다면 점신이 검증한\n400여명의 멘토를 지금 만나보세요.", image:"web_only/app_usage_guide/counsel.webp"},
]

const TaroGuide:AppGuideItem[] = [
    {title:"하루, 한 달 운명을 보는\n일상 타로에서 이색 타로까지!", subText:"신년운세부터 연애, 취업/학업\n그리고 반려동물의 속마음까지 들여다봐요!", image:"web_only/app_usage_guide/taro.webp"},
]

const JeomsinMallGuide:AppGuideItem[] = [
    {title:"2025 행운을 더해줄\n행운 아이템 집합소!", subText:"점신몰에서 당신의 일상에 특별한 에너지를\n더해줄 아이템을 구매해 보세요!", image:"web_only/app_usage_guide/jeomsin_mall.webp"},
]

export const GuideType = {
    JeomsinApp : "JeomsinApp",
    Taro : "Taro",
    JeomsinMall : "JeomsinMall",
}
export type GuideType = (typeof GuideType)[keyof typeof GuideType];

export const getGuideItme = (guideType: GuideType) => {
    let result;
    switch(guideType) {
        case GuideType.Taro:
            result = TaroGuide;
            break;
        case GuideType.JeomsinMall:
            result = JeomsinMallGuide;
            break;
        case GuideType.JeomsinApp:
        default:
            result = JeomsinGuide;
            break;
    }

    return result;
}

export const getGuideBottomIndex = (guideType: GuideType) => {
    let result;
    switch(guideType) {
        case GuideType.Taro:
            result = BottomTabMenu.TaroHome;
            break;
        case GuideType.JeomsinMall:
            result = BottomTabMenu.JeomsinMallHome;
            break;
        case GuideType.JeomsinApp:
        default:
            result = BottomTabMenu.JeomsinHome;
            break;
    }

    return result;
}