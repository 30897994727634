import { FC } from "react";
import css from "./vertical_divider.module.css";
import { getJeomsinColor, JeomsinColors } from "@Config/jeomsin_colors";

interface VerticalDividerProps {
    height:number;
    color?: string;
}

export const VerticalDivider: FC<VerticalDividerProps> = (props) => {
  return (
    <div className={css.divider_container}>
        <div className={css.divider} style={{ height: props.height, backgroundColor: props.color ?? getJeomsinColor(JeomsinColors.Line02)}}></div>
    </div>
  );
};
