import { getJeomsinColor, JeomsinColors } from "@Config/jeomsin_colors";
import css from "./company_info.module.css";
import React from "react";

interface CompanyInfoProps {
    padding?:number|number[]
    paddingTop?:number
    paddingRight?:number
    paddingBottom?:number
    paddingLeft?:number
    backgroundColor?:JeomsinColors
}

export const CompanyInfo: React.FC<CompanyInfoProps> = (props) => {
    let paddingValue = getPaddingOption(props);

    return (
        <div className={css.company_info_container} style={{
            padding: `${paddingValue.Top}px ${paddingValue.Right}px ${paddingValue.Bottom}px ${paddingValue.Left}px`,
            backgroundColor: props.backgroundColor ? getJeomsinColor(props.backgroundColor) : 'transparent'
        }}>
            <div>
            <div className={css.company_info_title}>(주) 테크랩스</div>
            <div className={css.company_info_detail}>
                <span>대표이사 : 유지헌</span>
                <span>서울특별시 강남구 강남대로 84길 13, 5~9층</span>
                <span>(역삼동 825-26, 강남역KR타워)</span>
                <span>사업자 등록번호 : 144-81-15705</span>
                <span>통신판매업 신고 : 제2018-서울강남-03394호</span>
                <span>
                대표전화 :{" "}
                <span 
                    className={css.company_info_telephone}
                    onClick={() => {
                        window.location.href = "tel:1811-9329";
                    }}
                >
                    1811-9329
                </span>
                </span>
            </div>
            </div>
        </div>
    );
}

const getPaddingOption = (props:CompanyInfoProps) => {
    //default
    var paddingValue = {
        Top : props.paddingTop ?? 40,
        Right: props.paddingRight ?? 0,
        Bottom: props.paddingBottom ?? 40,
        Left: props.paddingLeft ?? 0
    }
    if(Array.isArray(props.padding)) {
        props.padding.forEach((value, index) => {

            switch(index) {
                case 0:
                    paddingValue.Top = value;
                    break
                case 1:
                    paddingValue.Top = value;
                    paddingValue.Right = value;
                    break
                case 2:
                    paddingValue.Top = value;
                    paddingValue.Right = value;
                    paddingValue.Bottom = value;
                    break
                case 3:
                    paddingValue.Top = value;
                    paddingValue.Right = value;
                    paddingValue.Bottom = value;
                    paddingValue.Left = value;
                    break
            }
        });
    } else if(typeof props.padding === "number") {
        paddingValue.Top = props.padding;
        paddingValue.Right = props.padding;
        paddingValue.Bottom = props.padding;
        paddingValue.Left = props.padding;
    }

    return paddingValue;
}