import CookieKeys from "@Storage/cookie_keys"
import CookieManager from "@Storage/cookie_manager";
import { v4 as uuidv4 } from 'uuid';


export let JeomsinFirstVisit = (performance?.navigation?.type === 0); //0 일반 진입, 1 새로고침

export const checktJeomsinFirstVisit = () => {
  const isFrist = JeomsinFirstVisit;
  JeomsinFirstVisit = false;
  return isFrist;
}

export const JeomsinPlatformType = {
  PC: "web_pc",
  Android: "web_android",
  iOS: "web_ios"
}
export type JeomsinPlatformType = (typeof JeomsinPlatformType)[keyof typeof JeomsinPlatformType];

export const getAppType = () => {
  return "0"; //0 무료, 1 유료
}

export const getVersion = () => {
  return "8.3.1"
}

export const getStoreType = () => {
  return "ios"
}

let osType: string;
export const getPlatformType = () => {
  if(!osType) {
    if(/android/i.test(navigator.userAgent.toLocaleLowerCase())) {
      osType = JeomsinPlatformType.Android;
    } else if(/ipad|iphone|ipod/i.test(navigator.userAgent.toLocaleLowerCase())) {
      osType = JeomsinPlatformType.iOS;
    } else {
      osType = JeomsinPlatformType.PC;
    }
  }

  return osType;
}

let osTypeForJeomsinMall: string;
export const getPlatformTypeForJeomsinMoall = () => {
  if(!osType) {
    if(/android/i.test(navigator.userAgent.toLocaleLowerCase())) {
      osTypeForJeomsinMall = "MOBILE_WEB";
    } else if(/ipad|iphone|ipod/i.test(navigator.userAgent.toLocaleLowerCase())) {
      osTypeForJeomsinMall = "MOBILE_WEB";
    } else {
      osTypeForJeomsinMall = "PC";
    }
  }

  return osTypeForJeomsinMall;
}

export const getDeviceId = () => {
    let deviceId = CookieManager.GetString(CookieKeys.deviceId);

    // app config를 부르기 앞서 만약, 설정된 device id가 없다면 local storage에 설정 먼저 합니다.
    if (deviceId === undefined) {
      deviceId = uuidv4();
      CookieManager.SetString(CookieKeys.deviceId, deviceId);
    }

    return deviceId
}