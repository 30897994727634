/** @jsxImportSource @emotion/react */

import { ReactElement } from "react";
import { create } from "zustand";
import AsyncPageEachPage from "./async_page_each_page";

export default function AsyncPageManager() {
  const { pageContents } = useAsyncPageStoreInternal();

  return (
    <>
      {pageContents.map((eachContent, contentIndex) => (
        <AsyncPageEachPage
          key={eachContent.removalId}
          contentIndex={contentIndex}
          content={eachContent}
        />
      ))}
    </>
  );
}

interface ZAsyncPageInfo {
  pageContents: {
    removalId: string;
    isHidden: boolean;
    isAnimated: boolean;
    isScreenFilled: boolean;
    element: ReactElement;
    resolver: (returned: any) => void;
  }[];
  setPageContent: (
    removalId: string,
    element: ReactElement,
    isAnimated?: boolean,
    isScreenFilled?: boolean
  ) => Promise<any>;
  removePageByIdAndReturn: (removalId: string, returned: any) => void;
}

export const useAsyncPageStoreInternal = create<ZAsyncPageInfo>((set, get) => ({
  pageContents: [],
  setPageContent: (removalId, element, isAnimated, isScreenFilled) => {
    const promise = new Promise((resolve: (returned: any) => void) => {
      set((state) => ({
        ...state,
        pageContents: [
          ...state.pageContents,
          {
            removalId,
            isHidden: false,
            isAnimated: isAnimated ?? true,
            isScreenFilled: isScreenFilled ?? false,
            element,
            resolver: resolve,
          },
        ],
      }));
    });

    return promise;
  },
  removePageByIdAndReturn: (removalId, returned) => {
    let found = get().pageContents.find((each) => each.removalId === removalId);

    if (found === undefined || found.isHidden === true) {
      return;
    }

    set((state) => {
      let newState = {
        ...state,
      };

      let found = newState.pageContents.find(
        (each) => each.removalId === removalId
      );

      if (found) {
        found.isHidden = true;
        found.resolver(returned);
      }

      return newState;
    });

    setTimeout(() => {
      set((state) => {
        const filtered = state.pageContents.filter(
          (each) => each.removalId !== removalId
        );

        return {
          ...state,
          pageContents: filtered,
        };
      });
    }, 500);
  },
}));
