import { SimplePayPwCheckServerModel } from "@Models/simple_pay_model";
import {
  JeomsinDomain,
  JeomsinMethod,
  RequestProps,
  ShopByEndPoint,
  ShopByRequest
} from "@Network/network";

interface Response {
  passwordChangeRequired:boolean,
  dormantMemberResponse:DormantMemberResponseModel,
  expireIn:number,
  daysFromLastPasswordChange:number,
  accessToken:string,
  result: boolean
}

interface DormantMemberResponseModel {
  memberName:string,
  mobileNo:string,
  email:string,
}

const apiShopByLogin = async (
  memberId: string,
  memberPassword: string,
): Promise<Response | undefined> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.ShopBy,
    endpoint: ShopByEndPoint.Login,
    params: {
      memberId:memberId,
      password: memberPassword,
      keepLogin: true
    },
  };

  try {
    const inferred = (await ShopByRequest(request)) as Response;
    return inferred;
  } catch {
    return undefined;
  }
};

export { apiShopByLogin };
export type {
  Response as ShopByLoginResponse
};

