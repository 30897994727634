/** @jsxImportSource @emotion/react */
import { useModalPopup } from "@Views/common_component/async_modal_popup/async_modal_popup";
import styles from "./app_landing_button.module.css";
import CloseIcon from "@Views/common_component/icons/close_icon";
import { goToAppStore } from "@Util/link_app_store";
import { css } from "@emotion/react";
import { QRCodeCanvas } from "qrcode.react";
import { Spacing, SpacingType } from "@Views/common_component/spacer/spacing";

export const useAppLandingPopup = () => {
  const { setModalPopupContent } = useModalPopup();
  const Choices = ["앱에서 확인하기", "다음에 확인할게요."] as const;
  const cancelButton = (
    <div className={styles.choice1Style}>
      <CloseIcon
        color="var(--SubText01)"
        className={styles.choice1CloseIconStyle}
      />
      <span className={styles.choice1TextStyle}>{Choices[1]}</span>
    </div>
  );

  const showAppLandingPopup = async () => {
      const choice = await setModalPopupContent({
        title: "앱에서만 확인 가능해요!",
        description: (
          <span className={styles.popupDescription}>
            {
              "선택하신 서비스는 앱에서만 확인 가능해요.\n지금 설치하고 더 많은 행운을 만나보세요!"
            }
          </span>
        ),
        buttonTitles: [
          <div className={styles.choice0Style}>{Choices[0]}</div>,
          cancelButton,
        ],
        buttonGap: 12,
        customVerticalGaps: [50, 24, 24, 30],
      });

      if (choice === -1) {
        return;
      }

      const userChoice = Choices[choice];

      if (userChoice === "다음에 확인할게요.") {
        return;
      }

      const { didFail } = goToAppStore();

      if (didFail === false) {
        return;
      }

      showQRCodePopup();
    };
    
    const showQRCodePopup = async () => {
      await setModalPopupContent({
        title: (
          <span className={styles.qrTitle}>
            {"QR스캔으로 간편하게\n 받아보세요!"}
          </span>
        ),
        description: (
          <>
          <div>해당 서비스는 모바일에서 확인 가능해요.</div>
          <Spacing direction={SpacingType.vertical} gap={24}/>
          <div className={styles.qrContainer}>
            <QRCodeCanvas size={144} value="https://onelink.to/4npf9k" />
          </div>
          </>
        ),
        buttonTitles: [
          cancelButton,
        ],
        buttonGap: 24,
        customVerticalGaps: [50, 15, 24, 30],
        decisionHandler: (clickedIndex) => {
          return true;
        },
      });
    };

  return {
    showAppLandingPopup,
    showQRCodePopup,
  }
}

export default function AppLandingButton({
  disableClick,
  type = "forMainPage",
}: {
  disableClick?: boolean;
  type?: "forMainPage" | "forSubPage";
}) {
  const { showAppLandingPopup } = useAppLandingPopup();

  return (
    <div
      className={styles.container}
      css={css`
        border-radius: 4px 4px 4px ${type === "forMainPage" ? 0 : 4}px;
      `}
      onClick={async () => {
        if (disableClick) {
          return;
        }

        await showAppLandingPopup();
      }}
    >
      APP 전용
    </div>
  );
}
