import {
  JeomsinRequest,
  JeomsinDomain,
  JeomsinEndPoint,
  RequestProps,
  JeomsinMethod,
} from "@Network/network";

interface Response {
  field_list: CounselorFieldV3[],
  result:boolean,
  TTL:string
}

interface CounselorFieldV3 {
  key:string,
  field_name:string,
  yn_unse:string,
  yn_sim:string
}

const apiGetFieldV3 = async (): Promise<Response | undefined> => {
  const request: RequestProps = {
    method: JeomsinMethod.Post,
    domain: JeomsinDomain.Jeomsin,
    endpoint: JeomsinEndPoint.GetFieldV3,
    params: {},
  };

  try {
    const inferred = (await JeomsinRequest(request)) as Response;

    return inferred;
  } catch {
    return undefined;
  }
};

export { apiGetFieldV3 }
export type {
  Response as GetFieldV3Response,
  CounselorFieldV3
}
