import { CounselorField } from "@DataDefine/counselor_data";

export type CounselorMenu = {
    image:string;
    title:string;
    type:string;
    isRegular:boolean;
}

export const CounselorDefaultField:CounselorField[] = [
  CounselorField.All,
  CounselorField.Unse,
  CounselorField.Gunghap,
  CounselorField.Employment,
  CounselorField.Business,
  CounselorField.Dream,
  CounselorField.ChooseDay,
  CounselorField.Health,
  CounselorField.Family,
  CounselorField.Academic,
  CounselorField.Emotion,
  CounselorField.SelfUnderstanding,
  CounselorField.Feeding,
  CounselorField.MarriedCouple,
  CounselorField.WorkPlace,
  CounselorField.Relationships,
  CounselorField.Gender,
  CounselorField.Children,
  CounselorField.Romance,
  CounselorField.Toxicosis,
  CounselorField.Riches,
  CounselorField.FengShui,
];

export const CounselorSimliField:CounselorField[] = [
  CounselorField.All,
  CounselorField.Unse,
  CounselorField.Gunghap,
  CounselorField.Romance,
  CounselorField.Employment,
  CounselorField.Academic,
  CounselorField.Business,
  CounselorField.WorkPlace,
  CounselorField.Dream,
  CounselorField.ChooseDay,
  CounselorField.Health,
  CounselorField.Family,
  CounselorField.Riches,
  CounselorField.Relationships,
  CounselorField.MarriedCouple,
  CounselorField.Children,
  CounselorField.Gender,
  CounselorField.FengShui,
]

export const CounselorOtherField:CounselorField[] = [
  CounselorField.All,
  CounselorField.Emotion,
  CounselorField.SelfUnderstanding,
  CounselorField.Feeding,
  CounselorField.MarriedCouple,
  CounselorField.WorkPlace,
  CounselorField.Relationships,
  CounselorField.Health,
  CounselorField.Gender,
  CounselorField.Children,
  CounselorField.Romance,
  CounselorField.Academic,
  CounselorField.Toxicosis,
  CounselorField.Employment,
  CounselorField.Family,
]