import { RecommendUnseListModel } from "@Network/request/api_get_mz_main_list";
import FlexBox, { FlexDirectionType, FlexJustifyContentType } from "@Views/layer/flex_box/flex_box";
import { FC } from "react";
import css from "./reommend_unse_view.module.css";
import { useNavigate } from "react-router";
import { GetPageInfo, GetPagePath } from "@DataDefine/page_code";
import usePageLanding, { LandingType } from "@Hooks/page_landing_util";
import { useFortuneStore } from "../../use_fortune_store";

interface RecommendUnseViewProps {
    className?:string
    order:number,
    maxCount:number,
    age:number,
    gender:string,
    data:RecommendUnseListModel
}

const RecommendUnseView: FC<RecommendUnseViewProps> = ({
    className,
    order,
    maxCount,
    age,
    gender,
    data
}) => {
    const { landingPage } = usePageLanding();
    const { saveScrollYPosition } = useFortuneStore();

    return (
        <FlexBox
            class={`${css.recommend_unse_view_container} ${className}`}
            direction={FlexDirectionType.column}
            justifyContent={FlexJustifyContentType.spaceBetween}
            onClick={() => {
                saveScrollYPosition();
                landingPage({landingType:LandingType.AllMenu, pageInfo:GetPageInfo(data.page_code)});
            }}
            >
            <FlexBox
                class={css.top_area}
                direction={FlexDirectionType.column}
            >
                <span className={css.sub_text}>맞춤 운세</span>
                <FlexBox
                    direction={FlexDirectionType.row}
                    alignItems={FlexJustifyContentType.center}
                >
                    <span className={css.main_text}>
                        <span className={css.highlight}>{age}대 {gender}</span>에게 필요한
                    </span>
                    <span className={css.count_text}>{order}/{maxCount}</span>
                </FlexBox>
            </FlexBox>
            <FlexBox
                class={css.bottom_area}
                direction={FlexDirectionType.column}
            >
                <span>{data.title}</span>
            </FlexBox>
        </FlexBox>
    )
}

export default RecommendUnseView;