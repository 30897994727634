import * as React from "react";
import {
  DateField,
  LocalizationProvider,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ko";
import css from "./calendar_selection.module.css";
import { useEffect } from "react";
import { createPortal } from "react-dom";

interface CalendarSelectionProps {
  postSelectionAction: (result: Date | undefined) => void;
  targetDate?:string;
}

const CalandarAttribution = {
  lang: "ko",
  dateFormat: "M월 D일 (ddd)",
  inputFormat: "YYYY.M.D.",
  calanderTitle: "날짜 선택",
  textInputTitle: "날짜 입력",
  okButtonText: "확인",
  cancleButtonText: "취소",
  headerFormat: "YYYY년 M월",
}

const CalendarSelection = ({ 
  postSelectionAction,
  targetDate
}: CalendarSelectionProps) => {
  const [date, setDate] = React.useState<Dayjs | null>(targetDate ? dayjs(targetDate) : dayjs().add(2, "day")); //Today + 2
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isStaticCalendar, setStaticCalendar] = React.useState<boolean>(true);
  
  const okAction = () => {
    postSelectionAction(date?.toDate() ?? new Date());
  };

  const cancelAction = () => {
    postSelectionAction(undefined);
  };

  useEffect(() => {
    if (containerRef.current) {
      const toolbarContent = containerRef.current.querySelector(
        ".MuiPickersToolbar-content"
      ) as HTMLDivElement;

      if (toolbarContent) {
        const existingImage = toolbarContent.querySelector(
          `.${css.pencil_view_container}`
        );
        if (existingImage !== null) {
          return;
        }

        const pencilViewContainer = document.createElement("div");
        pencilViewContainer.className = css.pencil_view_container;
        pencilViewContainer.onclick = () => {
          setStaticCalendar((current) => {
            return !current;
          });
        };

        const pencilView = document.createElement("img");
        pencilView.className = css.pencil_view;
        pencilView.src = "img/edit_pencil.svg";
        pencilView.alt = "Edit";

        pencilViewContainer.appendChild(pencilView);
        toolbarContent.appendChild(pencilViewContainer);
      }

      const overline = containerRef.current.querySelector('.MuiTypography-overline') as HTMLSpanElement;
      if(overline) {
        overline!.textContent = CalandarAttribution.calanderTitle;
      }
    }
  });

  return (
    <LocalizationProvider 
      dateAdapter={AdapterDayjs} 
      adapterLocale={CalandarAttribution.lang}
      dateFormats={{
        normalDate: CalandarAttribution.dateFormat
      }}
      localeText={{
        okButtonLabel:CalandarAttribution.okButtonText,
        cancelButtonLabel:CalandarAttribution.cancleButtonText
      }}
    >
      <div
        ref={containerRef}
        className={`${css.custom_date_picker} ${
          isStaticCalendar ? css.static_calendar_sizing : css.text_field_sizing
        }`}
      >
        {isStaticCalendar ? (
          <StaticDatePicker
            sx={{
              ".MuiPickersToolbar-root": {
                backgroundColor: "#2196F3",
                height: "100px",
                color: "white",
              },
              ".MuiTypography-overline": {
                color: "white",
              },
              ".MuiDatePickerToolbar-title": {
                color: "white",
              },
              ".MuiPickersToolbar-content": {
                alignItems: "flex-end",
              },
              ".Mui-selected": {
                backgroundColor: "#2196F3 !important",
              },
              ".MuiPickersDay-today": {
                color: "#2196F3",
                borderRadius: "1000px",
                borderWidth: "1px",
                borderColor: "#2196F3",
                border: "1px solid rgb(33, 150, 243) !important",
              },
              ".MuiPickersCalendarHeader-label": {
                opacity: "1 !important",
                transition: "none !important"
              },
            }}
            value={date}
            onChange={(selected) => setDate(selected)}
            slotProps={{
              actionBar: {
                actions: ["cancel", "accept"],
                onAccept: () => {
                  okAction();
                },
                onCancel: () => {
                  cancelAction();
                },
              },
              calendarHeader: {
                format: CalandarAttribution.headerFormat,
              }
            }}
          />
        ) : (
          <>
            <div className={`${css.header_container}`}>
              <span className={css.select_date_title}>{CalandarAttribution.calanderTitle}</span>
              <div className={css.header_title_container}>
                <span className={css.header_title}>{date?.locale(CalandarAttribution.lang).format(CalandarAttribution.dateFormat)}</span>
                <div className={css.pencil_view_container}>
                  <img
                    className={css.pencil_view}
                    src="img/calendar.svg"
                    alt="Edit"
                    onClick={() => {
                      setStaticCalendar((current) => {
                        return !current;
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={css.custom_date_field_container}>
              <DateField
                className={css.custom_date_field}
                label={CalandarAttribution.textInputTitle}
                value={date}
                format={CalandarAttribution.inputFormat}
                formatDensity="dense"
                onChange={(selected) => setDate(selected)}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderStyle: "none",
                    borderBottom: "2px solid",
                  },
                  ".MuiOutlinedInput-root": {
                    borderRadius: "0px !important",
                  },
                  ".MuiOutlinedInput-input": {
                    padding: "0px 0px 8px 0px !important",
                  },
                  ".MuiInputLabel-root": {
                    fontSize: "0.75rem !important",
                    WebkitTransform: "none !important",
                    msTransform: "none !important",
                    transform: "translateY(-100%) !important",
                  },
                }}
              />
            </div>
            <div className={css.action_buttons_container}>
              <div className={css.action_buttons_container_padding}>
                <button
                  className={css.action_button_text}
                  type="button"
                  onClick={(_) => {
                    cancelAction();
                  }}
                >
                  {CalandarAttribution.cancleButtonText}
                </button>
                <button
                  className={css.action_button_text}
                  type="button"
                  onClick={(_) => {
                    okAction();
                  }}
                >
                  {CalandarAttribution.okButtonText}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </LocalizationProvider>
    );
};

export default CalendarSelection;
