import CookieKeys from "./cookie_keys";

export type CookieKey = keyof typeof CookieKeys;

const CookieManager = {
  SetNumber: setNumber,
  GetNumber: getNumber,
  SetBoolean: setBoolean,
  GetBoolean: getBoolean,
  SetString: setString,
  GetString: getString,
  SetJSON: setJSON,
  GetJSON: getJSON,
  RemoveKeyValue: removeKeyValue,
};

function isLocalStorageAvailable(): boolean {
  var checker = "sm_checker_local_storage";
  try {
    localStorage.setItem(checker, checker);
    localStorage.removeItem(checker);
    return true;
  } catch (e) {
    return false;
  }
}

function setNumber(key: string, value: number) {
  if (isLocalStorageAvailable() === false) {
    return;
  }

  localStorage.setItem(key, `${value}`);
}

function getNumber(key: string): number | undefined {
  if (isLocalStorageAvailable() === false) {
    return;
  }

  const result = localStorage.getItem(key);

  if (result) {
    return Number(result);
  } else {
    return undefined;
  }
}

function setBoolean(key: string, value: boolean) {
  if (isLocalStorageAvailable() === false) {
    return;
  }

  if (value) {
    localStorage.setItem(key, "true");
  } else {
    localStorage.setItem(key, "false");
  }
}

function getBoolean(key: string): boolean | undefined {
  if (isLocalStorageAvailable() === false) {
    return;
  }

  const result = localStorage.getItem(key);

  if (result === "true") {
    return true;
  } else {
    return false;
  }
}

function setString(key: string, value: string) {
  if (isLocalStorageAvailable() === false) {
    return;
  }

  localStorage.setItem(key, value);
}

function getString(key: string): string | undefined {
  if (isLocalStorageAvailable() === false) {
    return undefined;
  }
  const nullable = localStorage.getItem(key);
  if (nullable) {
    return nullable;
  } else {
    return undefined;
  }
}

function setJSON(key: string, jsonValue: object) {
  if (isLocalStorageAvailable() === false) {
    return;
  }
  localStorage.setItem(key, JSON.stringify(jsonValue));
}

function getJSON(key: string): any | undefined {
  if (isLocalStorageAvailable() === false) {
    return undefined;
  }
  const rawString = localStorage.getItem(key);
  if (rawString) {
    try {
      return JSON.parse(rawString);
    } catch (e) {
      console.error("Error parsing JSON from localStorage", e);
      return undefined;
    }
  } else {
    return undefined;
  }
}

function removeKeyValue(key: string) {
  if (isLocalStorageAvailable() === false) {
    return;
  }
  localStorage.removeItem(key);
}

export default CookieManager;
