import { getJeomsinColor, JeomsinColors } from "@Config/jeomsin_colors";
import { SelectedScaleOption } from "@Config/jeomsin_scale";
import { CSSProperties, FC } from "react";

export const SpacingType = {
  horizontal:"horizontal",
  vertical:"vertical",
} as const
export type SpacingType = (typeof SpacingType)[keyof typeof SpacingType];

type SpacingProps = {
  direction:SpacingType;
  gap:number;
  color?: JeomsinColors;
  className?: string;
};

const Spacing:FC<SpacingProps> = ( props ) => {
  const gap = props.gap;
  
  return (       
      props.direction == SpacingType.horizontal ? (
        <div
          className={props.className}
          style={{
            minWidth:gap + "px",
            maxWidth:gap + "px",
            height: "1px",
            backgroundColor: props.color ? getJeomsinColor(props.color) : undefined,
          }}
        > </div>
      ) : (
        <div
          className={props.className}
          style={{
            width: "100%",
            height: gap + "px",
            backgroundColor: props.color ? getJeomsinColor(props.color) : undefined,
          }}
        />
      )
  );
}

export { Spacing };
